import {action, computed} from 'mobx';
import {deserialize} from 'serializr';
import {computedFn} from 'mobx-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {
  getCoinDecimalPrecision,
  getCoinOrderIndex,
} from '@youtoken/ui.coin-utils';
import {createResource} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Wallet, WalletsResponse} from './WalletsResponse';
import {assign, orderBy} from 'lodash';

export {
  MainCurrencyVariants,
  Wallet,
  PayoutObj,
  Version,
  WalletPayout,
  WalletPayoutEarn,
  WalletReferralReward,
  WalletsResponse,
} from './WalletsResponse';

export class WalletsResource extends createResource({
  skipRefreshOnVisible: false,
  getKey: () => 'wallets',
  getData: () => {
    return TRANSPORT.API.get('/v3/balance').then(res => {
      const data = deserialize(WalletsResponse, res.data);

      return assign(data, {
        wallets: filterWallets(data.wallets),
      }) as WalletsResponse;
    });
  },
}) {
  @computed
  public get isWalletsListCreating() {
    return this.walletsList.length === 0;
  }

  @computed
  get totalCapital() {
    return this.data.totalCapital;
  }

  @computed
  get payout() {
    return this.data.payout;
  }

  /**
   * wallets in default order
   */
  @computed
  get walletsList() {
    return this.data.wallets;
  }

  // NOTE: Includes only wallets which a user already opened regardless of their balance
  @computed
  get openedWalletsListSortedByEquivalent(): Wallet[] {
    const {showedWalletTickers, hiddenWalletTickers} =
      AuthMeResource.getInstance({});

    const openedWallets = this.walletsList.filter(wallet => {
      return (
        showedWalletTickers.includes(wallet.ticker) ||
        hiddenWalletTickers.includes(wallet.ticker)
      );
    });

    return openedWallets.sort((a, b) => {
      return b.equivalent.minus(a.equivalent).toNumber();
    });
  }

  @computed
  get walletsListWithAmountOrder() {
    return orderBy(
      this.walletsList,
      [
        wallet => {
          return wallet.amount.gt(0);
        },
      ],
      ['desc']
    ) as Wallet[];
  }

  @computed
  get walletsListOrderedByEquivalentAmount() {
    return this.sortWalletsByEquivalentAmount(this.walletsList);
  }

  @computed
  get fiatWallets() {
    return this.walletsList.filter(wallet => wallet.isFiat);
  }

  @computed
  get stableWallets() {
    return this.walletsList.filter(wallet =>
      wallet.tags.includes('stableCoin')
    );
  }

  @computed
  get cryptoWallets() {
    return this.walletsList.filter(
      wallet =>
        wallet.tags.includes('crypto') && !wallet.tags.includes('stableCoin')
    );
  }

  @computed
  get cryptoAndStableWallets() {
    return this.walletsList.filter(wallet => wallet.tags.includes('crypto'));
  }

  @computed
  get bonusesWallet() {
    return this.walletsList.find(wallet => wallet.tags.includes('bonus'));
  }

  @computed
  get marketEnabledWallets() {
    return this.walletsListWithAmountOrder.filter(wallet => {
      return wallet.marketEnabled;
    });
  }

  @computed
  get loanEnabledWallets() {
    return orderBy(
      this.walletsList.filter(wallet => {
        return wallet.loanEnabled;
      }),
      [
        wallet => {
          return wallet.amount.gt(0);
        },
        wallet => {
          return wallet.tags.includes('crypto');
        },
        wallet => {
          return wallet.tags.includes('stableCoin');
        },
        wallet => {
          return wallet.tags.includes('fiat');
        },
      ],
      ['desc', 'desc', 'desc', 'desc']
    );
  }

  @computed
  get hodlEnabledWallets() {
    return this.walletsListWithAmountOrder.filter(wallet => {
      return wallet.hodlEnabled;
    });
  }

  @computed
  get walletsTickers() {
    return this.walletsList.map(wallet => wallet.ticker);
  }

  @computed
  get walletsTickersWithAmountOrder() {
    return this.walletsListWithAmountOrder.map(wallet => wallet.ticker);
  }

  @computed
  get walletsTickersEquivalentAmountOrder() {
    return this.walletsListOrderedByEquivalentAmount.map(
      wallet => wallet.ticker
    );
  }

  @computed
  get fiatTickers() {
    return this.fiatWallets.map(wallet => wallet.ticker);
  }

  @computed
  get stableTickers() {
    return this.stableWallets.map(wallet => wallet.ticker);
  }

  @computed
  get cryptoTickers() {
    return this.cryptoWallets.map(wallet => wallet.ticker);
  }

  @computed
  get cryptoAndStableTickers() {
    return this.cryptoAndStableWallets.map(wallet => wallet.ticker);
  }

  @computed
  get marketEnabledTickers() {
    return this.marketEnabledWallets.map(wallet => {
      return wallet.ticker;
    });
  }

  @computed
  get loanEnabledTickers() {
    return this.loanEnabledWallets.map(wallet => {
      return wallet.ticker;
    });
  }

  @computed
  get hodlEnabledTickers() {
    return this.hodlEnabledWallets.map(wallet => {
      return wallet.ticker;
    });
  }

  @computed
  get fiatWalletsCount() {
    return this.fiatWallets.length;
  }

  @computed
  get stableWalletsCount() {
    return this.stableWallets.length;
  }

  @computed
  get cryptoWalletsCount() {
    return this.cryptoWallets.length;
  }

  @computed
  get cryptoAndStableWalletsCount() {
    return this.cryptoAndStableWallets.length;
  }

  @action
  createWalletAddress = (ticker: string, version: string | null = null) => {
    return TRANSPORT.API.post('/v3/balance/create', {
      ticker,
      version,
    }).then(() => {
      this.refetch();
    });
  };

  @action
  sortWalletsByEquivalentAmount = (wallets: Wallet[]) => {
    return orderBy(
      wallets,
      [
        wallet => {
          return wallet.equivalent.toNumber();
        },
      ],
      ['desc']
    ) as Wallet[];
  };

  getByTicker = computedFn((ticker: string) => {
    return this.walletsList.find(wallet => {
      return wallet.ticker === ticker;
    });
  });
}

const filterWallets = (wallets: WalletsResponse['wallets']) => {
  return orderBy(
    wallets
      .filter(wallet => !wallet.ticker.startsWith('_'))
      .map(wallet => {
        const collateralWallet = wallets.find(
          w => w.ticker === `_${wallet.ticker}`
        );

        const amount = wallet.amount.round(
          getCoinDecimalPrecision(wallet.ticker),
          0
        );

        let preparedWallet = assign(wallet, {
          amount,
          // alive demo, need fix in backend, still fixed in front
          ...(['yusd', 'ybtc'].includes(wallet.ticker)
            ? {
                loanEnabled: true,
                hodlEnabled: true,
                marketEnabled: true,
              }
            : {}),
          tags: [
            ...(wallet.tags || []),
            ...(['yusd', 'ybtc'].includes(wallet.ticker) ? ['demo'] : []),
          ],
        });

        if (collateralWallet) {
          preparedWallet = assign(preparedWallet, {
            collateral: collateralWallet.amount,
          });
        }

        return preparedWallet;
      }),
    [
      wallet => {
        return getCoinOrderIndex(wallet.ticker);
      },
    ]
  );
};
