import {action, computed, IReactionDisposer, observable, reaction} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {toBig} from '@youtoken/ui.formatting-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {CompanyResource} from '@youtoken/ui.resource-companies';
import {FeeAllResource} from '@youtoken/ui.resources-fee';
import {i18n, type TKey} from '@youtoken/ui.service-i18n';
import {
  IBANAccountsResource,
  IBANProviders,
} from '@youtoken/ui.resource-iban-accounts';
import {ITab} from '@youtoken/ui.tabs';
import {invariant} from '@youtoken/ui.utils';
import {type Account} from '../types/Account';
import {type AccountRequisite} from '../types/AccountRequisite';
import {type AccountType} from '../types/AccountType';
import {getBankAccounts} from '../utils/bankAccounts';

enum AlternativeRequisitesType {
  NEX_PAY = 'NexPay',
  BANK_FRICK = 'BankFrick',
}

interface BankWireFeatureArgs {
  ticker: string;
}

interface BankWireFeatureResources {
  authMeResource: AuthMeResource;
  companyResource: CompanyResource;
  feeAllResource: FeeAllResource;
  ibanAccountsResource: IBANAccountsResource;
}

export class BankWireFeature extends createFeature({
  getKey: (args: BankWireFeatureArgs) =>
    `feature:bankWire(${JSON.stringify(args)})`,
  getResources: () => ({
    authMeResource: getResourceDescriptor(AuthMeResource, {}),
    companyResource: getResourceDescriptor(CompanyResource, {}),
    feeAllResource: getResourceDescriptor(FeeAllResource, {}),
    ibanAccountsResource: getResourceDescriptor(IBANAccountsResource, {}),
  }),
}) {
  private disposers: Array<IReactionDisposer> = [];

  constructor(args: BankWireFeatureArgs, resources: BankWireFeatureResources) {
    super(args, resources);

    this.disposers.push(
      reaction(
        () => this.isIBANAccountActive,
        () => {
          if (
            (['sepa', 'wirex', 'fiat-republic'] as AccountType[]).includes(
              this.accountType
            )
          ) {
            this.setAccountType(this.accountTypesTabs[0]!.value as AccountType);
          }
        },
        {fireImmediately: true}
      )
    );
  }

  public onDestroy() {
    super.onDestroy();
    this.disposers?.forEach(disposer => disposer?.());
  }

  @observable
  public accountType: AccountType = null;

  @computed
  public get ticker() {
    return this.args.ticker;
  }

  @computed
  private get fee() {
    return this.resources.feeAllResource.data.find(
      fee =>
        fee.method === 'deposit' &&
        fee.provider === 'wire' &&
        fee.ticker === this.ticker &&
        fee.conversionTicker === this.ticker
    );
  }

  @computed
  public get IBANAccount() {
    return this.resources.ibanAccountsResource.getIBANAccountByTicker(
      this.ticker
    );
  }

  @computed
  public get IBANAccountRequisites() {
    const account = this.IBANAccount;
    const requisites: AccountRequisite[] = [];

    if (account) {
      if (account.fullName) {
        requisites.push({
          priority: 100,
          type: 'accountHolder',
          title: 'Account holder',
          value: account.fullName,
        });
      }

      if (account.iban) {
        requisites.push({
          priority: 400,
          type: 'iban',
          title: 'IBAN',
          value: account.iban,
        });
      }

      if (account.bic) {
        requisites.push({
          priority: 500,
          type: 'bankCode',
          title: 'Bank SWIFT Code',
          value: account.bic,
        });
      }

      if (account.accountNumber) {
        requisites.push({
          priority: 600,
          type: 'accountNumber',
          title: 'Account number',
          value: account.accountNumber,
        });
      }

      if (account.bankName) {
        requisites.push({
          priority: 700,
          type: 'bankName',
          title: 'Name of the bank',
          value: account.bankName,
        });
      }

      if (account.bankAddressString) {
        requisites.push({
          priority: 800,
          type: 'bankAddress',
          title: 'Address of the bank',
          value: account.bankAddressString,
        });
      }

      if (account.sortCode) {
        requisites.push({
          priority: 900,
          type: 'sortCode',
          title: 'Sort Code',
          value: account.sortCode,
        });
      }
    }

    return requisites;
  }

  @computed
  private get bankAccounts() {
    return getBankAccounts(this.resources.companyResource.data);
  }

  @computed
  private get accountCommon(): Partial<Account> {
    return {
      minAmount: toBig(this.fee?.minAmount).toNumber(),
      feeAmount: toBig(this.fee?.min).toNumber(),
    };
  }

  @computed
  private get accountRequisitesCommon(): AccountRequisite[] {
    return [
      {
        type: 'payment-reference',
        priority: 50,
        title: 'Payment reference (mandatory)',
        value: this.resources.authMeResource.data.accountId.substr(0, 6),
        copyMessage: i18n.t(
          'surface.wallets.fiat_deposit_wire.requisites.payment-reference-copied'
        ),
      },
    ];
  }

  @computed
  private get accountsByTickerUSD(): Account[] {
    return [
      {
        ...this.accountCommon,
        requisites: [
          ...this.accountRequisitesCommon,
          ...this.bankAccounts.EqualsMoneyUSD,
        ],
      },
    ];
  }

  @computed
  private get accountsByTickerEUR(): Account[] {
    return [
      {
        ...this.accountCommon,
        type: this.provider,
        disabled: !this.isIBANAccountActive,
        requisites: this.IBANAccountRequisites,
      },
      {
        ...this.accountCommon,
        type: 'sepa',
        disabled: this.isIBANAccountActive,
        minAmount: 100,
        requisites: [
          ...this.accountRequisitesCommon,
          //  TODO: Fix incident
          // ...this.bankAccounts.NexPayYouhodler,
          ...this.bankAccounts.IntergiroEUR,
        ],
      },
      {
        ...this.accountCommon,
        type: 'swift',
        minAmount: 500,
        requisites: [
          ...this.accountRequisitesCommon,
          ...this.bankAccounts.EqualsMoneyEUR,
        ],
      },
    ];
  }

  @computed
  private get accountAlternativeToEURTicker(): Account {
    return {
      ...this.accountCommon,
      type: 'sepa',
      disabled: this.isIBANAccountActive,
      minAmount: 100,
      requisites: [
        ...this.accountRequisitesCommon,
        ...this.bankAccounts.IntergiroEUR,
      ],
    };
  }

  @computed
  private get accountsByTickerGBP(): Account[] {
    return [
      {
        type: this.provider,
        disabled: !this.isIBANAccountActive,
        ...this.accountCommon,
        requisites: this.IBANAccountRequisites,
      },
      {
        ...this.accountCommon,
        type: 'swift',
        requisites: [
          ...this.accountRequisitesCommon,
          ...this.bankAccounts.EqualsMoneyGBP,
        ],
      },
      {
        ...this.accountCommon,
        type: 'domestic',
        requisites: [
          ...this.accountRequisitesCommon,
          ...this.bankAccounts.EqualsMoneyGBPInsideUK,
        ],
      },
    ];
  }

  @computed
  private get accountsByTickerCHF(): Account[] {
    return [
      {
        ...this.accountCommon,
        requisites: [
          ...this.accountRequisitesCommon,
          ...this.bankAccounts.EqualsMoneyCHF,
        ],
      },
    ];
  }

  @computed
  private get accountsByTicker() {
    switch (this.ticker) {
      case 'usd':
        return this.accountsByTickerUSD;
      case 'eur':
        return this.accountsByTickerEUR;
      case 'gbp':
        return this.accountsByTickerGBP;
      case 'chf':
        return this.accountsByTickerCHF;
      default:
        return [];
    }
  }

  @computed
  private get accountsByTickerFiltered() {
    return this.accountsByTicker.filter(account => !account.disabled);
  }

  @computed
  public get accountTypesTabs(): ITab[] {
    const accountTypes = new Set<string>();

    this.accountsByTickerFiltered.forEach(account => {
      if (account.type) {
        accountTypes.add(account.type);
      }
    });

    return Array.from(accountTypes).map(accountType => {
      const accountTypeFormatted = accountType.toUpperCase();
      let label: string = accountTypeFormatted;

      switch (accountType) {
        case 'swift':
          if (this.ticker === 'eur') {
            label = i18n.t('surface.bank_wire.account_type.eu_sepa.outside');
          } else if (this.ticker === 'gbp') {
            label = i18n.t('surface.bank_wire.account_type.uk.outside');
          }
          break;
        case 'sepa':
        case IBANProviders.WIREX:
        case IBANProviders.FIAT_REPUBLIC:
          if (this.ticker === 'eur') {
            label = i18n.t('surface.bank_wire.account_type.eu_sepa.inside');
          } else if (this.ticker === 'gbp') {
            label = i18n.t('surface.bank_wire.account_type.eu_uk.inside');
          }
          break;
        case 'domestic':
          label = i18n.t('surface.bank_wire.account_type.uk.inside');
          break;
        default:
          label = accountTypeFormatted;
      }

      return {
        type: 'text',
        label: label,
        value: accountType,
        testID: `FIAT_DEPOSIT_BANK_WIRE_ACCOUNT_TYPE_${accountTypeFormatted}`,
      };
    });
  }

  @computed
  public get accountTypesTabActive() {
    return this.accountTypesTabs.findIndex(
      tab => tab.value === this.accountType
    );
  }

  @computed
  public get account() {
    const accountType = this.accountType;

    const account = accountType
      ? this.accountsByTickerFiltered.find(
          account => account.type === accountType
        )
      : this.accountsByTickerFiltered[0];

    invariant(
      account,
      `Bank account not found for "${this.ticker}"${
        accountType ? ` and type "${accountType}"` : ''
      }`
    );

    return account;
  }

  @computed
  public get alternativeToIBANAccount() {
    if (
      this.alternativeRequisitesType === AlternativeRequisitesType.BANK_FRICK
    ) {
      return this.accountAlternativeToEURTicker;
    } else {
      const accounts = this.accountsByTicker.filter(
        account =>
          account.type !== IBANProviders.WIREX &&
          account.type !== IBANProviders.FIAT_REPUBLIC
      );

      return accounts.length > 1
        ? accounts.find(account => account.type !== 'swift')!
        : accounts[0]!;
    }
  }

  @computed
  public get isTypeSwitcherAvailable() {
    return this.accountTypesTabs.length > 1;
  }

  @computed
  public get isIBANAccountExist() {
    return Boolean(this.IBANAccount);
  }

  @computed
  public get isIBANAccountActive() {
    return this.IBANAccount?.status === 'ACTIVE';
  }

  @computed public get needActivatePersonalIBAN() {
    return this.resources.ibanAccountsResource.getIBANShouldBeActivatedByTicker(
      this.ticker
    );
  }

  @computed
  private get alternativeRequisitesType() {
    return this.isIBANAccountActive &&
      (this.accountType === IBANProviders.WIREX ||
        this.accountType === IBANProviders.FIAT_REPUBLIC ||
        this.accountType === 'swift')
      ? AlternativeRequisitesType.NEX_PAY
      : !this.isIBANAccountActive &&
        this.ticker === 'eur' &&
        //  TODO: Fix incident
        this.accountType !== 'sepa'
      ? AlternativeRequisitesType.BANK_FRICK
      : null;
  }

  @computed
  public get isAlternativeRequisitesLinkAvailable() {
    return Boolean(this.alternativeRequisitesType);
  }

  @computed
  public get alternativeRequisitesLinkText(): TKey | null {
    switch (this.alternativeRequisitesType) {
      case AlternativeRequisitesType.NEX_PAY:
      case AlternativeRequisitesType.BANK_FRICK:
        return 'surface.bank_wire.wirex.alternative_requisites.link';
      default:
        return null;
    }
  }

  @computed
  public get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  public get provider() {
    return this.resources.ibanAccountsResource.provider ?? IBANProviders.WIREX;
  }

  @action
  public setAccountType = (value: AccountType) => {
    this.accountType = value;
  };
}
