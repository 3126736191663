import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';

type ProfileButtonProps = {
  onPress: () => void;
};

export const ProfileButton: React.FC<ProfileButtonProps> = observer(
  ({onPress}) => {
    const {t} = useTranslation();

    return (
      <TouchableBox
        bg="$interactive-02"
        borderRadius={16}
        gap={4}
        p={6}
        flexDirection="row"
        alignItems="center"
        onPress={onPress}
      >
        <Icon name="profile" size={20} color="$text-05" />
      </TouchableBox>
    );
  }
);
