import React from 'react';
import {observer} from 'mobx-react';
import {PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {HODLsInfo} from '@youtoken/ui.surfaces-hodl-common';
import {Box} from '@youtoken/ui.primitives';
import {HodlTabSwitcher} from '../HodlTabSwitcher';
import {TraderQuizPromo} from '../TraderQuizPromo';

export interface HodlsMainSurfaceContentProps {
  innerPage: PageSwitcherValue;
}

export const HodlsMainSurfaceContent: React.FC<HodlsMainSurfaceContentProps> =
  observer(({innerPage, children}) => {
    return (
      <>
        <Box gap={{default: 20, desktop: 24}}>
          <HODLsInfo />
          <HodlTabSwitcher page={innerPage} />
          <TraderQuizPromo testID="TRADING_QUIZ_BUTTON" />
          {children}
        </Box>
      </>
    );
  });
