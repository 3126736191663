import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {FeeAllResource} from '@youtoken/ui.resources-fee';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {Box, type WithBoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {type CryptoWithdrawalProps} from '../types';
import {CryptoWithdrawalExternalFormState} from './state';
import {CryptoWithdrawalExternalFooter} from '../CryptoWithdrawalComponents';
import {CryptoWithdrawalExternalContent} from './components';

export const CryptoWithdrawalExternalForm: React.FC<
  WithBoxProps<CryptoWithdrawalProps>
> = cell(({ticker, version, ...rest}) => {
  const resources = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    wallets: getResourceDescriptor(WalletsResource, {}),
    feeAll: getResourceDescriptor(FeeAllResource, {}),
    verification: getResourceDescriptor(VerificationResource, {}),
  });

  const formState = makeForm(
    () =>
      new CryptoWithdrawalExternalFormState(
        {
          ticker,
          version,
        },
        resources
      )
  );

  const {walletTicker, walletVersionValue, shouldShowContent} = formState;

  React.useEffect(() => {
    DATA_LAYER.trackStrict('withdrawal-crypto-attempt', {
      ticker: walletTicker,
      provider: walletVersionValue,
    });
  }, [walletTicker, walletVersionValue]);

  return (
    <Box
      flexDirection="column"
      flexGrow={1}
      justifyContent="space-between"
      {...rest}
    >
      <Form form={formState}>
        <CryptoWithdrawalExternalContent />
        {shouldShowContent && <CryptoWithdrawalExternalFooter />}
      </Form>
    </Box>
  );
});
