import * as React from 'react';
import {useWizard} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {CryptoDeposit} from '../../../../CryptoDeposit';

import {
  type CryptoDirectDepositWizardNavigator,
  CryptoDirectDepositWizardScreenName,
} from '../index';

export const CryptoDirectDeposit: React.FC = cell(
  () => {
    const {
      step: {
        props: {ticker, version},
      },
      goBack,
    } = useWizard<
      CryptoDirectDepositWizardNavigator,
      CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT
    >();

    return (
      <CryptoDeposit ticker={ticker} version={version} onPressBack={goBack} />
    );
  },
  {
    CellWrap: ({children}) => <Box minHeight={200}>{children}</Box>,
  }
);
