import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text, type BoxProps} from '@youtoken/ui.primitives';
import {LoanReopenFeature} from './Feature';
import {AgreementsList} from '@youtoken/ui.elements';

export type TotalProps = {
  loanId: string;
};

export const Total: React.FC<TotalProps & BoxProps> = observer(
  ({loanId, ...boxProps}) => {
    const {t} = useTranslation();

    const {total, borrowedTickerFormatted, agreements} = LoanReopenFeature.use({
      id: loanId,
    });

    return (
      <Box {...boxProps}>
        <Box flexDirection="row" alignItems="center" mb={16}>
          <Heading variant="$heading-01">{total} </Heading>
          <Text color="$text-02" mt={2}>
            {t('surface.loans.reopen_form.commission', {
              ticker: borrowedTickerFormatted,
            })}
          </Text>
        </Box>

        <Text variant="$body-02" color="$text-02">
          <AgreementsList
            items={agreements}
            beforeListText={t(
              'agreements.documents.without_checkbox.start_text',
              {
                call_to_action: t('surface.loans.reopen_form.cta'),
              }
            )}
          />
        </Text>
      </Box>
    );
  }
);
