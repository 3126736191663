import {observable, action, computed} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {
  BundleReleaseFormStateArgs,
  BundleReleaseFormStateResources,
} from './types';
import {TRANSPORT} from '@youtoken/ui.transport';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';

export class Form {
  @observable
  public args: BundleReleaseFormStateArgs;

  @observable
  public resources: BundleReleaseFormStateResources;

  @observable
  public instance: MobxReactForm;

  @computed
  public get bundleTariffOverview() {
    return this.resources.bundleTariffOverview;
  }

  //#region tariff

  @computed
  public get tariff() {
    return this.bundleTariffOverview.data.tariff;
  }

  @computed
  public get tariffId() {
    return this.tariff.id;
  }

  //#endregion tariff

  //#region userPortfolio

  @computed
  public get userPortfolio() {
    return this.bundleTariffOverview.data.userPortfolio;
  }

  @computed
  public get userPortfolioId() {
    return this.userPortfolio!.id;
  }

  //#endregion userPortfolio

  @action
  public submit = () => {
    DATA_LAYER.trackStrict('bundles-transaction-started', {
      transactionType: 'release',
      tariffId: this.tariffId,
      bundleId: this.userPortfolioId,
    });

    return TRANSPORT.API.post(`/v1/bundle/release`, {
      bundleId: this.userPortfolioId,
    })
      .then(() => {
        this.bundleTariffOverview.refetch();
        this.resources.bundleOperations.refetch();
      })
      .then(this.args.onClose)
      .catch(e => {
        handleFormSubmitError(this.instance, e);
      });
  };

  public constructor(
    args: BundleReleaseFormStateArgs,
    resources: BundleReleaseFormStateResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {},
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
        },
      }
    );
  }
}
