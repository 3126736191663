import {createTheme} from '@shopify/restyle';
import {type Theme} from './types';
import {
  type TColorTokens,
  COLORS,
  HODLS_INSTRUMENTS_SECTORS_COLOR_TOKENS,
} from './colors';
import {sharedTheme} from './sharedTheme';

export const DARK_THEME_COLOR_TOKENS: TColorTokens = {
  '$ui-overflow': COLORS['Gray 100'],
  '$ui-overlay': COLORS.Black,
  '$ui-background': COLORS['Gray 90'],
  '$ui-backdrop': COLORS['Gray 100'],
  '$ui-shadow': COLORS['Black - 40%'],
  '$ui-01': COLORS['Gray 80'],
  '$ui-02': COLORS['Gray 70'],
  '$ui-03': COLORS['Gray 60'],
  '$ui-04': COLORS['Gray 50'],
  '$blackout-01': COLORS['Black - 60%'],
  '$blackout-02': COLORS['Black - 60%'],
  $transparent: COLORS.Transparent,
  '$success-01': COLORS['Green 60'],
  '$success-02': COLORS['Green 100'],
  '$success-03': COLORS['Green 90'],
  '$danger-01': COLORS['Red 60'],
  '$danger-02': COLORS['Red 100'],
  '$danger-03': COLORS['Red 90'],
  '$danger-04': COLORS['Red 60'],
  '$attention-01': COLORS['Orange 60'],
  '$attention-02': COLORS['Orange 100'],
  '$attention-03': COLORS['Orange 90'],
  '$attention-04': COLORS['Orange 60'],
  '$fill-01': COLORS['Gray 80'],
  '$fill-02': COLORS['Gray 70'],
  '$fill-03': COLORS['Blue 70'],
  '$fill-04': COLORS['White'],
  '$text-01': COLORS['Gray 20'],
  '$text-02': COLORS['Gray 60'],
  '$text-03': COLORS['Gray 70'],
  '$text-04': COLORS.White,
  '$text-05': COLORS['Blue 60'],
  '$text-06': COLORS['Gray 100'],
  '$interactive-01': COLORS['Blue 60'],
  '$interactive-02': COLORS['Blue 100'],
  '$interactive-03': COLORS['Blue 90'],
  '$interactive-04': COLORS['Blue 80'],

  '$gradient-success-01': COLORS['Green 60'],
  '$gradient-danger-01': COLORS['Red 60'],
  '$gradient-ui-01': COLORS['Gray 40'],

  '$loyalty-level-01': COLORS['L1 Dark'],
  '$loyalty-level-02': COLORS['L2 Dark'],
  '$loyalty-level-03': COLORS['L3 Dark'],
  '$loyalty-level-04': COLORS['L4 Dark'],
  '$loyalty-level-05': COLORS['L5 Dark'],
  '$loyalty-level-06': COLORS['L6 Dark'],
  '$loyalty-level-07': COLORS['L7 Dark'],
  '$loyalty-level-08': COLORS['L8 Dark'],
  $hover: COLORS['Blue 60 - 40%'],
  '$miner-gloss': COLORS['Orange 80'],
  '$miner-gloss-blocked': COLORS['Gray 70'],
  '$chart-neutral': COLORS['Gray 70'],
  '$chart-success-01': COLORS['Green 60'],
  '$chart-success-02': COLORS['Green 100'],
  '$chart-danger-01': COLORS['Red 60'],
  '$chart-danger-02': COLORS['Red 100'],
  '$chart-neutral-01': COLORS['Gray 60'],
  '$chart-interactive-01': COLORS['Blue 60'],
  '$chart-interactive-02': COLORS['Blue 100'],
  '$chart-disabled-01': COLORS['Gray 70'],
  '$chart-disabled-02': COLORS['Gray 60'],
  ...HODLS_INSTRUMENTS_SECTORS_COLOR_TOKENS,
};

export const darkTheme = createTheme<Theme>({
  ...sharedTheme,
  colors: DARK_THEME_COLOR_TOKENS,
  shadow: `0px 5px 30px ${DARK_THEME_COLOR_TOKENS['$ui-shadow']}`,
});
