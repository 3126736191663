import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BundleOperation} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';

export interface BundleOperationReceiptProps {
  url: BundleOperation['agreement'];
}

export const BundleOperationReceipt: React.FC<
  BundleOperationReceiptProps & BoxProps
> = observer(({url, ...boxProps}) => {
  const {t} = useTranslation();

  const handlePress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('Agreement', {url});
  }, [url]);

  return (
    <TouchableBox
      testID="BUNDLE_OPERATIONS_RECEIPT"
      onPress={handlePress}
      flexDirection="row"
      alignItems="center"
      {...boxProps}
    >
      <Text color="$text-05" variant="$body-02">
        {t('surface.history.item.receipt')}
      </Text>
    </TouchableBox>
  );
});
