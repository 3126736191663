import * as React from 'react';
import {Platform} from 'react-native';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {
  InputWithSlider,
  PredefinedAmountButtons,
  type WalletComboboxItem,
} from '@youtoken/ui.ticker-and-amount-input';
import {Separator} from '@youtoken/ui.elements';
import {Label, Form, makeForm} from '@youtoken/ui.form-elements';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {HODLByIdResource} from '@youtoken/ui.resource-hodl';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {cell} from '@youtoken/ui.cell';
import {AmountInTickerTextInput} from '@youtoken/ui.inputs';
import {ExtendTpSlForm} from '../../form';
import {
  HowExtendTpSlWorkTouchable,
  InfoTable,
  ExtendTpSlValidationMessage,
} from '../../components';
import {UseBonuses} from '../../../components';

interface ExtendTpSlSurfaceProps {
  hodlId: string;
  onClose: () => void;
  onPressHowItWorks: () => void;
  onPressDeposit: () => void;
}

export const ExtendTpSlSurface: React.FC<ExtendTpSlSurfaceProps & BoxProps> =
  cell(({hodlId, onClose, onPressHowItWorks, onPressDeposit, ...boxProps}) => {
    const {t} = useTranslation();

    const isNative = Platform.OS !== 'web';

    const {residenceOrCountry, incentivesEnabled} = AuthMeResource.use({});

    const resources = useResources({
      authMe: getResourceDescriptor(AuthMeResource, {}),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      hodl: getResourceDescriptor(HODLByIdResource, {id: hodlId}),
      docs: getResourceDescriptor(DocsResource, {country: residenceOrCountry}),
      rates: getResourceDescriptor(RatesResource, {product: 'hodl'}),
      tariffs: getResourceDescriptor(HodlsTariffsResource, {}),
    });

    const form = makeForm(
      () => new ExtendTpSlForm(resources, {hodlId, onSuccess: onClose})
    );

    const {
      form: {
        inputTickerWallet,
        inputTicker,
        setInputTicker,
        setInputAmount,
        setMaxAmount,
        inputAmount,
        maxInputAmount,
        maxInputAmountFormatted,
        predefinedAmountsData,
        inputAmountError,
        submitForm,
        isLoading,
        isHodlExpired,
        bonusesEnabled,
        hasBalance,
      },
      view: {inputTickerFormatted},
    } = form;

    React.useEffect(() => {
      if (isHodlExpired) {
        onClose();
      }
    }, [isHodlExpired, onClose]);

    return (
      <Box {...boxProps}>
        <Form form={form}>
          {hasBalance ? (
            <>
              <InputWithSlider
                wallets={[inputTickerWallet] as WalletComboboxItem[]}
                ticker={inputTicker}
                tickerFormatted={inputTickerFormatted}
                amount={inputAmount}
                maximumValue={maxInputAmount}
                maximumValueFormatted={maxInputAmountFormatted}
                setAllSourceToAmount={setMaxAmount}
                onChangeAmount={setInputAmount}
                onChangeTicker={setInputTicker}
                validationComponent={
                  Boolean(inputAmountError) ? (
                    <ExtendTpSlValidationMessage />
                  ) : undefined
                }
                isTickerImmutable
                sliderKey="EXTEND-FORM-SLIDER"
              />

              <Box mb={isNative ? 8 : 0}>
                <PredefinedAmountButtons
                  data={predefinedAmountsData}
                  mt={16}
                  mb={8}
                  currentAmount={inputAmount}
                  onPressAmount={setInputAmount}
                  buttonsSize="small"
                />
              </Box>
            </>
          ) : (
            <Box mb={isNative ? 24 : 8}>
              <Label>{t('surface.hodls.hodl_form.amount_label')}</Label>
              <Box mt={8}>
                <AmountInTickerTextInput
                  ticker={inputTicker}
                  disabled
                  editable={false}
                />
              </Box>
              <Button
                size="medium"
                type="secondary"
                onPress={onPressDeposit}
                mt={16}
              >
                {t('surface.hodls.extend_tp.action_deposit')}
              </Button>
            </Box>
          )}

          {incentivesEnabled && bonusesEnabled && (
            <>
              {isNative && <Separator mx={-24} />}
              <UseBonuses pt={isNative ? 22 : 16} pb={isNative ? 22 : 8} />
              {isNative && <Separator mx={-24} />}
            </>
          )}

          <InfoTable my={16} />
          <HowExtendTpSlWorkTouchable onPress={onPressHowItWorks} />

          <Box flexDirection="row" my={24}>
            <Button
              size="large"
              type="primary"
              flexGrow={1}
              loading={isLoading}
              onPress={submitForm}
            >
              {t('surface.hodls.extend_tp.action_extend')}
            </Button>
          </Box>
        </Form>
      </Box>
    );
  });
