import {observable, computed} from 'mobx';
import {Form} from './Form';
import {toBig} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';
import type {
  LoanRepayOwnFundsFormArgs,
  LoanRepayOwnFundsFormResources,
} from '../../types';

export class LoanRepayOwnFundsFormV1State {
  @observable
  public form: Form;

  @computed
  public get loadingOrSubmitting() {
    return this.form.submitting;
  }

  @computed
  public get ticker() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  public get conversionTicker() {
    return this.form.conversionTicker.toUpperCase();
  }

  @computed
  public get walletAmount() {
    return toBig(this.form.wallet?.amount)
      .round(getCoinDecimalPrecisionForLoans(this.form.ticker))
      .toString();
  }

  @computed
  public get overdraftAmount() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.loanOverdraftAmount)
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toString()
    );
  }

  @computed
  public get walletAmountFormatted() {
    return toBig(this.form.wallet?.amount)
      .round(getCoinDecimalPrecisionForLoans(this.form.ticker))
      .toFixedWithSeparators();
  }

  @computed
  public get overdraftAmountFormatted() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.loanOverdraftAmount)
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toFixedWithSeparators()
    );
  }

  @computed
  public get conversionAmount() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.conversionAmount)
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toFixedWithSeparators()
    );
  }

  @computed
  public get feeAmount() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.fee)
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toFixedWithSeparators()
    );
  }

  constructor(
    args: LoanRepayOwnFundsFormArgs,
    resources: LoanRepayOwnFundsFormResources
  ) {
    this.form = new Form(args, resources);
  }
}
