import * as React from 'react';
import {type GestureResponderEvent} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';
import {
  TouchableBox,
  Text,
  useIsMediumDesktopAndUp,
} from '@youtoken/ui.primitives';

export type MenuItemProps = {
  variant?: 'bar' | 'dropdown';
  isActive?: boolean;
  to?: string;
  icon: BaseIconName;
  text: string;
  pathKey?: string;
  testID?: string;
  onPress?: (event: GestureResponderEvent) => void;
};

export const MenuItem: React.FC<MenuItemProps> = cell(
  ({variant = 'bar', isActive, icon, text, testID, onPress}) => {
    const isMediumDesktop = useIsMediumDesktopAndUp();
    const handlePress = (event: GestureResponderEvent) => {
      onPress?.(event);
    };

    const renderIcon = variant === 'bar' ? isMediumDesktop : true;
    const contentColor =
      isActive || variant === 'dropdown' ? '$text-05' : '$text-02';

    return (
      <TouchableBox
        activeOpacity={0.8}
        onPress={handlePress}
        py={8}
        px={12}
        bg={isActive ? '$interactive-02' : '$ui-background'}
        borderRadius={variant === 'bar' ? 8 : 0}
        borderLeftWidth={variant === 'bar' ? 0 : 2}
        borderColor={isActive ? '$text-05' : '$ui-background'}
        minWidth={variant === 'bar' ? undefined : 240}
        gap={8}
        flexDirection="row"
        alignItems="center"
        testID={testID}
        mb={variant === 'bar' ? 0 : 5}
      >
        {renderIcon && <Icon size={24} color={contentColor} name={icon} />}
        <Text variant="$body-01-medium-accent" color={contentColor}>
          {text}
        </Text>
      </TouchableBox>
    );
  }
);
