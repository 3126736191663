import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useSafeArea} from '@youtoken/ui.hooks';
import {Box} from '@youtoken/ui.primitives';
import {AgreementsList} from '@youtoken/ui.elements';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {BundleReleaseFormState} from '../../state';

export const Footer: React.FC = observer(() => {
  const {t} = useTranslation();

  const {bottom} = useSafeArea();

  const {
    form: {
      resources: {
        docs: {
          documents: {bundleRelease},
        },
      },
    },
  } = useForm<BundleReleaseFormState>();

  const submitActionText = t('surface.bundles.release_form.submit');

  return (
    <Box p={24} pb={bottom} borderColor="$ui-01" borderTopWidth={1} gap={24}>
      <AgreementsList
        testID="AGREEMENTS"
        beforeListText={t('agreements.documents.without_checkbox.start_text', {
          call_to_action: submitActionText,
        })}
        items={bundleRelease}
      />
      <SubmitButton>{submitActionText}</SubmitButton>
    </Box>
  );
});
