import {Routes} from '../Routes';

// mapping routes to urls
type RoutesToUrlMap = {
  [RouteName in Routes.AllRoutes[0]]: string;
};

// maps our routes to how we should build it to string;
export const routesToUrlMap: RoutesToUrlMap = {
  MultiHODL: '/multihodl',
  NewMultiHODL: '/multihodl/create',
  HODLInstrument: '/multihodl/instrument/:symbol',
  HodlExtendTpSl: '/multihodl/item/:id/increase',
  HodlCloseNow: '/multihodl/item/:id/close-now',
  HodlFlip: '/multihodl/item/:id/flip',
  MultiHODLPortfolio: '/multihodl/portfolio',
  MultiHODLsClosed: '/multihodl/portfolio/closed',
  MultiHODLInstruments: '/multihodl/instruments',
  HODLItemDetailed: '/multihodl/item/:id',
  TradingViewChart: '/multihodl/tradingview',
  TradingQuiz: '/not-found',
  HodlHistory: '/multihodl/item/:id/history',
  NotFound: '/not-found',
  Agreement: '/agreement',
  Explorer: '/explorer',
  AddWallets: '/wallets/add',
  WalletsItem: '/wallets/:ticker',
  WalletsList: '/wallets',
  WalletsSavings: '/wallets/savings',
  WalletsSavingsSelect: '/wallets/savings/select',
  WalletStats: '/wallets/account-stats',
  EarnMore: '/wallets/earn_more',
  Verification: '/profile/verification',
  TrustedDevices: '/profile/trusted_devices',
  SecurityActivity: '/profile/security_activity',
  LegalAndInfo: '/profile/legal-info',
  DeleteAccount: '/profile/delete-account',
  __CloseModal: '',
  FormAVerificationModal: '/profile/verification/form_a',
  MultiHodlSpecification: '/profile/hodl_specification',
  AntiPhishing: '/profile/anti-phishing',
  PaymentMethods: '/profile/payment-methods',
  AddPaymentMethod: '/profile/payment-methods/add-card',
  FirstLoginModal: '/wallets/first_login',
  DeviceModal: '/profile/trusted_devices/device',
  SessionModal: '/profile/trusted_devices/session',
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  VerifyEmail: '/verify-email',
  RestorePassword: '/restore-password',
  AccountLevels: '/profile/account-level',
  // Loans start (Regular and Turbo)
  Loans: '/loans',
  LoanCreate: '/loans/create',
  LoanItem: '/loans/:id',
  LoanPay: '/loans/:id/pay',
  LoanCloseNow: '/loans/:id/close-now',
  LoanIncrease: '/loans/:id/increase',
  LoanDecrease: '/loans/:id/decrease',
  LoanReopen: '/loans/:id/reopen',
  LoanFTP: '/loans/:id/ftp',
  Turbocharge: '/turbocharge',
  TurbochargeCreate: '/turbocharge/create',
  TurbochargeDetails: '/turbocharge/details',
  TurbochargePay: '/turbocharge/:id/pay',
  TurbochargeCloseNow: '/turbocharge/:id/close-now',
  TurbochargeFTP: '/turbocharge/:id/ftp',
  // Loans end
  Exchange: '/wallets',
  FirstAutoConvert: '/wallets',
  AccountStats: '/account-stats',
  HistoryItemNew: '/account-stats', // NOTE: this is a modal, with "id" as a query param
  Miner: '/miner',
  MinerHowItWorks: '/not-found',
  DisclosureWalletsModal: '/wallets/disclosure',
  Coindrop: '/portfolio/coindrop',
  DisclosureHodlsModal: '/multihodl/disclosure',
  DisclosureLoansModal: '/loans/disclosure',
  DisclosureTurboModal: '/turbocharge/disclosure',
  RewardsMain: '/profile/rewards',
  RewardDetails: '/profile/rewards/:id',
  RewardsReferrals: '/profile/rewards/referrals',
  More: '/profile',
  DeviceConfirm: '/device-confirm',
  SignInConfirm: '/sign-in-confirm',
  DepositWizard: '/wallets/deposit',
  CryptoBuy: '/wallets/buy',
  FiatDeposit: '/wallets/:ticker/deposit/fiat',
  FiatDepositBankWire: '/wallets/:ticker/deposit/fiat/bank-wire',
  FiatDepositCard: '/wallets/:ticker/deposit/fiat/card',
  FiatDepositVolet: '/wallets/:ticker/deposit/fiat/volet',
  FiatDepositApplePay: '/wallets/:ticker/deposit/fiat/apple-pay',
  FiatDepositCoDi: '/wallets/:ticker/deposit/fiat/codi',
  FiatDepositSPEI: '/wallets/:ticker/deposit/fiat/spei',
  WithdrawalWizard: '/wallets/:ticker/withdrawal',
  WithdrawalBankWire: '/wallets/:ticker/withdrawal/fiat/bank-wire',
  WithdrawalBankCard: '/wallets/:ticker/withdrawal/fiat/bank-card',
  CryptoDeposit: '/wallets/:ticker/deposit/crypto',
  Web3Wallets: '/web3',
  Web3BackupInfo: '/web3/:address/backup-info',
  Web3BackupPhrase: '/web3/:address/backup-phrase',
  Web3BackupQuiz: '/web3/:address/backup-quiz',
  Web3BackupSuccess: '/web3/backup-success',
  Web3SettingsMenu: '/web3/:address/settings',
  Web3SettingsRename: '/web3/:address/settings/rename-wallet',
  Web3SettingsViewPhrase: '/web3/:address/settings/view-phrase',
  Web3SettingsViewKey: '/web3/:address/settings/view-key',
  Web3AddWallet: '/web3/add-wallet',
  Web3WalletImport: '/web3/wallet-import',
  Web3AddWalletSuccess: '/web3/:address/add-wallet-success',
  Web3Deposit: '/web3/:address/deposit',
  Web3Withdrawal: '/web3/:address/withdrawal',
  Web3WithdrawalSuccess: '/web3/:address/withdrawal/success',
  AppearanceSettings: '/profile/appearance',
  UpgradeToLevel: '/notification/upgrade-to-level/:levelNumber',
  AccountLevelProgressDetailed: '/profile/account-level/progress-detailed',
  AccountLevelAllBenefits: '/profile/account-level/all-benefits',
  PleaseVerify: '/please-verify',
  AcquisitionSurvey: '/not-found',
  ReSignDisclosures: '/not-found',
  BankCards: '/wallets/cards',
  // cards todo: actually support these 3 deeplinks?
  BankCardAddMoney: '/wallets/cards/topup/:id',
  BankCardTransactions: '/wallets/transactions/:id',
  BankCardTransfer: '/wallets/cards/transfer/:id',
  SetupPasscode: '/passcode/setup',
  TwoFactorSetup: '/not-found', // TODO
  TwoFactorAuthenticationGADisable: '/not-found',
  TwoFactorAuthenticationGAEnable: '/not-found',
  TwoFactorAuthenticationSMSDisable: '/not-found',
  SignUpAdditional: '/not-found',
  UpgradeYourLevel: '/not-found',
  Notifications: '/not-found',
  Settings: '/not-found',
  FeesAndCommissions: '/not-found',
  UmaCreate: '/profile/uma/create',
  UmaSettings: '/profile/uma',
  UmaWithdrawal: '/wallets/:ticker/withdrawal/uma',
  UmaInvoiceConfirmation:
    '/wallets/:ticker/withdrawal/uma/invoice-confirmation',
  InviteFriends: '/referral/invite-friends',
  MyFriends: '/referral/my-friends',
  FriendRewards: '/referral/friend-rewards',
  IncentivesDetailsModal: '/wallets/incentives',
  SignUpCorporate: '/sign-up/business',
  MinerGetMoreSparksModal: '/not-found',
  MinerResetBlocksModal: '/not-found',
  AutoConvertsList: '/account-stats/auto-converts',
  ActivatePersonalIBAN: '/wallets/:ticker/activate-personal-iban',
  Bundles: '/wallets/bundles',
  Bundle: '/wallets/bundles/:id',
  BundleDeposit: '/not-found',
  BundleRelease: '/not-found',
  BundleWithdrawal: '/not-found',
};
