import {observable, action, computed, reaction, IReactionDisposer} from 'mobx';
import {Form} from './Form';
import {
  LoanItemRegularResource,
  LoanItemTurboResource,
} from '@youtoken/ui.resource-loans';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {Calculated} from './Calculated';
import {debounce} from 'lodash';
import {
  formatByTickerLoan,
  toBig,
  formatPercent,
} from '@youtoken/ui.formatting-utils';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export interface FeatureLoanFTPFormArgs {
  mode: 'regular' | 'turbo';
  id: string;
}

export interface FeatureLoanFTPFormResources {
  docsResource: DocsResource;
  ratesResource: RatesResource;
  walletsResource: WalletsResource;
  loanResource: LoanItemRegularResource | LoanItemTurboResource;
}

export class FeatureLoanFTPForm extends createFeature({
  getKey: (args: FeatureLoanFTPFormArgs) => `FTPForm:${JSON.stringify(args)}`,
  getResources: (args: FeatureLoanFTPFormArgs) => {
    const authMeResource = AuthMeResource.use({});

    return {
      docsResource: getResourceDescriptor(DocsResource, {
        country: authMeResource.residenceOrCountry,
      }),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      loanResource: getResourceDescriptor(
        args.mode === 'regular'
          ? LoanItemRegularResource
          : LoanItemTurboResource,
        {id: args.id}
      ),
    };
  },
}) {
  @observable
  public restored = false;

  @observable
  public form: Form;

  @observable
  public calculated: Calculated;

  @observable
  public disposers: IReactionDisposer[] = [];

  @computed
  public get loan() {
    return this.form.loan;
  }

  @computed
  public get loanCollateralTicker() {
    return this._loanCollateralTicker.toUpperCase();
  }

  @computed
  public get loanBorrowedTicker() {
    return this._loanBorrowedTicker.toUpperCase();
  }

  @computed
  public get initialPrice() {
    if (this._initialPrice && this._loanBorrowedTicker) {
      return formatByTickerLoan(
        this._initialPrice,
        this._loanBorrowedTicker,
        false
      );
    }

    return '0';
  }

  @computed
  public get initialPriceFormatted() {
    if (this._initialPrice && this._loanBorrowedTicker) {
      return formatByTickerLoan(this._initialPrice, this._loanBorrowedTicker);
    }

    return '0';
  }

  @computed
  public get diff() {
    if (this._diff.gt(0)) {
      return formatPercent(this._diff);
    }

    return '0';
  }

  @computed
  public get diffFormatted() {
    if (this._diff.gt(0)) {
      return this._diff.toFixedWithSeparators(2);
    }

    return '0';
  }

  @computed
  public get currentPrice() {
    if (this._loanCollateralTicker && this._loanBorrowedTicker) {
      return formatByTickerLoan(
        this.resources.ratesResource.getRate(
          this._loanCollateralTicker,
          this._loanBorrowedTicker
        ),
        this._loanBorrowedTicker
      );
    }

    return '0';
  }

  @computed
  public get currentFTP() {
    if (this.form.loanFTP && this._loanBorrowedTicker) {
      return formatByTickerLoan(this.form.loanFTP, this._loanBorrowedTicker);
    }

    return '0';
  }

  @computed
  public get collateral() {
    if (this._loanCollateralAmount && this._loanCollateralTicker) {
      return formatByTickerLoan(
        this._loanCollateralAmount,
        this._loanCollateralTicker
      );
    }

    return '0';
  }

  @computed
  public get repayment() {
    if (this.calculated.data?.debtAmount && this._loanBorrowedTicker) {
      return formatByTickerLoan(
        this.calculated.data?.debtAmount,
        this._loanBorrowedTicker
      );
    }

    return '0';
  }

  @computed
  public get hasServiceFee() {
    return this.calculated.data?.changingFee > 0;
  }

  @computed
  public get serviceFee() {
    if (this.calculated.data?.changingFee && this._loanBorrowedTicker) {
      return formatByTickerLoan(
        this.calculated.data?.changingFee,
        this._loanBorrowedTicker
      );
    }

    return '0';
  }

  @computed
  public get newClosePrice() {
    if (this.calculated.data?.price && this._loanBorrowedTicker) {
      return formatByTickerLoan(
        this.calculated.data?.price,
        this._loanBorrowedTicker
      );
    }

    return '0';
  }

  @computed
  public get expectedReturn() {
    if (this.calculated.data?.recompenseAmount && this._loanCollateralTicker) {
      return formatByTickerLoan(
        this.calculated.data?.recompenseAmount,
        this._loanCollateralTicker
      );
    }

    return '0';
  }

  @computed
  public get agreements() {
    const documents = this.resources.docsResource.data;

    if (
      this.loan.company === 'NAUMARD' &&
      documents.loanFtpFormNaumard?.length
    ) {
      return documents.loanFtpFormNaumard;
    }

    if (
      this.loan.company === 'YOUHODLER' &&
      documents.loanFtpFormYouhodler?.length
    ) {
      return documents.loanFtpFormYouhodler;
    }

    return documents.loanFtpForm || [];
  }

  @action
  public restore = () => {
    this.form.restore();
    this.restored = true;
  };

  @action
  public reset = () => {
    this.restored = false;
    this.form.instance.reset();
  };

  @computed
  private get _loanCollateralTicker() {
    return this.form.loanCollateralTicker;
  }

  @computed
  private get _loanBorrowedTicker() {
    return this.form.loanBorrowedTicker;
  }

  @computed
  private get _initialPrice() {
    return this.form.loanInitialPrice;
  }

  @computed
  private get _loanCollateralAmount() {
    return this.loan.collateralAmount;
  }

  @computed
  private get _diff() {
    return toBig(this.form.amount)
      .minus(this.initialPrice)
      .div(this.initialPrice);
  }

  constructor(
    args: FeatureLoanFTPFormArgs,
    resources: FeatureLoanFTPFormResources
  ) {
    super(args, resources);
    this.form = new Form(args, resources);
    this.calculated = new Calculated(this.form);
    this.disposers = [
      reaction(
        () => {
          return [this.restored, this.form.amount];
        },
        debounce(() => {
          if (this.restored) {
            this.calculated.load();
          }
        }, 300),
        {
          fireImmediately: true,
        }
      ),
    ];
  }
}
