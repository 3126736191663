import * as React from 'react';
import {Platform} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {Icon, LogoColored} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import type {MethodData} from '../../types';
import {MethodItem} from '../MethodItem';
import {DepositMethodEnum} from '../../../../constants';
import {DepositMethodsFeature, Providers} from './state/DepositMethodsFeature';
import {CardDepositMethods} from '../../../../smart';

interface DepositMethodsProps {
  ticker: string;
  onChange: (
    method: DepositMethodEnum,
    provider?: Providers,
    operationMethod?: CardDepositMethods
  ) => void;
}

export const DepositMethods: React.FC<DepositMethodsProps> = cell(
  ({ticker, onChange}) => {
    const {t} = useTranslation();

    const {
      methodsList,
      allowApplePay,
      allowCheckoutBankCardFrame,
      createHandleMethodPress,
    } = DepositMethodsFeature.use({ticker});

    const methodsData: Record<DepositMethodEnum, MethodData> = React.useMemo(
      () => ({
        [DepositMethodEnum.APPLE_PAY]: {
          title: t('web_app.wallets.fiat_deposit.item_apple_pay_title.default'),
          description: t(
            'web_app.wallets.fiat_deposit.item_apple_pay_duration'
          ),
          icon: <Icon name="os_mac" color="$text-05" />,
          testID: 'FIAT_DEPOSIT_METHOD_APPLE_PAY',
        },
        [DepositMethodEnum.BANK_CARD]: {
          title: t(
            allowCheckoutBankCardFrame
              ? 'surface.wallets.fiat_deposit.item_apple_or_google_title'
              : 'surface.wallets.fiat_deposit.item_card_title'
          ),
          icon: <Icon name="card" color="$text-05" />,
          testID: allowCheckoutBankCardFrame
            ? 'FIAT_DEPOSIT_METHOD_APPLE_OR_GOOGLE_PAY'
            : 'FIAT_DEPOSIT_METHOD_BANK_CARD',
        },
        [DepositMethodEnum.BANK_CARD_IFRAME]: {
          title: t('surface.wallets.fiat_deposit.item_card_title'),
          icon: <Icon name="card" color="$text-05" />,
          testID: 'FIAT_DEPOSIT_METHOD_BANK_CARD_FRAME',
        },
        [DepositMethodEnum.BANK_WIRE]: {
          title: t('surface.wallets.fiat_deposit.item_bank_wire_title'),
          icon: <Icon name="bank_wire" color="$text-05" />,
          testID: 'FIAT_DEPOSIT_METHOD_BANK_WIRE',
        },
        [DepositMethodEnum.CODI]: {
          title: t('surface.wallets.fiat_deposit.item.codi.title'),
          icon: <LogoColored name="codi" />,
          testID: 'FIAT_DEPOSIT_METHOD_CODI',
        },
        [DepositMethodEnum.SPEI]: {
          title: t('surface.wallets.fiat_deposit.item.spei.title'),
          icon: <LogoColored name="spei" />,
          testID: 'FIAT_DEPOSIT_METHOD_SPEI',
        },
        [DepositMethodEnum.VOLET]: {
          title: t('surface.wallets.fiat_deposit.item_advcash_title'),
          icon: <LogoColored name="volet" />,
          testID: 'FIAT_DEPOSIT_METHOD_ADVCASH',
        },
        [DepositMethodEnum.UMA]: {
          title: t('surface.wallets.uma'),
          description: t('surface.wallets.uma.withdrawal.duration'),
          icon: <Icon name="uma" color="$interactive-01" />,
          testID: 'FIAT_DEPOSIT_METHOD_UMA',
        },
        [DepositMethodEnum.SKRILL]: {
          title: t('surface.wallets.skrill'),
          icon: <Icon name="skrill" color="$interactive-01" />,
          testID: 'FIAT_DEPOSIT_METHOD_SKRILL',
        },
      }),
      [t, ticker, allowApplePay]
    );

    return (
      <Box>
        {methodsList.map(
          (
            {method, disabled, provider, fee, paymentSystems, operationMethod},
            index
          ) => (
            <MethodItem
              key={`${method}_${provider}`}
              disabled={disabled}
              showIconBadge={method === DepositMethodEnum.BANK_WIRE}
              onPress={createHandleMethodPress({
                method,
                provider,
                operationMethod,
                onChange,
              })}
              fee={fee}
              tags={paymentSystems}
              isLast={Platform.OS === 'web' && index === methodsList.length - 1}
              {...methodsData[method]}
            />
          )
        )}
      </Box>
    );
  },
  {
    SuspenseFallback: () => (
      <Box minHeight={80} justifyContent="center" alignItems="center">
        <ActivityIndicator size="large" />
      </Box>
    ),
  }
);
