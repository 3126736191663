import {Big} from 'big.js';
import {debounce} from 'lodash';
import axios, {Canceler} from 'axios';
import {observable, action, runInAction, computed} from 'mobx';
import {
  serializable,
  deserialize,
  alias,
  primitive,
  list,
  object,
} from 'serializr';
import {currencyName} from '@youtoken/ui.coin-utils';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {AxiosError} from '@youtoken/ui.errors';
import {TRANSPORT} from '@youtoken/ui.transport';
import {
  BundlePortfolioTickersListItem,
  bundlePortfolioTickersListItemAdditionalProps,
} from '@youtoken/ui.resource-bundles';

export type CalculatedCallBack = (data: CalculatedResponse) => void;

export class Calculated {
  @observable
  loading: boolean = false;

  @observable
  error: null | AxiosError = null;

  @observable
  data?: CalculatedResponse;

  @action
  load = (props: CalculatedRequestParams, cb?: CalculatedCallBack) => {
    this.loading = true;
    return this.requestDebounced(props, cb);
  };

  @observable
  requestCanceller?: Canceler;

  @action
  request = (
    {bundleId, quoteTicker, quoteAmount, withdrawAll}: CalculatedRequestParams,
    cb?: CalculatedCallBack
  ) => {
    const {token, cancel} = axios.CancelToken.source();

    this.requestCanceller?.('__CANCELLED_REQUEST__');

    this.requestCanceller = cancel;

    this.loading = true;

    return TRANSPORT.API.get('/v1/bundle/withdraw/calculate', {
      cancelToken: token,
      params: {
        bundleId,
        quoteTicker,
        quoteAmount: Number(quoteAmount),
        withdrawAll,
      },
    })
      .then(response => {
        const data = deserialize(CalculatedResponse, response.data);

        runInAction(() => {
          this.error = null;
          this.data = data;
        });

        return data;
      })
      .then(cb)
      .catch(error => {
        runInAction(() => {
          this.error = error;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  requestDebounced = debounce(this.request, 500);
}

export interface CalculatedRequestParams {
  bundleId: string;
  quoteTicker: string;
  quoteAmount: string;
  withdrawAll: boolean;
}

export class CalculatedResponse {
  @serializable(primitive())
  onlyWithdrawAll!: boolean;

  @serializable(alias('ticker', primitive()))
  quoteTicker!: currencyName;

  @computed
  get tickerFormatted() {
    return this.quoteTicker.toUpperCase();
  }

  @serializable(bigNumber())
  fee!: Big;

  @computed
  get feeFormatted() {
    return formatByTicker(this.fee, this.quoteTicker);
  }

  @serializable(bigNumber())
  minWithdrawal!: Big;

  @computed
  get minWithdrawalFormatted() {
    return formatByTicker(this.minWithdrawal, this.quoteTicker);
  }

  @serializable(bigNumber())
  maxWithdrawal!: Big;

  @computed
  get maxWithdrawalFormatted() {
    return formatByTicker(this.maxWithdrawal, this.quoteTicker);
  }

  @serializable(bigNumber())
  totalBundleAmount!: Big;

  @computed
  get totalBundleAmountFormatted() {
    return formatByTicker(this.totalBundleAmount, this.quoteTicker);
  }

  @serializable(bigNumber())
  afterWithdrawalBalance!: Big;

  @computed
  get afterWithdrawalBalanceFormatted() {
    return formatByTicker(this.afterWithdrawalBalance, this.quoteTicker);
  }

  @serializable(
    list(
      object(
        BundlePortfolioTickersListItem,
        bundlePortfolioTickersListItemAdditionalProps
      )
    )
  )
  tickers!: BundlePortfolioTickersListItem[];
}
