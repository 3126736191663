import {computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {CryptoDepositWizardScreenName} from '../../';

interface CryptoDepositMethodsFeatureArgs {
  ticker?: string;
}

export class CryptoDepositMethodsFeature extends createFeature({
  getKey: (args: CryptoDepositMethodsFeatureArgs) =>
    `cryptoDepositMethods(${JSON.stringify(args)})`,
  getResources: () => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      verification: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @computed
  get ticker() {
    return this.args.ticker;
  }

  @computed
  get allowUma() {
    const {
      available,
      settings: {enable},
    } = this.resources.authMe.products.uma;

    if (
      this.ticker &&
      !WalletsResource.getInstanceSafely({})?.getByTicker(this.ticker)
        ?.umaEnabled
    ) {
      return false;
    }

    return available && enable;
  }

  @computed
  get methodsList(): {
    method: CryptoDepositWizardScreenName;
  }[] {
    const list: {
      method: CryptoDepositWizardScreenName;
    }[] = [
      {
        method: CryptoDepositWizardScreenName.DIRECT_DEPOSIT,
      },
    ];

    if (this.allowUma) {
      list.push({
        method: CryptoDepositWizardScreenName.UMA,
      });
    }

    return list;
  }
}
