import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {FeatureLoanCreateForm} from '../../LoanRegularCreateForm/state';
import {BoxProps, Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {Link, Switch} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {TextInput} from '@youtoken/ui.inputs';
import {Section} from '../Section';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';
import Big from 'big.js';

export const TP: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const isMobile = useIsMobile();

  const {ticker, conversionTicker, minTP, maxTP, form} =
    useForm<FeatureLoanCreateForm>();

  return (
    <Section {...props}>
      <Box flexDirection="row" mb={form.tpActive ? 24 : 0}>
        <Box flexGrow={1} flexShrink={1} mr={20}>
          <Label mb={0}>{t('surface.loans.create_form.ftp')}</Label>
        </Box>
        <Box>
          <Switch value={form.tpActive} onValueChange={form.tpActiveOnChange} />
        </Box>
      </Box>
      {form.tpActive && (
        <Box flexDirection={{tablet: 'row', default: 'column'}}>
          <Box flex={1} mb={{tablet: 0, default: 12}} position="relative">
            <TextInput
              numeric
              RightPartComponent={
                <Box
                  justifyContent="center"
                  alignItems="center"
                  flex={1}
                  px={20}
                >
                  <Text variant="$body-02" color="$text-02">
                    {ticker} / {conversionTicker}
                  </Text>
                </Box>
              }
              value={form.tp}
              onChangeText={form.tpOnChange}
              placeholder={getTickerPlaceholder(form.conversionTicker)}
              keyboardType="decimal-pad"
            />
            <FieldErrorMessage visible={form.tpHasError}>
              {form.tpError}
            </FieldErrorMessage>
          </Box>
          {!isMobile && <Box width={1} backgroundColor="$ui-03" mx={24} />}
          <Box
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text variant="$body-02" color="$text-02">
                <Trans
                  t={t}
                  i18nKey={'surface.loans.create_form.ftp_min'}
                  components={{
                    Strong: <Text />,
                  }}
                  values={{
                    amount: minTP,
                    ticker: conversionTicker,
                  }}
                />
              </Text>
              <Text variant="$body-02" color="$text-02">
                <Trans
                  t={t}
                  i18nKey={'surface.loans.create_form.ftp_max'}
                  components={{
                    Strong: <Text />,
                  }}
                  values={{
                    amount: maxTP,
                    ticker: conversionTicker,
                  }}
                />
              </Text>
            </Box>
            <Link url="https://help.youhodler.com/en/articles/3325776">
              <Box flexDirection="row" alignItems="center">
                <Box mr={2}>
                  <Icon name="question" color="$interactive-01" />
                </Box>
                <Text variant="$body-02" color="$interactive-01">
                  {t('surface.loans.create_form.ftp_how_it_works')}
                </Text>
              </Box>
            </Link>
          </Box>
        </Box>
      )}
    </Section>
  );
});

const getTickerPlaceholder = (ticker: string) => {
  return Big(0).toFixed(getCoinDecimalPrecisionForLoans(ticker));
};
