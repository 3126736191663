import * as React from 'react';
import {observer} from 'mobx-react';
import {type TKey} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {ExchangeFormState} from '../../../state';
import {ExchangeFormConversionPairField} from '../Field';

interface ExchangeFormConversionPairTargetProps {
  labelKey?: TKey;
  amountEditable?: boolean;
}

export const ExchangeFormConversionPairTarget: React.FC<
  ExchangeFormConversionPairTargetProps & BoxProps
> = observer(
  ({
    labelKey = 'surface.wallets.conversion_form_v2.to',
    amountEditable = true,
    ...boxProps
  }) => {
    const {
      form: {
        conversionTicker,
        conversionAmount,
        hasTargetError,
        targetError,
        setConversionTicker,
        setConversionAmount,
      },
      view: {conversionWallets},
    } = useForm<ExchangeFormState>();

    return (
      <ExchangeFormConversionPairField
        labelKey={labelKey}
        wallets={conversionWallets}
        ticker={conversionTicker}
        amount={conversionAmount}
        hasError={hasTargetError}
        error={targetError}
        textInputEditable={amountEditable}
        textInputAccessible={amountEditable}
        onChangeTicker={setConversionTicker}
        onChangeAmount={setConversionAmount}
        zIndex={1}
        {...boxProps}
      />
    );
  }
);
