import * as React from 'react';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router';
import {modal} from '@web-app/src/stores';
import {modals} from '@web-app/src/components/modals';
import styled from 'styled-components';
import {InsideModalContextProvider} from './InsideModalContext';
import {type Theme, useTheme} from '@youtoken/ui.primitives';

export * from './InsideModalContext';
export * from './ModalOpenedContext';

const ModalOverlay = styled.aside<{colors: Theme['colors']}>`
  background-color: ${props => props.colors['$blackout-02']};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  z-index: 300;
`;

export const Modals: React.FC = observer(() => {
  const {colors} = useTheme();

  const {listen} = useHistory();

  const {opened, close, reset} = modal;

  // NOTE: If location changed, close all modals
  React.useEffect(() => {
    const unregister = listen(reset);

    return () => {
      unregister();
    };
  }, [listen, reset]);

  if (opened) {
    return (
      <InsideModalContextProvider value={true}>
        <ModalOverlay colors={colors}>
          {modal.openedStack.map(({name, props}) => {
            const Modal = modals[name as keyof typeof modals];

            return (
              <Modal
                key={name}
                isOpen
                visible={name === opened.name} // NOTE: opened in background
                webOverlayBackgroundColor="$transparent"
                onClose={close}
                {...props}
              />
            );
          })}
        </ModalOverlay>
      </InsideModalContextProvider>
    );
  }

  return null;
});
