import z from 'zod';
import {AxiosRequestConfig} from 'axios';

export const sumsubChallengeSchema = z.object({
  type: z.literal('sumsub'),
  payload: z.object({
    level: z.string(),
  }),
});

export type SumsubChallenge = z.infer<typeof sumsubChallengeSchema>;

export const termsAndConditionsChallengeSchema = z.object({
  type: z.literal('terms-and-conditions'),
  payload: z.object({
    link: z.string(),
  }),
});

export type TermsAndConditionsChallenge = z.infer<
  typeof termsAndConditionsChallengeSchema
>;

export const challengeScheme = z.discriminatedUnion('type', [
  sumsubChallengeSchema,
  termsAndConditionsChallengeSchema,
]);

export type Challenge = z.infer<typeof challengeScheme>;

export type ChallengeResult =
  | {
      type: 'throw-original-error';
    }
  | {
      type: 'contain-original-error';
    }
  | {
      type: 'retry-request';
      payload: {
        config: AxiosRequestConfig;
      };
    };
