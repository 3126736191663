import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Tabs} from '@youtoken/ui.tabs';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DevicesList} from './DevicesList';
import {SessionsList} from './SessionsList';
import {useTabItems} from './tabsItems';
import {SecurityDevicesResource} from '@youtoken/ui.state-security';

export const TrustedDevicesSurfaceContent: React.FC = observer(() => {
  const {t} = useTranslation();

  const {data, sessionListFiltered} = SecurityDevicesResource.use({});

  const tabsItems = useTabItems(t);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleChangeTab = React.useCallback((value: string, index: number) => {
    setActiveIndex(index);
  }, []);

  return (
    <Box width="100%">
      <Box maxWidth={342} mb={32} px={{default: 16, phone: 24, desktop: 0}}>
        <Tabs
          tabs={tabsItems}
          activeIndex={activeIndex}
          onChange={handleChangeTab}
        />
      </Box>
      <Text
        variant="$body-01"
        mb={24}
        px={{default: 16, phone: 24, desktop: 0}}
      >
        {activeIndex === 0
          ? t('surface.trusted_devices.devices.description')
          : t('surface.trusted_devices.sessions.description')}
      </Text>
      {activeIndex === 0 ? (
        <DevicesList data={data} />
      ) : (
        <SessionsList data={sessionListFiltered} />
      )}
    </Box>
  );
});
