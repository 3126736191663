import * as React from 'react';
import {Trans} from 'react-i18next';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanReopenFeature} from './Feature';

type NewParametersProps = {
  loanId: string;
};

const SingleParameter: React.FC<BoxProps & {title: string}> = observer(
  ({title, children, ...boxProps}) => {
    return (
      <Box
        p={16}
        borderRadius={8}
        flexGrow={1}
        flexShrink={1}
        flexBasis={{default: '100%', tablet: 1}}
        backgroundColor="$interactive-02"
        {...boxProps}
      >
        <Text variant="$body-02-high-accent" mb={6}>
          {title}
        </Text>
        {children}
      </Box>
    );
  }
);

export const NewParameters: React.FC<NewParametersProps & BoxProps> = observer(
  ({loanId, ...boxProps}) => {
    const {
      borrowedTickerFormatted,
      aprFormatted,
      ltvFormatted,
      settlementFeeAmount,
      maxDurationInDays,
      pdlFormatted,
      until,
      showNewDuration,
    } = LoanReopenFeature.use({id: loanId});

    const {t} = useTranslation();

    return (
      <Box flexDirection="column" {...boxProps}>
        <Box flexDirection="row" justifyContent="space-between" mb={8}>
          <Text variant="$body-01-high-accent">
            {t('surface.loans.reopen_form.new_params')}
          </Text>
        </Box>
        <Box flexDirection={{default: 'column', tablet: 'row'}} width="100%">
          <SingleParameter
            title={t('surface.loans.reopen_form.params.daily_fee')}
            mr={{default: 0, tablet: showNewDuration ? 8 : 0}}
            mb={8}
          >
            <Text variant="$body-02" color="$text-02">
              <Trans
                t={t}
                i18nKey="surface.loans.reopen_form.params.apr"
                components={{
                  Strong: <Text color="$text-01" />,
                }}
                values={{
                  fee: settlementFeeAmount,
                  tickerFormatted: borrowedTickerFormatted,
                  apr: aprFormatted,
                }}
              />
            </Text>
          </SingleParameter>
          {showNewDuration && (
            <SingleParameter
              title={t('surface.loans.reopen_form.params.duration')}
              mb={8}
            >
              <Text variant="$body-02" color="$text-02">
                <Trans
                  t={t}
                  i18nKey="surface.loans.reopen_form.params.duration_until"
                  components={{
                    Strong: <Text color="$text-01" />,
                  }}
                  values={{days: maxDurationInDays, until}}
                />
              </Text>
            </SingleParameter>
          )}
        </Box>
        <Box flexDirection={{default: 'column', tablet: 'row'}} width="100%">
          <SingleParameter
            title={t('surface.loans.reopen_form.params.pdl')}
            mr={{default: 0, tablet: 8}}
            mb={{default: 8, tablet: 0}}
          >
            <Text variant="$body-02" color="$text-02">
              <Text color="$text-01">{pdlFormatted}</Text>{' '}
              {borrowedTickerFormatted}
            </Text>
          </SingleParameter>
          <SingleParameter title={t('surface.loans.create_form.term_ltv')}>
            <Text variant="$body-02" color="$text-02">
              <Text color="$text-01">{ltvFormatted}</Text> %
            </Text>
          </SingleParameter>
        </Box>
      </Box>
    );
  }
);
