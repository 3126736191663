import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, Box, type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {type TMenuItem} from '@youtoken/ui.screen-components';
import {Dropdown} from './Dropdown';
import {WebMenuItems} from './WebMenuItems';

type MenuButtonProps = {};

const menuItems: TMenuItem[] = [
  {icon: 'wallets', i18nKey: 'surface.menu.wallets', to: 'wallets'},
  {icon: 'get_loan', i18nKey: 'surface.menu.loans', to: 'loans'},
  {
    icon: 'hodl',
    i18nKey: 'surface.menu.multihodl',
    to: 'multihodl',
  },
  {
    icon: 'mining',
    i18nKey: 'surface.menu.cloud_miner',
    to: 'miner',
  },
  {
    icon: 'turbo',
    i18nKey: 'surface.menu.turbos',
    to: 'turbocharge',
  },
  {
    icon: 'history',
    i18nKey: 'surface.menu.history_short',
    to: 'account-stats',
  },
];

export const MenuButton: React.FC<MenuButtonProps & BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    // todo: restore this logic
    // const {forceIdentify} = AuthMeResource.use({});

    const menuItem = menuItems[0] as TMenuItem;

    return (
      <Box {...boxProps}>
        <Dropdown
          renderTrigger={({toggle, isOpen}) => (
            <TouchableBox
              bg={isOpen ? '$interactive-01' : '$interactive-02'}
              borderRadius={8}
              py={6}
              px={8}
              flexDirection="row"
              alignItems="center"
              gap={4}
              onPress={toggle}
            >
              <Icon
                name={menuItem.icon}
                size={20}
                color={isOpen ? '$text-04' : '$text-05'}
              />
              <Text
                variant="$body-02-medium-accent"
                color={isOpen ? '$text-04' : '$text-05'}
              >
                {t(menuItem.i18nKey)}
              </Text>
              {isOpen ? (
                <Icon name="chevron_up" color="$text-04" size={16} />
              ) : (
                <Icon name="chevron_down" color="$text-05" size={16} />
              )}
            </TouchableBox>
          )}
          renderItems={({toggle}) => (
            <WebMenuItems variant="dropdown" toggle={toggle} />
          )}
        />
      </Box>
    );
  }
);
