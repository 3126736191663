import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {
  MODAL_RADIUS,
  ModalDynamic,
  type ModalDynamicProps,
} from '@youtoken/ui.modal';
import {HistoryItemDetailsSurface} from '../HistoryItemDetailsSurface';

type HistoryItemModalProps = {
  id: string;
} & Omit<ModalDynamicProps, 'children'>;

export const HistoryItemModal = observer(
  ({id, onClose, ...modalProps}: HistoryItemModalProps) => {
    return (
      <ModalDynamic onClose={onClose} {...modalProps}>
        <HistoryItemDetailsSurface
          id={id}
          onClose={onClose}
          pb={Platform.select({default: 0, web: 24})}
          borderRadius={MODAL_RADIUS}
          overflow="hidden"
        />
      </ModalDynamic>
    );
  }
);
