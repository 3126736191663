import * as React from 'react';

export const SurfaceMetaContext = React.createContext<{
  name: string;
}>({name: ''});

export function SurfaceMetaProvider<Name extends string>({
  children,
  name,
}: {
  children: React.ReactNode;
  name: Name;
}) {
  const value = React.useMemo(() => ({name}), [name]);
  return (
    <SurfaceMetaContext.Provider value={value}>
      {children}
    </SurfaceMetaContext.Provider>
  );
}

export function useSurfaceMeta() {
  return React.useContext(SurfaceMetaContext).name;
}
