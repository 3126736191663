import * as React from 'react';
import {Platform} from 'react-native';
import {ExchangeForm} from '@youtoken/ui.surfaces-wallets';
import {useWizard} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HeaderBase} from '../../../components';
import {type ConvertWizardParams} from '..';

const isNative = Platform.select({
  default: false,
  native: true,
});

export const ConvertStep = () => {
  const {
    step: {
      props,
      props: {fromTicker, toTicker, initialAmount, orderType, desiredRate},
    },
  } = useWizard<ConvertWizardParams, 'Convert'>();

  const {t} = useTranslation();

  return (
    <>
      {!isNative && (
        <HeaderBase shouldShowSeparator>
          {t('surface.wallets.conversion_form.title')}
        </HeaderBase>
      )}
      <ExchangeForm
        ticker={fromTicker}
        conversionTicker={toTicker}
        initialAmount={initialAmount}
        orderType={orderType}
        desiredRate={desiredRate}
      />
    </>
  );
};
