import React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {
  HODLByIdResource,
  HODLItem,
  HodlStatus,
} from '@youtoken/ui.resource-hodl';
import {AdjustPendingOrderFormClass} from './form';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {
  ActionsSection,
  PendingOrderSection,
  StopLossSection,
  TakeProfitSection,
} from './sections';
import {Separator} from '@youtoken/ui.elements';
import {subtitleProps} from '../Position';

export interface AdjustPendingOrderSurfaceProps {
  hodl: HODLItem;
  onCancel: () => void;
  onSuccess: () => void;
  onSubtitlePropsChange: (props: subtitleProps) => void;
}

export const AdjustPendingOrderSurface: React.FC<AdjustPendingOrderSurfaceProps> =
  observer(({hodl, onCancel, onSuccess, onSubtitlePropsChange}) => {
    const resources = useResources({
      rates: getResourceDescriptor(RatesResource, {
        product: 'hodl',
      }),
      tariffs: getResourceDescriptor(HodlsTariffsResource, {}),
      hodl: getResourceDescriptor(HODLByIdResource, {id: hodl.id}),
    });

    const form = makeForm(
      () =>
        new AdjustPendingOrderFormClass(resources, {
          hodl,
          onSuccess,
        })
    );

    const {view} = form;

    // NOTE: to update subtitle props
    React.useEffect(() => {
      onSubtitlePropsChange({
        baseTicker: view.baseTickerFormatted,
        quoteTicker: view.quoteTickerFormatted,
        currentPriceFormatted: view.currentPriceFormatted,
      });
    }, [
      view.baseTickerFormatted,
      view.quoteTickerFormatted,
      view.currentPriceFormatted,
    ]);

    // NOTE: close modal in case of MH triggered / closed
    React.useEffect(() => {
      if (
        [
          HodlStatus.CLOSED,
          HodlStatus.CLOSING,
          HodlStatus.OPEN,
          HodlStatus.DECLINED,
          HodlStatus.CANCELED,
        ].includes(hodl.status)
      ) {
        onCancel();
      }
    }, [hodl.status]);

    React.useEffect(() => {
      return () => {
        form.form.resetCancelToken();
      };
    }, []);

    return (
      <Box>
        <Form form={form}>
          <PendingOrderSection p={24} pt={0} />
          <Separator />
          <TakeProfitSection mt={24} />
          <StopLossSection mt={20} />
          <ActionsSection onCancel={onCancel} />
        </Form>
      </Box>
    );
  });
