export const thousandMask = (rawValue: string = ''): (string | RegExp)[] => {
  if (!rawValue) return [];

  // Remove commas and extract integer/decimal parts
  const [integerPart = '', decimalPart = ''] = rawValue
    .replace(/,/g, '')
    .split('.');

  // Format integer part with thousand separators
  const formattedInteger = integerPart
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Format decimal part
  const cleanedDecimal = decimalPart.replace(/\D/g, '');
  const formatted = rawValue.includes('.')
    ? `${formattedInteger}.${cleanedDecimal}`
    : formattedInteger;

  // Generate mask array
  return formatted
    .split('')
    .map(char => (char === ',' ? char : char === '.' ? /\./ : /\d/));
};
