import {z} from 'zod';
import * as React from 'react';
import {surface} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {AccountStatsSurfaceContent} from './Content';

export const AccountStatsSurface = surface(
  {
    name: 'AccountStats',
    paramsSchema: z.object({}),
    headerShown: true,
    getTitle: () => i18n.t('common.title.transactions'),
  },
  () => {
    return <AccountStatsSurfaceContent />;
  }
);
