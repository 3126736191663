import * as React from 'react';
import {Platform} from 'react-native';
import {ActivityIndicator, Separator} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {invariant} from '@youtoken/ui.utils';
import {Icon} from '@youtoken/ui.icons';
import {cell} from '@youtoken/ui.cell';
import {WithdrawalWizardScreenName} from '../../../../smart';
import {type MethodData} from '../../types';
import {
  WithdrawalMethodsFeature,
  type WithdrawalMethodsFeatureArgs,
  type FeatureMethodItem,
} from './state/WithdrawalMethodsFeature';
import {MethodItem} from '../MethodItem';

export interface WithdrawalMethodsProps {
  ticker?: string;
  variant: WithdrawalMethodsFeatureArgs['variant'];
  onChange: (method: WithdrawalWizardScreenName) => void;
}

export const WithdrawalMethods: React.FC<WithdrawalMethodsProps> = cell(
  ({ticker, variant, onChange}) => {
    const {t} = useTranslation();

    const {
      fiatMethodsList,
      cryptoMethodsList,
      universalMethodsList,
      bankCardFeeFormatted,
      bankWireFeeFormatted,
      createHandleMethodPress,
      showFiatMethods,
      showCryptoMethods,
      showUniversalMethods,
    } = WithdrawalMethodsFeature.use({ticker, variant});

    const methodsData: {[key in WithdrawalWizardScreenName]?: MethodData} =
      React.useMemo(
        () => ({
          [WithdrawalWizardScreenName.BANK_WIRE]: {
            title: t('surface.wallets.fiat_withdrawal.bank_wire'),
            fee: bankWireFeeFormatted,
            description: !ticker
              ? t('surface.wallets.fiat_withdrawal.bank_account_description')
              : '',
            icon: <Icon name="bank_wire" color="$interactive-01" />,
            testID: 'FIAT_WITHDRAWAL_METHOD_BANK_WIRE',
            tags: ['iban', 'sepa'],
          },
          [WithdrawalWizardScreenName.BANK_CARD]: {
            title: t('surface.wallets.fiat_deposit.item_card_title'),
            fee: bankCardFeeFormatted,
            description: !ticker
              ? t('surface.wallets.fiat_withdrawal.bank_card_description')
              : '',
            icon: <Icon name="card" color="$interactive-01" />,
            testID: 'FIAT_WITHDRAWAL_METHOD_CREDIT_CARDS',
            tags: ['visa', 'mastercard'],
          },
          [WithdrawalWizardScreenName.UMA_WITHDRAWAL]: {
            title: t('surface.wallets.uma'),
            icon: <Icon name="uma" color="$interactive-01" />,
            testID: 'WITHDRAWAL_METHOD_UMA',
          },
          [WithdrawalWizardScreenName.CRYPTO_DIRECT]: {
            title: t('surface.wallets.direct_to_wallet'),
            icon: <Icon name="crypto_withdraw" color="$interactive-01" />,
            testID: 'CRYPTO_WITHDRAWAL_METHOD',
          },
        }),
        [t, bankCardFeeFormatted, bankWireFeeFormatted, ticker]
      );

    const renderItems = React.useCallback(
      (methodsList: FeatureMethodItem[]) => {
        return methodsList.map(({method, type, disabled}, index) => {
          invariant(
            methodsData[method],
            `Method ${method} is not supported`,
            {},
            {methodsData, method}
          );

          const methodItemData = methodsData[method]!;

          return (
            <MethodItem
              key={method}
              disabled={disabled}
              onPress={
                !disabled
                  ? createHandleMethodPress(method, type, onChange)
                  : () => {}
              }
              isLast={index === methodsList.length - 1}
              {...methodItemData}
            />
          );
        });
      },
      []
    );

    const renderHeader = React.useCallback(
      (title: string, isTherePreviousElement: boolean = false) => {
        const shouldShowSeparator =
          Platform.OS === 'web'
            ? isTherePreviousElement
            : !isTherePreviousElement;

        return (
          <>
            {shouldShowSeparator && <Separator />}
            <Box py={12} px={16}>
              <Text variant="$body-02" color="$text-02">
                {title}
              </Text>
            </Box>
            <Separator />
          </>
        );
      },
      []
    );

    return (
      <>
        {showFiatMethods && (
          <Box>
            {variant === 'all' &&
              renderHeader(
                t('surface.wallets.withdrawal_wizard.fiat_methods_title')
              )}
            {renderItems(fiatMethodsList)}
          </Box>
        )}
        {showCryptoMethods && (
          <Box>
            {variant === 'all' &&
              renderHeader(
                t('surface.wallets.withdrawal_wizard.crypto_methods_title'),
                showFiatMethods
              )}
            {renderItems(cryptoMethodsList)}
          </Box>
        )}
        {showUniversalMethods && (
          <Box>
            {renderHeader(
              t('surface.wallets.withdrawal_wizard.universal_methods_title'),
              showFiatMethods || showCryptoMethods
            )}
            {renderItems(universalMethodsList)}
          </Box>
        )}
      </>
    );
  },
  {
    SuspenseFallback: () => (
      <Box minHeight={80} justifyContent="center" alignItems="center">
        <ActivityIndicator size="large" />
      </Box>
    ),
  }
);
