import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BundlePortfolio, BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

interface BundleGeneralActionsProps {
  data: {
    tariff: BundleTariff;
    userPortfolio: BundlePortfolio | null;
  };
}

export const BundleGeneralActions: React.FC<
  BundleGeneralActionsProps & BoxProps
> = observer(
  ({
    testID = 'BUNDLE_ACTIONS',
    data: {
      tariff: {id},
      userPortfolio,
    },
    ...boxProps
  }) => {
    const {t} = useTranslation();

    const disabled = userPortfolio?.status === 'CREATING';

    const handlePressDeposit = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('BundleDeposit', {
        id,
      });
    }, [id]);

    const handlePressWithdrawal = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('BundleWithdrawal', {
        id,
      });
    }, [id]);

    const handlePressRelease = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('BundleRelease', {
        id,
      });
    }, [id]);

    if (userPortfolio) {
      return (
        <Box
          testID={testID}
          flexDirection={{default: 'column', tablet: 'row'}}
          gap={8}
          {...boxProps}
        >
          <Button
            testID="BUY_MORE_BUTTON"
            flex={{tablet: 1}}
            size="large"
            disabled={disabled}
            onPress={handlePressDeposit}
          >
            {t('surface.bundle.actions.buy_more')}
          </Button>
          <Button
            testID="WITHDRAW_BUTTON"
            flex={{tablet: 1}}
            type="secondary"
            size="large"
            disabled={disabled}
            onPress={handlePressWithdrawal}
          >
            {t('surface.bundle.actions.withdraw')}
          </Button>
          <Button
            testID="RELEASE_BUTTON"
            flex={{tablet: 1}}
            type="secondary"
            size="large"
            disabled={disabled}
            onPress={handlePressRelease}
          >
            {t('surface.bundle.actions.release')}
          </Button>
        </Box>
      );
    }

    return (
      <Box testID={testID} {...boxProps}>
        <Button testID="BUY_BUTTON" onPress={handlePressDeposit}>
          {t('surface.bundle.actions.buy')}
        </Button>
      </Box>
    );
  }
);
