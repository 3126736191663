import * as React from 'react';
import {TSurfaceOptions} from './types';
import {useSurfaceOptions} from '../SurfaceOptionsProvider';

export function SurfaceOptions<Params extends {}>(
  options: TSurfaceOptions<Params>
) {
  const {setOptions} = useSurfaceOptions<Params>();

  React.useLayoutEffect(() => {
    if (options && Object.keys(options).length) {
      setOptions(options);
    }
  }, [
    options.getTitle,
    options.headerLeft,
    options.headerRight,
    options.headerShown,
  ]);

  return null;
}
