import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';
import {cell} from '@youtoken/ui.cell';

type MinerRewardsButtonProps = {
  showText: boolean;
  onPress: () => void;
};

export const MinerRewardsButton: React.FC<MinerRewardsButtonProps> = cell(
  ({showText, onPress}) => {
    const {t} = useTranslation();

    const {
      overview: {
        conditions: {percentFormatted},
      },
    } = NewReferralOverviewResource.use({});

    return (
      <TouchableBox
        bg="$attention-02"
        borderRadius={16}
        gap={4}
        py={6}
        px={showText ? 8 : 6}
        flexDirection="row"
        alignItems="center"
        onPress={onPress}
      >
        <Icon name="gift" size={20} color="$attention-01" />
        {showText && (
          <Text variant="$body-01" color="$attention-01">
            {t('surface.top_bar.referral_badge', {percent: percentFormatted})}
          </Text>
        )}
      </TouchableBox>
    );
  },
  {
    ErrorFallback: () => {
      return null;
      return (
        <Box
          bg="$attention-02"
          borderRadius={16}
          gap={4}
          p={6}
          flexDirection="row"
          alignItems="center"
        >
          <Icon name="gift" size={20} color="$attention-01" />
          <Text variant="$body-01" color="$attention-01">
            0%
          </Text>
        </Box>
      );
    },
  }
);
