import {without} from 'lodash';
import {matchSorter} from 'match-sorter';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {WalletsChooseTickerMethod} from '../types';

export const getItemsBySearch = (wallets: Wallet[], search: string) => {
  if (search.length > 0) {
    return matchSorter(wallets, search, {
      keys: ['ticker', 'tickerName', item => item.versions.map(v => v.version)],
    });
  }

  return wallets;
};

//#region filters
export const toggleFilter = (filters: string[], filter: string) => {
  if (filters.includes(filter)) {
    return without(filters, filter);
  }

  return [...filters, filter];
};
//#endregion filters

export const getIsMethodEnabled = (
  wallet: Wallet,
  method: WalletsChooseTickerMethod
) => {
  switch (method) {
    case 'addWallet':
      return wallet.createEnabled;
    case 'buyCrypto':
      return wallet.marketToEnabled;
    case 'deposit':
      return wallet.depositEnabled;
    case 'withdrawBankWire':
    case 'withdrawBankCard':
    case 'withdrawCryptoDirect':
      return wallet.withdrawEnabled;
    case 'withdrawUma':
      return wallet.umaEnabled;
    default:
      return false;
  }
};
