import type {IObservableArray} from 'mobx';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {invariant} from '@youtoken/ui.utils';
import {WalletsChooseTickerMethod} from '../../../surfaces/wallets/src/components';
import {WalletsMethodTickersResponse} from './WalletsMethodTickersResponse';

export {WalletsMethodTickersResponse} from './WalletsMethodTickersResponse';

export type WalletsMethodTickersResourceMethods = Exclude<
  WalletsChooseTickerMethod,
  'deposit' | 'buyCrypto' | 'addWallet'
>;

interface WalletsMethodTickersResourceArgs {
  type: 'deposit' | 'withdrawal';
  method: WalletsMethodTickersResourceMethods;
}

const methodMapping: Record<WalletsMethodTickersResourceMethods, string> = {
  withdrawBankCard: 'bankCard',
  withdrawBankWire: 'bankWire',
  withdrawCryptoDirect: 'crypto',
  withdrawUma: 'uma',
};

export class WalletsMethodTickersResource extends createStaticResource<
  WalletsMethodTickersResourceArgs,
  WalletsMethodTickersResponse[]
>({
  getKey: args => `WalletsMethodTickersResource:${JSON.stringify(args)}`,
  getData: ({type, method}) => {
    invariant(methodMapping[method], `Unacceptable method: ${method}`);

    return TRANSPORT.API.get(
      `/v1/${type}/methods/${methodMapping[method]}/tickers`
    ).then(res => {
      return deserialize(
        WalletsMethodTickersResponse,
        res.data as IObservableArray<WalletsMethodTickersResponse>
      );
    });
  },
}) {}
