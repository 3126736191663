import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TextInput} from '@youtoken/ui.inputs';
import {Label, useForm} from '../../../../../components';
import {Ticker} from './Ticker';
import {FeatureLoanFTPForm} from '../../state';

export const ConversionTicker: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {expectedReturn} = useForm<FeatureLoanFTPForm>();

  return (
    <Box zIndex={20} width="100%" {...props}>
      <Label>{t('surface.loans.ftp_form.conversion_amount')}</Label>
      <TextInput
        numeric
        editable={false}
        focusable={false}
        value={expectedReturn}
        textAlign="right"
        LeftPartComponent={<Ticker />}
        placeholder="0.00"
      />
    </Box>
  );
});
