import * as React from 'react';
import {Platform} from 'react-native';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {FiatDepositVisaBanner, Providers} from '@youtoken/ui.surfaces-wallets';
import {FeeDepositResource, FeeAllResource} from '@youtoken/ui.resources-fee';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import {Form, Label, makeForm} from '@youtoken/ui.form-elements';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Select, type SelectItem} from '@youtoken/ui.select';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import PlatformPayment from '@youtoken/ui.platform-payment';
import {CardsResource} from '@youtoken/ui.resource-cards';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {IconPaymentMethod} from '@youtoken/ui.icons';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {cell} from '@youtoken/ui.cell';
import {FeeRow, PaySumRow, HeaderBase} from '../../../components';
import {CardDepositForm} from './state';
import {
  IncentivesBonuses,
  RiskJsComponent,
  WithdrawSumField,
} from './components';
import {
  CardDepositWizardNavigator,
  CardDepositWizardScreenName,
} from '../index';

export const CardDepositSurface: React.FC = cell(() => {
  const {
    step: {
      props: {ticker, provider, method},
    },
    navigate,
    goBack,
    close,
  } = useWizard<CardDepositWizardNavigator, CardDepositWizardScreenName.MAIN>();

  const {t} = useTranslation();

  const resources = useResources({
    feeDeposit: getResourceDescriptor(FeeDepositResource, {
      ticker,
      provider: provider,
    }),
    feeAll: getResourceDescriptor(FeeAllResource, {}),
    authMe: getResourceDescriptor(AuthMeResource, {}),
    rates: getResourceDescriptor(RatesResource, {}),
    cards: getResourceDescriptor(CardsResource, {}),
  });

  React.useEffect(() => {
    DATA_LAYER.trackStrict('deposit-fiat-attempt', {
      type: 'fiat',
      category: 'deposit',
      provider: `${provider}BankCard`,
      ticker,
    });
  }, [ticker]);

  const conversionTicker = React.useMemo(() => {
    return (ticker === 'eur' || ticker === 'usd') &&
      resources.authMe.residenceOrCountry === 'ARG'
      ? 'ars'
      : undefined;
  }, [ticker, resources.authMe.residenceOrCountry]);

  const {view, form} = makeForm(() => {
    return new CardDepositForm(
      {
        ticker,
        conversionTicker,
        provider: provider,
        onSubmit: close,
        method,
      },
      resources
    );
  });

  const {
    tickerFees,
    cardsList,
    isCardsListEmpty,
    setCardId,
    selectedCardId,
    isSubmitDisabled,
    isSubmitLoading,
    performApplePay,
    instance: {onSubmit, submitting},
  } = form;
  const {tickerFormatted, conversionTickerFormatted, paySumFormatted} = view;

  const handlePressManage = React.useCallback(() => {
    navigate(CardDepositWizardScreenName.PAYMENT_METHODS);
  }, [navigate]);

  const selectItems: SelectItem[] = React.useMemo(
    () => [
      // NOTE: Main items
      ...cardsList.map(card => {
        const cardBrandFormatted = card.brand.toLowerCase() as
          | 'visa'
          | 'mastercard';

        return {
          value: card.id,
          label: `• ${card.last4}`,
          icon: (
            <Box height={20} flexDirection="row" alignItems="center">
              <IconPaymentMethod name={cardBrandFormatted} size={32} />
            </Box>
          ),
        };
      }),
      // NOTE: Action button (Manage cards)
      {
        type: 'action-button',
        label: t('surface.wallets.manage'),
        onPress: handlePressManage,
      },
    ],
    [cardsList, handlePressManage]
  );

  const handleSubmitWithAddCard = React.useCallback(
    (cardId: string) => {
      setCardId(cardId);

      // NOTE: preventDefault is required avoid conflict with submitting Checkout Frames form inside our MobxReactForm
      onSubmit({
        preventDefault: () => {},
      });
    },
    [navigate, onSubmit]
  );

  const handleNavigateToAddCard = React.useCallback(() => {
    navigate(CardDepositWizardScreenName.ADD_CARD_WITH_PAYMENT, {
      onSubmit: handleSubmitWithAddCard,
      ticker: ticker.toUpperCase(),
    });
  }, [navigate]);

  if (!tickerFees) {
    return null;
  }

  return (
    <>
      {Platform.OS === 'web' && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {t(
            method === 'checkoutApplePay'
              ? 'surface.wallets.fiat_deposit_with'
              : 'surface.wallets.fiat_deposit_card.title',
            {
              ticker: ticker.toUpperCase(),
              provider: t(
                'web_app.wallets.fiat_deposit.item_apple_pay_title.default'
              ),
            }
          )}
        </HeaderBase>
      )}
      <KeyboardScrollView
        insideTabs={false}
        wrapInSafeArea
        scrollViewProps={{
          keyboardDismissMode: 'interactive',
          style: {flex: 1},
          contentContainerStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <Box flex={1} py={24}>
          <Form form={{view, form}}>
            {provider === Providers.CHECKOUT && <RiskJsComponent />}
            <Box flex={1} justifyContent="space-between">
              <Box zIndex={10}>
                <Box px={24}>
                  <WithdrawSumField mb={24} />

                  <IncentivesBonuses mb={24} />
                  {method === 'checkoutIframe' && !isCardsListEmpty && (
                    <>
                      <Label mb={8}>
                        {t(
                          'surface.wallets.checout_bank_card.select_card_action'
                        )}
                      </Label>
                      <Box mb={24}>
                        <Select
                          bottomSheetTitle={t(
                            'surface.wallets.checout_bank_card.select_card_action'
                          )}
                          items={selectItems}
                          onSelect={setCardId}
                          selected={selectedCardId}
                          size="large"
                          width="100%"
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box>
                <FeeRow px={24} />
                <PaySumRow
                  px={24}
                  value={paySumFormatted}
                  ticker={conversionTickerFormatted || tickerFormatted}
                  text={t('ramp.conversion_form.table.total_pay')}
                />
                {method === 'checkoutApplePay' &&
                PlatformPayment.canMakePayments() ? (
                  <Button
                    disabled={isSubmitDisabled || submitting}
                    loading={isSubmitLoading || submitting}
                    onPress={performApplePay}
                    domType="submit"
                    size="large"
                    mx={24}
                    borderRadius={6}
                    icon="os_mac"
                    color="apple-pay"
                    testID="APPLE_PAY_DEPOSIT_SUBMIT_BUTTON"
                  >
                    {t('surface.apple_pay.deposit.components.submit.default')}
                  </Button>
                ) : (
                  <Box
                    flexDirection="row"
                    width="100%"
                    justifyContent="space-around"
                    px={24}
                  >
                    <Box flex={1} justifyContent="center">
                      <Text variant="$body-02" color="$text-02" pr={10}>
                        {t('surface.wallets.fiat_deposit.redirect_notice', {
                          providerUrl:
                            provider === Providers.UNLIMINT
                              ? 'cardpay.com'
                              : provider === Providers.SKRILL
                              ? 'pay.skrill.com'
                              : 'checkout.com',
                        })}
                      </Text>
                    </Box>
                    <Box flex={1} pl={10}>
                      <Button
                        disabled={isSubmitDisabled || submitting}
                        loading={isSubmitLoading || submitting}
                        onPress={
                          method === 'checkoutIframe' && isCardsListEmpty
                            ? handleNavigateToAddCard
                            : onSubmit
                        }
                        size="large"
                        testID="FIAT_DEPOSIT_SUBMIT_BUTTON"
                        color="interactive"
                      >
                        {method === 'checkoutIframe' && isCardsListEmpty
                          ? t(
                              'surface.wallets.fiat_deposit_advcash.continue_button'
                            )
                          : t('surface.wallets.fiat_deposit.title', {
                              ticker: ticker.toUpperCase(),
                            })}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Form>
        </Box>

        {provider === Providers.UNLIMINT && <FiatDepositVisaBanner />}
      </KeyboardScrollView>
    </>
  );
});
