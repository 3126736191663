import * as React from 'react';
import {Pressable} from 'react-native';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useForm} from '@youtoken/ui.form-elements';
import {ConversionUnauthorizedFormClass, ExchangeFormState} from '../../state';

const REVERSE_BUTTON_SIZE = 36;
const REVERSE_BUTTON_PRESSED_SIZE = 32;

export const ExchangeFormReverseButton: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      form: {swapEnable, swapOnPress},
    } = useForm<ConversionUnauthorizedFormClass | ExchangeFormState>();

    return (
      <Box zIndex={2} pt={32} {...boxProps}>
        <Box
          alignSelf="center"
          justifyContent="center"
          alignItems="center"
          width={REVERSE_BUTTON_SIZE}
          height={REVERSE_BUTTON_SIZE}
          position="absolute"
          top={12}
        >
          <Pressable disabled={!swapEnable} onPress={swapOnPress}>
            {({pressed}) => (
              <Box
                bg={swapEnable ? '$interactive-02' : '$ui-02'}
                width={
                  pressed ? REVERSE_BUTTON_SIZE : REVERSE_BUTTON_PRESSED_SIZE
                }
                height={
                  pressed ? REVERSE_BUTTON_SIZE : REVERSE_BUTTON_PRESSED_SIZE
                }
                borderRadius={16}
                justifyContent="center"
                alignItems="center"
                style={{transform: [{rotate: '90deg'}]}}
              >
                <Icon
                  name="convert"
                  color={swapEnable ? '$interactive-01' : '$text-01'}
                />
              </Box>
            )}
          </Pressable>
        </Box>
      </Box>
    );
  }
);
