import * as React from 'react';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';
import {CardsResource} from '@youtoken/ui.resource-cards';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {TRANSPORT} from '@youtoken/ui.transport';
import {Button} from '@youtoken/ui.buttons';
import {ModalDynamic} from '@youtoken/ui.modal';
import {cell} from '@youtoken/ui.cell';

interface ManagePaymentMethodsSurfaceProps {
  isOpen: boolean;
  onClose: () => void;
  cardId: string;
}

export const ManagePaymentMethodModal: React.FC<ManagePaymentMethodsSurfaceProps> =
  cell(({cardId, isOpen, onClose}) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const {refetch: refetchCardsResource} = CardsResource.use({});

    const handlePressRemoveCard = React.useCallback(() => {
      setIsLoading(true);
      TRANSPORT.API.delete(`/v2/cards/${cardId}`, {
        data: {
          provider: 'checkoutBankCard',
        },
      })
        .then(() => {
          refetchCardsResource().finally(() => {
            onClose();
          });
        })
        .catch(error => {
          handleGeneralErrorTranslated(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [onClose, cardId]);

    return (
      <ModalDynamic
        title={t('surface.wallets.checout_bank_card.remove_card_action')}
        isOpen={isOpen}
        onClose={onClose}
        webMinHeight={150}
      >
        <Box p={24}>
          <Box alignItems="center" pb={24}>
            <Text color="$text-02">
              {t('surface.wallets.checout_bank_card.remove_card_disclosure')}
            </Text>
          </Box>
          <Box flexDirection="row">
            <Box flexBasis={0} flexGrow={1} mr={16}>
              <Button
                type="secondary"
                size="large"
                onPress={onClose}
                testID="CANCEL_BUTTON"
              >
                {t('surface.wallets.cancel')}
              </Button>
            </Box>
            <Box flexBasis={0} flexGrow={1}>
              <Button
                type="primary"
                size="large"
                onPress={handlePressRemoveCard}
                disabled={isLoading}
                loading={isLoading}
                testID="REMOVE_CONFIRMATION_BUTTON"
              >
                {t('surface.wallets.item.remove')}
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalDynamic>
    );
  });
