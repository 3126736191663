export enum DepositMethodRampEnum {
  BANK_CARD = 'bankCard',
  BANK_CARD_FRAME = 'bankCardFrame',
  APPLE_PAY = 'applePay',
  BANK_WIRE = 'bankWire',
  VOLET = 'volet',
  SKRILL = 'skrill',
  YH_BALANCE = 'yhBalance',
}

export type DepositMethodType = 'instant' | 'later';
