import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {CryptoDepositMethodsFeature} from './state/CryptoDepositMethodsFeature';
import type {MethodData} from '../../../components/PaymentMethods/types';
import {HeaderBase, MethodItem} from '../../../components';
import {
  CryptoDepositWizardScreenName,
  type CryptoDepositWizardNavigator,
} from '../index';

export const getTitle = (ticker?: string) => {
  return ticker
    ? i18n.t('surface.wallets.crypto_deposit.title', {
        ticker: ticker.toUpperCase(),
      })
    : i18n.t('surface.wallets.withdrawal_wizard.select_method');
};

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CryptoDepositMethods: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    step: {
      props: {ticker},
    },
    navigate,
    goBack,
  } = useWizard<
    CryptoDepositWizardNavigator,
    CryptoDepositWizardScreenName.CHOOSE_METHOD
  >();

  const {methodsList} = CryptoDepositMethodsFeature.use({ticker});

  const methodsData: Partial<
    Record<CryptoDepositWizardScreenName, MethodData>
  > = React.useMemo(
    () => ({
      [CryptoDepositWizardScreenName.DIRECT_DEPOSIT]: {
        title: t('surface.wallets.direct_to_wallet'),
        icon: <Icon name="crypto_deposit" color="$text-05" />,
        testID: 'CRYPTO_DEPOSIT_METHOD_DIRECT',
      },
      [CryptoDepositWizardScreenName.UMA]: {
        title: t('surface.wallets.uma'),
        description: t('surface.wallets.uma.withdrawal.duration'),
        icon: <Icon name="uma" color="$interactive-01" />,
        testID: 'CRYPTO_DEPOSIT_METHOD_UMA',
      },
    }),
    [t]
  );

  const handleChangeMethod = React.useCallback(
    (method: CryptoDepositWizardScreenName) => {
      if (
        ticker ||
        // NOTE: UMA deposits don't require a ticker
        method === CryptoDepositWizardScreenName.UMA
      ) {
        navigate(method, {ticker});
        return;
      }

      navigate(CryptoDepositWizardScreenName.CHOOSE_TICKER, {method});
    },
    [navigate, ticker]
  );

  return (
    <>
      {showHeader && (
        <>
          <HeaderBase onPressBack={goBack}>{getTitle(ticker)}</HeaderBase>
          <Separator />
        </>
      )}
      {methodsList.map(({method}, index) => (
        <MethodItem
          key={method}
          onPress={() => handleChangeMethod(method)}
          isLast={index === methodsList.length - 1}
          {...methodsData[method]!}
        />
      ))}
    </>
  );
});
