import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {TouchableBox, YStack, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';

interface NetworkSelectorItemProps {
  titleFormatted: string;
  feeStringFormatted?: string;
  networkVersion: string;
  onPress: (networkVersion: string) => void;
  isLast: boolean;
}

export const NetworkSelectorItem: React.FC<NetworkSelectorItemProps> = observer(
  ({titleFormatted, feeStringFormatted, networkVersion, onPress, isLast}) => {
    const handlePress = React.useCallback(
      () => onPress(networkVersion),
      [networkVersion]
    );

    return (
      <>
        <TouchableBox
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={24}
          gap={16}
          onPress={handlePress}
          testID={`NETWORK_SELECTOR_ITEM_${networkVersion.toUpperCase()}`}
        >
          <YStack gap={8}>
            <Text variant="$body-01-high-accent">{titleFormatted}</Text>
            {Boolean(feeStringFormatted) && (
              <Text variant="$body-02" color="$text-02">
                {feeStringFormatted}
              </Text>
            )}
          </YStack>
          <Icon name="chevron_right" size={20} color="$text-02" />
        </TouchableBox>
        {(!isLast || Platform.OS !== 'web') && <Separator />}
      </>
    );
  }
);
