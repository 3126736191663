import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardLevel} from '@youtoken/ui.resource-rewards-v3';
import {Box} from '@youtoken/ui.primitives';
import {RewardsLevelItem} from './Item';

export interface RewardsLevelProps {
  level: RewardLevel;
  showActions?: boolean;
}

export const RewardsLevel: React.FC<RewardsLevelProps> = observer(
  ({
    level: {
      currentDeposit,
      requiredDeposit,
      currentVolume,
      requiredVolume,
      currentDepositFormatted,
      requiredDepositFormatted,
      currentVolumeFormatted,
      requiredVolumeFormatted,
    },
    showActions = true,
  }) => {
    return (
      <Box alignItems="center" mt={12}>
        <Box
          width={20}
          height={20}
          bg="$fill-01"
          borderTopLeftRadius={4}
          position="absolute"
          top={2}
          style={{
            transform: [{rotate: '45deg'}],
          }}
        />
        <Box
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          bg="$fill-01"
          px={8}
          py={16}
          mt={12}
          borderRadius={8}
        >
          <RewardsLevelItem
            type="deposit"
            value={currentDeposit}
            requiredValue={requiredDeposit}
            valueFormatted={currentDepositFormatted}
            requiredValueFormatted={requiredDepositFormatted}
            showAction={showActions}
          />
          <RewardsLevelItem
            type="trade"
            value={currentVolume}
            requiredValue={requiredVolume}
            valueFormatted={currentVolumeFormatted}
            requiredValueFormatted={requiredVolumeFormatted}
            showAction={showActions}
          />
        </Box>
      </Box>
    );
  }
);
