import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {BundleTariffOverviewResource} from '@youtoken/ui.resource-bundles';

export const useDataLayer = (id: string) => {
  const {
    data: {
      tariff: {
        design: {_title},
      },
    },
  } = BundleTariffOverviewResource.use({id});

  React.useEffect(() => {
    DATA_LAYER.trackStrict('bundles-details-viewed', {
      tariffId: id,
      tariffName: _title,
    });
  }, []);
};
