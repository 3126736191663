import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {SearchButton} from '..';

export type SearchMode = 'icon' | 'full';

export interface SearchFieldProps extends BoxProps {
  mode: SearchMode;
  onSetFullMode: () => void;
  onSetIconMode: () => void;
}

export const SearchField: React.FC<SearchFieldProps> = observer(
  ({mode, onSetFullMode, onSetIconMode, ...boxProps}) => {
    const {setInputVal} = HODLsTariffsFeature.use({});

    const handleInputChange = React.useCallback(
      (event: any) => {
        setInputVal(event.target.value);
      },
      [setInputVal]
    );

    const handleCloseSearch = React.useCallback(() => {
      onSetIconMode();
      setInputVal('');
    }, [setInputVal, onSetIconMode]);

    return (
      <Box justifyContent="flex-end" flexGrow={0} {...boxProps}>
        {mode === 'icon' && (
          <Box width={24}>
            <SearchButton onPress={onSetFullMode} />
          </Box>
        )}
        {mode === 'full' && (
          <TextInput
            style={{width: '100%'}}
            autoFocus
            size="small"
            underlineColorAndroid="transparent"
            onChange={handleInputChange}
            testID="ALL_INSTRUMENTS_SEARCH_INPUT"
            LeftPartComponent={
              <Box ml={14} mr={6} justifyContent="center" height="100%">
                <Icon name="search" color="$text-05" />
              </Box>
            }
            RightPartComponent={
              <Box mr={14} justifyContent="center" height="100%">
                <Button
                  height={20}
                  px={0}
                  icon="close"
                  type="ghost"
                  color="interactive"
                  onPress={handleCloseSearch}
                />
              </Box>
            }
          />
        )}
      </Box>
    );
  }
);
