import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {type TKey, useTranslation} from '@youtoken/ui.service-i18n';
import {type BaseIconName} from '@youtoken/ui.icons';
import {TMenuItem} from '@youtoken/ui.screen-components';
import {type MenuItemsProps} from '../../WebLayout/types';
import {MenuItem} from '..';

export const WebMenuItems: React.FC<MenuItemsProps> = observer(
  ({variant, toggle}) => {
    const {t} = useTranslation();

    const {
      data: {enableHodl, enableMiner},
    } = AuthMeResource.use({});

    const menuItems: TMenuItem[] = React.useMemo(() => {
      const items: TMenuItem[] = [
        {
          icon: 'wallets',
          to: 'wallets',
          i18nKey: 'surface.menu.wallets',
          testID: 'MENU_ITEM_WALLETS_BUTTON',
        },
        {
          icon: 'get_loan',
          to: 'loans',
          i18nKey: 'surface.menu.loans',
          testID: 'MENU_ITEM_LOANS_BUTTON',
        },
      ];

      if (enableHodl) {
        items.push({
          icon: 'hodl',
          to: 'multihodl',
          i18nKey: 'surface.menu.trading',
          testID: 'MENU_ITEM_MULTIHODL_BUTTON',
        });
      }

      if (enableMiner) {
        items.push({
          icon: 'mining',
          to: 'miner',
          i18nKey: 'surface.menu.cloud_miner',
          testID: 'MENU_ITEM_MINER_BUTTON',
        });
      }

      items.push({
        icon: 'turbo',
        to: 'turbocharge',
        i18nKey: 'surface.menu.turbos',
        testID: 'MENU_ITEM_TURBOCHARGE_BUTTON',
      });

      items.push({
        icon: 'history',
        to: 'account-stats',
        i18nKey: 'surface.menu.history_short',
        testID: 'MENU_ITEM_HISTORY_BUTTON',
      });

      return items;
    }, [enableHodl, enableMiner]);

    return (
      <>
        {menuItems.map(item => (
          <MenuItem
            isActive={item.to === 'wallets'}
            variant={variant}
            testID={item.testID}
            to={`/${item.to}`}
            icon={item.icon as BaseIconName}
            pathKey={item.to}
            text={t(item.i18nKey as TKey)}
            onPress={toggle}
          />
        ))}
      </>
    );
  }
);
