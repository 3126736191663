import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';
import {Error, Label, useForm} from '../../../../../components';
import {FeatureLoanFTPForm} from '../../state';
import {Ticker as TickerField} from './Ticker';

export const Ticker: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {instance, amount, amountOnChange, loanBorrowedTicker},
  } = useForm<FeatureLoanFTPForm>();

  const handleChangeText = React.useCallback((text: string) => {
    amountOnChange(
      normalizeAmountWithPrecision(
        text,
        getCoinDecimalPrecisionForLoans(loanBorrowedTicker)
      )
    );
  }, []);

  return (
    <Box zIndex={20} width="100%" {...props}>
      <Label>{t('surface.loans.ftp_form.amount')}</Label>
      <TextInput
        numeric
        value={amount}
        onChangeText={handleChangeText}
        textAlign="right"
        LeftPartComponent={<TickerField />}
        placeholder="0.00"
      />
      <Error errors={instance.errors()} name={['amount']} />
    </Box>
  );
});
