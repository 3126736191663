import * as React from 'react';
import {observer} from 'mobx-react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {ModalDynamic} from '@youtoken/ui.modal';
import {Box} from '@youtoken/ui.primitives';
import {RateModalChart} from './Chart';

interface RateModalProps<T> {
  isOpen: boolean;
  onClose: () => void;
  fromTicker: string;
  toTicker: string;
  fromTickerFormatted: string;
  toTickerTickerFormatted: string;
  chartComponent?: T;
}

export const RateModal: React.FC<RateModalProps<typeof RateModalChart>> =
  observer(
    ({
      isOpen,
      onClose,
      fromTicker,
      fromTickerFormatted,
      toTicker,
      toTickerTickerFormatted,
      chartComponent: ChartComponent = RateModalChart,
    }) => {
      const {bottom} = useSafeAreaInsets();

      return (
        <ModalDynamic
          isOpen={isOpen}
          onClose={onClose}
          title={`${fromTickerFormatted}/${toTickerTickerFormatted}`}
        >
          <Box pb={bottom}>
            <Box width="100%">
              <ChartComponent
                fromTicker={fromTicker}
                toTicker={toTicker}
                onClose={onClose}
              />
            </Box>
          </Box>
        </ModalDynamic>
      );
    }
  );
