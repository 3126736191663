import {observable} from 'mobx';
import {
  BundleReleaseFormStateArgs,
  BundleReleaseFormStateResources,
} from './types';
import {Form} from './Form';

export type {BundleReleaseFormStateResources};

export class BundleReleaseFormState {
  @observable
  public form: Form;

  constructor(
    args: BundleReleaseFormStateArgs,
    resources: BundleReleaseFormStateResources
  ) {
    this.form = new Form(args, resources);
  }
}
