import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text, useIsMobile} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {FieldWrap} from '../../../../components';
import {FeatureTurboCreateForm} from '../stores';

export const Footer: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const isMobile = useIsMobile();

  const {
    form: {instance},
    calculated,
  } = FeatureTurboCreateForm.use({});

  return (
    <FieldWrap
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={isMobile ? 'flex-start' : 'space-between'}
      alignItems={isMobile ? 'stretch' : 'center'}
      {...props}
    >
      <Box mb={isMobile ? 20 : 0}>
        {calculated.data && (
          <>
            <Text variant="$body-01" lineHeight={28} color="$text-02">
              <Trans
                t={t}
                i18nKey="surface.loans.create_turbo_form.total_collateral"
                components={{
                  Strong: <Text variant="$heading-01" color="$text-01" />,
                }}
                values={{
                  amount: calculated.data.collateralAmountFormatted,
                  ticker: calculated.data.collateralTicker.toUpperCase(),
                }}
              />
            </Text>
            <Text variant="$body-02" color="$text-02">
              <Trans
                t={t}
                i18nKey="surface.loans.create_turbo_form.total_repayment"
                components={{
                  Strong: <Text variant="$body-02-high-accent" />,
                }}
                values={{
                  amount: calculated.data.overdraftAmountFormatted,
                  ticker: calculated.data.borrowedTicker.toUpperCase(),
                }}
              />
            </Text>
            <Text variant="$body-02" color="$text-02">
              {t('surface.loans.create_turbo_form.repay_until', {
                date: calculated.data.repayUntil,
              })}
            </Text>
          </>
        )}
      </Box>
      <Box flexShrink={0}>
        <Button size="large" onPress={instance.onSubmit}>
          {t('surface.loans.create_turbo_form.submit')}
        </Button>
      </Box>
    </FieldWrap>
  );
});
