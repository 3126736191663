import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {TouchableBox} from '@youtoken/ui.primitives';
import {HeaderBase, WalletsChooseTicker} from '../../../components';
import {type WithdrawalWizardNavigator, WithdrawalWizardScreenName} from '..';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const WithdrawalWizardChooseTicker: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    step: {
      props: {onPress, type, method},
    },
    goBack,
  } = useWizard<
    WithdrawalWizardNavigator,
    WithdrawalWizardScreenName.CHOOSE_TICKER
  >();

  const TouchableWrapper = useWalletsChooseTickerItemWrapper(onPress);

  return (
    <>
      {showHeader && (
        <>
          <HeaderBase onPressBack={goBack}>
            {t('surface.more.currency.title')}
          </HeaderBase>
          <Separator />
        </>
      )}
      <WalletsChooseTicker
        method={method}
        variant="amount"
        type={type}
        ListItemWrapper={TouchableWrapper}
      />
    </>
  );
});

export const useWalletsChooseTickerItemWrapper = (
  handlePress: (ticker: string) => void
) => {
  return React.useCallback(
    ({item, children}: {item: Wallet; children?: React.ReactNode}) => {
      return (
        <WithdrawWizardChooseTickerItemWrapper
          item={item}
          onPress={handlePress}
        >
          {children}
        </WithdrawWizardChooseTickerItemWrapper>
      );
    },
    [handlePress]
  );
};

interface WithdrawWizardChooseTickerItemWrapperProps {
  item: Wallet;
  onPress: (ticker: string) => void;
}

const WithdrawWizardChooseTickerItemWrapper: React.FC<WithdrawWizardChooseTickerItemWrapperProps> =
  observer(({item: {ticker}, onPress, ...touchableBoxProps}) => {
    const handlePress = React.useCallback(() => {
      onPress(ticker);
    }, [ticker, onPress]);

    return (
      <TouchableBox
        flexDirection="row"
        alignItems="center"
        onPress={handlePress}
        {...touchableBoxProps}
      />
    );
  });
