import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {TextInput} from '@youtoken/ui.inputs';
import {useForm, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {coinNames} from '@youtoken/ui.coin-utils';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type UnlimintCoDiForm} from '../../state/UnlimintCoDiForm';

export const FieldAmount: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {ticker, tickerFormatted, amountValue, amountError, onChangeAmount},
  } = useForm<UnlimintCoDiForm>();

  return (
    <Box mb={24}>
      <Box mb={8}>
        <Text variant="$body-01-high-accent">
          {t('surface.wallets.fiat_deposit.unlimint_codi.amount.label')}
        </Text>
      </Box>
      <TextInput
        numeric
        value={amountValue}
        onChangeText={onChangeAmount}
        placeholder="0.00"
        keyboardType="decimal-pad"
        LeftPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            pl={15}
          >
            <LogoColored name={ticker as LogoColoredIconName} size={20} />

            <Text ml={8} variant="$body-01-medium-accent">
              {coinNames[ticker]}
            </Text>
          </Box>
        }
        RightPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            ml={3}
            pr={15}
          >
            <Text color="$text-02">{tickerFormatted}</Text>
          </Box>
        }
        textAlign="right"
        hasError={Boolean(amountError)}
        testID="FIAT_DEPOSIT_CODI_AMOUNT_INPUT"
      />

      <FieldErrorMessage visible={Boolean(amountError)} placement="right">
        <Text testID="FIAT_DEPOSIT_CODI_AMOUNT_INPUT_ERROR">
          {getTranslatedValidationMessage(amountError)}
        </Text>
      </FieldErrorMessage>
    </Box>
  );
});
