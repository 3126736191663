import * as React from 'react';
import {
  AllInstrumentsSection,
  SectorsSection,
  StarredSection,
  TopCapSection,
  TopMoversSection,
  InstrumentsSearchBar,
  MultiplyYourCryptoModal,
} from '@youtoken/ui.surface-hodl-instruments';

export const HodlsInstrumentsSurfaceContent = () => {
  return (
    <>
      <InstrumentsSearchBar zIndex={1} />
      <StarredSection />
      <TopCapSection />
      <TopMoversSection />
      <SectorsSection />
      <AllInstrumentsSection />
      <MultiplyYourCryptoModal />
    </>
  );
};
