import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const DaiIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
        {...props}
      >
        <Path
          fill="#F5AC37"
          d="M16 0c8.837 0 16 7.164 16 16s-7.163 16-16 16S0 24.837 0 16 7.164 0 16 0"
        />
        <Path
          fill="#FEFEFD"
          d="M16.584 17.122h6.08c.13 0 .191 0 .2-.17q.075-.928 0-1.86c0-.12-.06-.17-.19-.17h-12.1c-.15 0-.19.05-.19.19v1.78c0 .23 0 .23.24.23zm5.602-4.28a.2.2 0 0 0 0-.14 4 4 0 0 0-.362-.63 5 5 0 0 0-.74-.93 2.5 2.5 0 0 0-.46-.45 7.2 7.2 0 0 0-3-1.52 7.5 7.5 0 0 0-1.7-.18h-5.37c-.15 0-.17.06-.17.19v3.55c0 .15 0 .19.19.19h11.54s.1-.02.12-.08h-.05zm0 6.36a2.3 2.3 0 0 0-.512 0h-11.09c-.149 0-.2 0-.2.2v3.471c0 .16 0 .2.2.2h5.12q.369.028.73-.05a7.6 7.6 0 0 0 2.17-.48q.383-.133.73-.34h.07a6.5 6.5 0 0 0 2.79-2.808s.07-.152-.008-.191zm-13.811 5.67v-5.53c0-.13 0-.149-.16-.149h-2.17c-.12 0-.17 0-.17-.16v-1.9h2.32c.13 0 .18 0 .18-.17v-1.88c0-.12 0-.15-.16-.15h-2.17c-.12 0-.17 0-.17-.16v-1.76c0-.11 0-.139.16-.139h2.15c.15 0 .19 0 .19-.19v-5.39c0-.16 0-.2.2-.2h7.5q.817.032 1.62.18a9.8 9.8 0 0 1 3.13 1.16 8.8 8.8 0 0 1 1.76 1.36q.593.618 1.07 1.33c.314.48.575.993.78 1.53a.26.26 0 0 0 .3.21h1.79c.229 0 .229 0 .239.22v1.64c0 .16-.06.2-.22.2h-1.38c-.14 0-.18 0-.17.18q.081.916 0 1.83c0 .17 0 .19.19.19h1.58c.07.09 0 .18 0 .271q.015.174 0 .35v1.21c0 .17-.05.22-.2.22h-1.89a.25.25 0 0 0-.291.19 8 8 0 0 1-2.1 3.06q-.51.46-1.07.86c-.4.23-.79.47-1.2.66-.756.34-1.548.59-2.36.75-.772.138-1.555.2-2.34.19h-6.94v-.01z"
        />
      </Svg>
    );
  }
);
