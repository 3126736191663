import * as React from 'react';
import {observer} from 'mobx-react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {Box} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {
  AccountLevelProgressBarVariant,
  AccountLevelProgressWidgetV2,
} from '@youtoken/ui.surface-account-levels';
import {type ExchangeFormContentProps} from '../types';
import {
  ExchangeFormConversionPair,
  ExchangeFormInfoTableV2,
  AutoConvertSection,
  ExchangeFormAgreeToTerms,
} from '../..';
import {ExchangeFormContentWrap} from '../Wrap';
import {ExchangeFormState} from '../../../state';

export const ExchangeFormContentV2: React.FC<ExchangeFormContentProps> =
  observer(({showBalance, initialAmount}) => {
    const shouldShowWidget = ENVIRONMENT.WEB_APP_ENV !== WebAppEnv['ramp-app'];

    const {
      form: {volumeForecast, sparksAmount, ticker, conversionTicker, amount},
      view: {autoConvertEnabled},
    } = useForm<ExchangeFormState>();

    return (
      <ExchangeFormContentWrap>
        <Box flex={1} p={24} gap={20}>
          <ExchangeFormConversionPair
            showBalance={showBalance}
            initialAmount={initialAmount}
            testIdSourceInput="CONVERSION_FORM_SOURCE_INPUT"
            testIdTargetInput="CONVERSION_FORM_TARGET_INPUT"
            testIdReverseButton="CONVERSION_FORM_REVERSE_BUTTON"
          />
          <ExchangeFormInfoTableV2 />
          {autoConvertEnabled && <AutoConvertSection />}
          {shouldShowWidget && (
            <AccountLevelProgressWidgetV2
              variant={AccountLevelProgressBarVariant.EXCHANGE}
              volume={volumeForecast}
              sparks={sparksAmount}
            />
          )}
          <ExchangeFormAgreeToTerms />
        </Box>
      </ExchangeFormContentWrap>
    );
  });
