import {createTheme} from '@shopify/restyle';
import {type Theme} from './types';
import {
  type TColorTokens,
  COLORS,
  HODLS_INSTRUMENTS_SECTORS_COLOR_TOKENS,
} from './colors';
import {sharedTheme} from './sharedTheme';

export const LIGHT_THEME_COLOR_TOKENS: TColorTokens = {
  '$ui-overflow': COLORS['Gray 10'],
  '$ui-overlay': COLORS['Gray 100'],
  '$ui-background': COLORS.White,
  '$ui-backdrop': COLORS['Gray 10'],
  '$ui-shadow': COLORS['Black - 12%'],
  '$ui-01': COLORS['Gray 10'],
  '$ui-02': COLORS['Gray 20'],
  '$ui-03': COLORS['Gray 30'],
  '$ui-04': COLORS['Gray 40'],
  '$blackout-01': COLORS['Gray 100 - 40%'],
  '$blackout-02': COLORS['Gray 100 - 80%'],
  $transparent: COLORS.Transparent,
  '$success-01': COLORS['Green 60'],
  '$success-02': COLORS['Green 10'],
  '$success-03': COLORS['Green 20'],
  '$danger-01': COLORS['Red 60'],
  '$danger-02': COLORS['Red 10'],
  '$danger-03': COLORS['Red 20'],
  '$danger-04': COLORS['Red 80'],
  '$attention-01': COLORS['Orange 60'],
  '$attention-02': COLORS['Orange 10'],
  '$attention-03': COLORS['Orange 20'],
  '$attention-04': COLORS['Orange 80'],
  '$fill-01': COLORS['Blue 10'],
  '$fill-02': COLORS['Blue 20'],
  '$fill-03': COLORS['Blue 70'],
  '$fill-04': COLORS['Gray 100'],
  '$text-01': COLORS['Gray 100'],
  '$text-02': COLORS['Gray 60'],
  '$text-03': COLORS['Gray 40'],
  '$text-04': COLORS.White,
  '$text-05': COLORS['Blue 60'],
  '$text-06': COLORS['White'],
  '$interactive-01': COLORS['Blue 60'],
  '$interactive-02': COLORS['Blue 10'],
  '$interactive-03': COLORS['Blue 20'],
  '$interactive-04': COLORS['Blue 30'],
  '$gradient-success-01': COLORS['Green 60'],
  '$gradient-danger-01': COLORS['Red 60'],
  '$gradient-ui-01': COLORS['Gray 40'],
  '$loyalty-level-01': COLORS.L1,
  '$loyalty-level-02': COLORS.L2,
  '$loyalty-level-03': COLORS.L3,
  '$loyalty-level-04': COLORS.L4,
  '$loyalty-level-05': COLORS.L5,
  '$loyalty-level-06': COLORS.L6,
  '$loyalty-level-07': COLORS.L7,
  '$loyalty-level-08': COLORS.L8,
  $hover: COLORS['Blue 60 - 40%'],
  '$miner-gloss': COLORS['White'],
  '$miner-gloss-blocked': COLORS['White'],
  '$chart-neutral': COLORS['Gray 40'],
  '$chart-success-01': COLORS['Green 60'],
  '$chart-success-02': COLORS['Green 10'],
  '$chart-danger-01': COLORS['Red 60'],
  '$chart-danger-02': COLORS['Red 10'],
  '$chart-neutral-01': COLORS['Gray 60'],
  '$chart-interactive-01': COLORS['Blue 60'],
  '$chart-interactive-02': COLORS['Blue 10'],
  '$chart-disabled-01': COLORS['Gray 30'],
  '$chart-disabled-02': COLORS['Gray 40'],
  ...HODLS_INSTRUMENTS_SECTORS_COLOR_TOKENS,
};

export const lightTheme = createTheme<Theme>({
  ...sharedTheme,
  colors: LIGHT_THEME_COLOR_TOKENS,
  shadow: `0px 5px 30px ${LIGHT_THEME_COLOR_TOKENS['$ui-shadow']}`,
});
