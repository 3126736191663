import {createResource} from '@youtoken/ui.data-storage';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SENTRY} from '@youtoken/ui.sentry';
import {FeeItemResponse} from './FeeResponse';

export interface FeeWithdrawalResourceArgs {
  ticker: string;
  provider?: string;
}

export class FeeWithdrawalResource extends createResource({
  getKey: (args: FeeWithdrawalResourceArgs) =>
    `FeeWithdrawalResource: ticker:${args.ticker} provider:${args.provider}`,
  getData: ({
    ticker,
    provider = 'checkoutBankCard',
  }: FeeWithdrawalResourceArgs) =>
    TRANSPORT.API.get(`v1/fee/withdrawal`, {
      params: {ticker: ticker, provider},
    })
      .then(res => {
        return deserialize(FeeItemResponse, res.data);
      })
      .catch(error => {
        // NOTE: Error is blocked here because otherwise it will break the entire withdrawal methods cell
        SENTRY.capture(error, {source: 'FeeWithdrawalResource'});
        return deserialize(FeeItemResponse, {enabled: false});
      }),
}) {}
