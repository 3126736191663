import * as React from 'react';
import {z} from 'zod';
import {PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {HodlsMainSurfaceContent} from '@youtoken/ui.surfaces-hodl-common';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {HodlsInstrumentsSurfaceContent} from './Content';

const paramsSchema = z.object({});

export const HodlsInstrumentsSurface = surface(
  {
    name: 'Instruments',
    getTitle: () => i18n.t('surface.menu.multihodl'),
    paramsSchema,
    headerShown: true,
  },
  () => {
    React.useEffect(() => {
      DATA_LAYER.trackStrict('hodl-what-to-trade', {});
    }, []);

    return (
      <VariableLayout
        PrimaryContent={
          <HodlsMainSurfaceContent innerPage={PageSwitcherValue.TRADING}>
            <HodlsInstrumentsSurfaceContent />
          </HodlsMainSurfaceContent>
        }
      />
    );
  }
);
