import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Select} from '@youtoken/ui.select';
import {Icon} from '@youtoken/ui.icons';
import {CryptoDepositBannerByVersion} from '../../CryptoDepositBannerByVersion';
import {
  FeatureCryptoDepositExternal,
  type FeatureCryptoDepositExternalArgs,
} from '../state';
import {Details} from './Details';

export const CryptoDepositExternalVersions: React.FC<FeatureCryptoDepositExternalArgs> =
  observer(({ticker, version}) => {
    const {t} = useTranslation();

    const {
      walletTickerFormatted,
      walletVersion,
      walletVersionAddress,
      walletVersions,
      addressError,
      setWalletVersionValue,
      retryCreateAddress,
    } = FeatureCryptoDepositExternal.use({
      ticker,
      version,
    });

    React.useEffect(() => {
      return () =>
        // NOTE: return the right version if it was changed through the selector
        version ? setWalletVersionValue(version) : setWalletVersionValue(null);
    }, []);

    return (
      <Box flexGrow={1} py={24}>
        {walletVersions.length && (
          <>
            <Box px={24} zIndex={1}>
              <Box alignItems="center" mb={16}>
                <Text variant="$body-01-high-accent">
                  {t('surface.wallets.crypto_deposit.protocol', {
                    ticker: walletTickerFormatted,
                  })}
                </Text>
              </Box>
              <Box alignItems="center">
                <Select
                  items={walletVersions}
                  placeholder={t(
                    'surface.wallets.crypto_deposit.protocol_placeholder'
                  )}
                  onSelect={setWalletVersionValue}
                  selected={walletVersion?.value}
                  width="100%"
                  testID="CRYPTO-DEPOSIT-VERSION-SELECTOR"
                  size="large"
                />
              </Box>
            </Box>
            {Boolean(walletVersion) && (
              <CryptoDepositBannerByVersion
                ticker={ticker}
                walletVersion={walletVersion!.value}
              />
            )}
          </>
        )}
        {walletVersion !== null && (
          <Box flexGrow={1} mt={32}>
            {!walletVersionAddress && (
              <Box
                flexGrow={1}
                minHeight={250}
                alignItems="center"
                justifyContent="center"
              >
                {addressError && (
                  <Box alignItems="center">
                    <Icon name="warning" color="$danger-01" />
                    <Text variant="$body-02" color="$danger-01" mt={10} mb={16}>
                      {addressError}
                    </Text>
                    <Button onPress={retryCreateAddress} color="danger">
                      {t('surface.wallets.crypto_deposit.action.try_again')}
                    </Button>
                  </Box>
                )}
                {!addressError && <ActivityIndicator />}
              </Box>
            )}

            {walletVersionAddress && (
              <Details ticker={ticker} version={version} />
            )}
          </Box>
        )}
      </Box>
    );
  });
