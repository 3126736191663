import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  LoanReopenSurfaceCell,
  type LoanReopenFeatureArgs,
} from '@youtoken/ui.surface-loans';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';

type ModalLoanReopenProps = {
  loan: LoanReopenFeatureArgs;
} & Omit<ModalDynamicProps, 'children'>;

export const ModalLoanReopen: React.FC<ModalLoanReopenProps> = ({
  loan,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    <ModalDynamic
      title={t('surface.loans.item.extend_loan')}
      shouldBeScrollable
      minHeight={200}
      {...props}
    >
      <LoanReopenSurfaceCell loanId={loan.id} />
    </ModalDynamic>
  );
};
