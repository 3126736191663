import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {ModalDynamic} from '@youtoken/ui.modal';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Illustration} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsFeatureListSmart} from '@youtoken/ui.hodl-elements';

export const MultiplyYourCryptoModal: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    data: {
      clientSettings: {multiplyYourCryptoModalShown},
    },
    setMultiplyYourCryptoModalShown,
  } = AuthMeResource.use({});

  const [isModalOpen, setIsModalOpen] = React.useState(
    !multiplyYourCryptoModalShown
  );

  const isNative = Platform.select({default: true, web: false});

  const onClose = React.useCallback(() => {
    setIsModalOpen(false);
    setMultiplyYourCryptoModalShown();
  }, [setMultiplyYourCryptoModalShown]);

  const onButtonPress = React.useCallback(() => {
    DATA_LAYER.trackStrict('hodl-explore-click', {});
    onClose();
  }, [onClose]);

  return (
    <ModalDynamic
      isOpen={isModalOpen}
      onClose={onClose}
      shouldBeScrollable
      shouldShowHeaderSeparator={false}
      overflow="hidden"
    >
      <Box mt={32} mx={{tablet: 8, default: 0}} mb={isNative ? 6 : 32}>
        <Box alignSelf="center" height={210}>
          <Illustration name="illustration-16" />
        </Box>

        <Box mt={-50} px={24}>
          <Heading color="$text-01" textAlign="center" mb={8}>
            {t('surface.hodls.portfolio.empty.title')}
          </Heading>
          <Text color="$text-02" textAlign="center">
            {t('surface.hodls.portfolio.empty.text')}
          </Text>
        </Box>

        <HODLsFeatureListSmart px={0} mx={24} my={32} />

        <Box px={24}>
          <Button size="large" onPress={onButtonPress}>
            <Text>{t('surface.hodls.multiply_crypto_modal.action')}</Text>
          </Button>
        </Box>
      </Box>
    </ModalDynamic>
  );
});
