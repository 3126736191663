import * as React from 'react';
import {z} from 'zod';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {surface} from '@youtoken/ui.screen-components';
import {HodlInstrumentSurfaceContent} from './Content';

const paramsSchema = z.object({
  symbol: z.string(),
});

export const HodlInstrumentSurface = surface(
  {
    name: 'Instrument',
    getTitle: params => params.symbol.toUpperCase().replace('_', '/'),
    paramsSchema,
    headerShown: true,
  },
  ({symbol}) => {
    const {getInstrumentById} = HODLsTariffsFeature.use({});
    const item = getInstrumentById(symbol);

    if (!item) {
      SHARED_ROUTER_SERVICE.navigate('NotFound', {});
      return null;
    }

    return <HodlInstrumentSurfaceContent symbol={symbol} />;
  }
);
