import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Banner} from '@youtoken/ui.elements';
import type {CryptoWithdrawalProps} from '../types';

export const CryptoWithdrawalBanner: React.FC<
  CryptoWithdrawalProps & BoxProps
> = observer(({ticker, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Banner variant="attention" testID="CRYPTO_WITHDRAWAL_BANNER" {...boxProps}>
      {t('surface.wallets.crypto_withdrawal.banner')}
    </Banner>
  );
});
