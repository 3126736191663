import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {AppShell} from '../AppShell';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {FeaturesResource} from '@youtoken/ui.resource-features';
import {InnerPageShellContent} from './Content';
import {
  LocaliseResource,
  LocaliseResourceNamespace,
} from '@youtoken/ui.resource-lokalise';

/** __Inner page shell;  wraps children with layout/menu/etc...__
 *
 * for authorized users, like `/wallets`, `/loans`
 *
 */
export const InnerPageShell: React.FC = cell(({children}) => {
  useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    verification: getResourceDescriptor(VerificationResource, {}),
    rates: getResourceDescriptor(RatesResource, {}),
    features: getResourceDescriptor(FeaturesResource, {}),
    localizeNotifications: getResourceDescriptor(LocaliseResource, {
      namespace: LocaliseResourceNamespace.NOTIFICATION,
    }),
  });

  if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
    return <AppShell>{children}</AppShell>;
  }

  return (
    <AppShell>
      <InnerPageShellContent>{children}</InnerPageShellContent>
    </AppShell>
  );
});

/** __Inner page title__ */
export const Title: React.FC<BoxProps & {isLoading?: boolean}> = ({
  children,
  ...otherProps
}) => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      mb={{
        default: 20,
        desktop: 24,
      }}
      {...otherProps}
    >
      <Heading variant="$heading-01-responsive" mb={0}>
        {children}
      </Heading>
    </Box>
  );
};
