import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {LoanRepayOwnFundsFormV2State} from '../../LoanRepayOwnFundsFormV2/state';

export const DataTableV2: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    ticker,
    conversionTicker,
    walletAmountFormatted,
    overdraftAmountFormatted,
    repaid,
    ltv,
    mcPercent,
    mcPriceFormatted,
    calculated: {ready},
  } = useForm<LoanRepayOwnFundsFormV2State>();

  return (
    <Box opacity={ready ? 1 : 0.7} {...props}>
      <InformationTableRow
        left={t('surface.loans.loan_pay_own_funds_form.wallet_balance')}
        right={
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              {walletAmountFormatted}
            </Text>{' '}
            {ticker}
          </Text>
        }
      />
      <InformationTableRow
        isLast={repaid}
        left={t('surface.loans.loan_pay_own_funds_form.loan_balance')}
        right={
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              {overdraftAmountFormatted}
            </Text>{' '}
            {conversionTicker}
          </Text>
        }
      />
      {!repaid && (
        <>
          <InformationTableRow
            left={t('surface.loans.loan_pay_own_funds_form.ltv')}
            right={
              <Text variant="$body-02" color="$text-02">
                <Text variant="$body-02-high-accent" color="$text-01">
                  {ltv}
                </Text>
                %
              </Text>
            }
          />
          <InformationTableRow
            isLast={true}
            left={t('surface.loans.loan_pay_own_funds_form.pdl')}
            right={
              <Text variant="$body-02" color="$text-02">
                {mcPercent}%{' '}
                <Text variant="$body-02-high-accent" color="$text-01">
                  {mcPriceFormatted}
                </Text>{' '}
                {conversionTicker}
              </Text>
            }
          />
        </>
      )}
    </Box>
  );
});
