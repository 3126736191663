import big from 'big.js';
import {action, computed, observable} from 'mobx';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {AddressStatus, KYCStatus} from '@youtoken/ui.resource-auth-me';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {type WithdrawalFormResources} from './types';

export class View {
  @observable
  public readonly ticker: string;

  @observable
  public resources: WithdrawalFormResources;

  public constructor(ticker: string, resources: WithdrawalFormResources) {
    this.ticker = ticker;
    this.resources = resources;
  }

  @computed
  public get wallet() {
    return this.resources.wallets.getByTicker(this.ticker);
  }

  @computed
  public get walletAmount() {
    return this.wallet?.amount ?? big(0);
  }

  @computed
  public get isWalletEmpty() {
    return this.walletAmount.lte(0);
  }

  @computed
  public get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  public get walletAmountFormatted() {
    return this.walletAmount.toFixedWithSeparators(
      getCoinDecimalPrecision(this.ticker)
    );
  }

  @computed
  public get templates() {
    return this.resources.bankWireTemplates.data;
  }

  @computed
  public get isIBANActive() {
    return this.resources.ibanAccounts.isActive(this.ticker);
  }

  @computed
  public get showAgreementField() {
    return !this.isIBANActive;
  }

  @computed
  public get showBeneficiaryDescriptions() {
    return !this.isIBANActive;
  }

  @computed
  public get shouldForceAllow() {
    return (
      this.resources.authme.residenceOrCountry === 'CAN' &&
      (this.resources.authme.kycResult === KYCStatus.KYC_RESTRICTION ||
        this.resources.authme.addressResult === AddressStatus.KYC_RESTRICTION)
    );
  }

  @computed
  public get isAllowed() {
    return (
      this.shouldForceAllow ||
      (['ACCEPT', 'RE_VERIFY', 'RESUBMIT_ITA'].includes(
        this.resources.authme.kycResult
      ) &&
        ['ACCEPT', 'RE_VERIFY', 'RESUBMIT_ITA'].includes(
          this.resources.authme.addressResult
        ))
    );
  }

  @computed
  public get shouldShowThirdPartyDisclaimer() {
    return !this.resources.ibanAccounts.isThirdPartyPaymentsAllowed;
  }

  @action
  public removeTemplate = (templateId: string) => {
    this.resources.bankWireTemplates.remove(templateId);
  };

  @action
  public handleBannerClick = () => {
    const {kycResult, addressResult} = this.resources.authme;

    // NOTE: two levels of "modals" are opened on native
    SHARED_ROUTER_SERVICE.navigate('__CloseModal');
    SHARED_ROUTER_SERVICE.navigate('__CloseModal');

    if (!kycResult) {
      return VerificationResource.launchIdentityVerification();
    }

    if (!['ACCEPT', 'RE_VERIFY', 'RESUBMIT_ITA'].includes(kycResult)) {
      SHARED_ROUTER_SERVICE.navigate('More', {});
      return;
    }

    if (!addressResult) {
      return VerificationResource.launchAddressVerification();
    }

    if (!['ACCEPT', 'RE_VERIFY', 'RESUBMIT_ITA'].includes(addressResult)) {
      SHARED_ROUTER_SERVICE.navigate('More', {});
      return;
    }
  };
}
