import {
  custom,
  date,
  list,
  object,
  optional,
  primitive,
  raw,
  serializable,
} from 'serializr';
import big, {Big} from 'big.js';
import {computed} from 'mobx';
import {RatesSettingsResource} from '@youtoken/ui.resource-rates-settings';
import {getCoinName, getCoinSymbol} from '@youtoken/ui.coin-utils';
import {
  priceFormatterWithEllipsis,
  formatBigNumber,
  formatByTicker,
  formatPercent,
} from '@youtoken/ui.formatting-utils';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

const _number = () => {
  return custom(
    outValue => outValue,
    inValue => {
      let inValuePrepared;
      try {
        inValuePrepared = big(inValue);
      } catch (e) {
        inValuePrepared = big(0);
      }
      return inValuePrepared;
    },
    {
      beforeDeserialize: (callback, jsonValue) => {
        callback(null, jsonValue || 0);
      },
    }
  );
};

export class MainCurrencyVariants {
  @serializable(_number())
  usd!: Big;

  @serializable(_number())
  eur!: Big;

  @serializable(_number())
  btc!: Big;
}

export class LimitRatios {
  @serializable(primitive())
  exchange: number = 1;

  @serializable(primitive())
  loan: number = 1;

  @serializable(primitive())
  hodl: number = 1;

  @serializable(primitive())
  turbo: number = 1;

  @serializable(primitive())
  dual: number = 1;
}

export class AvailableProfit {
  @serializable(object(MainCurrencyVariants))
  balanceToLimitAmount?: MainCurrencyVariants;

  @serializable(object(MainCurrencyVariants))
  limitToBalanceAmount?: MainCurrencyVariants;
}

export class PayoutObj {
  @serializable(object(MainCurrencyVariants))
  balanceVolume?: MainCurrencyVariants;

  @serializable(object(MainCurrencyVariants))
  maxEarningOn?: MainCurrencyVariants;

  @serializable(object(MainCurrencyVariants))
  savingPortfolio?: MainCurrencyVariants;

  @serializable(object(MainCurrencyVariants))
  hodlVolume?: MainCurrencyVariants;

  @serializable(object(MainCurrencyVariants))
  toEarn?: MainCurrencyVariants;

  @serializable(primitive())
  capacity?: number = 0;

  @serializable(date())
  nextSettlementPeriod?: Date;

  @serializable(primitive())
  settlementPeriod?: string;

  @serializable(object(AvailableProfit))
  availableProfit?: AvailableProfit;

  @serializable(object(LimitRatios))
  limitRatios?: LimitRatios;
}

export class Version {
  @serializable(primitive())
  address!: string | null;

  @serializable(primitive())
  version!: string;

  @serializable(optional(primitive()))
  name!: string;

  @serializable(optional(primitive()))
  extraId?: string;
}

export class WalletPayoutEarn {
  @serializable(primitive())
  amount: number = 0;
}

export class WalletPayout {
  @serializable(primitive())
  APY?: string;

  @serializable(object(WalletPayoutEarn))
  toEarn?: WalletPayoutEarn;
}

export class WalletReferralReward {
  @serializable(primitive())
  apr?: string;
}

export class Wallet {
  @serializable(_number())
  amount!: Big;

  @serializable(_number())
  amountForSavings!: Big;

  // We fill this value after parsing
  @serializable(_number())
  collateral!: Big;

  @serializable(primitive())
  ticker!: string;

  @serializable(primitive())
  address?: string;

  @serializable(primitive())
  extraId?: string;

  @serializable(primitive())
  umaEnabled!: boolean;

  @serializable(list(object(Version)))
  versions!: Version[];

  @computed
  get versionsByLedger() {
    if (this.ticker === 'btc') {
      return this.versions.filter(version => {
        return version.version && ['origin'].includes(version.version);
      });
    }

    if (this.ticker === 'eth') {
      return this.versions.filter(version => {
        return version.version && ['erc20'].includes(version.version);
      });
    }

    return this.versions;
  }

  @serializable(primitive())
  createEnabled!: boolean;

  @serializable(primitive())
  depositEnabled!: boolean;

  @serializable(primitive())
  marketToEnabled!: boolean;

  @serializable(primitive())
  withdrawEnabled!: boolean;

  @serializable(primitive())
  loanEnabled!: boolean;

  @serializable(primitive())
  marketEnabled!: boolean;

  @serializable(primitive())
  hodlEnabled!: boolean;

  @serializable(primitive())
  visible!: boolean;

  @serializable(primitive())
  chartEnabled!: boolean;

  @serializable(list(raw()))
  products!: any[]; // TODO

  @serializable(list(primitive()))
  tags: string[] = [];

  @serializable(object(WalletPayout))
  payout?: WalletPayout;

  @serializable(_number())
  hodlsInputAmount!: Big;

  @serializable(_number())
  dualsInputAmount!: Big;

  @serializable(object(WalletReferralReward))
  referralReward?: WalletReferralReward;

  @computed
  get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  get tickerName() {
    return getCoinName(this.ticker);
  }

  @computed
  get apr() {
    return big(this.payout?.APY ?? 0);
  }

  @computed
  get aprFormatted() {
    return formatPercent(this.apr) + '%';
  }

  @computed
  get hasApr() {
    return this.apr.gt(0);
  }

  @computed
  get shouldDisplayRate() {
    return this.ticker !== this.equivalentTicker;
  }

  @computed
  get aprPercent() {
    return this.apr.mul(100).toFixed(2);
  }

  @computed
  get equivalentTicker() {
    const {mainCurrency} = AuthMeResource.getInstance({});

    return mainCurrency;
  }

  @computed
  get equivalentTickerFormatted() {
    return this.equivalentTicker.toUpperCase();
  }

  @computed
  get equivalentTickerSign() {
    return getCoinSymbol(this.equivalentTicker);
  }

  @computed
  get rate() {
    const {getRate} = RatesResource.getInstance({});

    return getRate(this.ticker, this.equivalentTicker);
  }

  /**
   * If rate is less than 0.002, we consider it insignificant
   * and don't show it in the UI
   * WL-2801
   */
  @computed
  get isPriceSignificant() {
    return this.rate > 0.002;
  }

  @computed
  get precision() {
    const resource = RatesSettingsResource.getInstanceSafely({
      ticker: this.equivalentTicker,
    });
    const defaultPrecision = 4;

    if (!resource) {
      return defaultPrecision;
    }

    return resource.getPrecision(
      this.ticker,
      this.equivalentTicker,
      defaultPrecision
    );
  }

  @computed
  get rateFormatted() {
    const rateFormatted = formatBigNumber(this.rate, this.precision, true);

    return priceFormatterWithEllipsis(rateFormatted);
  }

  @computed
  get equivalent() {
    return this.amount.mul(this.rate);
  }

  @computed
  get equivalentForSavings() {
    return this.amountForSavings.mul(this.rate);
  }

  @computed
  get amountFormatted() {
    return formatByTicker(this.amount, this.ticker);
  }

  @computed
  get amountForSavingsFormatted() {
    return formatByTicker(this.amountForSavings, this.ticker);
  }

  @computed
  get equivalentFormatted() {
    return formatByTicker(this.equivalent, this.equivalentTicker);
  }

  @computed
  get equivalentForSavingsFormatted() {
    return formatByTicker(this.equivalentForSavings, this.equivalentTicker);
  }

  @computed
  get isFiat() {
    return this.tags.includes('fiat');
  }

  @computed
  get hasAmountOrHodlAmount() {
    return this.amount.gt(0) || this.hodlsInputAmount.gt(0);
  }

  @computed
  get diff() {
    const {getDiff24} = RatesResource.getInstance({});
    return getDiff24(this.ticker, this.equivalentTicker);
  }

  @computed
  get rateDiff() {
    const rate24hAgo = big(this.rate).minus(this.diff);
    return big(this.diff).div(rate24hAgo);
  }

  @computed
  get rateDiffPercent() {
    return this.rateDiff.mul(100);
  }

  @computed
  get rateDiffPercentFormatted() {
    return (
      (this.isRateDiffPositive ? '+' : '-') +
      '\u202F' +
      this.rateDiffPercent.toFixed(2).replace('-', '')
    );
  }

  @computed
  get isRateDiffPositive() {
    return this.rateDiff.gte(0);
  }

  @computed
  get isAmountPositive() {
    return this.amount.gt(0);
  }

  //#region sections

  @computed get isAdded() {
    return AuthMeResource.getInstance({}).addedWalletTickers.includes(
      this.ticker
    );
  }

  @computed get isHidden() {
    return AuthMeResource.getInstance({}).hiddenWalletTickers.includes(
      this.ticker
    );
  }

  @computed get allowMoveToShown() {
    return this.isAdded && this.isHidden;
  }

  @computed get allowMoveToHidden() {
    return this.isAdded && !this.isHidden;
  }

  @computed get allowRemove() {
    return this.isAdded && !(this.amount.gt(0) || this.hodlsInputAmount.gt(0));
  }

  //#endregion sections
}

export class WalletsResponse {
  @serializable(object(MainCurrencyVariants))
  totalCapital!: MainCurrencyVariants;

  @serializable(object(PayoutObj))
  payout!: PayoutObj;

  @serializable(list(object(Wallet)))
  wallets!: Wallet[];
}
