import * as React from 'react';
import {Platform} from 'react-native';
import {type BoxProps, Box, TouchableBox} from '@youtoken/ui.primitives';
import {useWizard} from '@youtoken/ui.elements';
import {cell} from '@youtoken/ui.cell';
import type {WalletsChooseTickerListItem} from './state/WalletsChooseTickerFeature';
import type {
  WalletsChooseTickerType,
  WalletsChooseTickerListItemWrapper,
  WalletsChooseTickerMethod,
} from './types';
import {
  WalletsChooseTickerFeature,
  WalletsChooseTickerBuyCryptoFeature,
} from './state';
import {
  Filter,
  WalletsListCreating,
  WalletsList,
  type WalletsListProps,
} from './components';
import {HeaderBase} from '../Header';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  CryptoBuyWizardNavigator,
  CryptoBuyWizardScreenName,
} from '../../smart/CryptoBuyWizard';

export type {WalletsChooseTickerType, WalletsChooseTickerMethod} from './types';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export interface WalletsChooseTickerProps {
  type: WalletsChooseTickerType;
  method: WalletsChooseTickerMethod;
  variant?: WalletsListProps['variant'];
  ListItemWrapper?: WalletsChooseTickerListItemWrapper;
}

export const WalletsChooseTicker: React.FC<
  WalletsChooseTickerProps & BoxProps
> = cell(
  ({type, method, ListItemWrapper, variant = 'versions', ...boxProps}) => {
    const {
      isWalletsListCreating,
      search,
      shownFilters,
      selectedFilters,
      updateSearch,
      shouldHideSearchbar,
      toggleFilter,
      filteredItems,
      reset,
    } = WalletsChooseTickerFeature.use({
      type,
      method,
    });

    React.useEffect(() => {
      return reset;
    }, []);

    if (isWalletsListCreating) {
      return <WalletsListCreating />;
    }

    return (
      <Box flex={1} {...boxProps}>
        <Filter
          shouldHideSearchbar={shouldHideSearchbar}
          search={search}
          filters={shownFilters}
          selectedFilters={selectedFilters}
          onSearchChange={updateSearch}
          onFilterPress={toggleFilter}
        />
        <WalletsList
          items={filteredItems}
          variant={variant}
          ListItemWrapper={ListItemWrapper}
        />
      </Box>
    );
  }
);

export interface WalletsChooseTickerBuyCryptoProps {
  method: WalletsChooseTickerMethod;
  ListItemWrapper?: WalletsChooseTickerListItemWrapper;
}

export const WalletsChooseTickerBuyCrypto: React.FC<
  WalletsChooseTickerBuyCryptoProps & BoxProps
> = cell(({method, ListItemWrapper, ...boxProps}) => {
  const {t} = useTranslation();
  const {goBack} = useWizard<
    CryptoBuyWizardNavigator,
    CryptoBuyWizardScreenName.CHOOSE_TICKER
  >();
  const {
    isWalletsListCreating,
    search,
    shownFilters,
    selectedFilters,
    updateSearch,
    shouldHideSearchbar,
    toggleFilter,
    filteredItems,
    reset,
  } = WalletsChooseTickerBuyCryptoFeature.use({method});

  React.useEffect(() => {
    return reset;
  }, [reset]);

  if (isWalletsListCreating) {
    return <WalletsListCreating />;
  }

  return (
    <Box flex={1} {...boxProps}>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {t('surface.wallets.deposit_wizard.select_ticker_crypto.title')}
        </HeaderBase>
      )}
      <Filter
        shouldHideSearchbar={shouldHideSearchbar}
        search={search}
        filters={shownFilters}
        selectedFilters={selectedFilters}
        onSearchChange={updateSearch}
        onFilterPress={toggleFilter}
      />
      <WalletsList items={filteredItems} ListItemWrapper={ListItemWrapper} />
    </Box>
  );
});

interface WalletsChooseTickerItemWrapperProps {
  item: WalletsChooseTickerListItem;
  onPress: (ticker: string) => void;
  withCheckProductAvailability?: boolean;
}

const WalletsChooseTickerItemWrapper: React.FC<
  WalletsChooseTickerItemWrapperProps & BoxProps
> = ({item: {ticker}, onPress, ...touchableBoxProps}) => {
  const handlePress = React.useCallback(
    () => onPress(ticker),
    [ticker, onPress]
  );

  return (
    <TouchableBox
      flexDirection="row"
      alignItems="center"
      onPress={handlePress}
      {...touchableBoxProps}
    />
  );
};

export const useWalletsChooseTickerItemWrapper = (
  handlePress: (ticker: string) => void
) => {
  return React.useCallback(
    ({
      item,
      children,
    }: {
      item: WalletsChooseTickerListItem;
      children?: React.ReactNode;
    }) => {
      return (
        <WalletsChooseTickerItemWrapper item={item} onPress={handlePress}>
          {children}
        </WalletsChooseTickerItemWrapper>
      );
    },
    [handlePress]
  );
};
