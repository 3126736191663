import * as React from 'react';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {i18n} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {
  type CryptoDepositWizardNavigator,
  CryptoDepositWizardScreenName,
} from '..';

const getTitle = (ticker: string) => {
  return i18n.t('surface.wallets.crypto_deposit.title', {
    ticker: ticker.toUpperCase(),
  });
};

const CryptDepositLedger = React.lazy(() =>
  import(
    /* webpackChunkName: "crypto-deposit-ledger"*/ '../../../CryptoDepositLedger'
  ).then(result => {
    return {default: result.CryptoDepositLedger};
  })
);

const CryptoDepositWizardYouHodler = React.lazy(() =>
  import(
    /* webpackChunkName: "crypto-deposit-youhodler"*/ '../../CryptoDirectWizards'
  ).then(result => {
    return {default: result.CryptoDirectDepositWizard};
  })
);

export const getCryptoDepositWizardDirectDepositOptions = ({
  route: {
    params: {ticker},
  },
}: {
  route: {
    params: {ticker: string};
  };
}) => {
  return {
    title: getTitle(ticker),
  };
};

export const CryptoDepositWizardDirectDeposit: React.FC = () => {
  const {
    step: {
      props: {ticker, version},
    },
    goBack,
  } = useWizard<
    CryptoDepositWizardNavigator,
    CryptoDepositWizardScreenName.DIRECT_DEPOSIT
  >();

  if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
    return <CryptDepositLedger ticker={ticker} onPressBack={goBack} />;
  }

  return (
    <CryptoDepositWizardYouHodler
      ticker={ticker}
      version={version}
      onPressBack={goBack}
    />
  );
};
