import * as React from 'react';
import {observer} from 'mobx-react';
import {type Notification} from '@youtoken/ui.resource-notifications';
import {Box, type BoxProps, useIsDesktop} from '@youtoken/ui.primitives';
import {
  Slider,
  PromoBanner,
  type PromoBannerImageName,
} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {CarouselBannersFeature} from './CarouselBannersFeature';
import {Platform} from 'react-native';

export const CarouselBanners: React.FC<BoxProps> = observer(boxProps => {
  const {carousel, close} = CarouselBannersFeature.use({});
  const isDesktop = useIsDesktop();
  const sliderRef = React.useRef<{
    scrollTo: (options: {index: number; animated?: boolean}) => void;
  } | null>(null);

  const handleClose = React.useCallback(
    (id: string) => {
      const indexToRemove = carousel.findIndex(
        item => item.notificationId === id
      );
      if (indexToRemove === -1) {
        return;
      }

      const newIndex = indexToRemove === 0 ? 0 : indexToRemove - 1;

      sliderRef.current?.scrollTo({index: newIndex, animated: true});

      setTimeout(() => {
        close(id);
      }, 100);
    },
    [carousel, close]
  );

  const handlePressBanner = React.useCallback((url?: string) => {
    if (!url) {
      return;
    }
    const [name, params, query] = SHARED_ROUTER_SERVICE.urlToRoute(url);
    SHARED_ROUTER_SERVICE.navigate(name, params, query);
  }, []);

  const renderItem = React.useCallback(
    ({item}: {item: Notification}) => {
      const {
        notificationId,
        conditions: {canClose, url},
        texts: {title, body, button},
        design,
      } = item;

      return (
        <PromoBanner
          onPressBanner={() => handlePressBanner(url)}
          onPressClose={
            canClose
              ? Platform.OS === 'web'
                ? () => close(notificationId)
                : () => handleClose(notificationId)
              : undefined
          }
          showActionButton={Boolean(button)}
          text={body ?? ''}
          secondaryText={title}
          buttonText={button}
          imageName={design?.iconName as PromoBannerImageName}
          testID={`CAROUSEL_BANNER_${notificationId}`}
        />
      );
    },
    [handlePressBanner, handleClose, close]
  );

  if (carousel.length === 0) {
    return null;
  }

  return (
    <Box testID="BANNERS_CAROUSEL" width="100%" minHeight={64} {...boxProps}>
      <Slider
        ref={sliderRef}
        cardFullWidth={isDesktop}
        cardHeight={64}
        gap={isDesktop ? 0 : 20}
        data={carousel}
        renderItem={renderItem}
        paginationBoxProps={{pt: 8, pb: 0}}
      />
    </Box>
  );
});
