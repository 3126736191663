import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox, useIsDesktop} from '@youtoken/ui.primitives';
import {CoinsPair, Separator} from '@youtoken/ui.elements';
import {LogoColoredIconName} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AutoConvertUiAdoptedItem} from './types';

interface AutoConvertListItemProps {
  item: AutoConvertUiAdoptedItem;
  testID?: string;
  isLast?: boolean;
  onPress: () => void;
}

export const AutoConvertListItem: React.FC<AutoConvertListItemProps> = observer(
  ({testID, item, isLast, onPress}) => {
    const {
      fromTicker,
      toTicker,
      fromAmount,
      fromTickerFormatted,
      toTickerFormatted,
      uiAdoptedTriggerRateFormatted,
      uiAdoptedBaseTickerFormatted,
      uiAdoptedQuoteTickerFormatted,
    } = item;

    const {t} = useTranslation();

    const isDesktop = useIsDesktop();

    return (
      <>
        <TouchableBox
          width="100%"
          backgroundColor="$ui-background"
          onPress={onPress}
          flexDirection="row"
          alignItems="center"
          px={24}
          height={72}
          testID={testID}
        >
          <Box mr={8}>
            <CoinsPair
              firstTicker={fromTicker as LogoColoredIconName}
              secondTicker={toTicker as LogoColoredIconName}
              iconSize={24}
              iconOffset={16}
            />
          </Box>

          <Box flexDirection="column">
            <Text variant="$body-01-high-accent">
              {t('surface.history.auto_convert_item.title', {
                fromAmount,
                fromTicker: fromTickerFormatted,
                toTicker: toTickerFormatted,
              })}
            </Text>
            <Text color="$text-02">
              {t('surface.history.auto_convert_item.subtitle', {
                fromTicker: uiAdoptedBaseTickerFormatted,
                rate: uiAdoptedTriggerRateFormatted,
                toTicker: uiAdoptedQuoteTickerFormatted,
              })}
            </Text>
          </Box>
        </TouchableBox>
        {isLast && !isDesktop && <Separator />}
      </>
    );
  }
);
