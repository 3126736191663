import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {type BoxProps, useIsMobile, Box} from '@youtoken/ui.primitives';
import {
  AllInstrumentsHeader,
  InstrumentsListSmart,
  SearchField,
  type SearchMode,
  FiltersBar,
} from './components';

export type AllInstrumentsSectionProps = {
  separateInstrumentsSection?: boolean;
};

export const AllInstrumentsSection: React.FC<
  AllInstrumentsSectionProps & BoxProps
> = React.memo(({separateInstrumentsSection, ...boxProps}) => {
  const isMobile = useIsMobile();

  const [mode, setMode] = React.useState<SearchMode>('icon');
  const showHeader = !(mode === 'full' && isMobile);

  const handleSetFullMode = React.useCallback(() => {
    DATA_LAYER.trackStrict('hodl-instruments-search', {});
    setMode('full');
  }, [setMode]);

  const handleSetIconMode = React.useCallback(() => {
    setMode('icon');
  }, [setMode]);

  return (
    <Box {...boxProps}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pb={16}
        px={{
          desktop: separateInstrumentsSection ? 24 : 0,
          default: 20,
        }}
      >
        {showHeader && <AllInstrumentsHeader />}
        <SearchField
          mode={mode}
          onSetFullMode={handleSetFullMode}
          onSetIconMode={handleSetIconMode}
          width={!showHeader ? '100%' : undefined}
        />
      </Box>

      <FiltersBar
        px={{
          desktop: separateInstrumentsSection ? 20 : 0,
          default: 20,
        }}
      />

      <Box mt={16}>
        <InstrumentsListSmart
          separateInstrumentsSection={separateInstrumentsSection}
        />
      </Box>
    </Box>
  );
});
