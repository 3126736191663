import {observable} from 'mobx';
import {
  BundleWithdrawalFormStateArgs,
  BundleWithdrawalFormStateResources,
} from './types';
import {Form} from './Form';

export type {BundleWithdrawalFormStateResources};

export class BundleWithdrawalFormState {
  @observable
  public form: Form;

  constructor(
    args: BundleWithdrawalFormStateArgs,
    resources: BundleWithdrawalFormStateResources
  ) {
    this.form = new Form(args, resources);
  }
}
