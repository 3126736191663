// @ts-ignore
import {Form} from 'mobx-react-form';
import {
  action,
  computed,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import big, {Big, BigSource} from 'big.js';
import {nanoid} from 'nanoid';
import {deserialize} from 'serializr';
import * as yupExtended from '@youtoken/ui.yup';
// @ts-ignore
import yup from 'mobx-react-form/lib/validators/YUP';
import {messages} from '@youtoken/ui.validation-messages';
import {formatBigNumber} from '@youtoken/ui.formatting-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {AdjustTpSlFormResources} from './index';
import {getPercentByAmount} from '@youtoken/ui.hodls-utils';
import {CalculatedData} from './CalculatedData';

export class AdjustTpSlForm extends Form {
  @observable
  prepareData = true;

  @computed get hodlObj() {
    // @ts-ignore
    const slSource = this.$('stopLoss').get('value');
    // @ts-ignore
    const tpSource = this.$('takeProfit').get('value');
    const requestId = nanoid();

    const obj: {[key: string]: any} = {
      id: this.id,
      sl: slSource || null,
      tp: tpSource || null,
      requestId,
    };

    return obj;
  }

  private plugins() {
    const $schema = (y: typeof yupExtended) =>
      y.lazy((value: any) => {
        if (typeof this.resources === 'undefined') {
          // this is hack
          // class not yet ready
          // think about it

          return y.object().shape({
            stopLoss: y.mixed(),
            takeProfit: y.mixed(),
          });
        }

        let takeProfitValidator = this.isShort
          ? y
              .big()
              .gt(0)
              .lt(this.currentPrice, messages.SHOULD_BE_LT_CURRENT_PRICE)
          : y.big().gt(this.currentPrice, messages.SHOULD_BE_GT_CURRENT_PRICE);

        if (!!this.ftpPrice && this.ftpPrice.gt(0)) {
          if (this.isShort) {
            takeProfitValidator = takeProfitValidator.gte(
              this.ftpPrice,
              'TAKE_PROFIT_LIMIT'
            );
          } else {
            takeProfitValidator = takeProfitValidator.lte(
              this.ftpPrice,
              'TAKE_PROFIT_LIMIT'
            );
          }
        }

        let stopLossValidator = this.isShort
          ? y
              .big()
              .gt(this.currentPrice, messages.SHOULD_BE_GT_CURRENT_PRICE)
              .lte(this.mcPrice, 'MARGIN_CALL_LIMIT')
          : y
              .big()
              .lt(this.currentPrice, messages.SHOULD_BE_LT_CURRENT_PRICE)
              .gte(this.mcPrice, 'MARGIN_CALL_LIMIT');

        return y.object().shape({
          stopLoss: stopLossValidator,
          takeProfit: takeProfitValidator,
        });
      });

    return {
      yup: yup({
        package: yupExtended,
        schema: $schema,
      }),
    };
  }

  private hooks() {
    return {
      onSuccess: (form: any) => {
        return TRANSPORT.API.put(`/v3/hodl/${this.id}`, this.hodlObj)
          .then(() => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.hodls.adjust_tp.message.adjusted'),
            });
            this.onSuccess();
          })
          .catch(e =>
            handleFormSubmitError(this, e, {
              tp: 'takeProfit',
              sl: 'stopLoss',
            })
          );
      },
    };
  }

  private options() {
    return {
      validateOnChange: true,
    };
  }

  private setup() {
    return {
      fields: {
        stopLoss: {
          value: '',
        },
        takeProfit: {
          value: '',
        },
      },
    };
  }

  currentCalculateRequestId: any = null;
  mcPrice: Big;
  private slPrice: BigSource;
  private tpPrice: BigSource;
  baseTicker: string;
  quoteTicker: string;
  precision: number;
  initialPrice: Big;
  maxLoss: BigSource;
  maxProfit: BigSource;
  inputTicker: string;
  isShort: boolean;

  @observable
  ftpPrice: Big;

  onSuccess: () => void;

  resources: AdjustTpSlFormResources;

  // TODO move form to instance
  instance: any;

  disposers: IReactionDisposer[];

  dispose() {
    this.disposers.forEach(disposer => disposer?.());
  }

  constructor(
    resources: AdjustTpSlFormResources,
    {
      id,
      onSuccess,
    }: {
      id: string;
      onSuccess: () => void;
    }
  ) {
    super();

    this.resources = resources;

    this.onSuccess = onSuccess;

    const {data} = this.resources.hodl.data;

    this.initialPrice = big(data.initialPrice ?? 0); // NOTE: fix for possible HODL v1, remove when no active
    this.isShort = data.isShort;
    this.mcPrice = data.mcPrice ? big(data.mcPrice) : big(0);
    this.slPrice = data.slPrice || this.mcPrice;
    this.ftpPrice = data.ftpPrice ? big(data.ftpPrice) : big(0);
    this.tpPrice =
      data.tpPrice ||
      (!!this.ftpPrice && this.ftpPrice.gt(0)
        ? this.ftpPrice
        : this.initialPrice.mul(this.isShort ? 1 / 2 : 2));

    this.baseTicker = data.baseTicker;
    this.quoteTicker = data.quoteTicker;
    this.precision = data.precision;
    this.maxLoss = data.maxLoss || 0;
    this.maxProfit = data.maxProfit || 0;
    this.inputTicker = data.inputTicker;

    this.takeProfitPercent = getPercentByAmount(
      this.initialPrice,
      this.tpPrice,
      this.takeProfitRoundingMode
    );

    this.stopLossPercent = getPercentByAmount(
      this.initialPrice,
      this.slPrice,
      this.stopLossRoundingMode
    );

    this.disposers = [
      reaction(
        () => this.hodlObj,
        async hodlObj => {
          const localCalculateRequestId = Date.now();
          this.currentCalculateRequestId = localCalculateRequestId;

          this.calculating = true;

          TRANSPORT.API.post(`/v3/hodl/${id}/calculate`, hodlObj)
            .then(res => {
              if (this.currentCalculateRequestId !== localCalculateRequestId) {
                return;
              }

              runInAction(async () => {
                this.calculatedRawData = res.data;
              });
            })
            .catch(() => {
              if (this.currentCalculateRequestId !== localCalculateRequestId) {
                return;
              }

              runInAction(() => {
                this.calculatedRawData = null;
              });
            })
            .finally(() => {
              if (this.currentCalculateRequestId !== localCalculateRequestId) {
                return;
              }

              runInAction(() => {
                this.calculating = false;
              });
            });
        },
        {delay: 300}
      ),
    ];

    // @ts-ignore
    this.$('stopLoss').set(
      'value',
      formatBigNumber(
        this.slPrice,
        this.precision,
        true,
        this.stopLossRoundingMode,
        false
      )
    );
    // @ts-ignore
    this.$('takeProfit').set(
      'value',
      formatBigNumber(
        this.tpPrice,
        this.precision,
        true,
        this.takeProfitRoundingMode,
        false
      )
    );
  }

  @computed get id() {
    return this.resources.hodl.data.id;
  }

  @computed get currentPrice() {
    if (!this.resources?.ratesResource) {
      return Big(0);
    }

    const {getRateObj} = this.resources.ratesResource;
    const rateObj = getRateObj(this.baseTicker, this.quoteTicker);
    return Big(this.isShort ? rateObj.ask : rateObj.bid);
  }

  @observable
  calculatedRawData: null | any = null;

  @computed get calculatedData() {
    if (!this.calculatedRawData) {
      return null;
    }

    return deserialize(CalculatedData, this.calculatedRawData);
  }

  @observable
  calculating: boolean = false;

  @computed get pricePrecision() {
    return this.resources.hodl.data.precision;
  }

  @action
  updateTakeProfitValue = (amount: string) => {
    // @ts-ignore
    this.$('takeProfit').set('value', amount);
    this.takeProfitPercent = getPercentByAmount(
      this.initialPrice,
      amount,
      this.takeProfitRoundingMode
    );
  };

  @computed get takeProfitRoundingMode() {
    return this.isShort ? big.roundUp : big.roundDown;
  }

  @computed get stopLossRoundingMode() {
    return this.isShort ? big.roundDown : big.roundUp;
  }

  @observable
  takeProfitPercent: string = '0';

  @action
  updateTakeProfitPercent = (value: string) => {
    this.takeProfitPercent = value;
    let nextAmountFormatted = '';

    try {
      const nextAmount = big(value).div(100).plus(1).mul(this.initialPrice);
      nextAmountFormatted = formatBigNumber(
        nextAmount,
        this.pricePrecision,
        true,
        this.takeProfitRoundingMode,
        false
      );
    } catch (e) {}

    // @ts-ignore
    this.$('takeProfit').set('value', nextAmountFormatted);
  };

  @observable
  stopLossPercent: string = '0';

  @action
  updateStopLossPercent = (value: string) => {
    this.stopLossPercent = value;
    let nextAmountFormatted = '';

    try {
      const nextAmount = big(value).div(100).plus(1).mul(this.initialPrice);
      nextAmountFormatted = formatBigNumber(
        nextAmount,
        this.pricePrecision,
        true,
        this.stopLossRoundingMode,
        false
      );
    } catch (e) {}

    // @ts-ignore
    this.$('stopLoss').set('value', nextAmountFormatted);
  };

  @action
  updateStopLossValue = (amount: string) => {
    // @ts-ignore
    this.$('stopLoss').set('value', amount);
    this.stopLossPercent = getPercentByAmount(
      this.initialPrice,
      amount,
      this.stopLossRoundingMode
    );
  };
}
