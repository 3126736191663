import {computed, observable} from 'mobx';
import {
  alias,
  date,
  list,
  object,
  optional,
  primitive,
  serializable,
} from 'serializr';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {priceFormatter} from '@youtoken/ui.formatting-utils';
import {number} from '@youtoken/ui.utils-serializr';
import big from 'big.js';

function handleGetAmountWithUnit(value: number, sign: string = '') {
  const {value: amount, unit} = priceFormatter({
    amount: value,
  });
  return {
    amountFormatted: `${sign}${amount}`,
    unit: unit ?? '',
  };
}

function calculateIncreasePercent(
  currentPrice: number,
  historicalPrice: number
) {
  return (((currentPrice - historicalPrice) / historicalPrice) * 100).toFixed(
    2
  );
}

function calculateDecreasePercent(
  currentPrice: number,
  historicalPrice: number
) {
  return (((historicalPrice - currentPrice) / historicalPrice) * 100).toFixed(
    2
  );
}

function priceRangeFormatter(_price: number) {
  const price = big(_price);
  if (price.gt(100)) {
    return price.round().toFixedWithSeparators();
  }
  if (price.gt(10)) {
    return price.toFixedWithSeparators(1);
  }
  if (price.gt(1)) {
    return price.toFixedWithSeparators(2);
  }
  // NOTE: for low-price coins
  if (price.lt(0.01)) {
    return big(price).round(10).toFixedWithSeparators();
  }
  return price.toFixedWithSeparators(3);
}

class MarketStatsData {
  @observable
  @serializable(primitive())
  slug!: string;

  @observable
  @serializable(primitive())
  symbol!: string;

  @observable
  @serializable(number())
  marketCap!: number;

  @observable
  @serializable(number())
  marketCapDominance!: number;

  @observable
  @serializable(number())
  volume24h!: number;

  @observable
  @serializable(number())
  volumeChange24h!: number;

  @observable
  @serializable(number())
  totalSupply!: number;

  @observable
  @serializable(number())
  circulatingSupply!: number;

  /* Coin market cap rank */
  @observable
  @serializable(alias('cmcRank', number()))
  rank?: number;

  @computed get marketCapAmount() {
    if (!this.marketCap) {
      return undefined;
    }

    return handleGetAmountWithUnit(this.marketCap, '$');
  }

  @computed get marketCapTotalPercentFormatted() {
    const prefixSign = this.marketCapDominance < 1 ? '~' : '';
    return `${prefixSign}${this.marketCapDominance.toFixed()}%`;
  }

  @computed get volumeAmount() {
    return handleGetAmountWithUnit(this.volume24h, '$');
  }

  @computed get volumePercentDiffFormatted() {
    return `${Math.abs(this.volumeChange24h).toFixed()}%`;
  }

  @computed get volumePercentDiffDirection() {
    return this.volumeChange24h < 0 ? 'down' : 'up';
  }

  @computed get supplyAmount() {
    return handleGetAmountWithUnit(this.circulatingSupply);
  }

  @computed get supplyFromTotalPercentFormatted() {
    if (!this.totalSupply) {
      return undefined;
    }

    const percentValue = (
      this.circulatingSupply /
      (this.totalSupply / 100)
    ).toFixed();
    return `${percentValue}%`;
  }

  @computed get rankFormatted() {
    if (!this.rank) {
      return undefined;
    }

    return `#${this.rank}`;
  }
}

export class PriceRangeData {
  @observable
  @serializable(primitive())
  period!: string;

  @observable
  @serializable(number())
  low!: number;

  @computed get lowPriceFormatted() {
    return priceRangeFormatter(this.low);
  }

  @observable
  @serializable(number())
  high!: number;

  @computed get highPriceFormatted() {
    return priceRangeFormatter(this.high);
  }

  @observable
  @serializable(primitive())
  baseTicker!: string;

  @observable
  @serializable(primitive())
  quoteTicker!: string;

  @computed get currentPricePercentFormatted() {
    const {getRate} = RatesResource.getInstance({
      product: 'hodl',
    });

    const currentPrice = getRate(this.baseTicker, this.quoteTicker);

    const priceDiffAmount = this.high - this.low;
    const currentPriceDiffAmount = currentPrice - this.low;

    const currentPricePercent =
      (currentPriceDiffAmount / priceDiffAmount) * 100;

    if (currentPricePercent < 0) {
      return `0%`;
    }
    if (currentPricePercent > 100) {
      return `100%`;
    }
    return `${currentPricePercent.toFixed()}%`;
  }
}

export class HistoricalPriceData {
  @observable
  @serializable(primitive())
  baseTicker!: string;

  @observable
  @serializable(primitive())
  quoteTicker!: string;

  @observable
  @serializable(primitive())
  period!: string;

  @computed get periodFormatted() {
    return this.period.toUpperCase();
  }

  @observable
  @serializable(number())
  price!: number;

  @computed get rate() {
    const {getRate} = RatesResource.getInstance({
      product: 'hodl',
    });
    return getRate(this.baseTicker, this.quoteTicker);
  }

  @computed get direction() {
    return this.rate < this.price ? 'down' : 'up';
  }

  @computed get amountFormatted() {
    if (this.price === 0) {
      return '';
    }

    const percent =
      this.direction === 'up'
        ? calculateIncreasePercent(this.rate, this.price)
        : calculateDecreasePercent(this.rate, this.price);

    return `${percent}%`;
  }
}

export class HODLInstrumentResponse {
  @observable
  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  @observable
  baseTicker!: string;

  @serializable(primitive())
  @observable
  quoteTicker!: string;

  @observable
  @serializable(date())
  createdAt!: Date;

  @observable
  @serializable(optional(object(MarketStatsData)))
  marketStats?: MarketStatsData;

  @observable
  @serializable(
    optional(
      list(
        object(PriceRangeData, {
          beforeDeserialize: (
            callback,
            jsonValue,
            _jsonParentValue,
            _propNameOrIndex,
            {json: {baseTicker, quoteTicker}}
          ) => {
            return callback(null, {
              ...jsonValue,
              baseTicker,
              quoteTicker,
            });
          },
        })
      )
    )
  )
  priceRange?: PriceRangeData[];

  @observable
  @serializable(
    alias(
      'historicalPrice',
      list(
        object(HistoricalPriceData, {
          beforeDeserialize: (
            callback,
            jsonValue,
            _jsonParentValue,
            _propNameOrIndex,
            {json: {baseTicker, quoteTicker}}
          ) => {
            return callback(null, {
              ...jsonValue,
              baseTicker,
              quoteTicker,
            });
          },
        })
      )
    )
  )
  performance!: HistoricalPriceData[];
}
