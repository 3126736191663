import * as React from 'react';
import {invariant} from '@youtoken/ui.utils';
import {AxiosError} from '@youtoken/ui.errors';
import {type Challenge, challengeScheme, type ChallengeResult} from './types';

type PasscodeRequestParams = {
  promptMessage: string;
};

export type CallbackWithToken<T extends any = any> = (
  recaptchaToken: string
) => Promise<T>;

/** transport layer provider as service */
export class ChallengesService {
  initialize() {}

  supportedTypes = ['sumsub', 'terms-and-conditions'];

  refs = {
    sumsub: React.createRef<{
      launch: (levelName: string, onDismiss?: () => void) => Promise<void>;
    }>(),
    recaptcha: React.createRef<{
      requestTokenV2: (action: string) => Promise<string>;
      requestTokenV3: (action: string) => Promise<string>;
      requestToken: (action: string) => Promise<string>;
    }>(),
    termsAndConditions: React.createRef<{
      show: (
        link: string,
        originalError: AxiosError
      ) => Promise<ChallengeResult>;
    }>(),
    passcode: React.createRef<{
      requestPasscode: (params: PasscodeRequestParams) => Promise<void>;
    }>(),
  };

  sumsub(level: string, onDismiss?: () => void): Promise<ChallengeResult> {
    invariant(this.refs.sumsub?.current, 'SumSubVerification not initialized');

    return this.refs.sumsub?.current?.launch(level, onDismiss).then(() => {
      return {
        type: 'throw-original-error',
      };
    });
  }

  termsAndConditions(link: string, originalError: AxiosError) {
    invariant(
      this.refs.termsAndConditions?.current,
      'TermsAndConditions not initialized'
    );
    return this.refs.termsAndConditions?.current?.show(link, originalError);
  }

  recaptcha(action: string) {
    invariant(this.refs.recaptcha?.current, 'Recaptcha not initialized');
    return this.refs.recaptcha?.current?.requestToken(action);
  }

  passcode(params: PasscodeRequestParams): Promise<ChallengeResult> {
    invariant(this.refs.passcode?.current, 'Passcode not initialized');
    return this.refs.passcode?.current?.requestPasscode(params).then(() => {
      return {
        type: 'contain-original-error',
      };
    });
  }

  extractChallenge(
    error: AxiosError<{challenge: Challenge}>
  ): Challenge | null {
    if (!error?.response?.data?.challenge) {
      return null;
    }

    const challenge = error.response.data.challenge;

    if (!challengeScheme.safeParse(challenge).success) {
      return null;
    }

    return error.response.data.challenge;
  }

  // for now only sumsub and T&C challenges are supported
  canProcessChallenge(challenge: Challenge): boolean {
    if (!challenge) {
      return false;
    }

    return this.supportedTypes.includes(challenge.type);
  }

  processChallenge(
    challenge: Challenge,
    originalError: AxiosError
  ): Promise<ChallengeResult> {
    invariant(
      this.canProcessChallenge(challenge),
      'Unsupported challenge type',
      {
        type: challenge!.type,
      },
      {
        challenge,
      }
    );

    if (challenge.type === 'sumsub') {
      return this.sumsub(challenge.payload.level);
    }

    if (challenge.type === 'terms-and-conditions') {
      return this.termsAndConditions(challenge.payload.link, originalError);
    }

    return Promise.reject();
  }
}
