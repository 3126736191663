import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {
  BundleTariffOverviewResource,
  BundleOperationsResource,
} from '@youtoken/ui.resource-bundles';
import {
  type BundleDepositFormStateResources,
  BundleDepositFormState,
} from './state';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Content, Footer} from './components';

export {getBundleDepositFormTitle} from './utils';

export interface BundleDepositFormProps {
  id: string;
  onClose?: () => void;
}

export const BundleDepositForm: React.FC<BundleDepositFormProps & BoxProps> =
  cell(({id, onClose, ...boxProps}) => {
    const {rates, authMe, wallets, bundleTariffOverview, bundleOperations} =
      useResources({
        rates: getResourceDescriptor(RatesResource, {}),
        authMe: getResourceDescriptor(AuthMeResource, {}),
        wallets: getResourceDescriptor(WalletsResource, {}),
        bundleTariffOverview: getResourceDescriptor(
          BundleTariffOverviewResource,
          {
            id,
          }
        ),
        bundleOperations: getResourceDescriptor(BundleOperationsResource, {
          id,
        }),
      });

    const docs = DocsResource.use({country: authMe.residenceOrCountry});

    const resources = {
      rates,
      authMe,
      wallets,
      bundleTariffOverview,
      bundleOperations,
      docs,
    } as BundleDepositFormStateResources;

    const state = makeForm(() => {
      return new BundleDepositFormState({onClose}, resources);
    });

    const {
      form: {
        calculated: {error},
      },
    } = state;

    if (error) {
      // NOTE: throw an error if the calculation fails
      throw error;
    }

    return (
      <Form form={state}>
        <Box testID="BUNDLE_DEPOSIT_FORM" flex={1} {...boxProps}>
          {!state.form.calculated.data ? (
            <DefaultSuspenseFallback />
          ) : (
            <>
              <Content />
              <Footer />
            </>
          )}
        </Box>
      </Form>
    );
  });
