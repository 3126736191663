import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {Version} from '@youtoken/ui.resource-wallets';
import {LogoColored} from '@youtoken/ui.icons';
import {Badge} from '@youtoken/ui.elements';
import type {WalletsChooseTickerListItem} from '../../../state/WalletsChooseTickerFeature';
import {isNativeNetwork} from './utils';

export interface ListItemProps extends BoxProps {
  item: WalletsChooseTickerListItem;
  variant?: 'amount' | 'versions';
}

export const ListItem: React.FC<ListItemProps> = observer(
  ({item, variant, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      ticker,
      tickerName,
      tickerFormatted,
      versions,
      hasApr,
      aprPercent,
      amountFormatted,
      isAmountPositive,
      fee,
    } = item;

    const itemVersions = React.useMemo(() => {
      return versions.filter(v => !isNativeNetwork(v));
    }, [versions]);

    const renderVersion = React.useCallback(({version}: Version) => {
      return (
        <Badge size="tiny" type="secondary" key={version} m={3}>
          {version.toUpperCase()}
        </Badge>
      );
    }, []);

    return (
      <Box
        flexGrow={1}
        flexShrink={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={60}
        px={24}
        py={16}
        testID={`CHOOSE_TICKER_LIST_ITEM_${item.tickerFormatted}`}
        {...boxProps}
      >
        <Box flexDirection="row" alignItems="center" mr={24}>
          <Box mr={10}>
            <LogoColored name={ticker as any} size={32} />
          </Box>

          <Box flexShrink={1} borderColor={'$attention-01'}>
            <Text
              variant="$body-01-high-accent"
              color={
                variant === 'amount' && !isAmountPositive
                  ? '$text-02'
                  : '$text-01'
              }
            >
              {tickerName}
            </Text>
            <Box flexDirection="row">
              <Text variant="$body-02" color="$text-02">
                {fee ?? `${ticker.toUpperCase()} `}
              </Text>
              {hasApr && (
                <Text variant="$body-02" color="$interactive-01">
                  {aprPercent}% {t('surface.wallets.apr')}
                </Text>
              )}
            </Box>
          </Box>
        </Box>

        {variant === 'versions' && (
          <Box
            flexDirection="row"
            flex={1}
            flexWrap="wrap"
            justifyContent="flex-end"
            m={-3}
          >
            {itemVersions.map(renderVersion)}
          </Box>
        )}
        {variant === 'amount' && (
          <Box
            flexDirection="row"
            flex={1}
            gap={3}
            flexWrap="wrap"
            justifyContent="flex-end"
          >
            <Text
              variant={isAmountPositive ? '$body-01-high-accent' : '$body-01'}
              color={isAmountPositive ? '$text-01' : '$text-02'}
            >
              {amountFormatted}
            </Text>
            <Text color="$text-02">{tickerFormatted}</Text>
          </Box>
        )}
      </Box>
    );
  }
);
