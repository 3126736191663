import * as React from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {KeyboardScrollView, Separator, useWizard} from '@youtoken/ui.elements';
import {i18n} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {CryptoDepositBanner} from '../../../../CryptoDepositBanner';
import {CryptoDirectDepositChooseNetworkFeature} from './state';
import {NetworkSelectorItem} from '../../components';
import {HeaderBase} from '../../../../components';
import {
  type CryptoDirectDepositWizardNavigator,
  CryptoDirectDepositWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const getCryptoDirectDepositChooseNetworkTitle = (ticker: string) => {
  return i18n.t('surface.wallets.crypto_choose_network.title', {
    ticker: ticker.toUpperCase(),
  });
};

const CryptoNetworksWrapper: React.FC = observer(({children}) => {
  const {bottom} = useSafeAreaInsets();

  return (
    <KeyboardScrollView
      insideTabs={false}
      scrollViewProps={{
        alwaysBounceVertical: false,
        keyboardDismissMode: 'none',
        style: {flexGrow: 0},
        contentContainerStyle: {
          flexGrow: 0,
          paddingTop: 0,
          paddingBottom: bottom,
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
    >
      {children}
    </KeyboardScrollView>
  );
});

export const CryptoDirectDepositChooseNetwork: React.FC = cell(
  () => {
    const {
      step: {
        props: {ticker},
      },
      navigate,
      goBack,
    } = useWizard<
      CryptoDirectDepositWizardNavigator,
      CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT_CHOOSE_NETWORKS
    >();

    const {networksListFormatted} = CryptoDirectDepositChooseNetworkFeature.use(
      {
        ticker,
      }
    );

    const handlePress = React.useCallback(
      (networkVersion: string) => {
        navigate(CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT, {
          ticker,
          version: networkVersion,
        });
      },
      [ticker]
    );

    return (
      <>
        {showHeader && (
          <>
            <HeaderBase onPressBack={goBack}>
              {getCryptoDirectDepositChooseNetworkTitle(ticker)}
            </HeaderBase>
            <Separator />
          </>
        )}

        <CryptoNetworksWrapper>
          <CryptoDepositBanner ticker={ticker} />
          {networksListFormatted.map(
            ({titleFormatted, networkVersion}, index) => (
              <NetworkSelectorItem
                titleFormatted={titleFormatted}
                networkVersion={networkVersion}
                onPress={handlePress}
                isLast={index === networksListFormatted.length - 1}
              />
            )
          )}
        </CryptoNetworksWrapper>
      </>
    );
  },
  {
    CellWrap: ({children}) => <Box minHeight={140}>{children}</Box>,
  }
);
