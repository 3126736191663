import React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {Text, Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

const handleNavigate = () => {
  SHARED_ROUTER_SERVICE.navigate('MultiHODLsClosed');
};

export const ShowAllClosedButton: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    return (
      <Box px={{default: 20, desktop: 0}} {...boxProps}>
        <Button
          onPress={handleNavigate}
          size="large"
          type="secondary"
          testID="SHOW_ALL_CLOSED_MH_BUTTON"
        >
          <Text>{t('surface.hodls.portfolio.show_closed_hodls.action')}</Text>
        </Button>
      </Box>
    );
  }
);
