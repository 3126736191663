import * as React from 'react';
import {observer} from 'mobx-react';
import {Text} from '@youtoken/ui.primitives';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  normalizeWithoutSpaces,
  normalizeWithoutMultiplySpaces,
} from '@youtoken/ui.normalizers';
import {type WithdrawalForm} from '../../state/WithdrawalForm';
import {FormInputField} from '../FormInputField';

export const FieldSortCode = observer(() => {
  const {t} = useTranslation();

  const {
    form: {accountType},
    view: {ticker},
  } = useForm<WithdrawalForm>();

  if (ticker !== 'gbp' || accountType !== 'iban') {
    return null;
  }

  return (
    <FormInputField
      // even if the name is Sort code it is still using the swiftCode internaly
      name="swiftCode"
      testID="FIAT_WITHDRAWAL_BANK_WIRE_SWIFT_CODE"
      maxLength={15}
      normalizer={normalizeWithoutSpaces}
      label={
        <Label>
          {t('surface.wallets.withdrawal_bank_wire_form.sort_code')}
        </Label>
      }
    />
  );
});
