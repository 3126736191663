import * as React from 'react';
import {z} from 'zod';
import {i18n} from '@youtoken/ui.service-i18n';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {ProfileSurfaceContent} from './Content';

export const ProfileSurface = surface(
  {
    name: 'Profile',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.profile.heading.title'),
    headerShown: true,
  },
  () => {
    return <VariableLayout PrimaryContent={<ProfileSurfaceContent />} />;
  }
);
