import React from 'react';
import {TFuncKey} from 'react-i18next';
import {Box, BoxProps, Text, useIsMobile} from '@youtoken/ui.primitives';
import {SecurityActivityData} from '@youtoken/ui.state-security';
import {observer} from 'mobx-react';
import {DeviceColumn} from './DeviceColumn';
import {BlankDeviceColumn} from './BlankDeviceColumn';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface SecurityActivityListItemProps extends BoxProps {
  item: SecurityActivityData;
  isLast?: boolean;
  index?: number;
}

export const SecurityActivityListItem: React.FC<SecurityActivityListItemProps> =
  observer(({item, isLast = false, index, ...boxProps}) => {
    const {type, target, action, fullDate, time, device, deviceInfo, id} = item;

    const isMobile = useIsMobile();
    const {t} = useTranslation();

    return (
      <Box
        flexDirection="row"
        borderBottomWidth={{default: 1, tablet: isLast ? 0 : 1}}
        borderBottomColor="$ui-01"
        px={{
          default: 16,
          phone: 24,
        }}
        py={15}
        testID={`SECURITY_ACTIVITY_${index}`}
        {...boxProps}
      >
        <Box flexDirection="row" flexBasis="46%" pr={isMobile ? 8 : 16}>
          {device || deviceInfo ? (
            <DeviceColumn device={device} deviceInfo={deviceInfo} />
          ) : (
            <BlankDeviceColumn />
          )}
        </Box>

        <Box alignItems="flex-start" flexBasis="32%" pr={isMobile ? 8 : 16}>
          <Text
            variant="$body-02"
            color="$text-01"
            mb={2}
            testID="SECURITY_ACTIVITY_TYPE"
          >
            {type && t(`security_activity.${type}` as TFuncKey)}{' '}
            {target && t(`security_activity.${target}` as TFuncKey)}
          </Text>
          <Text
            variant="$body-02"
            color="$text-01"
            testID="SECURITY_ACTIVITY_RESULT"
          >
            {action && t(`security_activity.${action}` as TFuncKey)}
          </Text>
        </Box>

        <Box flexBasis="22%" alignItems="flex-end">
          <Text
            variant="$body-02"
            color="$text-01"
            mb={2}
            textAlign="right"
            testID="SECURITY_ACTIVITY_DATE"
          >
            {fullDate}
          </Text>
          <Text
            variant="$body-02"
            color="$text-02"
            testID="SECURITY_ACTIVITY_TIME"
          >
            {time}
          </Text>
        </Box>
      </Box>
    );
  });
