import * as React from 'react';
import {type TSurfaceOptions} from './SurfaceOptions/types';

export const SurfaceOptionsContext = React.createContext<{
  setOptions: (options: Partial<TSurfaceOptions<{}>>) => void;
  options: TSurfaceOptions<{}>;
}>({
  setOptions: () => {},
  options: {
    getTitle: () => '',
  },
});

export const SurfaceOptionsProvider: React.FC<
  React.PropsWithChildren<{options: TSurfaceOptions<{}>}>
> = ({options: initialOptions, children}) => {
  const [options, setOptions] =
    React.useState<TSurfaceOptions<{}>>(initialOptions);

  const value = React.useMemo(
    () => ({
      options,
      setOptions: (newOptions: Partial<TSurfaceOptions<{}>>) => {
        setOptions(prevOptions => ({
          ...prevOptions,
          ...newOptions,
        }));
      },
    }),
    [options]
  );

  return (
    <SurfaceOptionsContext.Provider value={value}>
      {children}
    </SurfaceOptionsContext.Provider>
  );
};

export const useSurfaceOptions = <Params extends {}>(): {
  setOptions: (options: Partial<TSurfaceOptions<Params>>) => void;
  options: TSurfaceOptions<Params>;
} => {
  return React.useContext(SurfaceOptionsContext) as {
    setOptions: (options: Partial<TSurfaceOptions<Params>>) => void;
    options: TSurfaceOptions<Params>;
  };
};
