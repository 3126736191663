import React from 'react';
import {
  type CryptoDepositWizardProps,
  CryptoDepositWizard,
} from '@youtoken/ui.surfaces-wallets';
import {modal} from '../../../stores';
import {Modal} from '../_common';

type ModalCryptoDepositProps = Omit<CryptoDepositWizardProps, 'onPressBack'> &
  React.ComponentProps<typeof Modal>;

export const ModalCryptoDeposit: React.FC<ModalCryptoDepositProps> = ({
  ticker,
  ...modalProps
}) => {
  return (
    <Modal minHeight={140} {...modalProps}>
      <CryptoDepositWizard ticker={ticker} onPressBack={modal.close} />
    </Modal>
  );
};
