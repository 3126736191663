import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useSafeArea} from '@youtoken/ui.hooks';
import {AutoConvertFormState} from '../../state';
import {useForm} from '@youtoken/ui.form-elements';

export const Footer: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {bottom} = useSafeArea();

  const {
    form: {submitIsDisabled, handleSubmit},
  } = useForm<AutoConvertFormState>();

  return (
    <Box pt={24} px={24} pb={bottom} borderTopWidth={1} borderColor="$ui-01">
      <Button
        size="large"
        onPress={handleSubmit}
        disabled={submitIsDisabled}
        testID="DESIRED_RATE_SUBMIT_BUTTON"
      >
        {t('surface.hodls.adjust_tp.action_save')}
      </Button>
    </Box>
  );
});
