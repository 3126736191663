import * as React from 'react';
import {BundleTariffOverviewResource} from '@youtoken/ui.resource-bundles';
import {cell} from '@youtoken/ui.cell';
import {type ModalDynamicProps, ModalDynamic} from '@youtoken/ui.modal';
import {
  type BundleReleaseFormProps,
  getBundleReleaseFormTitle,
  BundleReleaseForm,
} from '../../forms';

export const BundleReleaseModal = cell<
  BundleReleaseFormProps & ModalDynamicProps
>(({id, onClose}) => {
  const {
    data: {
      tariff: {
        design: {title},
      },
    },
  } = BundleTariffOverviewResource.use({
    id,
  });

  return (
    <ModalDynamic
      testID="BUNDLE_RELEASE_MODAL"
      isOpen
      title={getBundleReleaseFormTitle(title)}
      onClose={onClose}
      webMinHeight={650}
      webMaxHeight="90vh"
    >
      <BundleReleaseForm id={id} onClose={onClose} />
    </ModalDynamic>
  );
});
