import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {MarketsResource} from '@youtoken/ui.resource-markets';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {ExchangeTariffsResource} from '@youtoken/ui.resource-exchange-tariffs';
import {type ExchangeFormProps} from './types';
import {useExchangeAttemptTracker} from './hooks';
import {ExchangeFormState} from './state';
import {
  ExchangeFormHeader,
  ExchangeFormContentV2,
  ExchangeFormFooterV2,
} from './components';

export const ExchangeForm: React.FC<ExchangeFormProps & BoxProps> = cell(
  ({
    showHeader = false,
    ticker: _ticker,
    conversionTicker: _conversionTicker,
    initialAmount,
    orderType,
    desiredRate,
    dl_source,
    ...boxProps
  }) => {
    const {residenceOrCountry} = AuthMeResource.use({});

    const resources = useResources({
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      verificationResource: getResourceDescriptor(VerificationResource, {}),
      docsResource: getResourceDescriptor(DocsResource, {
        country: residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      marketsResource: getResourceDescriptor(MarketsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      exchangeTariffs: getResourceDescriptor(ExchangeTariffsResource, {}),
    });

    const state = makeForm(
      () =>
        new ExchangeFormState(
          {
            ticker: _ticker,
            conversionTicker: _conversionTicker,
            initialAmount,
            orderType,
            desiredRate,
          },
          resources
        )
    );

    const {
      form: {ticker, conversionTicker},
    } = state;

    useExchangeAttemptTracker(ticker, conversionTicker, 'v2', dl_source);

    return (
      <Form form={state}>
        <Box flex={1} {...boxProps}>
          {showHeader && <ExchangeFormHeader />}
          <ExchangeFormContentV2 initialAmount={initialAmount} />
          <ExchangeFormFooterV2 />
        </Box>
      </Form>
    );
  }
);
