import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {AutoConvertFormState} from '../../state';
import {useForm} from '@youtoken/ui.form-elements';
import {Platform} from 'react-native';
import {AutoConvertRateModalChart} from '../Chart';

export const ChartSection: React.FC<BoxProps> = observer(({...boxProps}) => {
  const {
    form: {fromTicker, toTicker, chartModeDesiredRate, desiredRate},
  } = useForm<AutoConvertFormState>();

  return (
    <Box
      borderTopWidth={1}
      borderBottomWidth={Platform.OS === 'web' ? 0 : 1}
      borderColor="$ui-01"
      {...boxProps}
    >
      <AutoConvertRateModalChart
        fromTicker={fromTicker}
        toTicker={toTicker}
        mode={chartModeDesiredRate}
        triggerPrice={desiredRate}
      />
    </Box>
  );
});
