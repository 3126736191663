import * as React from 'react';
import {observer} from 'mobx-react';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {WithdrawalWizardChooseMethodBase} from './index.base';
import {HeaderBase} from '../../../components';
import {
  type WithdrawalWizardNavigator,
  WithdrawalWizardScreenName,
} from '../index';

export const WithdrawalWizardChooseMethod: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    step: {
      props: {ticker},
    },
    goBack,
  } = useWizard<
    WithdrawalWizardNavigator,
    WithdrawalWizardScreenName.CHOOSE_METHOD
  >();

  return (
    <Box>
      <>
        <HeaderBase onPressBack={goBack}>
          {ticker
            ? t('surface.wallets.fiat_withdrawal.title', {
                ticker: ticker.toUpperCase(),
              })
            : t('surface.wallets.withdrawal_wizard.select_method')}
        </HeaderBase>
        <Separator />
      </>
      <WithdrawalWizardChooseMethodBase />
    </Box>
  );
});
