import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {Slider} from '@youtoken/ui.legacy-slider';
import {ExchangeFormState} from '../../state';

export const ExchangeFormAmountSlider: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {hasBalance, balancePercent, setAmountByPercentOfBalance},
  } = useForm<ExchangeFormState>();

  return (
    <Slider
      value={balancePercent}
      minimumValue={0}
      maximumValue={100}
      label={t('surface.wallets.conversion_form.slider')}
      onChange={setAmountByPercentOfBalance}
      showLegend={false}
      disabled={!hasBalance}
      testID="CONVERT_AMOUNT_SLIDER"
    />
  );
});
