import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ChartControls, AutoConvertChart, useChartCursorState} from '../Charts';
import {LiveChartDataResource} from '../state/Chart';
import {ChartWithControlsSuspenseFallback} from './SuspenseFallbacks';
import {type ChartSmartCells} from './utils/types';
import {CellWrap} from './utils/CellWrap';
import {PanResponder} from '../PanResponder';

export const AutoConvertChartSmartCell =
  cell<ChartSmartCells.AutoConvertChartSmartCellProps>(
    ({
      width,
      height,
      fromTicker,
      toTicker,
      precision,
      mode,
      triggerPrice,
      ChartPanComponent = PanResponder,
      onPressTradingViewModeButton,
    }) => {
      const {setPointIndex, pointIndex, setCursorX, cursorX} =
        useChartCursorState();

      const {chartData, isLoading, chartControls, hasData, refetch} =
        LiveChartDataResource.use({
          key: 'AutoConvertChart',
          fromTicker,
          toTicker,
          mode,
        });

      return (
        <>
          <ChartPanComponent
            width={width}
            height={height}
            onCursorChange={setCursorX}
          >
            <AutoConvertChart
              data={chartData}
              height={height}
              width={width}
              cursorX={cursorX}
              isFetching={isLoading}
              displayActivityIndicator
              onCursorIndexChange={setPointIndex}
              triggerPrice={triggerPrice}
              hasData={hasData}
              refetch={refetch}
              formatNumberPrecision={precision}
            />
          </ChartPanComponent>
          <ChartControls
            isCursorActive={pointIndex !== undefined && hasData}
            onPressTradingViewModeButton={onPressTradingViewModeButton}
            {...chartControls}
          />
        </>
      );
    },
    {
      displayName: 'AutoConvertChartSmartCell',
      SuspenseFallback: ChartWithControlsSuspenseFallback,
      CellWrap: CellWrap,
    }
  );
