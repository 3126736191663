import {observable} from 'mobx';
import {object, optional, primitive, serializable} from 'serializr';
import {
  DemoProduct,
  DepositBuyCrypto,
  DepositCryptoProduct,
  DepositFiatAdvcashProduct,
  DepositFiatBankCardProduct,
  DepositFiatUnlimintApplePayProduct,
  DepositFiatUnlimintCoDiProduct,
  DepositFiatUnlimintSPEIProduct,
  DepositFiatUnlimitProduct,
  DepositFiatWireProduct,
  ExchangeProduct,
  FiatRepublicProduct,
  HodlProduct,
  IncentivesProduct,
  IntergiroProduct,
  LoanProduct,
  LoyaltyProduct,
  MinerProduct,
  QuizProduct,
  RewardsProduct,
  RewardsChallengeProduct,
  Saving3Product,
  Saving4Product,
  StroriesProduct,
  TurboProduct,
  WirexProduct,
  WithdrawalCryptoProduct,
  WithdrawalFiatProduct,
  CoindropProduct,
  Uma,
  OnboardingWidgetProduct,
  BundlesProduct,
} from './Product';

class KycAccepted {
  @observable
  @serializable(primitive())
  identity: boolean = false;

  @observable
  @serializable(primitive())
  formA: boolean = false;

  @observable
  @serializable(primitive())
  address: boolean = false;
}

export class Products {
  @observable
  @serializable(object(KycAccepted))
  kycAccepted!: KycAccepted;

  @observable
  @serializable(object(LoyaltyProduct))
  loyalty: LoyaltyProduct = new LoyaltyProduct();

  @observable
  @serializable(object(DemoProduct))
  demo: DemoProduct = new DemoProduct();

  @observable
  @serializable(object(HodlProduct))
  hodl: HodlProduct = new HodlProduct();

  @observable
  @serializable(object(LoanProduct))
  loan: LoanProduct = new LoanProduct();

  @observable
  @serializable(object(TurboProduct))
  turbo: TurboProduct = new TurboProduct();

  @observable
  @serializable(object(ExchangeProduct))
  exchange: ExchangeProduct = new ExchangeProduct();

  @observable
  @serializable(object(Saving3Product))
  saving3: Saving3Product = new Saving3Product();

  @observable
  @serializable(object(DepositCryptoProduct))
  depositCrypto: DepositCryptoProduct = new DepositCryptoProduct();

  @observable
  @serializable(object(DepositFiatAdvcashProduct))
  depositFiatAdvcash: DepositFiatAdvcashProduct =
    new DepositFiatAdvcashProduct();

  @observable
  @serializable(object(DepositFiatBankCardProduct))
  depositFiatBankCard: DepositFiatBankCardProduct =
    new DepositFiatBankCardProduct();

  @observable
  @serializable(object(DepositFiatUnlimintApplePayProduct))
  depositFiatUnlimintApplePay: DepositFiatUnlimintApplePayProduct =
    new DepositFiatUnlimintApplePayProduct();

  @observable
  @serializable(object(DepositFiatBankCardProduct))
  depositFiatUnlimintBankCard: DepositFiatBankCardProduct =
    new DepositFiatBankCardProduct();

  @observable
  @serializable(object(DepositFiatBankCardProduct))
  depositFiatCheckoutBankCard: DepositFiatBankCardProduct =
    new DepositFiatBankCardProduct();

  @observable
  @serializable(object(DepositFiatBankCardProduct))
  depositFiatCheckoutBankCardFrame: DepositFiatBankCardProduct =
    new DepositFiatBankCardProduct();

  @observable
  @serializable(object(DepositFiatUnlimintCoDiProduct))
  depositFiatUnlimintCoDi: DepositFiatUnlimintCoDiProduct =
    new DepositFiatUnlimintCoDiProduct();

  @observable
  @serializable(object(DepositFiatUnlimintSPEIProduct))
  depositFiatUnlimintSPEI: DepositFiatUnlimintSPEIProduct =
    new DepositFiatUnlimintSPEIProduct();

  @observable
  @serializable(object(DepositFiatWireProduct))
  depositFiatWire: DepositFiatWireProduct = new DepositFiatWireProduct();

  @observable
  @serializable(object(Saving4Product))
  saving4: Saving4Product = new Saving4Product();

  @observable
  @serializable(object(MinerProduct))
  miner: MinerProduct = new MinerProduct();

  @observable
  @serializable(object(StroriesProduct))
  stories: StroriesProduct = new StroriesProduct();

  @observable
  @serializable(object(QuizProduct))
  quiz: QuizProduct = new QuizProduct();

  @observable
  @serializable(object(RewardsProduct))
  rewards: RewardsProduct = new RewardsProduct();

  @observable
  @serializable(object(RewardsChallengeProduct))
  rewardsChallenge: RewardsChallengeProduct = new RewardsChallengeProduct();

  // optional so that the release goes smoothly, as discussed here
  // https://yhdl.slack.com/archives/C05D9FRKJMP/p1714476236428989
  // it will be removed in https://youhodler.atlassian.net/browse/TRD-5025
  @observable
  @serializable(optional(object(IncentivesProduct)))
  incentives?: IncentivesProduct = new IncentivesProduct();

  @observable
  @serializable(object(WirexProduct))
  wirex: WirexProduct = new WirexProduct();

  @observable
  @serializable(object(IntergiroProduct))
  intergiro: IntergiroProduct = new IntergiroProduct();

  @observable
  @serializable(object(WithdrawalCryptoProduct))
  withdrawalCrypto: WithdrawalCryptoProduct = new WithdrawalCryptoProduct();

  @observable
  @serializable(object(WithdrawalFiatProduct))
  withdrawalFiat: WithdrawalFiatProduct = new WithdrawalFiatProduct();

  @observable
  @serializable(object(FiatRepublicProduct))
  fiatRepublic: FiatRepublicProduct = new FiatRepublicProduct();

  @observable
  @serializable(object(DepositFiatUnlimitProduct))
  depositFiatUnlimit: DepositFiatUnlimitProduct =
    new DepositFiatUnlimitProduct();

  @observable
  @serializable(object(DepositBuyCrypto))
  depositBuyCrypto: DepositBuyCrypto = new DepositBuyCrypto();

  @observable
  @serializable(object(CoindropProduct))
  drops: CoindropProduct = new CoindropProduct();

  @observable
  @serializable(object(Uma))
  uma: Uma = new Uma();

  @observable
  @serializable(object(OnboardingWidgetProduct))
  onboardingWidget: OnboardingWidgetProduct = new OnboardingWidgetProduct();

  @observable
  @serializable(object(BundlesProduct))
  bundles: BundlesProduct = new BundlesProduct();
}
