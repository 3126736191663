import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Label, RadioField, useForm} from '@youtoken/ui.form-elements';
import {type WithdrawalForm} from '../../state/WithdrawalForm';

export const FieldTemplates: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {templateIdValue, changeTemplateId},
    view: {templates, removeTemplate, ticker},
  } = useForm<WithdrawalForm>();

  const createRemoveHandler = React.useCallback(
    (templateId: string) => () => {
      removeTemplate(templateId);

      if (templateIdValue === templateId) {
        changeTemplateId('new');
      }
    },
    [templateIdValue, removeTemplate]
  );

  if (ticker === 'gbp') {
    return null;
  }

  return (
    <Box mb={24}>
      <Box mb={8}>
        <Label>
          {t('surface.wallets.withdrawal_bank_wire_form.saved_account')}
        </Label>
      </Box>

      <RadioField
        value={templateIdValue}
        items={[
          ...templates.map(template => ({
            key: template.templateId,
            label: (
              <Box flexDirection="row" alignItems="center">
                <Text mr={10}>
                  {template.beneficiaryName} / {template.bankName}
                </Text>
                <TouchableBox
                  onPress={createRemoveHandler(template.templateId)}
                  testID={`FIAT_WITHDRAWAL_BANK_WIRE_TEMPLATE_REMOVE_${template.templateId}`}
                >
                  <Icon name="remove" color="$text-05" />
                </TouchableBox>
              </Box>
            ),
            value: template.templateId,
            testID: `FIAT_WITHDRAWAL_BANK_WIRE_TEMPLATE_${template.templateId}`,
          })),
          {
            key: 'new',
            value: 'new',
            label: t(
              'surface.wallets.withdrawal_bank_wire_form.saved_account_new'
            ),
            testID: 'FIAT_WITHDRAWAL_BANK_WIRE_TEMPLATE_NEW',
          },
        ]}
        onChange={changeTemplateId}
      />
    </Box>
  );
});
