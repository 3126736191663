import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {UmaInfo, TickerSelector} from './components';
import {LightSparkLogo} from '../../components';

export const UmaSettingsSurfaceContent: React.FC = observer(() => {
  const {t} = useTranslation();
  return (
    <Box
      justifyContent={{default: 'space-between', desktop: undefined}}
      flexGrow={{default: 1, desktop: undefined}}
      pb={24}
    >
      <Box zIndex={1}>
        <Text color="$text-02" variant="$body-02" py={20} px={24}>
          {t('surface.wallets.uma.create.explanation')}
        </Text>
        <Separator />
        <UmaInfo />
        <Separator />
        <TickerSelector />
      </Box>
      <LightSparkLogo />
    </Box>
  );
});
