import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

export interface LoanItemActionsAdditionalsProps extends BoxProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemActionsAdditionals: React.FC<LoanItemActionsAdditionalsProps> =
  observer(({item, ...rest}) => {
    const {t} = useTranslation();

    const handlePressIncrease = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('LoanIncrease', {
        id: item.id,
      });
    }, [item.id]);

    const handlePressDecrease = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('LoanDecrease', {
        id: item.id,
      });
    }, [item.id]);

    const handlePressFTP = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('LoanFTP', {
        id: item.id,
      });
    }, [item.id]);

    const handlePressExtendLoan = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('LoanReopen', {
        id: item.id,
      });
    }, [item.id]);

    if (item.isClosed) {
      return null;
    }

    return (
      <Box testID="LOAN_ITEM_ACTIONS_ADDITIONALS" {...rest}>
        <Box
          flexDirection={{default: 'column', tablet: 'row'}}
          flexWrap="wrap"
          m={-5}
        >
          <Box flexDirection="row" flex={1}>
            <Button
              testID="LOAN_ITEM_INCREASE_BUTTON"
              type="secondary"
              size="medium"
              icon="increase"
              disabled={!item.allowIncrease}
              onPress={handlePressIncrease}
              m={5}
              flexGrow={1}
              flexShrink={1}
              flexBasis={1}
            >
              {t('surface.loans.item.increase')}
            </Button>
            <Button
              testID="LOAN_ITEM_DECREASE_BUTTON"
              type="secondary"
              size="medium"
              icon="extend"
              disabled={!item.allowDecrease}
              onPress={handlePressDecrease}
              m={5}
              flexGrow={1}
              flexShrink={1}
              flexBasis={1}
            >
              {t('surface.loans.item.extend')}
            </Button>
          </Box>

          <Box flexDirection="row" flex={item.version === 2 ? 1 : 0.5}>
            <Button
              testID="LOAN_ITEM_FTP_BUTTON"
              type="secondary"
              size="medium"
              icon="ftp"
              disabled={!item.allowSetClosePrice}
              onPress={handlePressFTP}
              m={5}
              flexGrow={1}
              flexShrink={1}
              flexBasis={1}
            >
              {t('surface.loans.item.ftp')}
            </Button>
            {item.version === 2 && (
              <Button
                testID="LOAN_ITEM_REOPEN_BUTTON"
                type="secondary"
                size="medium"
                icon="reopen"
                disabled={!item.reopenAvailable}
                onPress={handlePressExtendLoan}
                m={5}
                flexGrow={1}
                flexShrink={1}
                flexBasis={1}
              >
                {t('surface.loans.item.extend_loan')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  });
