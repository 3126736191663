import * as React from 'react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {invariant} from '@youtoken/ui.utils';
import {Icon} from '@youtoken/ui.icons';
import type {MethodData} from '../../types';
import {MethodItemTag} from './Tag';
import {Platform} from 'react-native';

export interface MethodItemProps extends MethodData {
  disabled?: boolean;
  onPress: () => void;
  fee?: string;
  description?: string;
  hasActive?: boolean;
  isActive?: boolean;
  isLast?: boolean;
  tags?: string[];
  showIconBadge?: boolean;
}

export const MethodItem: React.FC<MethodItemProps> = ({
  title,
  fee,
  description,
  icon,
  disabled,
  testID,
  onPress,
  hasActive,
  isActive,
  showIconBadge = false,
  isLast,
  tags,
}) => {
  const showCheckIcon = hasActive && isActive;
  const showForwardIcon = !hasActive; // NOTE: default sate, as hasActive == undefined

  invariant(title, 'MethodItem: title is required', {}, {title});

  return (
    <>
      <TouchableBox
        px={24}
        py={16}
        flexDirection="row"
        borderColor="$ui-01"
        onPress={onPress}
        disabled={disabled}
        testID={testID}
        alignItems="center"
      >
        {React.isValidElement(icon) && (
          <Box
            position="relative"
            mr={16}
            width={48}
            height={48}
            borderRadius={24}
            backgroundColor="$interactive-02"
            alignItems="center"
            justifyContent="center"
            opacity={disabled ? 0.5 : 1}
          >
            {icon}
            {showIconBadge && (
              <Box
                position="absolute"
                top={-4}
                right={-4}
                backgroundColor="$interactive-01"
                width={23}
                height={13}
                borderRadius={9}
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  // NOTE: Without lineHeight, text is not centered vertically on native
                  lineHeight={13}
                  variant="$body-03-high-accent"
                  color="$text-04"
                >
                  0%
                </Text>
              </Box>
            )}
          </Box>
        )}
        <Box
          flexDirection="column"
          flexGrow={1}
          flexBasis={0}
          opacity={disabled ? 0.5 : 1}
        >
          <Text variant="$body-01-high-accent" color="$text-01">
            {title}
          </Text>
          {Boolean(fee) && (
            <Text mt={4} variant="$body-02" color="$text-02">
              {fee}
            </Text>
          )}
          {Boolean(description) && (
            <Text mt={4} variant="$body-02" color="$text-02">
              {description}
            </Text>
          )}
          {Boolean(tags?.length) && tags!.length > 0 && (
            <Box
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              pt={4}
              m={-2}
            >
              {tags!.map(name => (
                <MethodItemTag key={name} name={name} m={2} />
              ))}
            </Box>
          )}
        </Box>
        {(showCheckIcon || showForwardIcon) && (
          <Box justifyContent="center">
            {showCheckIcon && <Icon name="check" color="$interactive-01" />}
            {showForwardIcon && <Icon name="chevron_right" color="$text-02" />}
          </Box>
        )}
      </TouchableBox>
      {(!isLast || Platform.OS !== 'web') && <Separator />}
    </>
  );
};
