import * as React from 'react';
import {observer} from 'mobx-react';
import {useWizard} from '@youtoken/ui.elements';
import {CryptoDirectWithdrawalWizard} from '../../CryptoDirectWizards';
import {
  type WithdrawalWizardNavigator,
  type WithdrawalWizardScreenName,
} from '..';

export const CryptoDirectWithdrawalWizardWrapper: React.FC = observer(() => {
  const {
    step: {
      props: {ticker, version},
    },
    goBack,
  } = useWizard<
    WithdrawalWizardNavigator,
    WithdrawalWizardScreenName.CRYPTO_DIRECT
  >();

  return (
    <CryptoDirectWithdrawalWizard
      ticker={ticker}
      version={version}
      onPressBack={goBack}
    />
  );
});
