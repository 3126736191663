import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useWizard} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import type {BuyCryptoFormAuthorizedYouHodlerWizardNavigatorParams} from '..';
import {BuyCryptoFormAuthorizedYouHodlerState} from '../../../../state';
import {CardSelector, HeaderBase} from '../../../../../../components';
import {IncentivesBonuses} from '../../../IncentivesBonuses';
import {DepositMethodRampEnum} from '../../../../types';
import {PayWithField} from '../../../PayWith';
import {
  ConversionPairSourceAuthed,
  ConversionPairTarget,
  FormFooter,
  InfoTableV2,
  TwoFA,
} from '../../..';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {ScrollWrapper} from './ScrollWrapper';

type ContentProps = {
  withScrollView?: boolean;
};

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const Content: React.FC<ContentProps> = observer(
  ({withScrollView = true}) => {
    const {t} = useTranslation();
    const {navigate, goBack} = useWizard<
      BuyCryptoFormAuthorizedYouHodlerWizardNavigatorParams,
      'Main'
    >();

    const {
      form: {depositMethod, cardId, cardsList, shouldHideFooter, setCardId},
    } = useForm<BuyCryptoFormAuthorizedYouHodlerState>();

    const handlePressManage = React.useCallback(() => {
      navigate('PaymentMethods');
    }, [navigate]);

    const handlePressAddCard = React.useCallback(() => {
      navigate('AddCard');
    }, [navigate]);

    // in web-app, no scroll is needed because it's handled by the modal
    const ScrollWrap = withScrollView ? ScrollWrapper : React.Fragment;

    return (
      <>
        {showHeader && (
          <HeaderBase onPressBack={goBack}>
            {t('surface.menu.buy_crypto')}
          </HeaderBase>
        )}
        <ScrollWrap>
          <Box pt={16} height="100%" justifyContent="space-between">
            <Box px={24}>
              <ConversionPairSourceAuthed />
              <ConversionPairTarget mt={24} />
              <IncentivesBonuses mt={16} />
              <PayWithField mt={24} />
              {depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME && (
                <CardSelector
                  cardsList={cardsList}
                  onSelectCard={setCardId}
                  selectedCardId={cardId}
                  onPressAddNewCard={handlePressAddCard}
                  onPressManage={handlePressManage}
                  mt={12}
                  mb={shouldHideFooter ? 24 : 0}
                  zIndex={1}
                />
              )}
              {!shouldHideFooter && (
                <Box>
                  {depositMethod !== DepositMethodRampEnum.BANK_WIRE && (
                    <InfoTableV2 mt={24} />
                  )}
                  <TwoFA mt={24} />
                </Box>
              )}
            </Box>
            {!shouldHideFooter && (
              <FormFooter
                zIndex={-1}
                onPressSubmit={
                  depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME &&
                  cardsList.length === 0
                    ? handlePressAddCard
                    : undefined
                }
              />
            )}
          </Box>
        </ScrollWrap>
      </>
    );
  }
);
