import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {ExchangeFormConversionPairSource} from '../../ExchangeForm';

export const ConversionPairSourceAuthed: React.FC<BoxProps> = observer(
  boxProps => {
    return (
      <ExchangeFormConversionPairSource
        labelKey="ramp.conversion_form.label.source"
        withFastHandlers={false}
        {...boxProps}
      />
    );
  }
);
