import * as React from 'react';
import {Platform} from 'react-native';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AccountLevelFull} from '@youtoken/ui.surface-account-levels';
import {Box} from '@youtoken/ui.primitives';
import {useAttachPullToRefreshToFeature} from '@youtoken/ui.hooks';
import {SurfaceOptions} from '@youtoken/ui.screen-components';
import {
  TopButtons,
  AccountNumber,
  AccountVerifications,
  Email,
  TwoFA,
  AntiPhishing,
  SelectCurrency,
  TrustedDevices,
  ResetPassword,
  Settings,
  Appearance,
  Language,
  LegalAndInfo,
  DeleteAccount,
  PaymentMethods,
  FeesAndCommissions,
  Notifications,
  SectionTitle,
  ContactSupport,
  HelpCenter,
  SignOutItem,
  GoFullVersionButton,
  ReferralsAndBonuses,
  SecurityActivity,
  TraderQuiz,
  AccountStats,
  Turbo,
  MenuWrapper,
  UniversalMoneyAddress,
} from './components';
import {MoreFeature} from './state/MoreFeature';

const isNative = Platform.select({
  native: true,
  default: false,
});

export const ProfileSurfaceContent: React.FC = () => {
  const {t} = useTranslation();

  const moreFeature = MoreFeature.use({});

  const {
    me: {
      forceIdentify,
      enableRewards,
      enableHodlQuiz,
      fullName,
      products: {
        loyalty: {available: loyaltyAvailable},
        uma: {
          available: isUmaAvailable,
          settings: {enable: isUmaEnable},
        },
        depositFiatCheckoutBankCardFrame: {isEnabled: isBankCardEnabled},
      },
      newReferralEnabled,
    },
  } = moreFeature;

  const {refreshControl} = useAttachPullToRefreshToFeature(moreFeature);

  return (
    <>
      {isNative && Boolean(fullName) && (
        <SurfaceOptions getTitle={() => fullName!} />
      )}
      <MenuWrapper refreshControl={refreshControl}>
        <Box backgroundColor="$ui-background">
          <TopButtons
            mb={24}
            mt={isNative ? 24 : 0}
            px={{default: 24, desktop: 0}}
            testID="TOP_BUTTONS"
          />
        </Box>
        <AccountNumber
          mb={24}
          testID="ACCOUNT_NUMBER"
          px={{default: 24, desktop: 0}}
        />
        {loyaltyAvailable && !forceIdentify && (
          <AccountLevelFull
            size={isNative ? 'small' : 'large'}
            mb={24}
            testID="ACCOUNT_LEVEL"
            px={{default: 24, desktop: 0}}
          />
        )}
        {!isNative && <GoFullVersionButton />}
        {!forceIdentify && (
          <AccountVerifications
            px={{default: 24, desktop: 0}}
            testID="VERIFICATIONS"
          />
        )}
        <Email
          borderTopWidth={1}
          testID="EMAIL"
          borderBottomWidth={0}
          mt={24}
        />
        <SectionTitle testID="SECTION_HELP">
          {t('surface.more.section_title.help')}
        </SectionTitle>
        <ContactSupport testID="CONTACT_SUPPORT" />
        <HelpCenter
          testID="HELP_CENTER"
          borderBottomWidth={forceIdentify && !isNative ? 1 : 0}
        />
        {!forceIdentify && (
          <SectionTitle testID="SECTION_ACCOUNT">
            {t('surface.more.section_title.account')}
          </SectionTitle>
        )}
        {!forceIdentify && isBankCardEnabled && (
          <PaymentMethods testID="PAYMENT_METHODS" />
        )}
        {!forceIdentify && isUmaAvailable && isUmaEnable && (
          <UniversalMoneyAddress testID="UMA" />
        )}
        {enableRewards && !forceIdentify && !newReferralEnabled && (
          <ReferralsAndBonuses />
        )}
        {!forceIdentify && <TwoFA testID="TWO_FA" />}
        {!forceIdentify && <AntiPhishing testID="ANTI_PHISHING" />}
        {!forceIdentify && <TrustedDevices testID="TRUSTED_DEVICES" />}
        {!forceIdentify && <SecurityActivity testID="SECURITY_ACTIVITY" />}
        {enableHodlQuiz && !forceIdentify && (
          <TraderQuiz testID="TRADER_QUIZ" />
        )}
        {isNative && !forceIdentify && <AccountStats testID="ACCOUNT_STATS" />}
        {!isNative && (
          <ResetPassword
            testID="RESET_PASSWORD"
            borderBottomWidth={forceIdentify ? 0 : 1}
          />
        )}
        {!forceIdentify && (
          <FeesAndCommissions
            testID="FEES_AND_COMMISSIONS"
            borderBottomWidth={0}
          />
        )}

        <SectionTitle testID="SECTION_APPLICATION">
          {t('surface.more.section_title.app')}
        </SectionTitle>
        {isNative && !forceIdentify && <Turbo testID="TURBO" />}
        <Notifications testID="NOTIFICATIONS" />
        <SelectCurrency />
        <Language zIndex={10} />
        <Appearance testID="APPEARANCE" />
        {isNative && <Settings testID="Settings" />}
        <LegalAndInfo testID="LEGAL_AND_INFO" />
        {isNative && <SignOutItem testID="SIGN_OUT" />}
        <DeleteAccount testID="DELETE_ACCOUNT" />
      </MenuWrapper>
    </>
  );
};
