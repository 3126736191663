import {comparer, computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import Big from 'big.js';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  tickerToComboboxItem,
  ViewBase as ExchangeFormViewBase,
} from '../../../ExchangeForm';
import {formatBigNumber, toBig} from '@youtoken/ui.formatting-utils';
import {DepositMethodEnum} from '../../../../constants';
import {DepositMethodRampEnum} from '../../types';
import {FormBase} from './FormBase';

//@ts-ignore
export class ViewBase extends ExchangeFormViewBase<FormBase> {
  //#region wallets

  @computed({
    equals: comparer.shallow,
  })
  get tickersShowed() {
    return this.form.tickers.filter(ticker => {
      return this.form.resources.authMeResource.showedWalletTickers.includes(
        ticker
      );
    });
  }

  @computed({
    equals: comparer.shallow,
  })
  get tickersHidden() {
    return this.form.tickers.filter(ticker => {
      return this.form.resources.authMeResource.hiddenWalletTickers.includes(
        ticker
      );
    });
  }

  @computed({
    equals: comparer.shallow,
  })
  get wallets() {
    return this.form.tickers.map(ticker => {
      return tickerToComboboxItem(ticker);
    });
  }

  //#endregion wallets

  @computed get convertActionText() {
    if (this.form.depositMethod === DepositMethodRampEnum.APPLE_PAY) {
      return i18n.t('surface.apple_pay.deposit.components.submit.default');
    }

    if (this.form.useYHBalance) {
      return i18n.t('ramp.conversion_form.action.submit_convert', {
        ticker: this.conversionTickerFormatted,
      });
    }
    return i18n.t('ramp.conversion_form.action.submit_buy', {
      ticker: this.conversionTickerFormatted,
    });
  }

  @computed get currentDepositMethod() {
    return this.form.depositMethodsData[this.form.depositMethod];
  }

  @computed get shouldShowDepositMethodSelector() {
    return this.form.possibleDepositMethods.length >= 1;
  }

  @computed get shouldDisableDepositMethodSelector() {
    return this.form.possibleDepositMethods.length === 1;
  }

  //#region fees

  @computed
  get convertFeeFormatted() {
    return formatBigNumber(this.form.fee, this.tickerPrecision, true);
  }
  @computed get isMinPaymentFeeSet() {
    return !this.paymentFeeData.min.eq(0);
  }

  @computed get isMaxPaymentFeeSet() {
    return !this.paymentFeeData.max.eq(0);
  }

  private getCalculatedFee = computedFn((fee: Big) => {
    if (this.isMinPaymentFeeSet && fee.lte(this.paymentFeeData.min)) {
      return this.paymentFeeData.min;
    }

    if (this.isMaxPaymentFeeSet && fee.gte(this.paymentFeeData.max)) {
      return this.paymentFeeData.max;
    }

    return fee;
  });

  @computed
  get paymentFeeData() {
    return this.form.checkoutBankCardFee;
  }

  @computed
  public get paymentFee() {
    if (!this.form.amountBig || !this.paymentFeeData.percent) {
      return toBig(0);
    }

    const calculatedFee = this.form.amountBig.mul(this.paymentFeeData.percent);
    return this.getCalculatedFee(calculatedFee);
  }

  @computed
  get paymentFeeFormatted() {
    return formatBigNumber(this.paymentFee, this.tickerPrecision, true);
  }

  @computed get totalPay() {
    let res = this.form.amountBig.plus(this.paymentFee);

    if (res.lt(0)) {
      return toBig(0);
    }

    return res;
  }

  @computed
  get totalPayFormatted() {
    return formatBigNumber(this.totalPay, this.tickerPrecision, true);
  }

  @computed get paymentFeeIsActive() {
    return !this.form.useYHBalance;
  }

  @computed get paymentFeeType() {
    // NOTE: for now we can get payment fee only for bank card deposit method
    if (
      this.form.depositMethod === DepositMethodEnum.BANK_CARD ||
      this.form.depositMethod === DepositMethodEnum.BANK_CARD_IFRAME
    ) {
      return 'defined';
    }

    return 'volatile';
  }

  // NOTE: if total to Pay - is only paymentFee, hide it
  @computed get showTotalPay() {
    return this.totalPay.gt(this.paymentFee);
  }

  //#endregion fees

  public constructor(form: FormBase) {
    super(form);
  }
}
