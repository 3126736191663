import {primitive, serializable, object, optional} from 'serializr';
import {observable} from 'mobx';
import Big from 'big.js';
import {bigNumberNullable} from '@youtoken/ui.utils-serializr';

class WalletsMethodTickersFee {
  @observable
  @serializable(optional(bigNumberNullable()))
  percent?: Big = undefined;

  @observable
  @serializable(optional(bigNumberNullable()))
  min?: Big = undefined;
}

export class WalletsMethodTickersResponse {
  @observable
  @serializable(primitive())
  ticker!: string;

  @observable
  @serializable(object(WalletsMethodTickersFee))
  fee!: WalletsMethodTickersFee;
}
