import * as React from 'react';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {LoyaltyIcon, LoyaltyIconName, Icon} from '@youtoken/ui.icons';
import {
  Box,
  Text,
  type TColorTokens,
  useIsMobile,
} from '@youtoken/ui.primitives';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {CardParameter} from './CardParameter';

interface CardLevelProps {
  icon: LoyaltyIconName;
  levelTitle: string;
  level: LoyaltyLevel;
  textColor: keyof TColorTokens;
}

const isWeb = Platform.OS === 'web';

export const CardHeader: React.FC<CardLevelProps> = observer(
  ({icon, levelTitle, level, textColor}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();
    const isMobileWeb = isMobile && isWeb;

    const parameterTextVariant = React.useMemo(
      () => (isMobile ? '$heading-02' : '$body-01-high-accent'),
      [isMobile]
    );

    return (
      <Box flexDirection={{default: 'column', tablet: 'row'}}>
        <Box flexDirection="row" mb={{default: 20, tablet: 0}}>
          <Box
            justifyContent="center"
            alignItems="center"
            width={{default: 42, tablet: 36}}
            height={{default: 42, tablet: 36}}
            backgroundColor="$ui-background"
            borderRadius={30}
            mr={8}
          >
            <LoyaltyIcon name={icon} size={24} />
          </Box>
          {isMobile && (
            <CardParameter textColor={textColor} title={levelTitle}>
              <Text
                color={textColor}
                variant={parameterTextVariant}
                testID={`MINER_LEVEL_${level.level}_NAME`}
              >
                {level.name}
              </Text>
            </CardParameter>
          )}
        </Box>
        <Box
          flexDirection={{default: 'column', tablet: 'row'}}
          justifyContent="space-between"
          flex={{default: undefined, tablet: 1}}
          gap={{default: 16, tablet: 0}}
        >
          {!isMobile && (
            <CardParameter textColor={textColor} title={levelTitle}>
              <Text
                testID={`MINER_LEVEL_${level.level}_NAME`}
                color={textColor}
                variant={parameterTextVariant}
              >
                {level.name}
              </Text>
            </CardParameter>
          )}
          <CardParameter
            textColor={textColor}
            title={t('surface.miner.cards.monthly_profit')}
            zIndex={1}
          >
            <Box flexDirection="row" alignItems="center" zIndex={10}>
              <Text
                color={textColor}
                variant={parameterTextVariant}
                testID={`MINER_LEVEL_${level.level}_EARN_LIMIT`}
                mr={3}
              >
                {t('surface.miner.cards.up_to', {
                  amountWithTicker: `${level.details.minerMaxMonthlyIncomeBTCFormatted} ${level.details.minerMaxIncomeTickerFormatted}`,
                })}
              </Text>
              <QuestionTooltip
                content={t('surface.miner.cards.monthly_profit.tooltip')}
                testID={`MINER_LEVEL_${level.level}_EARN_LIMIT_TOOLTIP`}
                iconColor="$text-01"
                iconSize={20}
                iconOpacity={0.3}
                webTooltipPosition={isMobileWeb ? 'bottom' : 'right'}
                bodyHorizontalOffset={isMobileWeb ? -80 : 0}
                width={isMobileWeb ? 200 : undefined}
              />
            </Box>
          </CardParameter>
          <CardParameter
            textColor={textColor}
            title={t('surface.miner.loyalty.welcome-pack')}
          >
            <Box flexDirection="row" alignItems="center">
              <Icon color={textColor} name="spark" size={18} />
              <Text
                color={textColor}
                variant={parameterTextVariant}
                ml={2}
                testID={`MINER_LEVEL_${level.level}_WELCOME_PACK`}
              >
                {level.miner.welcomePackFormatted}
              </Text>
            </Box>
          </CardParameter>
          <CardParameter
            textColor={textColor}
            title={t('surface.miner.cards.free_sparks')}
          >
            <Box flexDirection="row" alignItems="center">
              <Icon color={textColor} name="spark" size={18} />
              <Text
                color={textColor}
                variant={parameterTextVariant}
                ml={2}
                testID={`MINER_LEVEL_${level.level}_FREE_SPARKS_PERIOD`}
              >
                {level.miner.freeSparksPeriodLokalised}
              </Text>
            </Box>
          </CardParameter>
          <CardParameter
            textColor={textColor}
            title={t('surface.miner.cards.blocks')}
          >
            <Box flexDirection="row" alignItems="center">
              <Icon color={textColor} name="miner_block" />
              <Text
                color={textColor}
                variant={parameterTextVariant}
                ml={2}
                testID={`MINER_LEVEL_${level.level}_BLOCKS`}
              >
                {level.miner.blocksAvailable}
              </Text>
            </Box>
          </CardParameter>
        </Box>
      </Box>
    );
  }
);
