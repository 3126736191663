import React from 'react';
import {z} from 'zod';
import {PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {HodlsMainSurfaceContent} from '@youtoken/ui.surfaces-hodl-common';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {HodlsPortfolioSurfaceContent} from './Content';

const paramsSchema = z.object({});

export const HodlsPortfolioSurface = surface(
  {
    name: 'Portfolio',
    getTitle: () => i18n.t('surface.menu.multihodl'),
    paramsSchema,
    headerShown: true,
  },
  () => {
    React.useEffect(() => {
      DATA_LAYER.trackStrict('hodl-portfolio', {});
    }, []);

    return (
      <VariableLayout
        PrimaryContent={
          <HodlsMainSurfaceContent innerPage={PageSwitcherValue.PORTFOLIO}>
            <HodlsPortfolioSurfaceContent />
          </HodlsMainSurfaceContent>
        }
      />
    );
  }
);
