import {ValidationMessageLocalized} from './ValidationMessageLocalized';
import {i18n} from '@youtoken/ui.service-i18n';

export const REQUIRED = () =>
  new ValidationMessageLocalized(i18n.t('validation.REQUIRED'), {
    label: 'REQUIRED',
  });

export const MAX_LENGTH = ({max}: {max: number}) =>
  new ValidationMessageLocalized(i18n.t('validation.MAX_LENGTH'), {
    label: 'MAX_LENGTH',
    params: {
      max,
    },
  });

export const EMAIL_FORMAT = () =>
  new ValidationMessageLocalized(i18n.t('validation.EMAIL_FORMAT'), {
    label: 'EMAIL_FORMAT',
  });

export const AGREE_TO_TERMS = ({}) =>
  new ValidationMessageLocalized(i18n.t('validation.AGREE_TO_TERMS'), {
    label: 'AGREE_TO_TERMS',
  });

export const AGREE_TO_PRIVACY_NOTICE = ({}) =>
  new ValidationMessageLocalized(i18n.t('validation.AGREE_TO_PRIVACY_NOTICE'), {
    label: 'AGREE_TO_PRIVACY_NOTICE',
  });

export const WITHDRAWAL_ADDRESS_REQUIRED = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.WITHDRAWAL_ADDRESS_REQUIRED'),
    {
      label: 'WITHDRAWAL_ADDRESS_REQUIRED',
    }
  );

export const WITHDRAWAL_EXTRA_ID_REQUIRED = ({name}: {name: string}) =>
  new ValidationMessageLocalized(
    i18n.t('validation.WITHDRAWAL_EXTRA_ID_REQUIRED', {name}),
    {
      label: 'WITHDRAWAL_EXTRA_ID_REQUIRED',
      params: {
        name,
      },
    }
  );

export const TFA_REQUIRED = () =>
  new ValidationMessageLocalized(i18n.t('validation.TFA_REQUIRED'), {
    label: 'TFA_REQUIRED',
  });

export const FUNDS_INSUFFICIENT = () =>
  new ValidationMessageLocalized(i18n.t('validation.FUNDS_INSUFFICIENT'), {
    label: 'FUNDS_INSUFFICIENT',
  });

export const LOAN_REPAYMENT_SHOULD_BE_LT_LOAN_BALANCE = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.LOAN_REPAYMENT_SHOULD_BE_LT_LOAN_BALANCE'),
    {
      label: 'LOAN_REPAYMENT_SHOULD_BE_LT_LOAN_BALANCE',
    }
  );

export const IS_NOT_LATIN_SYMBOLS = () =>
  new ValidationMessageLocalized(i18n.t('validation.IS_NOT_LATIN_SYMBOLS'), {
    label: 'IS_NOT_LATIN_SYMBOLS',
  });

export const DATE_OF_BIRTH_FORMAT = () =>
  new ValidationMessageLocalized(i18n.t('validation.DATE_OF_BIRTH_FORMAT'), {
    label: 'DATE_OF_BIRTH_FORMAT',
  });

export const INVALID_IMAGE = () =>
  new ValidationMessageLocalized(i18n.t('validation.INVALID_IMAGE'), {
    label: 'INVALID_IMAGE',
  });

export const SIGNUP_EMAIL_REGEXP = () =>
  new ValidationMessageLocalized(i18n.t('validation.SIGNUP_EMAIL_REGEXP'), {
    label: 'SIGNUP_EMAIL_REGEXP',
  });

export const SIGNUP_PASSWORD_REGEXP = () =>
  new ValidationMessageLocalized(i18n.t('validation.SIGNUP_PASSWORD_REGEXP'), {
    label: 'SIGNUP_PASSWORD_REGEXP',
  });

export const WITHDRAWAL_PAYMENT_REFERENCE_REGEXP = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.WITHDRAWAL_PAYMENT_REFERENCE_REGEXP'),
    {
      label: 'WITHDRAWAL_PAYMENT_REFERENCE_REGEXP',
    }
  );

export const SHOULD_BE_LTE_TOTAL_BALANCE = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.SHOULD_BE_LTE_TOTAL_BALANCE'),
    {
      label: 'SHOULD_BE_LTE_TOTAL_BALANCE',
    }
  );

export const WITHDRAWAL_VERSION = () =>
  new ValidationMessageLocalized(i18n.t('validation.WITHDRAWAL_VERSION'), {
    label: 'WITHDRAWAL_VERSION',
  });

export const WITHDRAWAL_TO_SAME_WALLET = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.WITHDRAWAL_TO_SAME_WALLET'),
    {
      label: 'WITHDRAWAL_TO_SAME_WALLET',
    }
  );

export const WITHDRAWAL_CONFIRM_ADDRESS = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.WITHDRAWAL_CONFIRM_ADDRESS'),
    {
      label: 'WITHDRAWAL_CONFIRM_ADDRESS',
    }
  );

export const SHOULD_BE_GTE = ({value}: {value: string}) =>
  new ValidationMessageLocalized(i18n.t('validation.SHOULD_BE_GTE'), {
    label: 'SHOULD_BE_GTE',
    params: {
      value,
    },
  });

export const SHOULD_BE_GT = ({
  value,
  ticker = '',
}: {
  value: string;
  ticker?: string;
}) =>
  new ValidationMessageLocalized(i18n.t('validation.SHOULD_BE_GT'), {
    label: 'SHOULD_BE_GT',
    params: {
      value,
      ticker: ticker.toUpperCase(),
    },
  });

export const SHOULD_BE_LTE = ({value}: {value: string}) =>
  new ValidationMessageLocalized(i18n.t('validation.SHOULD_BE_LTE'), {
    label: 'SHOULD_BE_LTE',
    params: {
      value,
    },
  });

export const SHOULD_BE_LT = ({value}: {value: string}) =>
  new ValidationMessageLocalized(i18n.t('validation.SHOULD_BE_LT'), {
    label: 'SHOULD_BE_LT',
    params: {
      value,
    },
  });

export const SHOULD_BE_EQUAL = ({value}: {value: string}) =>
  new ValidationMessageLocalized(i18n.t('validation.SHOULD_BE_EQUAL'), {
    label: 'SHOULD_BE_EQUAL',
    params: {
      value,
    },
  });

export const MIN_AMOUNT = ({
  value,
  ticker = '',
}: {
  value: string;
  ticker?: string;
}) =>
  new ValidationMessageLocalized(i18n.t('validation.MIN_AMOUNT'), {
    label: 'MIN_AMOUNT',
    params: {
      value,
      ticker: ticker.toUpperCase(),
    },
  });

export const MAX_AMOUNT = ({
  value,
  ticker = '',
}: {
  value: string;
  ticker?: string;
}) =>
  new ValidationMessageLocalized(i18n.t('validation.MAX_AMOUNT'), {
    label: 'MAX_AMOUNT',
    params: {
      value,
      ticker: ticker.toUpperCase(),
    },
  });

export const MAX_EXCEEDED = ({
  target,
  value,
  ticker = 'usd',
}: {
  target: string;
  value: string;
  ticker?: string;
}) =>
  new ValidationMessageLocalized(i18n.t('validation.MAX_EXCEEDED'), {
    label: 'MAX_EXCEEDED',
    params: {
      target,
      value,
      ticker: ticker.toUpperCase(),
    },
  });

export const MAX_VOLUME_EXCEEDED = ({
  target,
  value,
  ticker = 'usd',
}: {
  target: string;
  value: string;
  ticker?: string;
}) =>
  new ValidationMessageLocalized(i18n.t('validation.MAX_VOLUME_EXCEEDED'), {
    label: 'MAX_VOLUME_EXCEEDED',
    params: {
      target,
      value,
      ticker: ticker.toUpperCase(),
    },
  });

export const SHOULD_BE_GT_CURRENT_PRICE = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.SHOULD_BE_GT_CURRENT_PRICE'),
    {
      label: 'SHOULD_BE_GT_CURRENT_PRICE',
    }
  );

export const SHOULD_BE_LT_CURRENT_PRICE = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.SHOULD_BE_LT_CURRENT_PRICE'),
    {
      label: 'SHOULD_BE_LT_CURRENT_PRICE',
    }
  );

export const SHOULD_BE_GT_TRIGGER_PRICE = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.SHOULD_BE_GT_TRIGGER_PRICE'),
    {
      label: 'SHOULD_BE_GT_TRIGGER_PRICE',
    }
  );

export const SHOULD_BE_LT_TRIGGER_PRICE = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.SHOULD_BE_LT_TRIGGER_PRICE'),
    {
      label: 'SHOULD_BE_LT_TRIGGER_PRICE',
    }
  );

export const IBAN_WRONG_FORMAT = () =>
  new ValidationMessageLocalized(i18n.t('validation.IBAN_WRONG_FORMAT'), {
    label: 'IBAN_WRONG_FORMAT',
  });

export const ACCOUNT_NUMBER_WRONG_FORMAT = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.ACCOUNT_NUMBER_WRONG_FORMAT'),
    {
      label: 'ACCOUNT_NUMBER_WRONG_FORMAT',
    }
  );

export const MIN_VOLUME_NOT_REACHED = ({
  minVolume,
  ticker,
}: {
  minVolume: string;
  ticker: string;
}) =>
  new ValidationMessageLocalized(i18n.t('validation.MIN_AMOUNT'), {
    label: 'MIN_AMOUNT',
    params: {
      minVolume,
      ticker: ticker.toUpperCase(),
    },
  });

export const SHOULD_BE_LTE_CURRENT_PRICE = () =>
  new ValidationMessageLocalized(
    i18n.t('validation.SHOULD_BE_LTE_CURRENT_PRICE'),
    {
      label: 'SHOULD_BE_LTE_CURRENT_PRICE',
    }
  );

export const SORT_CODE_WRONG_FORMAT = () =>
  new ValidationMessageLocalized(i18n.t('validation.SORT_CODE_WRONG_FORMAT'), {
    label: 'SORT_CODE_WRONG_FORMAT',
  });
