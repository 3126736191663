//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  getTranslatedValidationMessage,
  messages,
  ValidationMessageLocalized,
} from '@youtoken/ui.validation-messages';
import {CryptoWithdrawalForm} from '../../CryptoWithdrawalForm';
import {action, computed} from 'mobx';
import {normalizeWithoutSpaces} from '@youtoken/ui.normalizers';
import type {
  CryptoWithdrawalExternalFormStateArgs,
  CryptoWithdrawalExternalFormStateResource,
} from './index';
import {getExtraIdNameByTicker} from '../../utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export class Form extends CryptoWithdrawalForm {
  //#region address

  @computed
  get address() {
    return this.instance?.$('address').get('value') ?? '';
  }

  @computed
  get addressError() {
    return getTranslatedValidationMessage(
      this.instance.$('address').get('error')
    );
  }

  @action
  addressOnChange = (value: string) => {
    this.instance.$('address').get('onChange')(normalizeWithoutSpaces(value));
  };

  //#endregion address

  //#region extraId

  @computed
  get extraId() {
    return this.instance?.$('extraId').get('value') ?? '';
  }

  @computed
  get extraIdError() {
    const extraIdErrorLocalized: ValidationMessageLocalized = this.instance
      .$('extraId')
      .get('error');
    let tickerLocalizedMessage = '';

    if (extraIdErrorLocalized?.i18n?.label === 'EXTRA_ID_INVALID') {
      switch (getExtraIdNameByTicker(this.ticker)) {
        case 'memo':
          tickerLocalizedMessage = i18n.t('validation.MEMO_INVALID', {
            ticker: this.tickerFormatted,
          });
          break;
        case 'memo_id':
          tickerLocalizedMessage = i18n.t('validation.MEMO_ID_INVALID', {
            ticker: this.tickerFormatted,
          });
          break;
        case 'destination_tag':
          tickerLocalizedMessage = i18n.t(
            'validation.DESTINATION_TAG_INVALID',
            {
              ticker: this.tickerFormatted,
            }
          );
          break;
      }
    }

    return (
      tickerLocalizedMessage ||
      getTranslatedValidationMessage(extraIdErrorLocalized)
    );
  }

  @action
  extraIdOnChange = (value: string) => {
    this.instance.$('extraId').get('onChange')(normalizeWithoutSpaces(value));
  };

  //#endregion extraId

  constructor(
    args: CryptoWithdrawalExternalFormStateArgs,
    resources: CryptoWithdrawalExternalFormStateResource
  ) {
    super(args, resources);
    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.ticker,
          },
          version: {
            value: args.version ?? this.version,
          },
          amount: {
            value: this.amount,
          },
          address: {
            value: this.address,
          },
          extraId: {
            value: this.extraId,
          },
          agree: {
            value: this.agree,
          },
          twoFactorAuthOperationId: {
            value: this.twoFactorAuthOperationId,
          },
          twoFactorAuthOperationCode: {
            value: this.twoFactorAuthOperationCode,
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit().then(() => {
              SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
                ticker: this.ticker,
              });
            });
          },
          onError: () => {
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('validation.VALIDATION'),
            });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                let tickerSchema = yup.string().required();
                let versionSchema = yup
                  .string()
                  .required(messages.WITHDRAWAL_VERSION);
                let amountSchema = yup
                  .big()
                  .gt(0)
                  .lte(this.walletAmount, messages.SHOULD_BE_LTE_TOTAL_BALANCE);
                let addressSchema = yup
                  .string()
                  .required(messages.WITHDRAWAL_ADDRESS_REQUIRED);
                let extraIdSchema = yup.string();
                let agreeSchema = yup
                  .agreeToTerms()
                  .required(messages.WITHDRAWAL_CONFIRM_ADDRESS);
                let twoFactorAuthOperationSchema = yup.string();

                if (this.fee) {
                  amountSchema = amountSchema
                    .gte(this.fee.minAmount)
                    .lte(this.fee.maxAmount);
                }

                if (this.walletVersion) {
                  if (this.extraIdVisible) {
                    addressSchema = addressSchema.test(
                      'checkAddress',
                      messages.WITHDRAWAL_TO_SAME_WALLET,
                      value => {
                        return !(
                          this.walletVersionAddress === value &&
                          this.walletVersionExtraId === this.extraId
                        );
                      }
                    );

                    extraIdSchema = yup
                      .string()
                      .required(() =>
                        messages.WITHDRAWAL_EXTRA_ID_REQUIRED({
                          name: this.extraIdLabel,
                        })
                      )
                      .test(
                        'checkExtraId',
                        messages.WITHDRAWAL_TO_SAME_WALLET,
                        value => {
                          return !(
                            this.walletVersionAddress === this.address &&
                            this.walletVersionExtraId === value
                          );
                        }
                      );
                  } else {
                    addressSchema = addressSchema.test(
                      'checkAddress',
                      messages.WITHDRAWAL_TO_SAME_WALLET,
                      value => {
                        return this.walletVersionAddress !== value;
                      }
                    );
                  }
                }

                if (this.twoFactorAuthType) {
                  twoFactorAuthOperationSchema =
                    twoFactorAuthOperationSchema.required(
                      messages.TFA_REQUIRED
                    );
                }

                const schema = {
                  ticker: tickerSchema,
                  version: versionSchema,
                  amount: amountSchema,
                  address: addressSchema,
                  extraId: extraIdSchema,
                  agree: agreeSchema,
                  twoFactorAuthOperationId: twoFactorAuthOperationSchema,
                  twoFactorAuthOperationCode: twoFactorAuthOperationSchema,
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }
}
