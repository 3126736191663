import * as React from 'react';
import {z} from 'zod';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {SecurityActivitySurfaceContent} from './Content';

export const SecurityActivitySurface = surface(
  {
    name: 'SecurityActivity',
    getTitle: () => i18n.t('surface.security_activity.title'),
    paramsSchema: z.object({}),
    headerShown: true,
  },
  () => {
    return (
      <VariableLayout PrimaryContent={<SecurityActivitySurfaceContent />} />
    );
  }
);
