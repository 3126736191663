import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BundlePortfolio, BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {ChartWithoutControlsSuspenseFallback} from '@youtoken/ui.legacy-charts';
import {
  useResizableBox,
  ResizableBox,
  BundleChartSmartCell,
} from '@youtoken/ui.legacy-charts';

interface BundleGeneralChartProps {
  data: {
    tariff: BundleTariff;
    userPortfolio: BundlePortfolio | null;
  };
}

export const BundleGeneralChart: React.FC<BundleGeneralChartProps & BoxProps> =
  observer(({data: {tariff, userPortfolio}, ...boxProps}) => {
    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    return (
      <ResizableBox testID="BUNDLE_CHART" height={298} {...boxProps} {...bind}>
        {Boolean(width && height) && (
          <>
            {userPortfolio?.status === 'CREATING' ? (
              <BundleGeneralChartCreating width={width!} height={height!} />
            ) : (
              <BundleChartSmartCell
                source={userPortfolio ? 'bundle' : 'tariff'}
                sourceId={userPortfolio ? userPortfolio.id : tariff.id}
                width={width!}
                height={height!}
              />
            )}
          </>
        )}
      </ResizableBox>
    );
  });

const BundleGeneralChartCreating: React.FC<
  React.ComponentProps<typeof ChartWithoutControlsSuspenseFallback>
> = observer(({width, height}) => {
  const {t} = useTranslation();

  return (
    <Box>
      <ChartWithoutControlsSuspenseFallback width={width} height={height} />
      <Box
        justifyContent="center"
        alignItems="center"
        p={24}
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
      >
        <Box
          px={16}
          py={8}
          bg="$ui-background"
          borderColor="$ui-01"
          borderWidth={1}
          borderRadius={10}
        >
          <Text variant="$body-02" color="$text-01" textAlign="center">
            {t('surface.bundle.chart.creating')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
});
