import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {AutoConvertFormState} from '../../state';
import {RateTextInput} from '@youtoken/ui.inputs';
import {DesiredRateButtons} from './DesiredRateButtons';

export const DesiredRateField: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    const {
      form: {
        fromTicker,
        toTicker,
        desiredRate,
        setDesiredRate,
        hasDesiredRateError,
        desiredRateError,
      },
    } = useForm<AutoConvertFormState>();

    return (
      <Box {...boxProps}>
        <Label mb={8}>{t('surface.auto_converts.desired_rate')}</Label>
        <RateTextInput
          baseTicker={fromTicker}
          quoteTicker={toTicker}
          value={desiredRate}
          onChangeText={setDesiredRate}
          size="large"
          testID="AUTO_CONVERT_FORM_DESIRED_RATE_INPUT"
        />
        <FieldErrorMessage visible={hasDesiredRateError}>
          {desiredRateError}
        </FieldErrorMessage>
        <DesiredRateButtons mt={8} />
      </Box>
    );
  }
);
