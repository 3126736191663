import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {
  HeaderBase,
  useWalletsChooseTickerItemWrapper,
  WalletsChooseTicker,
} from '../../../components';
import {type FiatDepositWizardNavigator, FiatDepositWizardScreenName} from '..';
import {Platform} from 'react-native';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const FiatDepositWizardChooseTicker: React.FC = observer(() => {
  const {t} = useTranslation();

  const {navigate, goBack} = useWizard<
    FiatDepositWizardNavigator,
    FiatDepositWizardScreenName.CHOOSE_TICKER
  >();

  const handlePress = React.useCallback(
    (ticker: string) => {
      DATA_LAYER.trackStrict('buy-wizard-currency-chosen', {
        type: 'fiat',
        ticker,
      });

      navigate(FiatDepositWizardScreenName.CHOOSE_METHOD, {
        ticker,
      });
    },
    [navigate]
  );

  const TouchableWrapper = useWalletsChooseTickerItemWrapper(handlePress);

  return (
    <>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {t('surface.wallets.deposit_wizard.select_ticker_fiat.title')}
        </HeaderBase>
      )}
      <WalletsChooseTicker
        type="fiat"
        method="deposit"
        ListItemWrapper={TouchableWrapper}
      />
    </>
  );
});
