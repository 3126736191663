import {computed, action} from 'mobx';
import Big from 'big.js';
import {
  LoanReopenResource,
  LoanItemRegularResource,
} from '@youtoken/ui.resource-loans';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {getGeneralAgreements} from '@youtoken/ui.resource-documents';

export interface LoanReopenFeatureArgs {
  id: string;
}

export class LoanReopenFeature extends createFeature({
  getKey: () => `LoanReopenFeature`,
  getResources: ({id}: LoanReopenFeatureArgs) => {
    return {
      loanReopen: getResourceDescriptor(LoanReopenResource, {id}),
      loanItemRegular: getResourceDescriptor(LoanItemRegularResource, {id}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      authMe: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {
  @computed get borrowedTicker() {
    return this.resources.loanItemRegular.data.borrowedTicker;
  }

  @computed get borrowedTickerFormatted() {
    return this.resources.loanItemRegular.data.borrowedTickerFormatted;
  }

  @computed get aprFormatted() {
    return this.resources.loanReopen.data.aprFormatted;
  }

  @computed get ltvFormatted() {
    return this.resources.loanReopen.data.ltvFormatted;
  }

  @computed get maxDurationInDays() {
    return this.resources.loanReopen.data.maxDuration.days;
  }

  @computed get until() {
    return this.resources.loanReopen.data.until;
  }

  @computed get showNewDuration() {
    return this.maxDurationInDays && this.until;
  }

  @computed get reopenFeeFormatted() {
    return this.resources.loanReopen.data.reopenFeeFormatted;
  }

  @computed
  get borrowedTickerPrecision() {
    return getCoinDecimalPrecision(this.borrowedTicker);
  }

  @computed
  get borrowedTickerWallet() {
    return this.resources.wallets.getByTicker(this.borrowedTicker);
  }

  @computed
  get borrowedTickerAmount() {
    return this.borrowedTickerWallet?.amount.toFixed(
      this.borrowedTickerPrecision
    );
  }

  @computed
  get settlementFeeAmount() {
    return this.resources.loanReopen.data.settlementFeeAmount.toFixed(
      this.borrowedTickerPrecision
    );
  }

  @computed
  get pdlFormatted() {
    return Big(this.resources.loanReopen.data.mcPrice).toFixed(
      this.borrowedTickerPrecision
    );
  }

  @computed
  get overdraftDiff() {
    return this.resources.loanReopen.data.overdraftDiff.toFixed(
      this.borrowedTickerPrecision
    );
  }

  @computed
  get reopenFeeAmount() {
    return this.resources.loanReopen.data.reopenFeeAmount.toFixed(
      this.borrowedTickerPrecision
    );
  }

  @computed get hasPaymentsForExtend() {
    return (
      this.resources.loanReopen.data.overdraftDiff.gt(0) ||
      this.resources.loanReopen.data.reopenFeeAmount.gt(0)
    );
  }

  @computed
  get total() {
    return this.resources.loanReopen.data.reopenFeeAmount
      .plus(this.overdraftDiff)
      .toFixed(this.borrowedTickerPrecision);
  }

  @computed get isBalanceEnough() {
    return this.borrowedTickerWallet?.amount.gte(this.total);
  }

  @computed
  get agreements() {
    return getGeneralAgreements(this.resources.authMe.data.legalEntity);
  }

  @action
  reopen = () => {
    return this.resources.loanReopen.reopen();
  };
}
