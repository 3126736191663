// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {deserialize} from 'serializr';
import {type AxiosResponse} from 'axios';
import {nanoid} from 'nanoid';
import Big from 'big.js';
import {
  action,
  autorun,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
} from 'mobx';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import * as yupPackage from '@youtoken/ui.yup';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  getAmountListFormatted,
  getWalletComboboxItems,
  getSourceWallets,
} from '@youtoken/ui.hodls-utils';
import {CalculatedDataResponse} from './CalculatedDataResponse';
import type {FlipHODLFormArgs, FlipHODLFormResources} from './';

export class Form {
  //#region initial params

  @observable
  public args: FlipHODLFormArgs;

  @observable
  public resources: FlipHODLFormResources;

  @observable
  public instance: MobxReactForm;

  disposers: Array<IReactionDisposer> = [];

  //#endregion initial params

  //#region helpers

  @observable
  private requestId: string;

  @observable
  private readonly baseTicker: string;

  @observable
  public readonly quoteTicker: string;

  @observable
  private currentCalculateRequestId!: string | number;

  @observable
  rateInputTickerUsd!: Big;

  @observable
  public isLoading!: boolean;

  @observable
  public calculatedData!: CalculatedDataResponse;

  @computed.struct get hodlObj() {
    return {
      hodlId: this.args.hodlId,
      inputTicker: this.inputTicker,
      inputAmount: this.inputAmount,
      multiplier: this.multiplier,
    };
  }

  //#endregion helpers

  public constructor(resources: FlipHODLFormResources, args: FlipHODLFormArgs) {
    this.resources = resources;
    this.args = args;

    this.requestId = nanoid();
    const inputTicker = this.resources.hodl.data.inputTicker;
    this.quoteTicker = this.resources.hodl.data.quoteTicker;
    this.baseTicker = this.resources.hodl.data.baseTicker;

    const fields = {
      hodlId: {
        value: args.hodlId,
      },
      inputTicker: {
        value: inputTicker,
      },
      inputAmount: {
        value: formatByTicker(
          this.resources.hodl.data.inputAmount,
          inputTicker
        ),
      },
      multiplier: {
        value: resources.hodl.data.multiplier,
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              hodlId: yup.string().required(),
              inputTicker: yup.string().required(),
              inputAmount: yup.big().required().gt(0),
              multiplier: yup.number().required(),
            });
          }),
      }),
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        this.isLoading = true;
        const postData = form.values();

        DATA_LAYER.trackStrict('hodl-flip-order-attempt', {
          hodlId: this.hodlId,
        });

        return TRANSPORT.API.post('v3/hodl/flipOrder', {
          ...postData,
          requestId: this.requestId,
        })
          .then(async ({data}: AxiosResponse<{newHodlId: string}>) => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.hodls.flip_order.message.order_flipped'),
            });
            this.args.onFormSubmit();
            // NOTE: small delay is needed to be more sure, that new hodl indeed appeared in the hodl list
            await new Promise<void>(resolve =>
              setTimeout(() => {
                resolve();
                SHARED_ROUTER_SERVICE.navigate(
                  'HODLItemDetailed',
                  {id: data.newHodlId},
                  {isRedirectFromFlipOrder: true}
                );
              }, 700)
            );
          })
          .catch(e => {
            handleFormSubmitError(form, e, {});
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm(
      {fields},
      {
        plugins,
        hooks,
        options,
      }
    );

    //#region reactions on updates params

    this.disposers = [
      autorun(() => {
        if (this.sourceWallets.length > 0) {
          const walletExists = this.sourceWallets.some(
            wallet => wallet!.ticker === this.inputTicker
          );

          // NOTE: if no wallet for current inputTicker - update inputTicker
          if (!walletExists) {
            this.setDefaultSourceWalletValues();
            return;
          }
        }
        // NOTE: if no sourceWallets set some default settings
        else {
          const ticker =
            this.direction === 'buy' ? this.baseTicker : this.quoteTicker;
          this.setInputTicker(ticker);
          this.setInputAmount(this.defaultSourceAmount!);
        }
      }),

      // NOTE: update tp and sl after getting calculated data
      reaction(
        () => this.hodlObj,
        requestData => {
          this.isLoading = true;
          const localCalculateRequestId = Date.now();
          this.currentCalculateRequestId = localCalculateRequestId;
          const tariffId = this.currentTariff?._id;

          TRANSPORT.API.post('/v3/hodl/calculate', {...requestData, tariffId})
            .then(async res => {
              if (this.currentCalculateRequestId !== localCalculateRequestId) {
                return;
              }

              this.calculatedData = deserialize(
                CalculatedDataResponse,
                res.data
              );
            })
            .catch(() => {
              if (this.currentCalculateRequestId !== localCalculateRequestId) {
                return;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
        {fireImmediately: true, delay: 300}
      ),

      // NOTE: update rateInputTickerUsd after inputTicker changed
      reaction(
        () => this.inputTicker,
        () => {
          const {getRate} = this.resources.rates;
          this.rateInputTickerUsd = Big(getRate('usd', this.inputTicker));
        },
        {
          fireImmediately: true,
        }
      ),
    ];

    //#endregion reactions on updates params
  }

  dispose() {
    this.disposers.forEach(disposer => disposer?.());
  }

  //#region form fields and computed data

  @computed
  public get inputAmount(): string {
    return this.instance.$('inputAmount').get('value');
  }

  @computed
  public get inputTicker(): string {
    return (
      // NOTE: value is needed for validation, but instance doesn't exist on initialisation so initial ticker should be used
      this.instance?.$('inputTicker').get('value') ??
      this.resources.hodl.data.inputTicker
    );
  }

  @computed
  public get multiplier(): string {
    return this.instance.$('multiplier').get('value');
  }

  @computed
  public get hodlId(): string {
    return this.instance.$('hodlId').get('value');
  }

  @computed
  public get direction(): string {
    return this.resources.hodl.data.direction === 'down' ? 'up' : 'down';
  }

  @computed.struct
  private get tariffs() {
    return this.resources.tariffs?.tariffsWithoutIndexes || [];
  }

  @computed
  private get isShort() {
    return this.direction === 'sell';
  }

  @computed.struct
  public get currentTariff() {
    return this.tariffs.find(
      tariff =>
        tariff.baseTicker === this.baseTicker &&
        tariff.quoteTicker === this.quoteTicker &&
        tariff.isShort === this.isShort
    );
  }

  @computed.struct
  public get hodlsWalletList() {
    return getWalletComboboxItems(
      this.resources.wallets.walletsListOrderedByEquivalentAmount
    );
  }

  @computed.struct
  public get sourceWallets() {
    const tariff = this.currentTariff;

    if (!tariff) {
      return [];
    }

    return getSourceWallets(tariff, this.hodlsWalletList);
  }

  @computed.struct
  public get currentWallet() {
    // NOTE: wallets.hodlsWalletList is used instead of sourceWallets because of input ticker could be this.baseTicker
    // or this.quoteTicker if !sourceWallets[0] (check reactions)
    return this.hodlsWalletList.find(w => w.ticker === this.inputTicker);
  }

  @computed
  public get allSourceAmount() {
    return this.currentWallet?.amountFormatted ?? '0';
  }

  @computed get amountListFormatted() {
    return getAmountListFormatted(this.inputTicker, this.rateInputTickerUsd);
  }

  @computed get defaultSourceAmount() {
    const allSourceAmount = Number(this.allSourceAmount);
    return allSourceAmount < Number(this.amountListFormatted[0])
      ? this.allSourceAmount
      : allSourceAmount < Number(this.amountListFormatted[1])
      ? this.amountListFormatted[0]
      : this.amountListFormatted[1];
  }

  //#endregion form fields and computed data

  //region actions

  @action public setInputTicker = (value: string) => {
    this.instance.$('inputTicker').set('value', value);
  };

  @action public setInputAmount = (value: string) => {
    const normalizedValue = normalizeAmountWithPrecision(
      value,
      getCoinDecimalPrecision(this.inputTicker)
    );

    this.instance.$('inputAmount').set('value', normalizedValue);
  };

  @action public setMultiplier = (value: number) => {
    this.instance.$('multiplier').set('value', value);
  };

  @action public setAllSourceToAmount = () => {
    this.setInputAmount(this.allSourceAmount);
  };

  @action private setDefaultSourceWalletValues = () => {
    this.setInputTicker(this.sourceWallets?.[0]?.ticker ?? '');
    this.setInputAmount(this.defaultSourceAmount ?? '0');
  };

  @action
  public submitForm = () => {
    this.instance.submit();
  };

  //#endregion actions
}
