import {observable} from 'mobx';
import {View} from './View';
import {Form} from './Form';
import {AutoConvertFormArgs, AutoConvertFormResources} from './types';

export class AutoConvertFormState {
  @observable
  public form: Form;

  @observable
  public view: View;

  constructor(args: AutoConvertFormArgs, resources: AutoConvertFormResources) {
    this.form = new Form(args, resources);
    this.view = new View(this.form);
  }
}
