import * as React from 'react';
import {PropsWithChildren} from 'react';
import {CHALLENGES_SERVICE} from '@youtoken/ui.challenge-service';
import type {TermsAndConditionsImperativeMethods} from './types';
import {TermsAndConditionsComponent} from './TermsAndConditionsComponent';

export const __GLOBAL_TERMS_AND_CONDITIONS__: TermsAndConditionsImperativeMethods =
  {
    show: (link, originalError) => {
      if (!CHALLENGES_SERVICE.refs.termsAndConditions?.current?.show) {
        return Promise.reject(new Error('TermsAndConditions not ready'));
      }

      return CHALLENGES_SERVICE.refs.termsAndConditions?.current?.show(
        link,
        originalError
      );
    },
  };

export const TermsAndConditionsContext =
  React.createContext<TermsAndConditionsImperativeMethods>(
    __GLOBAL_TERMS_AND_CONDITIONS__
  );

export const TermsAndConditionsContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TermsAndConditionsContext.Provider value={__GLOBAL_TERMS_AND_CONDITIONS__}>
      <TermsAndConditionsComponent
        ref={CHALLENGES_SERVICE.refs.termsAndConditions}
      />
      {children}
    </TermsAndConditionsContext.Provider>
  );
};

export const useTermsAndConditions = () => {
  const context = React.useContext(TermsAndConditionsContext);

  if (!context) {
    throw new Error(
      'useTermsAndConditions must be used within a TermsAndConditionsContextProvider'
    );
  }

  return context;
};
