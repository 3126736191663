import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {createWizard} from '@youtoken/ui.elements';
import {cell} from '@youtoken/ui.cell';
import {ConvertStep, AutoConvertStep} from './steps';
import {type exchangeOrderType} from '../ExchangeForm';

export interface ParamList {
  Convert: {};
  AutoConvert: {};
}

const Wizard = createWizard<ParamList>();

type ConvertWizardProps = {
  fromTicker: string;
  toTicker?: string;
  initialAmount?: string;
  dl_source?: string;
  onPressBack?: () => void;
};

export type ConvertWizardStepConvertProps = ConvertWizardProps & {
  orderType?: exchangeOrderType;
  desiredRate?: string;
};

export type ConvertWizardStepAutoConvertProps = {
  fromTicker: string;
  toTicker: string;
  initialAmount?: string;
  orderType?: exchangeOrderType;
  desiredRate: string;
  isDesiredRateReversed: boolean;
  onSuccessNative?: (desiredRate: string) => void;
};

export interface ConvertWizardParams {
  Convert: ConvertWizardStepConvertProps;
  AutoConvert: ConvertWizardStepAutoConvertProps;
}

export const ConvertWizard: React.FC<ConvertWizardProps> = cell(
  ({onPressBack, ...params}) => {
    const {t} = useTranslation();

    return (
      <Wizard.Navigator
        initialName="Convert"
        goBack={onPressBack}
        initialProps={params}
      >
        <Wizard.Screen
          name="Convert"
          component={ConvertStep}
          options={{title: t('surface.wallets.conversion_form.title')}}
        />

        <Wizard.Screen
          name="AutoConvert"
          component={AutoConvertStep}
          options={{title: t('surface.auto_convert.title')}}
        />
      </Wizard.Navigator>
    );
  }
);
