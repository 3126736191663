import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {type WithdrawalForm} from '../../../../../../state/WithdrawalForm';

const providersMap = {
  sepa: {
    label: i18n.t('surface.wallets.withdrawal_bank_wire_form.sepa'),
    labelTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_FEE_SEPA_LABEL',
    amountTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_FEE_SEPA_AMOUNT',
  },
  swift: {
    label: i18n.t('surface.wallets.withdrawal_bank_wire_form.swift'),
    labelTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_FEE_SWIFT_LABEL',
    amountTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_FEE_SWIFT_AMOUNT',
  },
  faster_payments: {
    label: i18n.t('surface.wallets.withdrawal_bank_wire_form.faster_payments'),
    labelTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_FEE_FASTER_PAYMENTS_LABEL',
    amountTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_FEE_FASTER_PAYMENTS_AMOUNT',
  },
};

interface FeeRowProps {
  amount: string;
  provider: string;
  percent?: string;
  showSeparator?: boolean;
}

export const FeeRow: React.FC<FeeRowProps> = observer(
  ({provider, amount, percent, showSeparator}) => {
    const {t} = useTranslation();

    const {
      view: {tickerFormatted},
    } = useForm<WithdrawalForm>();

    const providerData = provider
      ? providersMap[provider as keyof typeof providersMap]
      : null;

    return (
      <Box
        mx={24}
        py={20}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottomWidth={showSeparator ? 1 : 0}
        borderColor="$ui-01"
      >
        <Text variant="$body-02" color="$text-02">
          {t('surface.bank_wire.withdrawal.fee.label')}

          {providerData && (
            <Text testID={providerData.labelTestID}>
              {' '}
              ({providerData.label})
            </Text>
          )}
        </Text>
        <Box flexDirection="row">
          {percent && (
            <Text variant="$body-02" color="$text-02">
              {percent}%{' '}
            </Text>
          )}
          <Text
            variant="$body-02-high-accent"
            color="$text-01"
            testID={
              providerData?.amountTestID ??
              'FIAT_WITHDRAWAL_BANK_WIRE_FEE_AMOUNT'
            }
          >
            {amount}{' '}
          </Text>
          <Text variant="$body-02" color="$text-02">
            {tickerFormatted}
          </Text>
        </Box>
      </Box>
    );
  }
);
