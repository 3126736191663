import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Form} from '@youtoken/ui.form-elements';
import {TextInput} from '@youtoken/ui.inputs';
import {Button} from '@youtoken/ui.buttons';
import {FieldErrorMessage, Label} from '@youtoken/ui.form-elements';
import {FeatureAntiPhishingForm} from './state';
import {
  useTwoFactorAuth,
  __GLOBAL_RECAPTCHA__,
} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  getTranslatedValidationMessage,
  extractErrorFromResponse,
} from '@youtoken/ui.validation-messages';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {AxiosError} from '@youtoken/ui.errors';

type AntiPhishingFormProps = {
  hasCode: boolean;
};

export const AntiPhishingForm: React.FC<AntiPhishingFormProps> = observer(
  ({hasCode}) => {
    const feature = FeatureAntiPhishingForm.use({});
    const {requestTwoFactorAuth} = useTwoFactorAuth();
    const {t} = useTranslation();

    const inputRef = React.useRef<{
      blur: () => void;
    }>(null);

    React.useEffect(() => {
      return () => feature.form.instance.clear();
    }, []);

    const handleSubmit = React.useCallback(() => {
      return feature.form.instance
        .submit()
        .then(() => {
          feature.resources.authMe.refetch();
        })
        .catch((error: AxiosError) => {
          // NOTE: need refactor
          if (
            error?.request?.status === 403 &&
            error?.response?.data?.operationId
          ) {
            inputRef.current?.blur();
            return requestTwoFactorAuth({
              operationId: error.response.data.operationId,
              twoFactorAuthType: error.response.data.twoFactorAuthType,
              phoneMask: error.response.data.phoneMask,
            })
              .then(() => {
                LOCAL_NOTIFICATIONS.info({
                  text: hasCode
                    ? t('surface.profile.security.antiphishing.changed')
                    : t('surface.profile.security.antiphishing.created'),
                });
              })
              .finally(() => {
                feature.resources.authMe.refetchSilently();
                feature.form.instance.clear();
              });
          } else {
            feature.resources.authMe.refetchSilently();
            LOCAL_NOTIFICATIONS.error({
              text: getTranslatedValidationMessage(
                extractErrorFromResponse(error.response?.data, '_error')
              ),
            });
          }
        });
    }, [requestTwoFactorAuth, hasCode]);

    const handleDelete = React.useCallback(() => {
      __GLOBAL_RECAPTCHA__
        .requestToken('anti_phishing')
        .then(token => {
          return TRANSPORT.API.post('/v1/security/disable-ap', {
            token,
          });
        })
        .then(() => {
          feature.resources.authMe.refetch();
        })
        .catch(error => {
          // NOTE: need refactor
          if (
            error?.request?.status === 403 &&
            error?.response?.data?.operationId
          ) {
            inputRef.current?.blur();
            return requestTwoFactorAuth({
              operationId: error.response.data.operationId,
              twoFactorAuthType: error.response.data.twoFactorAuthType,
              phoneMask: error.response.data.phoneMask,
            })
              .then(() => {
                LOCAL_NOTIFICATIONS.info({
                  text: t('surface.profile.security.antiphishing.deleted'),
                });
              })
              .finally(() => {
                feature.resources.authMe.refetchSilently();
                feature.form.instance.clear();
              });
          } else {
            feature.resources.authMe.refetchSilently();
            LOCAL_NOTIFICATIONS.error({
              text: getTranslatedValidationMessage(
                extractErrorFromResponse(error.response?.data, '_error')
              ),
            });
          }
        });
    }, [requestTwoFactorAuth]);

    return (
      <Form form={feature} style={{height: undefined, minHeight: undefined}}>
        <Box
          flex={1}
          width="100%"
          px={{default: 0, desktop: 0, phone: 24}}
          py={24}
          justifyContent="space-between"
        >
          <Box mb={32}>
            <Label>
              {hasCode
                ? t('surface.profile.security.antiphishing.form_title_new')
                : t('surface.profile.security.antiphishing.form_title')}
            </Label>
            <Box mt={10} mb={10} zIndex={10}>
              <TextInput
                value={feature.form.antiPhishingCode.get('value')}
                onChangeText={feature.form.antiPhishingCode.get('onChange')}
                size="medium"
                maxLength={15}
                autoCapitalize="none"
                autoCorrect={false}
                autoFocus={false}
                hasError={Boolean(
                  feature.form.instance.errors()?.antiPhishingCode
                )}
                ref={inputRef}
              />
              <FieldErrorMessage
                visible={Boolean(
                  feature.form.instance.errors()?.antiPhishingCode
                )}
                placement="left"
              >
                {getTranslatedValidationMessage(
                  feature.form.instance.errors()?.antiPhishingCode,
                  t
                )}
              </FieldErrorMessage>
            </Box>

            <Text variant="$body-02" color="$text-02" style={{zIndex: 1}}>
              {t('surface.profile.security.antiphishing.form_caption')}
            </Text>
          </Box>
          <Box>
            <Button onPress={handleSubmit}>
              {hasCode
                ? t('surface.profile.security.antiphishing.change')
                : t('surface.profile.security.antiphishing.create')}
            </Button>
            {hasCode && (
              <Button type="secondary" mt={16} onPress={handleDelete}>
                {t('surface.profile.security.antiphishing.delete')}
              </Button>
            )}
          </Box>
        </Box>
      </Form>
    );
  }
);
