import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type LogoColoredIconName, LogoColored} from '@youtoken/ui.icons';
import {TextInput} from '@youtoken/ui.inputs';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import type {LoanRepayOwnFundsFormState} from '../../types';
import {AmountSetAllDebtLink} from './AllDebtLink';

export const AmountSetTarget: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {form} = useForm<LoanRepayOwnFundsFormState>();

  return (
    <Box {...props}>
      <Box flexDirection="row" justifyContent="space-between" mb={8}>
        <Label>
          {t('surface.loans.loan_pay_own_funds_form.conversion_ticker')}
        </Label>
        <AmountSetAllDebtLink />
      </Box>
      <TextInput
        numeric
        size="large"
        value={form.conversionAmount}
        onChangeText={form.conversionAmountOnChange}
        textAlign="right"
        LeftPartComponent={
          <Box flexDirection="row" alignItems="center" height="100%" px={16}>
            <LogoColored
              name={form.conversionTicker as LogoColoredIconName}
              size={20}
            />
            <Text
              ml={8}
              mr={2}
              variant="$body-01-medium-accent"
              color="$text-01"
            >
              {form.conversionTicker.toUpperCase()}
            </Text>
          </Box>
        }
      />
      <FieldErrorMessage visible={form.targetHasError}>
        {form.targetError}
      </FieldErrorMessage>
    </Box>
  );
});
