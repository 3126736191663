import {
  action,
  type IReactionDisposer,
  observable,
  computed,
  reaction,
  autorun,
} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import Big from 'big.js';
import type {AutoConvertFormArgs, AutoConvertFormResources} from './types';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {computedFn, now} from 'mobx-utils';
import {toBig} from '@youtoken/ui.formatting-utils';

export const CURRENT_RATE_UPDATE_INTERVAL = 15;

export class Form {
  @observable
  args: AutoConvertFormArgs;

  @observable
  resources: AutoConvertFormResources;

  @observable
  instance: MobxReactForm;

  @observable
  disposers: IReactionDisposer[] = [];

  @observable
  fromTicker: string;

  @observable
  toTicker: string;

  //#region rate

  @observable
  rate!: Big;

  @observable
  rateTimeLeft: number = CURRENT_RATE_UPDATE_INTERVAL;

  @observable
  shouldTrackTime = true;

  @computed
  get currentTime() {
    if (!this.shouldTrackTime) {
      return 0;
    }

    return now();
  }

  @observable
  rateTimeInterval: number = 0;

  @computed
  get rateTimeIntervalName() {
    return `rate-interval-${this.rateTimeInterval}`;
  }

  @computed
  get rateTimeIntervalProgress() {
    return (
      (CURRENT_RATE_UPDATE_INTERVAL - this.rateTimeLeft) /
      (CURRENT_RATE_UPDATE_INTERVAL - 1)
    );
  }

  @computed get rateCurrent() {
    return toBig(
      this.resources.ratesResource.getBidAskRate(
        this.fromTicker,
        this.toTicker,
        this.chartModeDesiredRate
      )
    );
  }

  @computed get rateCurrentFormatted() {
    return this.rateCurrent.toFixedWithSeparators(
      getCoinDecimalPrecision(this.toTicker)
    );
  }

  @action
  updateRate = () => {
    this.rateTimeInterval = this.rateTimeInterval + 1;
    this.rate = this.rateCurrent;
    this.rateTimeLeft = CURRENT_RATE_UPDATE_INTERVAL;
  };

  @computed get chartModeDesiredRate() {
    return this.args.isDesiredRateReversed ? 'ask' : 'bid';
  }

  //#endregion rate

  //#region auto convert

  @computed get desiredRate() {
    return this.instance.$('desiredRate').get('value');
  }

  @computed
  get desiredRateError() {
    return getTranslatedValidationMessage(
      this.instance?.$('desiredRate').get('error')
    );
  }

  @computed
  get hasDesiredRateError() {
    return Boolean(this.desiredRateError);
  }

  @computed get buttonsPercentSign() {
    return this.args.isDesiredRateReversed ? '-' : '+';
  }

  @action
  setDesiredRate = (value: string) => {
    const precision = getCoinDecimalPrecision(this.args.toTicker, 8);

    this.instance
      .$('desiredRate')
      .set('value', normalizeAmountWithPrecision(value, precision));
  };

  calculateDesiredRateByPercent = computedFn((percent: number) => {
    const multiplayer = this.args.isDesiredRateReversed
      ? 1 - percent / 100
      : 1 + percent / 100;
    const value = this.rateCurrent.mul(multiplayer);

    this.setDesiredRate(value.toFixed());
  });

  //#endregion auto convert

  @computed get submitIsDisabled() {
    return this.instance.isSubmitting;
  }

  @action
  public handleSubmit = () => {
    this.instance.submit();
  };

  constructor(args: AutoConvertFormArgs, resources: AutoConvertFormResources) {
    this.args = args;
    this.resources = resources;
    this.rate = this.rateCurrent;
    this.fromTicker = this.args.fromTicker;
    this.toTicker = this.args.toTicker;

    this.instance = new MobxReactForm(
      {
        fields: {
          desiredRate: {
            value: this.args.desiredRate,
          },
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                const desiredRateValidator = this.args.isDesiredRateReversed
                  ? yup
                      .big()
                      .required()
                      .lt(
                        this.rateCurrent,
                        messages.SHOULD_BE_LT({
                          value: this.rateCurrentFormatted,
                        })
                      )
                  : yup
                      .big()
                      .required()
                      .gt(
                        this.rateCurrent,
                        messages.SHOULD_BE_GT({
                          value: this.rateCurrentFormatted,
                        })
                      );
                return yup.object().shape({
                  desiredRate: desiredRateValidator,
                });
              }),
          }),
        },
        hooks: {
          onSuccess: () => {
            this.args.onSuccess({
              fromTicker: this.args.isDesiredRateReversed
                ? this.toTicker
                : this.fromTicker,
              toTicker: this.args.isDesiredRateReversed
                ? this.fromTicker
                : this.toTicker,
              initialAmount: this.args.initialAmount,
              orderType: 'limit',
              desiredRate: this.desiredRate,
            });
          },
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
        },
      }
    );
    this.disposers = [
      reaction(
        () => this.currentTime,
        () => {
          if (this.rateTimeLeft > 0) {
            this.rateTimeLeft = this.rateTimeLeft - 1;
          } else {
            this.updateRate();
          }
        }
      ),
      autorun(() => {
        this.resources.ratesResource.refetch();
      }),
    ];
  }

  @action
  dispose() {
    this.shouldTrackTime = false;
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  }
}
