import {action, computed, observable} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {TRANSPORT} from '@youtoken/ui.transport';
import {getInputTickerOptions} from '../../../utils';

export class UmaInfoFeature extends createFeature({
  getKey: () => 'feature:uma_info',
  getResources: () => {
    return {
      wallets: getResourceDescriptor(WalletsResource, {}),
      umaInfo: getResourceDescriptor(UmaInfoResource, {}),
    };
  },
}) {
  @observable public isTickerUpdating: boolean = false;

  @computed
  public get ticker() {
    return this.resources.umaInfo.data.ticker;
  }

  @computed
  public get uma() {
    return this.resources.umaInfo.data.uma;
  }

  @computed
  public get umaFormatted() {
    return '$' + this.uma;
  }

  @computed.struct get tickersOptions() {
    return getInputTickerOptions(
      this.resources.wallets.walletsListOrderedByEquivalentAmount
    );
  }

  @action public setTicker = (ticker: string) => {
    if (this.isTickerUpdating || ticker === this.ticker) {
      return;
    }

    this.isTickerUpdating = true;

    return TRANSPORT.API.post('/v1/uma/currency', {
      ticker,
    })
      .then(async () => {
        await this.refetchUmaInfo();
      })
      .catch(async e => {
        handleGeneralErrorTranslated(e);

        // NOTE: In case the ticker was updated despite the error
        await this.refetchUmaInfo();
      })
      .finally(() => {
        this.isTickerUpdating = false;
      });
  };

  @action public refetchUmaInfo = () => {
    return this.resources.umaInfo.refetch();
  };
}
