import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, YStack} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalExternalFormState} from '../state';
import {
  CryptoWithdrawalVersions,
  CryptoWithdrawalAddress,
  CryptoWithdrawalExtraId,
  CryptoWithdrawalConfirmCode,
} from '../../CryptoWithdrawalComponents';
import {CryptoWithdrawalExternalAmount} from './Amount';
import {CryptoWithdrawalBannerByVersion} from '../../CryptoWithdrawalBannerByVersion';

export const CryptoWithdrawalExternalContent: React.FC = observer(() => {
  const {walletTicker, walletVersionValue, shouldShowContent} =
    useForm<CryptoWithdrawalExternalFormState>();

  return (
    <YStack>
      <Box py={24} zIndex={1}>
        <CryptoWithdrawalVersions />
        {shouldShowContent && (
          <>
            <CryptoWithdrawalBannerByVersion
              walletVersion={walletVersionValue}
              ticker={walletTicker}
            />
            <Box px={24} mt={32}>
              <CryptoWithdrawalExternalAmount />
              <CryptoWithdrawalAddress />
              <CryptoWithdrawalExtraId />
            </Box>
          </>
        )}
      </Box>
      {shouldShowContent && <CryptoWithdrawalConfirmCode />}
    </YStack>
  );
});
