import * as React from 'react';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {en} from '@youtoken/ui.lokalise-export';

type RewardsItemType = 'deposit' | 'trade';

const titleKeys: Record<RewardsItemType, keyof typeof en> = {
  deposit:
    'surface.referral_program.rewards.overview.progress.item.deposit.title',
  trade: 'surface.referral_program.rewards.overview.progress.item.trade.title',
};

const actionKeys: Record<RewardsItemType, keyof typeof en> = {
  deposit:
    'surface.referral_program.rewards.overview.progress.item.deposit.action',
  trade: 'surface.referral_program.rewards.overview.progress.item.trade.action',
};

const action: Record<RewardsItemType, () => void> = {
  deposit: () => {
    SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
  },
  trade: () => {
    SHARED_ROUTER_SERVICE.navigate('NewMultiHODL');
  },
};

interface RewardsLevelItemProps {
  type: RewardsItemType;
  value: number;
  requiredValue: number;
  valueFormatted?: string;
  requiredValueFormatted?: string;
  showAction?: boolean;
}

export const RewardsLevelItem: React.FC<RewardsLevelItemProps> = React.memo(
  ({
    type,
    value,
    requiredValue,
    valueFormatted,
    requiredValueFormatted,
    showAction = true,
  }) => {
    const {t} = useTranslation();

    return (
      <Box flex={1} p={8}>
        <Box flex={1}>
          <Text variant="$body-01-high-accent" textAlign="center">
            {t(titleKeys[type], {
              amount: priceFormatterInThousands(requiredValue),
            })}
          </Text>
          <Box my={8}>
            <ProgressBar
              color={value >= requiredValue ? 'success' : 'interactive'}
              progress={value / requiredValue}
            />
          </Box>
          <Text
            variant="$body-02-high-accent"
            color="$text-03"
            textAlign="center"
          >
            ${valueFormatted} / ${requiredValueFormatted}
          </Text>
        </Box>
        {showAction && (
          <Button onPress={action[type]} mt={16}>
            {t(actionKeys[type])}
          </Button>
        )}
      </Box>
    );
  }
);
