import {Platform} from 'react-native';
import {COLORS} from './colors/COLORS';
import {FONTS} from './fonts/FONTS';
import {BODY_TOKENS, HEADING_TOKENS, CODE_TOKENS} from './fonts/TOKENS';
import {Theme} from './types';

export const sharedTheme: Pick<
  Theme,
  'spacing' | 'breakpoints' | 'pallet' | 'textVariants' | 'fonts'
> = {
  pallet: COLORS,
  fonts: FONTS,

  spacing: {
    '$screen-section': 24,
    '$screen-default-offset': 24,
    '$gap-xxs': 4,
    '$gap-xs': 8,
    '$gap-s': 12,
    '$gap-m': 16,
    '$gap-l': 24,
    '$gap-xl': 32,
  },

  breakpoints: {
    default: 0,
    smallPhone: 0,
    phone: 360,
    largePhone: 480,
    tablet: 768,
    desktop: 991,
    mediumDesktop: 1280,
    largeDesktop: 1680,
  },

  textVariants: {
    defaults: BODY_TOKENS['$body-01'],
    ...HEADING_TOKENS,
    ...BODY_TOKENS,
    ...CODE_TOKENS,
  },
};
