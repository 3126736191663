import * as React from 'react';
import {observer} from 'mobx-react';
import {useWizard} from '@youtoken/ui.elements';
import {CardWithdrawalWizard} from '../../CardWithdrawalWizard';
import {
  type WithdrawalWizardNavigator,
  type WithdrawalWizardScreenName,
} from '..';

export const BankCardWithdrawalWizardWrapper: React.FC = observer(() => {
  const {
    step: {
      props: {ticker, conversionTicker, cardId, amount, withError},
    },
    goBack,
  } = useWizard<
    WithdrawalWizardNavigator,
    WithdrawalWizardScreenName.BANK_CARD
  >();

  return (
    <CardWithdrawalWizard
      ticker={ticker}
      conversionTicker={conversionTicker}
      cardId={cardId}
      amount={amount}
      withError={withError}
      onPressBack={goBack}
    />
  );
});
