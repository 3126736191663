import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  BundleTariffOverviewResource,
  BundleOperationsResource,
} from '@youtoken/ui.resource-bundles';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {
  type BundleReleaseFormStateResources,
  BundleReleaseFormState,
} from './state';
import {Content, Footer} from './components';

export {getBundleReleaseFormTitle} from './utils';

export interface BundleReleaseFormProps {
  id: string;
  onClose?: () => void;
}

export const BundleReleaseForm: React.FC<BundleReleaseFormProps & BoxProps> =
  cell(({id, onClose, ...boxProps}) => {
    const {authMe, bundleTariffOverview, bundleOperations} = useResources({
      authMe: getResourceDescriptor(AuthMeResource, {}),
      bundleTariffOverview: getResourceDescriptor(
        BundleTariffOverviewResource,
        {
          id,
        }
      ),
      bundleOperations: getResourceDescriptor(BundleOperationsResource, {
        id,
      }),
    });

    const docs = DocsResource.use({country: authMe.residenceOrCountry});

    const resources = {
      authMe,
      bundleTariffOverview,
      bundleOperations,
      docs,
    } as BundleReleaseFormStateResources;

    const state = makeForm(() => {
      return new BundleReleaseFormState({onClose}, resources);
    });

    return (
      <Form form={state}>
        <Box testID="BUNDLE_RELEASE_FORM" flex={1} {...boxProps}>
          <Content />
          <Footer />
        </Box>
      </Form>
    );
  });
