import {observable, action, computed} from 'mobx';
import {format as formatNumber} from 'd3-format';
import {min, max, bisector} from 'd3-array';
import {format as formatDate} from '@youtoken/ui.date-fns';
import {invariant} from '@youtoken/ui.utils';
import {LastValueLabel} from './Labels';
import {Grid} from './Grid';
import {Candles, Lines} from './DataDisplay';
import {LineCursor, CandlesCursor} from './Cursor';
import {computedFn} from 'mobx-utils';
import {LineChartLayout, CandlesChartLayout} from './Layout';
import {BaseChartLayout} from './Layout/BaseChartLayout';
import Big from 'big.js';

export interface LineChartDataItem {
  date: Date;
  rate: number;
}
export type LineChartData = LineChartDataItem[];

export interface CandlestickChartDataItem {
  date: Date;
  open: number;
  close: number;
  high: number;
  low: number;
}
export type CandlestickChartData = CandlestickChartDataItem[];

export type Item = LineChartDataItem | CandlestickChartDataItem;
export type Data = LineChartDataItem[] | CandlestickChartDataItem[];

export type IncomingData =
  | {type: 'line'; data: LineChartData}
  | {type: 'candle'; data: CandlestickChartData}
  | {type: 'line' | 'candle'; data: Data};

export type ChartDirection = 'up' | 'down' | 'neutral' | 'pending' | 'closed';

export interface BaseChartStateProps {
  width: number;
  height: number;
  data: IncomingData;
  startDate?: Date;
  endDate?: Date;
  direction?: ChartDirection;
  cursorX?: number;
  onCursorDataChange?: (cursorData: any) => void;
  onCursorIndexChange?: (index?: number) => void;
  isFetching?: boolean;
  isLive?: boolean;
  displayActivityIndicator?: boolean;
  formatNumberPrecision?: number;
  ticksNumber?: number;
  domainPaddingTop?: number;
  domainPaddingBottom?: number;
  domainPaddingLeft?: number;
  domainPaddingRight?: number;
  hasData?: boolean;
  refetch?: () => any;
}

const defaultDomainPaddingBottom = 16 + 28 + 16 + 8;
const defaultDomainPaddingTop = 8;
const defaultDomainPaddingLeft = 0;
const defaultDomainPaddingRight = 0;

export class BaseChartState {
  @observable type: 'line' | 'candle' = 'line';
  @observable _lineData?: LineChartData;
  @observable _candlesData?: CandlestickChartData;
  @observable startDate?: Date;
  @observable endDate?: Date;

  @observable width!: number;
  @observable height!: number;
  @observable _direction?: ChartDirection;

  @observable cursorX?: number;
  @observable cursorY?: number;
  @observable onCursorDataChange?: (cursorData?: {
    index: number;
    date: Date;
  }) => void;

  @observable onCursorIndexChange?: (index?: number) => void;

  @observable isFetching?: boolean;
  @observable isLive?: boolean;
  @observable displayActivityIndicator?: boolean;
  @observable formatNumberPrecision: number = 2;
  @observable ticksNumber: number = 5;

  @observable domainPaddingTop: number = defaultDomainPaddingTop;
  @observable domainPaddingBottom: number = defaultDomainPaddingBottom;
  @observable domainPaddingLeft: number = defaultDomainPaddingLeft;
  @observable domainPaddingRight: number = defaultDomainPaddingRight;

  // @observable layout!: ChartLayout;
  @observable lineLayout!: LineChartLayout;
  @observable candlesLayout!: CandlesChartLayout;
  @observable grid!: Grid;

  @observable lines!: Lines;
  @observable candles!: Candles;

  @observable lineCursor!: LineCursor;
  @observable candlesCursor!: CandlesCursor;
  @observable lastValueLabel!: LastValueLabel;

  @observable hasData!: boolean;

  constructor(props: BaseChartStateProps) {
    this.setData(props.data);
    this.setFromProps(props);
    this.setCursor(props.cursorX);
    this.lineLayout = new LineChartLayout(this);
    this.candlesLayout = new CandlesChartLayout(this);
    this.grid = new Grid(this);
    this.lines = new Lines(this);
    this.candles = new Candles(this);
    this.lineCursor = new LineCursor(this);
    this.candlesCursor = new CandlesCursor(this);
    this.lastValueLabel = new LastValueLabel(this);
  }

  @computed get layout(): BaseChartLayout {
    return this.type === 'line' ? this.lineLayout : this.candlesLayout;
  }

  formatNumber = (value: number) =>
    formatNumber(`,.${this.formatNumberPrecision}f`)(value);

  formatNumberToPrecision = (value: number, precision: number = 4) =>
    formatNumber(`,.${precision}f`)(value);

  formatPercent = (value: number | Big) =>
    formatNumber('+,.2%')(value as number);

  formatDate = (date: Date) => formatDate(date, "dd MMM 'at' HH:mm (O)");

  bisectorX = bisector<Item, Date | number>(d => d.date);

  @action setData(data: IncomingData) {
    if (data.type === 'line') {
      this.setLineData(data.data as LineChartData);
    }

    if (data.type === 'candle') {
      this.setCandlesData(data.data as CandlestickChartData);
    }
  }

  @action setLineData(data?: LineChartDataItem[]) {
    invariant(data, `line data is required`);

    this._lineData = data;
    this.type = 'line';
  }

  @action setCandlesData(data?: CandlestickChartData) {
    invariant(data, `candles data is required`);

    this._candlesData = data;
    this.type = 'candle';
  }

  @action setCursor(x?: number, y?: number) {
    this.cursorX = x;
    this.cursorY = y;
  }

  @action setFromProps(props: Omit<BaseChartStateProps, 'data' | 'cursorX'>) {
    invariant(props.width, 'width is required');
    invariant(props.height, 'height is required');

    this.startDate = props.startDate;
    this.endDate = props.endDate;

    this.hasData = props.hasData ?? true;

    this.width = props.width;
    this.height = props.height;
    this._direction = props.direction;

    this.onCursorDataChange = props.onCursorDataChange;
    this.onCursorIndexChange = props.onCursorIndexChange;

    this.isFetching = props.isFetching;
    this.isLive = props.isLive;
    this.displayActivityIndicator = props.displayActivityIndicator;
    this.formatNumberPrecision = props.formatNumberPrecision ?? 4;
    this.ticksNumber = props.ticksNumber ?? 5;
    this.domainPaddingTop = props.domainPaddingTop ?? defaultDomainPaddingTop;
    this.domainPaddingRight =
      props.domainPaddingRight ?? defaultDomainPaddingRight;
    this.domainPaddingBottom =
      props.domainPaddingBottom ?? defaultDomainPaddingBottom;
    this.domainPaddingLeft =
      props.domainPaddingLeft ?? defaultDomainPaddingLeft;
  }

  @computed get lineData() {
    return this._lineData;
  }

  @computed get candlesData() {
    return this._candlesData;
  }

  @computed get numberOfCandlesToRender() {
    return Math.floor(this.layout.chartWidth / 10);
  }

  @computed get visibleCandles() {
    if (!this.candlesData) {
      return this.candlesData;
    }

    return this.candlesData.slice(-this.numberOfCandlesToRender);
  }

  @computed get normalizedCandlesData() {
    if (!this.visibleCandles) {
      return undefined;
    }

    const candlesToFill =
      this.numberOfCandlesToRender - this.visibleCandles.length;

    return candlesToFill > 0
      ? Array(candlesToFill).fill(undefined).concat(this.visibleCandles)
      : this.visibleCandles;
  }

  @computed get data(): Data {
    return this.type === 'line' ? this.lineData! : this.candlesData!;
  }

  @computed get lastItem() {
    const lastItem = this.data[this.data.length - 1];

    invariant(lastItem, `lastItem is required`);

    return lastItem;
  }

  lastValueAccessor = (item: Item) => {
    if (this.type === 'line') {
      return (item as LineChartDataItem).rate;
    } else {
      return (item as CandlestickChartDataItem).close;
    }
  };

  minValueAccessor = (item: Item) => {
    if (this.type === 'line') {
      return (item as LineChartDataItem).rate;
    } else {
      return (item as CandlestickChartDataItem).low;
    }
  };

  maxValueAccessor = (item: Item) => {
    if (this.type === 'line') {
      return (item as LineChartDataItem).rate;
    } else {
      return (item as CandlestickChartDataItem).high;
    }
  };

  dateAccessor = (item: LineChartDataItem | CandlestickChartDataItem) => {
    return item.date;
  };

  getDiff = computedFn((value: number) => {
    return new Big(value)
      .sub(this.firstValueToCompare)
      .div(this.firstValueToCompare);
  });

  getDiffWithValue = computedFn((value: number, toCompareWith: number) => {
    return new Big(value).sub(toCompareWith).div(toCompareWith);
  });

  @computed get firstValue() {
    invariant(this.data[0], `data must contain values`);

    return this.lastValueAccessor(this.data[0]);
  }

  @computed get lastValue() {
    return this.lastValueAccessor(this.lastItem);
  }

  @computed get minValue() {
    return min(this.data, this.minValueAccessor) as number;
  }

  @computed get maxValue() {
    return max(this.data, this.maxValueAccessor) as number;
  }

  @computed get direction() {
    if (this._direction) {
      return this._direction;
    }

    if (this.type === 'candle') {
      const {close, open} = this.lastItem as CandlestickChartDataItem;

      return close > open ? 'up' : 'down';
    }

    return this.lastValue > this.firstValue ? 'up' : 'down';
  }

  @computed get isCursorActive() {
    return this.lineCursor.cursorActive || this.candlesCursor.cursorActive;
  }

  @computed get firstValueToCompare() {
    return this.firstValue;
  }

  @computed get lastValueToCompare() {
    return this.lastValue;
  }

  getDiffWithLastValueToCompare = computedFn((value: number) => {
    return new Big(value)
      .sub(this.firstValueToCompare)
      .div(this.firstValueToCompare);
    // return (value - this.firstValueToCompare) / this.firstValueToCompare;
  });
}
