import * as React from 'react';
import {ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {BundleTickersList} from '../../../../components';

import {BundleReleaseFormState} from '../../state';

const SCROLL_VIEW_PADDING = 24;

export const Content: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {
      resources: {
        bundleTariffOverview: {
          data: {userPortfolio},
        },
      },
    },
  } = useForm<BundleReleaseFormState>();

  if (!userPortfolio?.tickers) {
    return null;
  }

  return (
    <ScrollView
      keyboardDismissMode="interactive"
      keyboardShouldPersistTaps="handled"
      style={{
        zIndex: 1,
        flex: 1,
      }}
      contentContainerStyle={{
        padding: SCROLL_VIEW_PADDING,
        gap: SCROLL_VIEW_PADDING,
      }}
    >
      <Box>
        <Text variant="$body-02">
          {t('surface.bundles.release_form.info', {
            count: userPortfolio.tickers.length,
          })}
        </Text>
      </Box>
      <BundleTickersList
        showHeader={false}
        items={userPortfolio.tickers}
        mb={-SCROLL_VIEW_PADDING}
      />
    </ScrollView>
  );
});
