import * as React from 'react';
import {ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {CardsResource} from '@youtoken/ui.resource-cards';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {ManageCardItemComponent, ManagePaymentMethodModal} from './components';

export type ManagePaymentMethodsSurfaceContentProps = {
  onAddCard: () => void;
};

export const ManagePaymentMethodsSurfaceContent: React.FC<ManagePaymentMethodsSurfaceContentProps> =
  observer(({onAddCard}) => {
    const {t} = useTranslation();
    const {cardsList, setCardAsDefault} = CardsResource.use({});
    const isCardListEmpty = cardsList.length === 0;

    const [removingCardId, setRemovingCardId] = React.useState('');

    const [isRemoveCardModalVisible, setIsRemoveCardModalVisible] =
      React.useState(false);

    const handleOpenRemoveCardModal = React.useCallback(() => {
      setIsRemoveCardModalVisible(true);
    }, []);

    const handleCloseRemoveCardModal = React.useCallback(() => {
      setIsRemoveCardModalVisible(false);
    }, []);

    const handlePressRemoveItem = React.useCallback(
      (id: string) => {
        setRemovingCardId(id);
        handleOpenRemoveCardModal();
      },
      [handleOpenRemoveCardModal]
    );

    return (
      <>
        <ScrollView>
          <Box p={24}>
            <Text>{t('surface.wallets.checout_bank_card.disclosure')}</Text>
          </Box>

          <Separator />

          <Box p={24}>
            {isCardListEmpty && (
              <Box
                mt={8}
                mb={16}
                p={16}
                borderWidth={1}
                borderRadius={6}
                borderColor="$ui-01"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                testID="EMPTY_CARDS_STATE"
              >
                <Icon name="card" color="$text-02"></Icon>
                <Text ml={8} variant="$body-02" color="$text-02">
                  {t('surface.wallets.checout_bank_card.no_cards_disclosure')}
                </Text>
              </Box>
            )}

            {!isCardListEmpty &&
              cardsList.map(item => {
                return (
                  <ManageCardItemComponent
                    item={item}
                    onPressRemove={handlePressRemoveItem}
                    onPressSetDefault={setCardAsDefault}
                  />
                );
              })}

            <Box flexDirection="row">
              <Button
                icon="plus"
                type="secondary"
                size={isCardListEmpty ? 'large' : 'small'}
                pr={12}
                flexGrow={isCardListEmpty ? 1 : 0}
                onPress={onAddCard}
                testID="ADD_CARD_BUTTON"
              >
                {t('surface.wallets.checout_bank_card.add_new_card_action')}
              </Button>
            </Box>
          </Box>
        </ScrollView>
        <ManagePaymentMethodModal
          isOpen={isRemoveCardModalVisible}
          onClose={handleCloseRemoveCardModal}
          cardId={removingCardId}
        />
      </>
    );
  });
