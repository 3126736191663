import * as React from 'react';
import {observer} from 'mobx-react';
import {
  useForm,
  ValidationMessageTouchableMinAndMax,
} from '@youtoken/ui.form-elements';
import {AdjustPendingOrderFormClass} from '../../form';

export const ValidationMessageTouchable: React.FC = observer(() => {
  const {
    form: {
      updateTriggerPriceValue,
      triggerPriceValueMin,
      triggerPriceValueMax,
      triggerPriceValueMinFormatted,
      triggerPriceValueMaxFormatted,
    },
    view: {triggerPriceHasError},
  } = useForm<AdjustPendingOrderFormClass>();

  const handlePressMin = React.useCallback(() => {
    updateTriggerPriceValue(triggerPriceValueMin);
  }, [updateTriggerPriceValue, triggerPriceValueMin]);

  const handlePressMax = React.useCallback(() => {
    updateTriggerPriceValue(triggerPriceValueMax);
  }, [updateTriggerPriceValue, triggerPriceValueMax]);

  return (
    <ValidationMessageTouchableMinAndMax
      valueMin={triggerPriceValueMinFormatted}
      valueMax={triggerPriceValueMaxFormatted}
      handlePressMin={handlePressMin}
      handlePressMax={handlePressMax}
      fieldHasError={triggerPriceHasError}
    />
  );
});
