import * as React from 'react';
import {z} from 'zod';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {AccountLevelsSurfaceContent} from './Content';

export const AccountLevelsSurface = surface(
  {
    name: 'AccountLevels',
    getTitle: () => i18n.t('surface.loyalty.account_level'),
    paramsSchema: z.object({}),
    headerShown: true,
  },
  () => {
    React.useEffect(() => {
      DATA_LAYER.trackStrict('loyalty-opened', {});
    }, []);

    return <VariableLayout PrimaryContent={<AccountLevelsSurfaceContent />} />;
  }
);
