import React from 'react';
import {analytics} from '@web-app/src/utils/analytics/segment';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {cell} from '@youtoken/ui.cell';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {CreateHODLSurfaceCell} from '@youtoken/ui.surface-hodl-create';

export const Create: React.FC = cell(() => {
  const {queryParams} = RouterResource.use({});
  const {baseTicker, direction} = queryParams;

  React.useEffect(() => {
    if (baseTicker && direction) {
      DATA_LAYER.trackStrict('hodl-create-attempt', {
        isShort: direction === 'sell',
        ticker: baseTicker,
      });
    }
  }, []);

  return <CreateHODLSurfaceCell {...queryParams} />;
});
