import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface TermsAndConditionsFooterProps {
  isRead: boolean;
  isBottom: boolean;
  onScrollDown: () => void;
  onAccept: () => void;
}

export const TermsAndConditionsFooter: React.FC<
  TermsAndConditionsFooterProps & BoxProps
> = observer(({isRead, isBottom, onScrollDown, onAccept, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      position="sticky"
      bottom={0}
      left={0}
      right={0}
      backgroundColor="$ui-background"
      borderRadius={10}
      {...boxProps}
    >
      <Button
        type="secondary"
        disabled={isBottom}
        mb={20}
        onPress={onScrollDown}
      >
        {t('surface.terms_and_conditions.scroll_to_bottom')}
      </Button>
      <Button type="primary" disabled={!isRead} onPress={onAccept}>
        {t('surface.terms_and_conditions.accept')}
      </Button>
    </Box>
  );
});
