import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Illustration} from '@youtoken/ui.elements';
import {SparkPriceBasic} from '../SparkPriceBasic';

export type MinerResetBlocksContentProps = {
  onClose?: () => void;
};

export const MinerResetBlocksContent: React.FC<MinerResetBlocksContentProps> =
  observer(({onClose}) => {
    const {t} = useTranslation();

    const {
      hexagonalGrid: {resetCost, resetMiner},
    } = MinerOverviewResource.use({});

    const [isLoading, setLoading] = React.useState(false);

    const handleResetPress = () => {
      DATA_LAYER.trackStrict('miner-board-unlock-attempt', {
        category: 'miner',
        type: 'board-unlock',
      });

      setLoading(true);
      resetMiner()
        .then(() => {
          onClose?.();
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <Box flex={1} justifyContent="space-between">
        <Box alignItems="center" justifyContent="center" flexGrow={1}>
          {Platform.OS !== 'web' && (
            <Box mx={24}>
              <Illustration
                name="illustration-3"
                style={{width: 200, maxHeight: 200}}
              />
            </Box>
          )}
          {Platform.OS !== 'web' && (
            <Box pt={0}>
              <Heading variant="$heading-01">
                {t('surface.miner.unlock_blocks_confirmation.title')}
              </Heading>
            </Box>
          )}
          <Box pt={16}>
            <Text variant="$body-01" color="$text-02">
              {t('surface.miner.unlock_blocks.used_all')}
            </Text>
          </Box>

          <Box
            px={24}
            py={8}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Text variant="$body-01">
              {t('surface.miner.unlock_blocks.unlock_for')}
            </Text>
            <SparkPriceBasic amount={`${resetCost}`} />
            <Text ml={4}>{t('surface.miner.sparks')}</Text>
          </Box>
        </Box>

        <Box
          px={24}
          pt={8}
          pb={Platform.select({default: 0, web: 24})}
          flexDirection="row"
        >
          <Button
            size="large"
            type="primary"
            flex={1}
            loading={isLoading}
            onPress={handleResetPress}
            testID="MINER_BOARD_UNLOCK_BUTTON"
          >
            {t('surface.miner.unblock_blocks.button')}
          </Button>
        </Box>
      </Box>
    );
  });
