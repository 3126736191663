import {Big} from 'big.js';
import {debounce} from 'lodash';
import axios, {type Canceler} from 'axios';
import {observable, action, runInAction, computed} from 'mobx';
import {
  serializable,
  deserialize,
  alias,
  primitive,
  list,
  object,
} from 'serializr';
import {currencyName} from '@youtoken/ui.coin-utils';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {AxiosError} from '@youtoken/ui.errors';
import {TRANSPORT} from '@youtoken/ui.transport';

import {
  BundlePortfolioTickersListItem,
  bundlePortfolioTickersListItemAdditionalProps,
} from '@youtoken/ui.resource-bundles';

export class Calculated {
  @observable
  loading: boolean = false;

  @observable
  error: null | AxiosError = null;

  @observable
  data?: CalculatedResponse;

  @action
  load = (props: CalculatedRequestParams) => {
    this.loading = true;
    return this.requestDebounced(props);
  };

  @observable
  requestCanceller?: Canceler;

  @action
  request = ({tariffId, quoteTicker, quoteAmount}: CalculatedRequestParams) => {
    const {token, cancel} = axios.CancelToken.source();

    this.requestCanceller?.('__CANCELLED_REQUEST__');

    this.requestCanceller = cancel;

    this.loading = true;

    return TRANSPORT.API.get('/v1/bundle/deposit/calculate', {
      cancelToken: token,
      params: {
        tariffId,
        quoteTicker,
        quoteAmount: Number(quoteAmount),
      },
    })
      .then(response => {
        const data = deserialize(CalculatedResponse, response.data);

        runInAction(() => {
          this.error = null;
          this.data = data;
        });

        return data;
      })
      .catch(error => {
        runInAction(() => {
          this.error = error;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  requestDebounced = debounce(this.request, 500);
}

export interface CalculatedRequestParams {
  tariffId: string;
  quoteTicker: string;
  quoteAmount: string;
}

export class CalculatedResponse {
  @serializable(alias('ticker', primitive()))
  quoteTicker!: currencyName;

  @computed
  get tickerFormatted() {
    return this.quoteTicker?.toUpperCase();
  }

  @serializable(bigNumber())
  minDeposit!: Big;

  @computed
  get minDepositFormatted() {
    return formatByTicker(this.minDeposit, this.quoteTicker);
  }

  @serializable(bigNumber())
  fee!: Big;

  @computed
  get feeFormatted() {
    return formatByTicker(this.fee, this.quoteTicker);
  }

  @serializable(
    list(
      object(
        BundlePortfolioTickersListItem,
        bundlePortfolioTickersListItemAdditionalProps
      )
    )
  )
  tickers!: BundlePortfolioTickersListItem[];
}
