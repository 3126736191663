import {computed, observable} from 'mobx';
import {list, object, serializable} from 'serializr';
import Big from 'big.js';
import {bigNumber, number} from '@youtoken/ui.utils-serializr';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {
  toBig,
  formatBigNumber,
  formatWithSeparators,
} from '@youtoken/ui.formatting-utils';

export const defaultTicker = 'usd';

class LoyaltyUpgradeOption {
  @observable
  @serializable(number())
  volume!: number;

  @computed
  get volumeFormatted() {
    return formatBigNumber(
      toBig(this.volume),
      getCoinDecimalPrecision(defaultTicker),
      true
    );
  }

  @observable
  @serializable(number())
  multiplier!: number;
}

export class UpgradeOption {
  @observable
  @serializable(bigNumber())
  operationVolumeAmount!: Big;

  @observable
  @serializable(bigNumber())
  sparksAmount!: Big;

  @observable
  @serializable(bigNumber())
  volumeEquivalent!: Big;

  @observable
  @serializable(bigNumber())
  multiplier!: Big;

  @computed
  get operationVolumeAmountFormatted() {
    return this.operationVolumeAmount.toFixedWithSeparators();
  }

  @computed
  get sparksAmountFormatted() {
    return this.sparksAmount.toFixedWithSeparators();
  }

  @computed
  get volumeEquivalentFormatted() {
    return this.volumeEquivalent.toFixedWithSeparators();
  }

  @computed
  get multiplierFormatted() {
    return this.multiplier.toFixedWithSeparators();
  }
}

class ActionToUpgrade {
  @observable
  @serializable(object(LoyaltyUpgradeOption))
  loyalty!: LoyaltyUpgradeOption;

  @observable
  @serializable(list(object(UpgradeOption)))
  options?: UpgradeOption[];

  @observable
  @serializable(number())
  sparksAmount!: number;

  @computed
  get sparksAmountFormatted() {
    return formatWithSeparators(
      this.sparksAmount,
      getCoinDecimalPrecision(defaultTicker)
    );
  }
}

export class MinerUpgradeRequirementsResponse {
  @observable
  @serializable(object(ActionToUpgrade))
  convert!: ActionToUpgrade;

  @observable
  @serializable(object(ActionToUpgrade))
  hodl!: ActionToUpgrade;
}
