import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {createWizard} from '@youtoken/ui.elements';
import {cell} from '@youtoken/ui.cell';
import {CryptoDirectWithdrawalChooseNetworkFeature} from './state';
import {CryptoDirectWithdrawal} from './CryptoDirect';
import {ScanQRCode} from './ScanQRCode';
import {
  CryptoDirectWithdrawalChooseNetwork,
  getCryptoDirectWithdrawalChooseNetworkTitle,
} from './CryptoChooseNetwork';

export enum CryptoDirectWithdrawalWizardScreenName {
  CRYPTO_DIRECT_CHOOSE_NETWORKS = 'withdrawCryptoDirectNetworks',
  CRYPTO_DIRECT = 'withdrawCryptoDirect',
  SCAN_QR_CODE = 'scanQRCode',
}

export interface CryptoDirectWithdrawalWizardNavigator {
  [CryptoDirectWithdrawalWizardScreenName.CRYPTO_DIRECT]: {
    ticker: string;
    version: string;
  };
  [CryptoDirectWithdrawalWizardScreenName.CRYPTO_DIRECT_CHOOSE_NETWORKS]: {
    ticker: string;
  };
  [CryptoDirectWithdrawalWizardScreenName.SCAN_QR_CODE]: {
    onQRCodeScanSuccessful: Function;
  };
}

const Wizard = createWizard<CryptoDirectWithdrawalWizardNavigator>();

export interface CryptoDirectWithdrawalWizardProps {
  ticker: string;
  version?: string;
  onPressBack?: () => void;
}

export const CryptoDirectWithdrawalWizard: React.FC<CryptoDirectWithdrawalWizardProps> =
  cell(({ticker, version, onPressBack}) => {
    const {t} = useTranslation();
    const {networksListFormatted} =
      CryptoDirectWithdrawalChooseNetworkFeature.use({
        ticker,
      });

    return (
      <Wizard.Navigator
        initialName={
          version || networksListFormatted?.length === 1
            ? CryptoDirectWithdrawalWizardScreenName.CRYPTO_DIRECT
            : CryptoDirectWithdrawalWizardScreenName.CRYPTO_DIRECT_CHOOSE_NETWORKS
        }
        initialProps={{
          ticker,
          version: version ?? networksListFormatted[0]!.networkVersion,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={CryptoDirectWithdrawalWizardScreenName.CRYPTO_DIRECT}
          options={({route: {params}}: any) => ({
            headerShown: true,
            title: t('surface.wallets.crypto_withdrawal.title', {
              ticker: params.ticker.toUpperCase(),
            }),
          })}
          component={CryptoDirectWithdrawal}
        />
        <Wizard.Screen
          name={
            CryptoDirectWithdrawalWizardScreenName.CRYPTO_DIRECT_CHOOSE_NETWORKS
          }
          options={({route: {params}}: any) => ({
            headerShown: true,
            title: getCryptoDirectWithdrawalChooseNetworkTitle(params.ticker),
          })}
          component={CryptoDirectWithdrawalChooseNetwork}
        />
        <Wizard.Screen
          name={CryptoDirectWithdrawalWizardScreenName.SCAN_QR_CODE}
          options={{
            headerShown: true,
            title: t('common.action.scan_qr'),
          }}
          component={ScanQRCode}
        />
      </Wizard.Navigator>
    );
  });
