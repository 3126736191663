import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {TextInput} from '@youtoken/ui.inputs';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalExternalFormState} from '../state';
import {
  CryptoWithdrawalExternalAmountAllLink,
  CryptoWithdrawalExternalAmountFeeInfo,
} from '../../CryptoWithdrawalComponents';

export const CryptoWithdrawalExternalAmount: React.FC = observer(() => {
  const {
    form: {
      amountLabel,
      amountPlaceholder,
      amount,
      amountHasError,
      amountError,
      amountOnChange,
    },
  } = useForm<CryptoWithdrawalExternalFormState>();

  return (
    <Box>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={8}
      >
        <Label>{amountLabel}</Label>
        <CryptoWithdrawalExternalAmountAllLink />
      </Box>
      <TextInput
        numeric
        value={amount}
        placeholder={amountPlaceholder}
        onChangeText={amountOnChange}
        size="large"
        LeftPartComponent={<CryptoWithdrawalExternalAmountTicker />}
        keyboardType="decimal-pad"
        textAlign="right"
        testID="AMOUNT_INPUT"
      />
      <FieldErrorMessage visible={amountHasError}>
        {amountError}
      </FieldErrorMessage>
      <CryptoWithdrawalExternalAmountFeeInfo />
    </Box>
  );
});

export const CryptoWithdrawalExternalAmountTicker: React.FC = observer(() => {
  const {walletTicker, walletTickerFormatted} =
    useForm<CryptoWithdrawalExternalFormState>();

  return (
    <Box flexDirection="row" alignItems="center" height="100%" px={16}>
      <LogoColored name={walletTicker as LogoColoredIconName} size={20} />
      <Text ml={8} mr={2} variant="$body-01-medium-accent" color="$text-01">
        {walletTickerFormatted}
      </Text>
    </Box>
  );
});
