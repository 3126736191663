import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import type {LoanRepayOwnFundsFormState} from '../../types';

export const AmountSetAllDebtLink: React.FC<
  Omit<React.ComponentProps<typeof Link>, 'onPress'>
> = observer(props => {
  const {t} = useTranslation();

  const {form} = useForm<LoanRepayOwnFundsFormState>();

  return (
    <Link
      onPress={() => {
        form.conversionAmountOnChange(form.loanOverdraftAmount);
      }}
      {...props}
    >
      <Text variant="$body-02" color="$text-05">
        {t('surface.loans.loan_pay_own_funds_form.conversion_ticker_all')}{' '}
        <Text variant="$body-02-medium-accent">
          {form.loanOverdraftAmountFormatted}
        </Text>{' '}
        {form.loanBorrowedTicker.toUpperCase()}
      </Text>
    </Link>
  );
});
