import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useWizard} from '@youtoken/ui.elements';
import {
  useWalletsChooseTickerItemWrapper,
  WalletsChooseTickerBuyCrypto,
} from '../../../components';
import {type CryptoBuyWizardNavigator, CryptoBuyWizardScreenName} from '..';

export const CryptoBuyWizardChooseTicker: React.FC = observer(() => {
  const {
    step: {
      props: {source},
    },
    navigate,
  } = useWizard<
    CryptoBuyWizardNavigator,
    CryptoBuyWizardScreenName.CHOOSE_TICKER
  >();

  const handlePress = React.useCallback(
    (ticker: string) => {
      DATA_LAYER.trackStrict('buy-wizard-currency-chosen', {
        type: 'crypto',
        ticker,
      });

      navigate(CryptoBuyWizardScreenName.BUY, {
        source,
        ticker,
      });
    },
    [source, navigate]
  );

  const TouchableWrapper = useWalletsChooseTickerItemWrapper(handlePress);

  return (
    <WalletsChooseTickerBuyCrypto
      method="buyCrypto"
      ListItemWrapper={TouchableWrapper}
    />
  );
});
