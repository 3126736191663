import * as React from 'react';
import {observer} from 'mobx-react';
import {useIsMobile, type BoxProps} from '@youtoken/ui.primitives';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {
  HODLChartSmartCell,
  ResizableBoxWithOverlay,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';
import {ScreenNameMH} from '@youtoken/ui.trading-view-charts';
import {GLOBAL} from '@youtoken/ui.service-global';

type ChartSectionProps = {
  instrumentId: string;
  chartHeight?: number;
  onPressTradingViewModeButton?: () => void;
};

export const ChartSection: React.FC<ChartSectionProps & BoxProps> = observer(
  ({instrumentId, onPressTradingViewModeButton, chartHeight}) => {
    const isMobile = useIsMobile();
    const height = chartHeight ? chartHeight : isMobile ? 220 : 500;
    const [
      {
        size: {width},
      },
      bind,
    ] = useResizableBox();

    const {getInstrumentById} = HODLsTariffsFeature.use({});
    const {baseTicker, quoteTicker, precision} =
      getInstrumentById(instrumentId)!;

    if (!baseTicker || !quoteTicker) {
      return null;
    }

    return (
      <ResizableBoxWithOverlay mt={15} height={height} width="100%" {...bind}>
        {Boolean(width && height) && (
          <HODLChartSmartCell
            width={width!}
            height={height!}
            fromTicker={baseTicker}
            toTicker={quoteTicker}
            mode="mid"
            screenName={ScreenNameMH.INSTRUMENT}
            precision={precision}
            product="hodl"
            onPressTradingViewModeButton={onPressTradingViewModeButton}
            defaultTimePeriod={!GLOBAL.isAuthorized ? '24h' : undefined}
          />
        )}
      </ResizableBoxWithOverlay>
    );
  }
);
