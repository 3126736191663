import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableOpacity} from 'react-native';
import {
  AllInstrumentsHeader,
  FiltersBar,
} from '@youtoken/ui.surface-hodl-instruments';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsTariffsFeature} from '@youtoken/ui.legacy-components';
import {Box, useActiveThemeType} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {TextInput} from '@youtoken/ui.inputs';
import {Icon} from '@youtoken/ui.icons';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const InstrumentsAll: React.FC = observer(() => {
  const themeType = useActiveThemeType();
  const {setInputVal} = HODLsTariffsFeature.use({});

  const handleInputChange = React.useCallback(
    (value: string) => {
      setInputVal(value);
    },
    [setInputVal]
  );

  const [isIconMode, setMode] = React.useState(true);

  const handleToggleMode = () => {
    if (isIconMode) {
      DATA_LAYER.trackStrict('hodl-instruments-search', {});
    }
    setInputVal('');
    setMode(prev => !prev);
  };

  const {t} = useTranslation();

  return (
    <Box pb={10}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pb={16}
        px={20}
      >
        {isIconMode && <AllInstrumentsHeader py={4} />}
        {isIconMode && (
          <TouchableOpacity onPress={handleToggleMode}>
            <Icon name="search" />
          </TouchableOpacity>
        )}
        {!isIconMode && (
          <Box flex={1}>
            <TextInput
              size="small"
              style={{
                width: '100%',
                flex: 1,
              }}
              keyboardAppearance={themeType}
              autoFocus
              underlineColorAndroid="transparent"
              onChangeText={handleInputChange}
              maxLength={50}
              autoCorrect={false}
              spellCheck={false}
              LeftPartComponent={
                <Box ml={14} mr={6} justifyContent="center" height="100%">
                  <Icon name="search" color="$text-05" />
                </Box>
              }
            />
          </Box>
        )}
        {!isIconMode && (
          <Button ml={16} type="ghost" size="tiny" onPress={handleToggleMode}>
            {t('surface.hodls.instruments.close_search')}
          </Button>
        )}
      </Box>

      <FiltersBar px={20} />
    </Box>
  );
});
