import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';
import {ChallengesService} from './ChallengesService';

export {type Challenge, type ChallengeResult} from './types';

export const CHALLENGES_SERVICE = __SERVICE_REGISTRY__.registerService(
  'CHALLENGES',
  ChallengesService,
  require('../package.json').version
);
