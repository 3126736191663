import * as React from 'react';
import {type PasscodeModalProps, PasscodeModal} from './PasscodeModal';
import {InvariantError} from '@youtoken/ui.errors';
import {CHALLENGES_SERVICE} from '@youtoken/ui.challenge-service';

type PasscodeRequestParams = {
  promptMessage: string;
};

export const __GLOBAL_PASSCODE__ = {
  requestPasscode: (params: PasscodeRequestParams) => {
    if (!CHALLENGES_SERVICE.refs.passcode.current?.requestPasscode) {
      return Promise.reject(
        new InvariantError('PasscodeContext not initialized')
      );
    }

    return CHALLENGES_SERVICE.refs.passcode.current?.requestPasscode(params);
  },
};

export const PasscodeContext =
  React.createContext<PasscodeModalProps>(__GLOBAL_PASSCODE__);

export const PasscodeContextProvider: React.FC = ({children}) => {
  return (
    <PasscodeContext.Provider value={__GLOBAL_PASSCODE__}>
      <PasscodeModal ref={CHALLENGES_SERVICE.refs.passcode} />
      {children}
    </PasscodeContext.Provider>
  );
};

export const usePasscode = () => {
  const context = React.useContext(PasscodeContext);

  if (!context) {
    throw new Error(
      'usePasscode must be used within a PasscodeContextProvider'
    );
  }

  return context;
};
