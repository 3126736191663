import * as React from 'react';
import {ScrollView} from 'react-native';
import {Amount} from './Amount';
import {Tickers} from './Tickers';

const SCROLL_VIEW_PADDING = 24;

export const Content: React.FC = () => {
  return (
    <ScrollView
      keyboardDismissMode="interactive"
      keyboardShouldPersistTaps="handled"
      style={{
        zIndex: 1,
        flex: 1,
      }}
      contentContainerStyle={{
        padding: SCROLL_VIEW_PADDING,
        gap: SCROLL_VIEW_PADDING,
      }}
    >
      <Amount />
      <Tickers mb={-SCROLL_VIEW_PADDING} />
    </ScrollView>
  );
};
