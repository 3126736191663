import * as React from 'react';
import Svg, {Path, Defs, LinearGradient, Stop} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SolIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
        {...props}
      >
        <Path
          fill="#000"
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16"
        />
        <Path
          fill="url(#a)"
          d="M8.899 20.096a.7.7 0 0 1 .486-.2h16.79c.306 0 .46.37.242.587l-3.316 3.316a.7.7 0 0 1-.487.201H5.826a.344.344 0 0 1-.244-.587L8.9 20.096z"
        />
        <Path
          fill="url(#b)"
          d="M8.899 7.714a.7.7 0 0 1 .486-.201h16.789c.307 0 .46.37.243.587l-3.316 3.316a.7.7 0 0 1-.487.201H5.825a.344.344 0 0 1-.243-.587z"
        />
        <Path
          fill="url(#c)"
          d="M23.101 13.865a.7.7 0 0 0-.487-.2H5.826c-.307 0-.46.37-.244.587L8.9 17.567a.7.7 0 0 0 .486.201h16.79c.306 0 .46-.37.242-.587l-3.316-3.317z"
        />
        <Defs>
          <LinearGradient
            id="a"
            x1={25}
            x2={8}
            y1={10.5}
            y2={29.5}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00FFA3" />
            <Stop offset={1} stopColor="#DC1FFF" />
          </LinearGradient>
          <LinearGradient
            id="b"
            x1={25}
            x2={3.5}
            y1={6.5}
            y2={21}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00FFA3" />
            <Stop offset={1} stopColor="#DC1FFF" />
          </LinearGradient>
          <LinearGradient
            id="c"
            x1={23.5}
            x2={10.395}
            y1={7}
            y2={26.452}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00FFA3" />
            <Stop offset={1} stopColor="#DC1FFF" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
