import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  AutoConvertDetailsTopBlock,
  AutoConvertDeleteConfirmationModal,
} from './components';
import {AutoConvertListFeature} from '../AutoConvertListFeature';

interface AutoConvertDetailsProps {
  id: string;
  onClose?: () => void;
  ticker?: string;
}

export const AutoConvertDetails: React.FC<AutoConvertDetailsProps> = cell(
  ({id, onClose, ticker}) => {
    const {getAutoConvertById} = AutoConvertListFeature.use({ticker});
    const item = getAutoConvertById(id);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

    const handleOpenModal = React.useCallback(() => {
      setIsDeleteModalOpen(true);
    }, []);

    const handleCloseModal = React.useCallback(() => {
      setIsDeleteModalOpen(false);
    }, []);

    const handleDelete = React.useCallback(() => {
      setIsDeleteModalOpen(false);
      onClose?.();
    }, []);

    const {t} = useTranslation();

    if (!item) {
      return null;
    }

    const {
      fromTickerFormatted,
      feeAmount,
      uiAdoptedTriggerRateFormatted,
      uiAdoptedBaseTickerFormatted,
      uiAdoptedQuoteTickerFormatted,
    } = item;

    return (
      <>
        <Box>
          <AutoConvertDetailsTopBlock item={item} />

          <Box alignItems="center" mt={{default: 40, tablet: 64}} mb={40}>
            <Text variant="$body-02" color="$text-02" mb={8}>
              {t('surface.auto_converts.desired_rate')}{' '}
              {t('surface.auto_convert_details.desired_rate', {
                amount: 1,
                fromTicker: uiAdoptedBaseTickerFormatted,
                rate: uiAdoptedTriggerRateFormatted,
                toTicker: uiAdoptedQuoteTickerFormatted,
              })}
            </Text>
            <Text variant="$body-02" color="$text-02">
              {t('surface.auto_convert_details.fee')}: {feeAmount}{' '}
              {fromTickerFormatted}
            </Text>
          </Box>

          <Button
            type="secondary"
            alignSelf="stretch"
            mx={24}
            mb={24}
            onPress={handleOpenModal}
            testID="DELETE_AUTOCONVERT_BUTTON"
          >
            {t('surface.auto_convert_details.cancel_button')}
          </Button>
        </Box>
        <AutoConvertDeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseModal}
          onDelete={handleDelete}
          id={item.id}
          ticker={ticker}
        />
      </>
    );
  }
);
