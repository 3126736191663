import * as React from 'react';
import {observer} from 'mobx-react';
import {Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLByIdResource} from '@youtoken/ui.resource-hodl';
import {formatWithSeparators} from '@youtoken/ui.formatting-utils';

type TpSlInitialPriceProps = {
  hodlId: string;
};

export const TpSlInitialPrice: React.FC<TpSlInitialPriceProps> = observer(
  ({hodlId}) => {
    const {t} = useTranslation();

    const {data} = HODLByIdResource.use({id: hodlId});

    const baseTickerFormatted = data.baseTicker.toUpperCase();
    const quoteTickerFormatted = data.quoteTicker.toUpperCase();
    const initialPriceFormatted = data.initialPrice
      ? formatWithSeparators(data.initialPrice, data.precision)
      : '';

    return (
      <Text variant="$body-02" color="$text-02" textAlign="center">
        {t('surface.hodls.adjust_tp.initial_price', {
          pair: `${baseTickerFormatted}`,
        })}{' '}
        <Text color="$text-01">{initialPriceFormatted}</Text>{' '}
        {quoteTickerFormatted}
      </Text>
    );
  }
);
