import * as React from 'react';
import {z} from 'zod';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {i18n} from '@youtoken/ui.service-i18n';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {UmaWelcomeForm} from '../../components';
import {UmaSettingsSurfaceContent} from './UmaSettingsSurfaceContent';
import {UmaSettingsSurfaceWrapper} from './Wrapper';

const handlePressCreate = () => {
  SHARED_ROUTER_SERVICE.navigate('UmaCreate');
};

export const UmaSettingsSurface = surface(
  {
    name: 'UmaSettings',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.wallets.uma'),
    headerShown: true,
  },
  () => {
    const {isUmaOpened} = UmaInfoResource.use({});

    React.useEffect(() => {
      const {
        data: {
          products: {
            uma: {
              available,
              settings: {enable},
            },
          },
        },
      } = AuthMeResource.getInstance({});

      if (!enable || !available) {
        SHARED_ROUTER_SERVICE.navigate('More', {});
      }
    }, []);

    return (
      <VariableLayout
        PrimaryContent={
          <UmaSettingsSurfaceWrapper>
            {isUmaOpened ? (
              <UmaSettingsSurfaceContent />
            ) : (
              <UmaWelcomeForm onPress={handlePressCreate} />
            )}
          </UmaSettingsSurfaceWrapper>
        }
      />
    );
  }
);
