import {computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {CardWithdrawalFormResources} from './types';
import {Form} from './Form';

export class View {
  @observable
  public instance: MobxReactForm;

  @observable
  public form: Form;

  @observable
  public resources: CardWithdrawalFormResources;

  public constructor(form: Form, resources: CardWithdrawalFormResources) {
    this.resources = resources;
    this.form = form;
    this.instance = form.instance;
  }

  @computed
  get tickerFormatted() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  get sumValueFormatted(): string {
    return this.form.sumField.value;
  }

  @computed
  get conversionTickerFormatted() {
    return this.form.conversionTicker?.toUpperCase();
  }

  @computed
  public get minAmountFormatted(): string {
    return (
      this.form.tickerFees?.minAmount.toFixedWithSeparators(
        this.form.tickerPrecision
      ) ?? ''
    );
  }

  @computed
  public get maxAmountFormatted(): string {
    return (
      this.form.tickerFees?.maxAmount.toFixedWithSeparators(
        this.form.tickerPrecision
      ) ?? ''
    );
  }

  @computed
  public get feeValueFormatted(): string {
    if (this.form.conversionTicker) {
      return this.form.feeValueInConversionTicker.toFixed(
        this.form.conversionTickerPrecision
      );
    }
    return this.form.feeValue.toFixed(this.form.tickerPrecision, 0);
  }

  @computed
  public get feeMaxValueFormatted(): string {
    if (!this.form.isMaxCommissionEstablished) {
      return '';
    }

    return this.form.tickerFees?.max.toString() ?? '';
  }

  @computed
  public get feeMinValueFormatted(): string {
    if (!this.form.isMinCommissionEstablished) {
      return '';
    }

    return this.form.tickerFees?.min.toString() ?? '';
  }

  @computed
  public get feePercentFormatted(): string {
    return formatPercent(this.form.feePercent);
  }

  @computed
  public get paySumFormatted(): string {
    if (this.form.conversionTicker) {
      if (this.form.sumInConversionTickerWithFee?.lte(0)) {
        return '~0.0';
      }

      return '~' + this.form.sumInConversionTickerWithFeeFormatted;
    }

    const paySum = this.form.sumValue.minus(this.feeValueFormatted);

    if (paySum.lte(0)) {
      return '0.0';
    }

    return paySum.toFixedWithSeparators(this.form.tickerPrecision, 0);
  }
}
