import {type Canceler} from 'axios';
import {debounce} from 'lodash';
import {
  action,
  autorun,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
} from 'mobx';
import {computedFn, now} from 'mobx-utils';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {normalizeAmount} from '@youtoken/ui.normalizers';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import Big from 'big.js';
import {calculateAll, calculateAllReverse} from '@youtoken/converts-calculator';
import {invariant} from '@youtoken/ui.utils';
import {RatesResource} from '@youtoken/ui.resource-rates';

export const CURRENT_RATE_UPDATE_INTERVAL = 15;

interface FormBaseArgs {
  ticker?: string;
  conversionTicker?: string;
}

export interface FormBaseResources {
  ratesResource: RatesResource;
  exchangeTariffs: any;
}

export abstract class FormBaseV2<
  Args extends FormBaseArgs,
  Resources extends FormBaseResources
> {
  //#region common

  @observable args: Args;
  @observable resources: Resources;
  @observable instance: MobxReactForm;
  @observable disposers: IReactionDisposer[] = [];
  @observable side: 'from' | 'to' = 'from';

  //#endregion common

  //#region swap

  @observable
  swap: boolean = false;

  abstract swapEnable: boolean;
  abstract swapOnPress: () => void;

  //#endregion swap

  //#region balance

  abstract balance: Big;
  abstract hasBalance: boolean;
  abstract balancePercent: number;
  abstract setAmountByBalance(): void;
  abstract setAmountByPercentOfBalance(percent: number): void;

  //#endregion balance

  //#region source

  abstract tickers: string[];

  @computed
  get tickerInitial() {
    const tickerArg = this.args.ticker ?? 'eur';

    if (this.tickers.includes(tickerArg)) {
      return tickerArg;
    }
    invariant(this.tickers.length > 0, 'tickers are required');

    return this.tickers[0];
  }

  @computed
  get ticker() {
    return this.instance?.$('ticker').get('value') ?? this.tickerInitial;
  }

  @computed
  get amount() {
    return this.instance?.$('amount').get('value');
  }

  @computed
  get amountWithoutFee() {
    if (!this.amount) {
      return toBig(0);
    }

    let res = toBig(this.amount).minus(this.fee);

    if (res.lt(0)) {
      return toBig(0);
    }

    return res;
  }

  @computed
  get sourceError() {
    return getTranslatedValidationMessage(
      this.instance?.$('ticker').get('error') ||
        this.instance?.$('amount').get('error')
    );
  }

  @computed
  get hasSourceError() {
    return Boolean(this.sourceError);
  }

  @action
  setTicker = (value: string) => {
    const onChange = this.instance.$('ticker').get('onChange');

    onChange(value);
  };

  @action
  setAmount = (value: string) => {
    const onChange = this.instance.$('amount').get('onChange');

    onChange(normalizeAmount(value));
  };

  getConversionAmount = computedFn((_amount: string) => {
    const amount = toBig(_amount);

    const {toAmount} = calculateAll(
      amount,
      this.tariff.fee,
      this.rate,
      this.ticker,
      this.conversionTicker
    );

    return toAmount;
  });

  getConversionAmountFormatted = computedFn(amount => {
    if (amount === '') {
      return '';
    }

    const conversionAmount = this.getConversionAmount(amount);

    return conversionAmount.gt(0)
      ? formatByTicker(conversionAmount, this.conversionTicker, 0, false)
      : '0';
  });

  //#endregion source

  //#region target

  abstract conversionTickers: string[];
  abstract conversionTickerPrecision: number;

  @computed
  get conversionTickerInitial() {
    if (
      this.args.conversionTicker &&
      this.conversionTickers.includes(this.args.conversionTicker)
    ) {
      return this.args.conversionTicker;
    }
    invariant(
      this.conversionTickers.length > 0,
      'convert tickers are required'
    );
    return this.conversionTickers[0];
  }

  @computed
  get conversionTicker() {
    return (
      this.instance?.$('conversionTicker').get('value') ??
      this.conversionTickerInitial
    );
  }

  @computed
  get conversionAmount() {
    return this.instance?.$('conversionAmount').get('value');
  }

  @computed
  get targetError() {
    return getTranslatedValidationMessage(
      this.instance?.$('conversionTicker').get('error') ||
        this.instance?.$('conversionAmount').get('error')
    );
  }

  @computed
  get hasTargetError() {
    return Boolean(this.targetError);
  }

  @action
  setConversionTicker = (value: string) => {
    const onChange = this.instance.$('conversionTicker').get('onChange');

    onChange(value);
  };

  abstract setConversionAmount(value: string): void;

  getAmount = computedFn(_requiredConversionAmount => {
    const conversionAmountRequired = toBig(_requiredConversionAmount);

    const {fromAmount} = calculateAllReverse(
      conversionAmountRequired,
      this.tariff.fee,
      this.rate,
      this.ticker
    );

    return fromAmount;
  });

  getAmountFormatted = computedFn(conversionAmount => {
    if (conversionAmount === '') {
      return '';
    }

    const amount = this.getAmount(conversionAmount);

    return amount.gt(0) ? amount.toString() : '0';
  });

  //#endregion target

  //#region fee

  @computed
  get tariff() {
    const tariff = this.resources.exchangeTariffs.getTariff(
      this.ticker,
      this.conversionTicker
    );

    invariant(
      tariff,
      'Tariff not found',
      {},
      {ticker: this.ticker, conversionTicker: this.conversionTicker}
    );

    return tariff;
  }

  @computed
  get tariffReverse() {
    return this.resources.exchangeTariffs.getTariff(
      this.conversionTicker,
      this.ticker
    );
  }

  abstract get fee(): Big;

  //#endregion fee

  //#region rate

  @observable
  rate: Big = toBig(1);

  @observable
  rateTimeLeft: number = CURRENT_RATE_UPDATE_INTERVAL;

  @observable
  rateTimeInterval: number = 0;

  @observable
  shouldTrackTime = true;

  abstract rateIsFixed: boolean;

  @computed
  get currentTime() {
    if (!this.shouldTrackTime) {
      return 0;
    }

    return now();
  }

  @computed
  get rateTimeIntervalName() {
    return `rate-interval-${this.rateTimeInterval}`;
  }

  @computed
  get rateTimeIntervalProgress() {
    return (
      (CURRENT_RATE_UPDATE_INTERVAL - this.rateTimeLeft) /
      (CURRENT_RATE_UPDATE_INTERVAL - 1)
    );
  }

  abstract updateRate(): void;

  //#endregion rate

  //#region check

  @observable
  checkIsLoading = false;

  @observable
  checkCanceller?: Canceler;

  @computed
  get checkRateShowError() {
    return Boolean(!this.hasSourceError && this.instance.$submitted);
  }

  @action
  cancelCheck = () => {
    this.checkIsLoading = true;
    this.checkCanceller?.('__CANCELLED_REQUEST__');
  };

  abstract check: () => Promise<any>;

  checkDebounced = debounce(() => this.check(), 300);

  //#endregion check

  abstract submit(): Promise<any>;

  protected getReactions(): IReactionDisposer[] {
    return [
      // set rateTimeLeft
      reaction(
        () => this.currentTime,
        () => {
          if (this.rateTimeLeft > 0) {
            this.rateTimeLeft = this.rateTimeLeft - 1;
          } else {
            this.updateRate();
          }
        }
      ),
      // set amount / conversionAmount after fields changed
      autorun(() => {
        if (this.side === 'from') {
          this.instance
            .$('conversionAmount')
            .set(this.getConversionAmountFormatted(this.amount));
        } else {
          this.instance
            .$('amount')
            .set(this.getAmountFormatted(this.conversionAmount));
        }
      }),
      // set conversionTicker after fields changed
      reaction(
        () => [this.ticker],
        () => {
          if (this.swap) {
            this.swap = false;
          } else {
            if (this.conversionTickers.includes(this.conversionTicker)) {
              return;
            }
            // NOTE: conversionTicker can come from args,
            // but this case for form continue working if tariff with current conversionTicker doesn't exist
            this.instance.$('conversionTicker').set(this.conversionTickers[0]);
          }
        }
      ),
    ];
  }

  protected getAdditionalReactions(): IReactionDisposer[] {
    return [];
  }

  protected setupReactions(): void {
    this.dispose();

    this.shouldTrackTime = true;
    this.disposers = [...this.getReactions(), ...this.getAdditionalReactions()];
  }

  constructor(args: Args, resources: Resources) {
    this.args = args;
    this.resources = resources;
  }

  @action
  dispose() {
    this.shouldTrackTime = false;
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  }
}
