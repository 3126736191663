import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const SkrillIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 22 14"
        {...props}
      >
        <Path
          fill={color}
          d="M21.798 6.998v.008c0 .074-.031.145-.086.198l-6.905 6.658a.14.14 0 0 1-.103.042h-.043a.15.15 0 0 1-.104-.042.14.14 0 0 1-.043-.1V7.007c0 .074-.03.145-.085.198L7.567 13.82a.3.3 0 0 1-.206.083h-.007a.3.3 0 0 1-.207-.083L.285 7.204A.28.28 0 0 1 .2 7.007v-.008c0-.075.03-.145.086-.198L7.147.182A.3.3 0 0 1 7.355.1h.007c.079 0 .152.03.206.083l6.863 6.616a.28.28 0 0 1 .085.199V.24c0-.077.066-.141.146-.141h.044q.059 0 .103.041L21.714 6.8a.28.28 0 0 1 .086.199z"
        />
      </Svg>
    );
  }
);
