import {observer} from 'mobx-react';
import * as React from 'react';
import {FeatureTurboCreateForm} from '../stores';
import {Alert} from '@youtoken/ui.elements';
import {Text} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';

export const Informer = observer(() => {
  const {t} = useTranslation();

  const {calculated} = FeatureTurboCreateForm.use({});

  return (
    <Alert type="attention" minHeight={52}>
      {calculated.data && (
        <Trans
          t={t}
          i18nKey="surface.loans.create_turbo_form.info"
          components={{
            Strong: <Text variant="$body-01-high-accent" />,
          }}
          values={{
            borrowedTail: calculated.data.borrowedTailFormatted,
            borrowedTicker: calculated.data.borrowedTicker.toUpperCase(),
          }}
        />
      )}
    </Alert>
  );
});
