import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {SecurityActivityResource} from '@youtoken/ui.state-security';
import {SecurityActivityList} from '../SecurityActivityList';

export const SecurityActivitySurfaceContent = observer(({}) => {
  const {t} = useTranslation();

  const {data, refetchManually} = SecurityActivityResource.use({});

  return (
    <Box flex={1} bg="$ui-background" pt={Platform.OS === 'web' ? 0 : 24}>
      <Text variant="$body-01" px={{default: 16, phone: 24, desktop: 0}}>
        {t('surface.security_activity.description')}
      </Text>
      <SecurityActivityList
        checkStatus={refetchManually}
        items={data}
        flex={1}
        mt={24}
      />
    </Box>
  );
});
