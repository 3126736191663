import * as React from 'react';
import {z} from 'zod';
import {surface} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {MinerSurfaceContent} from './Content';
import {MinerSurfaceTooltip} from '../../components/Tooltip';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const MinerSurface = surface(
  {
    name: 'MinerMain',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.menu.cloud_miner'),
    headerRight: () => <MinerSurfaceTooltip mr={12} />,
    headerShown: true,
  },
  () => {
    const {
      data: {enableMiner},
    } = AuthMeResource.use({});

    if (!enableMiner) {
      SHARED_ROUTER_SERVICE.navigate('NotFound', {});
    }

    return <MinerSurfaceContent />;
  }
);
