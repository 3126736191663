import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const AgixIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
        {...props}
      >
        <Path
          fill="#1D0D4B"
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16"
        />
        <Path
          fill="#fff"
          d="M15.012 5.55c-.774.774-1.505 1.59-1.892 2.536-.386.903-.515 1.891-.3 2.837.171.946.601 1.72 1.289 2.407s1.59 1.204 2.536 1.805c.945.602 2.02 1.247 2.88 2.235.86.989 1.418 2.321 1.461 3.568.086 1.246-.215 2.579-.903 3.61-.644 1.075-1.59 1.806-2.621 2.407a.28.28 0 0 1-.387-.086c-.086-.129-.043-.258.043-.386.816-.731 1.547-1.548 1.977-2.494.43-.945.559-1.934.344-2.922-.172-.989-.602-1.805-1.333-2.45-.688-.645-1.59-1.204-2.579-1.762-.945-.56-2.02-1.16-2.922-2.15-.43-.472-.86-1.031-1.118-1.676a6.1 6.1 0 0 1-.473-1.934c-.086-1.29.215-2.622.946-3.696.688-1.075 1.676-1.806 2.708-2.364.129-.086.3 0 .387.129.085.171.043.3-.043.386m2.32-.43a6.5 6.5 0 0 1 2.02 1.462q.453.45.774 1.032c.215.386.43.86.56 1.289.128.473.171.946.171 1.418 0 .473-.129 2.794-1.418 3.912-.301.258-.817.172-1.032-.172a.8.8 0 0 1-.043-.817v-.043c.344-.645 1.762-3.18.13-5.931-.43-.645-.947-1.204-1.505-1.763-.086-.086-.086-.257 0-.386.129-.043.258-.043.344 0zm-2.621 21.835a6.6 6.6 0 0 1-2.02-1.461 4 4 0 0 1-.774-1.075 7 7 0 0 1-.559-1.29 5.3 5.3 0 0 1-.172-1.418c0-.472.13-2.793 1.419-3.91.3-.259.773-.173 1.031.171a.8.8 0 0 1 .043.817l-.043.043c-.344.645-1.762 3.18-.129 5.931.43.645.946 1.204 1.505 1.762.085.086.085.258 0 .387-.086.086-.172.086-.301.043"
        />
      </Svg>
    );
  }
);
