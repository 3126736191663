import * as React from 'react';
import {Heading, Text, Box, type BoxProps} from '@youtoken/ui.primitives';
import {type currencyName, getCoinSymbol} from '@youtoken/ui.coin-utils';

export interface TotalInfoElementProps {
  text: string | JSX.Element;
  currency: currencyName;
  currencyAmount: string;
}

export const Total: React.FC<TotalInfoElementProps & BoxProps> = ({
  text,
  currency,
  currencyAmount,
  ...boxProps
}) => {
  const currencySign = getCoinSymbol(currency);

  return (
    <Box
      flex={1}
      flexShrink={0}
      testID="TOTAL_INVESTED_FIELD"
      borderWidth={{default: 0, tablet: 1}}
      borderRadius={12}
      borderColor="$ui-01"
      p={{default: 0, tablet: 16}}
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center" height={24}>
        {typeof text === 'string' ? (
          <Text
            variant="$body-02"
            color={{default: '$text-02', tablet: '$text-01'}}
            mr={4}
          >
            {text}
          </Text>
        ) : (
          <Box flexDirection="row" alignItems="center" mr={4}>
            {text}
          </Box>
        )}
      </Box>
      <Box zIndex={-1}>
        <Heading variant="$heading-01" testID="TOTAL_FUNDS">
          {currencySign}
          {currencyAmount}
        </Heading>
      </Box>
    </Box>
  );
};
