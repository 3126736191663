import * as React from 'react';
import {Icon} from '@youtoken/ui.icons';
import {IconName} from '@youtoken/ui.icons/src/Icon';
import {Box, Text} from '@youtoken/ui.primitives';

type ContentItem = {
  icon: IconName;
  title: string;
  text: string;
};

export const Item: React.FC<ContentItem> = ({icon, title, text}) => {
  return (
    <>
      <Box flexDirection="row" py={24}>
        <Box mr={8}>
          <Icon name={icon} size={20} color="$interactive-01" />
        </Box>

        <Box flex={1}>
          <Box mb={8}>
            <Text variant="$body-01-medium-accent" color="$text-05">
              {title}
            </Text>
          </Box>
          <Text variant="$body-02">{text}</Text>
        </Box>
      </Box>
    </>
  );
};
