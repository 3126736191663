import * as React from 'react';
import {observer} from 'mobx-react';
import {Profit, Total} from '@youtoken/ui.elements-smart';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsListFeature} from '@youtoken/ui.surface-hodl-positions';

export const HODLsInfo: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    mainCurrency,
    totalInvestedFormatted,
    currentProfitFormatted,
    currentProfitColor,
  } = HODLsListFeature.use({});

  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        gap={12}
        mt={8}
        {...boxProps}
      >
        <Total
          text={t('surface.hodls.header.currently_invested')}
          currency={mainCurrency}
          currencyAmount={totalInvestedFormatted}
        />
        <Profit
          text={t('surface.hodls.header.current_profit')}
          currency={mainCurrency}
          formattedResult={currentProfitFormatted}
          resultDirection={currentProfitColor}
        />
      </Box>
    </>
  );
});
