import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {
  getTranslatedValidationMessage,
  extractErrorFromResponse,
} from '@youtoken/ui.validation-messages';
import {LoanReopenResponse} from './LoanReopenResponse';
import {i18n} from '@youtoken/ui.service-i18n';
import {SENTRY} from '@youtoken/ui.sentry';

export type LoanReopenResourceArgs = {
  id: string;
};

export class LoanReopenResource extends createResource<
  LoanReopenResourceArgs,
  LoanReopenResponse
>({
  getKey: (args: LoanReopenResourceArgs) => {
    return `loanReopen(${args.id})`;
  },
  getData: (args: LoanReopenResourceArgs) => {
    return TRANSPORT.API.post(`/v2/loan/reopen/calculate`, {
      id: args.id,
    }).then(response => {
      return deserialize(LoanReopenResponse, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  reopen = () => {
    return TRANSPORT.API.post('/v2/loan/reopen', {
      id: this.args.id,
    }).catch(error => {
      LOCAL_NOTIFICATIONS.error({
        text:
          getTranslatedValidationMessage(
            extractErrorFromResponse(error.response?.data, '_error')
          ) || i18n.t('common.errors.smth_went_wrong'),
      });
      SENTRY.capture(error as Error, {
        source: 'LoanReopenResource',
      });

      throw error;
    });
  };
}
