import * as React from 'react';
import {Platform} from 'react-native';
import {Box, type BoxProps, Text, useTheme} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CheckboxField, Form, SubmitButton} from '@youtoken/ui.form-elements';
import {observer} from 'mobx-react';
import {FeatureDeleteAccountForm} from '../../state';
import {useTwoFactorAuth} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const Footer: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {spacing} = useTheme();
  const feature = FeatureDeleteAccountForm.use({});
  const {instance, submit, requestSent} = feature.form;
  const {requestTwoFactorAuth} = useTwoFactorAuth();

  const handleSubmit = () => {
    return instance
      .validate({showErrors: true})
      .then(({isValid}: {isValid: boolean}) => {
        if (isValid) {
          return submit().catch(error => {
            if (
              error?.request?.status === 403 &&
              error?.response?.data?.operationId
            ) {
              return requestTwoFactorAuth({
                operationId: error.response.data.operationId,
                twoFactorAuthType: error.response.data.twoFactorAuthType,
                phoneMask: error.response.data.phoneMask,
              }).then(() => {
                LOCAL_NOTIFICATIONS.info({
                  text: t('surface.profile.delete_account.request_sent'),
                });
                feature.resources.authMe.refetchSilently();
                feature.reset();
                SHARED_ROUTER_SERVICE.navigate('More', {});
              });
            } else {
              handleGeneralErrorTranslated(error.response?.data);
            }
          });
        }
      });
  };

  React.useEffect(() => {
    feature.reset();
  }, []);

  return (
    <Box
      alignItems="center"
      flexShrink={0}
      borderTopWidth={Platform.OS !== 'web' ? 1 : 0}
      borderColor="$ui-01"
      {...boxProps}
    >
      <Form form={feature} style={{width: '100%'}}>
        <CheckboxField
          name="agree"
          width="100%"
          py={spacing['$screen-default-offset']}
          testID="DELETE_ACCOUNT_CHECKBOX"
        >
          <Text variant="$body-02">
            {t('surface.profile.delete_account.have_checked')}
          </Text>
        </CheckboxField>

        <Box width="100%">
          <SubmitButton disabled={requestSent} onPress={handleSubmit}>
            {t('surface.profile.delete_account.send_request')}
          </SubmitButton>
        </Box>
      </Form>
    </Box>
  );
});
