import {computed, action} from 'mobx';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {
  NotificationsResource,
  Notification as NotificationSchema,
  type Notification,
} from '@youtoken/ui.resource-notifications';
import {deserialize} from 'serializr';

const personalIBANPromoBanner = {
  notificationId: 'personalIBANPromoBanner',
  typeId: 'IBAN',
  category: 'CAROUSEL',
  type: 'BANNER',
  texts: {
    body: 'notification.personal_iban.activate_promo.body',
    title: 'notification.personal_iban.activate_promo.title',
    button: '',
  },
  conditions: {
    canClose: false,
    url: '/wallets/eur/activate-personal-iban',
  },
  design: {
    kind: 'secondary',
    variant: 'interactive',
    iconName: 'ibanOpenAccount',
  },
  showed: false,
};

export class CarouselBannersFeature extends createFeature({
  getKey: () => `CarouselBannersFeature`,
  getResources: () => {
    return {
      IBANAccounts: getResourceDescriptor(IBANAccountsResource, {}),
      notifications: getResourceDescriptor(NotificationsResource, {}),
    };
  },
}) {
  @computed public get carousel() {
    const extraBanners: Notification[] = [];

    // NOTE: if we want more tickers, do it on BE side!
    if (this.resources.IBANAccounts.getIBANShouldBeActivatedByTicker('eur')) {
      extraBanners.push(
        deserialize(NotificationSchema, personalIBANPromoBanner)
      );
    }

    return [...extraBanners, ...this.resources.notifications.carousel];
  }

  @action
  public close = (id: string) => {
    this.resources.notifications.close(id);
  };
}
