import * as React from 'react';
import {Box, useIsDesktop} from '@youtoken/ui.primitives';
import {useSurfaceOptions} from '../../Surface';

type VariableLayoutProps = {
  doubleColumn?: boolean;
  PrimaryContent: React.ReactNode;
  SecondaryContent?: React.ReactNode;
};

// NOTE: New design
// export const VariableLayout: React.FC<VariableLayoutProps> = ({
//   doubleColumn = false,
//   PrimaryContent,
//   SecondaryContent,
// }) => {
//   const isDesktop = useIsDesktop();
//
//   const isSideBySide = doubleColumn && isDesktop;
//
//   const hasSecondaryContent = React.isValidElement(SecondaryContent);
//
//   const {
//     options: {headerShown},
//   } = useSurfaceOptions();
//
//   const isPrimaryTopRound = Boolean(headerShown || isDesktop);
//   const isPrimaryBottomRound = isSideBySide || !hasSecondaryContent;
//   const isSecondaryTopRound = isSideBySide || !hasSecondaryContent;
//   const isSecondaryBottomRound = Boolean(headerShown || isDesktop);
//
//   return (
//     <Box flex={1} justifyContent="flex-start" alignItems="center" pb={24}>
//       <Box
//         flex={1}
//         width={{
//           default: '100%',
//           desktop: doubleColumn ? '100%' : 748,
//         }}
//         maxWidth={doubleColumn ? 1440 : undefined}
//       >
//         <Box
//           flexDirection={isSideBySide ? 'row' : 'column'}
//           alignItems={isSideBySide ? 'flex-start' : 'stretch'}
//           gap={isSideBySide ? 24 : 0}
//         >
//           <Box
//             backgroundColor="$ui-background"
//             flex={1}
//             borderTopLeftRadius={isPrimaryTopRound ? 16 : 0}
//             borderTopRightRadius={isPrimaryTopRound ? 16 : 0}
//             borderBottomLeftRadius={isPrimaryBottomRound ? 16 : 0}
//             borderBottomRightRadius={isPrimaryBottomRound ? 16 : 0}
//           >
//             {PrimaryContent}
//           </Box>
//
//           {hasSecondaryContent && (
//             <Box
//               backgroundColor="$ui-background"
//               width={isSideBySide ? 420 : undefined}
//               borderTopLeftRadius={isSecondaryTopRound ? 16 : 0}
//               borderTopRightRadius={isSecondaryTopRound ? 16 : 0}
//               borderBottomLeftRadius={isSecondaryBottomRound ? 16 : 0}
//               borderBottomRightRadius={isSecondaryBottomRound ? 16 : 0}
//             >
//               {SecondaryContent}
//             </Box>
//           )}
//         </Box>
//       </Box>
//     </Box>
//   );
// };

export const VariableLayout: React.FC<VariableLayoutProps> = ({
  PrimaryContent,
}) => {
  return <>{PrimaryContent}</>;
};
