import * as React from 'react';
import {observer} from 'mobx-react';
import {useWizard} from '@youtoken/ui.elements';
import {
  type WalletsChooseTickerType,
  WithdrawalMethods,
} from '@youtoken/ui.surfaces-wallets';
import type {WalletsChooseTickerMethod} from '../../../components';
import {
  type WithdrawalWizardNavigator,
  WithdrawalWizardScreenName,
} from '../index';

export const WithdrawalWizardChooseMethodBase: React.FC = observer(() => {
  const {
    step: {
      props: {ticker, variant},
    },
    navigate,
  } = useWizard<
    WithdrawalWizardNavigator,
    WithdrawalWizardScreenName.CHOOSE_METHOD
  >();

  const handleChangeMethod = React.useCallback(
    (method: WithdrawalWizardScreenName) => {
      if (ticker) {
        navigate(method, {ticker});
      } else {
        let type: WalletsChooseTickerType;

        switch (method) {
          case WithdrawalWizardScreenName.BANK_WIRE:
            type = 'fiat';
            break;
          case WithdrawalWizardScreenName.CRYPTO_DIRECT:
            type = 'cryptoAndStable';
            break;
          case WithdrawalWizardScreenName.UMA_WITHDRAWAL:
            type = 'all';
            break;
          default:
            type = 'fiat';
        }

        navigate(WithdrawalWizardScreenName.CHOOSE_TICKER, {
          onPress: selectedTicker => navigate(method, {ticker: selectedTicker}),
          type: type,
          method: method as WalletsChooseTickerMethod,
        });
      }
    },
    [navigate, ticker]
  );

  return (
    <>
      <WithdrawalMethods
        ticker={ticker}
        variant={!ticker ? 'all' : variant}
        onChange={handleChangeMethod}
      />
    </>
  );
});
