import {
  action,
  autorun,
  computed,
  type IReactionDisposer,
  observable,
} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import type {
  LoanRepayOwnFundsFormArgs,
  LoanRepayOwnFundsFormResources,
} from '../../types';
import {
  formatByTickerLoan,
  formatWithSeparators,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';

export class Form {
  @observable
  public args: LoanRepayOwnFundsFormArgs;

  @observable
  public resources: LoanRepayOwnFundsFormResources;

  @observable
  public instance: MobxReactForm;

  @observable
  public disposers: IReactionDisposer[] = [];

  @computed.struct
  public get submitting() {
    return this.instance.submitting;
  }

  @computed.struct
  public get errors() {
    return this.instance.errors();
  }

  @computed.struct
  public get wallets() {
    return this.resources.walletsResource;
  }

  @computed.struct
  public get wallet() {
    return this.resources.walletsResource.getByTicker(this.ticker);
  }

  @computed.struct
  public get loan() {
    return this.resources.loanResource.data;
  }

  @computed
  public get method() {
    return 'deposit';
  }

  @computed
  public get provider() {
    return 'self';
  }

  @computed
  public get loanBorrowedTicker() {
    return this.loan.borrowedTicker;
  }

  @computed
  public get loanOverdraftAmount() {
    return formatByTickerLoan(
      this.loan.overdraftAmount,
      this.loanBorrowedTicker,
      true
    );
  }

  @computed
  public get loanOverdraftAmountFormatted() {
    return formatWithSeparators(this.loanOverdraftAmount);
  }

  @observable
  public priceFrom = 'conversionTicker';

  @action
  public setPriceFromTicker = () => {
    this.priceFrom = 'ticker';
  };

  @action
  public setPriceFromConversionTicker = () => {
    this.priceFrom = 'conversionTicker';
  };

  @computed
  public get price() {
    return this.resources.ratesResource.getExchangeRate(
      this.priceFrom === 'ticker' ? this.ticker : this.conversionTicker,
      this.priceFrom === 'ticker' ? this.conversionTicker : this.ticker
    );
  }

  @computed.struct
  public get tickerItems() {
    if (this.loanBorrowedTicker) {
      const tickersStableAndFiatWithoutCustom = [
        ...this.resources.walletsResource.stableTickers,
        ...this.resources.walletsResource.fiatTickers.filter(
          (ticker: string) => !['yusd', 'rub'].includes(ticker)
        ),
      ];

      const tickers = tickersStableAndFiatWithoutCustom.includes(
        this.loanBorrowedTicker
      )
        ? tickersStableAndFiatWithoutCustom
        : [this.loanBorrowedTicker];

      return this.resources.walletsResource.walletsListWithAmountOrder
        .filter(wallet => {
          return tickers.includes(wallet.ticker);
        })
        .map(wallet => {
          return {
            ticker: wallet.ticker,
            tickerFormatted: wallet.ticker.toUpperCase(),
            coinName: wallet.tickerName,
            hasAmount: true,
            amountFormatted: normalizeAmountByTicker(
              wallet.amount.toString(),
              wallet.ticker
            ),
            key: `${wallet.ticker} ${wallet.tickerName}`,
          };
        });
    }

    return [];
  }

  // source start

  @computed
  public get ticker() {
    return this.instance?.$('ticker').get('value') ?? this.loanBorrowedTicker;
  }

  @computed
  public get amount() {
    return this.instance.$('amount').get('value');
  }

  @computed
  public get sourceError() {
    return getTranslatedValidationMessage(
      this.instance.$('ticker').get('error') ??
        this.instance.$('amount').get('error')
    );
  }

  @computed
  public get sourceHasError() {
    return Boolean(this.sourceError);
  }

  @computed
  public get tickerOnChange() {
    const onChange = this.instance.$('ticker').get('onChange');

    return (value: string) => {
      if (value !== this.ticker) {
        onChange(value);
      }
    };
  }

  @computed
  public get amountOnChange() {
    const onChange = this.instance.$('amount').get('onChange');

    return (value: string) => {
      onChange(normalizeAmountByTicker(value, this.ticker));
    };
  }

  // source end

  // target start

  @computed
  public get conversionTicker() {
    return (
      this.instance?.$('conversionTicker').get('value') ??
      this.loanBorrowedTicker
    );
  }

  @computed
  public get conversionAmount() {
    return this.instance.$('conversionAmount').get('value');
  }

  @computed
  public get targetError() {
    return getTranslatedValidationMessage(
      this.instance.$('conversionTicker').get('error') ??
        this.instance.$('conversionAmount').get('error')
    );
  }

  @computed
  public get targetHasError() {
    return Boolean(this.targetError);
  }

  @computed
  public get conversionAmountOnChange() {
    const onChange = this.instance.$('conversionAmount').get('onChange');

    return (value: string) => {
      onChange(normalizeAmountByTicker(value, this.conversionTicker));
    };
  }

  // target end

  @computed
  public get fee() {
    return this.resources.feeAllResource.calculateFee(
      this.method,
      this.provider,
      this.ticker,
      this.conversionTicker,
      this.conversionAmount
    );
  }

  @action
  public submit = () => {
    return TRANSPORT.API.post(`/v1/loan/payment`, {
      provider: this.provider,
      loanId: this.loan.id,
      ticker: this.ticker,
      conversionTicker: this.conversionTicker,
      amount: Number(this.amount),
      conversionAmount: Number(this.conversionAmount),
      priceFrom: this.priceFrom,
      price: Number(this.price),
    })
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.loans.loan_pay_own_funds_form.message.repaid'),
        });
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
        SHARED_ROUTER_SERVICE.navigate(
          this.args.mode === 'regular' ? 'Loans' : 'Turbocharge',
          {}
        );
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e);
      });
  };

  public constructor(
    args: LoanRepayOwnFundsFormArgs,
    resources: LoanRepayOwnFundsFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.ticker,
            handlers: {
              onChange: () => {
                this.setPriceFromConversionTicker();
              },
            },
          },
          conversionTicker: {
            value: this.conversionTicker,
          },
          amount: {
            value: '',
            handlers: {
              onChange: () => {
                this.setPriceFromTicker();
              },
            },
          },
          conversionAmount: {
            value: '',
            handlers: {
              onChange: () => {
                this.setPriceFromConversionTicker();
              },
            },
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
          onError: () => {
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('validation.VALIDATION'),
            });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                let amountSchema = yup.big().gt(0);
                if (this.ticker === this.conversionTicker) {
                  amountSchema = amountSchema.lte(this.loanOverdraftAmount);
                }
                if (this.wallet) {
                  amountSchema = amountSchema.lte(
                    this.wallet.amount,
                    messages.FUNDS_INSUFFICIENT
                  );
                }

                let conversionAmountSchema = yup
                  .big()
                  .gt(0)
                  .lte(this.loanOverdraftAmount);

                const schema = {
                  ticker: yup.string(),
                  conversionTicker: yup.string(),
                  amount: amountSchema,
                  conversionAmount: conversionAmountSchema,
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
    this.disposers = [
      autorun(() => {
        this.instance
          .$(this.priceFrom === 'ticker' ? 'conversionAmount' : 'amount')
          .set(
            'value',
            formatByTickerLoan(
              toBig(
                this.priceFrom === 'ticker'
                  ? this.amount
                  : this.conversionAmount
              ).mul(toBig(this.price)),
              this.priceFrom === 'ticker' ? this.conversionTicker : this.ticker,
              false
            )
          );
      }),
    ];
  }

  @action
  public dispose = () => {
    this.disposers.forEach(disposer => disposer?.());
  };
}
