import {
  AccountLevelAllBenefitsModal,
  AccountLevelProgressDetailedModal,
  UpgradeToLevelModal,
} from '@youtoken/ui.surface-account-levels';
import {
  MinerGetMoreSparksModal,
  MinerResetBlocksModal,
} from '@youtoken/ui.surface-miner';
import {
  BundleDepositModal,
  BundleReleaseModal,
  BundleWithdrawalModal,
} from '@youtoken/ui.surface-bundles';
import {ModalCloseNow} from './CloseNow';
import {ModalCommissions} from './Commissions';
import {ModalConversion} from './Conversion';
import {ModalCryptoBuy} from './CryptoBuy';
import {ModalCryptoDeposit} from './CryptoDeposit';
import {ModalDecreaseLtv} from './DecreaseLtv';
import {ModalDepositWizard} from './DepositWizard';
import {ModalFiatDeposit} from './FiatDeposit';
import {ModalWithdrawalWizard} from './WithdrawalWizard';
import {ModalHodlCloseNow} from './HodlCloseNow';
import {
  ModalHodlsAdjustTpSl,
  modalHodlsAdjustTpSlName,
} from './HodlsAdjustTpSl';
import {ModalIncreaseLtv} from './IncreaseLtv';
import {ModalLoanPay} from './LoanPay';
import {ModalLoanReopen} from './LoanReopen';
import {ModalSetClosePrice} from './SetClosePrice';
import {
  ModalTwoFactorAuthenticationGAEnable,
  ModalTwoFactorAuthenticationDisable,
} from './TwoFactorAuthentication';
import {ModalFormA} from './FormA';
import {ModalFirstLogin} from './FirstLogin';
import {ModalDeviceDetails} from './DeviceDetails';
import {ModalSessionDetails} from './SessionDetails';
import {ModalDisclosureWallets} from './DisclosureWallets';
import {ModalCoindrop} from './Coindrop';
import {ModalDisclosureHodls} from './DisclosureHodls';
import {ModalDisclosureLoans} from './DisclosureLoans';
import {ModalDisclosureTurbo} from './DisclosureTurbo';
import {ModalRewardDetails} from './RewardDetails';
import {WalletsSavingsSelect} from './WalletsSavingsSelect';
import {ModalAddWallets, modalAddWalletsName} from './AddWallets';
import {
  ModalBankCardAddMoney,
  modalBankCardAddMoneyName,
} from './BankCardAddMoney';
import {
  ModalBankCardTransfer,
  modalBankCardTransferName,
} from './BankCardTransfer';
import {
  ModalBankCardTransactions,
  modalBankCardTransactionsName,
} from './BankCardTransactions';
import {ModalAcquisitionSurvey} from './AcquisitionSurvey';
import {ModalReSignDisclosures} from './ReSignDisclosures';
import {ModalHodlExtendTpSl} from './HodlExtendTpSl';
import {ModalHodlHistory, modalHodlHistoryName} from './HodlHistory';
import {
  ModalSignUpAdditional,
  modalSignUpAdditionalName,
} from './SignUpAdditional';
import {ModalHodlFlip} from './HodlFlip';
import {
  ModalUpgradeYourLevel,
  modalUpgradeYourLevelName,
} from './UpgradeYourLevel';
import {
  modalIncentivesDetailsName,
  ModalIncentivesDetails,
} from './IncentivesDetails';
import {FirstAutoConvertModal} from './FirstAutoConvert';

export {
  modalAddWalletsName,
  modalBankCardAddMoneyName,
  modalBankCardTransactionsName,
  modalBankCardTransferName,
  modalSignUpAdditionalName,
  modalUpgradeYourLevelName,
  modalIncentivesDetailsName,
};

export const modals = {
  closeNow: ModalCloseNow,
  commissions: ModalCommissions, //+
  conversion: ModalConversion, //+
  firstAutoConvert: FirstAutoConvertModal, //+
  cryptoBuy: ModalCryptoBuy, //+
  cryptoDeposit: ModalCryptoDeposit, //+
  decreaseLtv: ModalDecreaseLtv,
  loanReopen: ModalLoanReopen,
  depositWizard: ModalDepositWizard,
  fiatDeposit: ModalFiatDeposit, //+
  withdrawalWizard: ModalWithdrawalWizard, //+
  hodlClose: ModalHodlCloseNow, //+
  [modalHodlsAdjustTpSlName]: ModalHodlsAdjustTpSl, //+
  hodlExtendTpSl: ModalHodlExtendTpSl,
  increaseLtv: ModalIncreaseLtv,
  loanPay: ModalLoanPay,
  disclosureWallets: ModalDisclosureWallets,
  coindrop: ModalCoindrop,
  disclosureHodls: ModalDisclosureHodls,
  disclosureLoans: ModalDisclosureLoans,
  disclosureTurbo: ModalDisclosureTurbo,
  setClosePrice: ModalSetClosePrice,
  twoFactorAuthenticationGAEnable: ModalTwoFactorAuthenticationGAEnable, //+, при разлогине нужно чистить mobx store
  twoFactorAuthenticationGADisable: ModalTwoFactorAuthenticationDisable, //+, при разлогине нужно чистить mobx store
  twoFactorAuthenticationSMSDisable: ModalTwoFactorAuthenticationDisable, //+, при разлогине нужно чистить mobx store
  formA: ModalFormA,
  firstLogin: ModalFirstLogin,
  deviceDetails: ModalDeviceDetails,
  sessionDetails: ModalSessionDetails,
  rewardDetails: ModalRewardDetails,
  walletsSavingsSelect: WalletsSavingsSelect,
  acquisitionSurvey: ModalAcquisitionSurvey,
  hodlFlip: ModalHodlFlip,
  [modalHodlHistoryName]: ModalHodlHistory,
  [modalAddWalletsName]: ModalAddWallets,
  [modalBankCardAddMoneyName]: ModalBankCardAddMoney,
  [modalBankCardTransactionsName]: ModalBankCardTransactions,
  [modalBankCardTransferName]: ModalBankCardTransfer,
  reSignDisclosures: ModalReSignDisclosures,
  [modalSignUpAdditionalName]: ModalSignUpAdditional,
  [modalUpgradeYourLevelName]: ModalUpgradeYourLevel,
  // SHARED_ROUTER_ROUTE: MODAL
  UpgradeToLevel: UpgradeToLevelModal,
  AccountLevelAllBenefits: AccountLevelAllBenefitsModal,
  AccountLevelProgressDetailed: AccountLevelProgressDetailedModal,
  MinerGetMoreSparks: MinerGetMoreSparksModal,
  MinerResetBlocks: MinerResetBlocksModal,
  [modalIncentivesDetailsName]: ModalIncentivesDetails,
  BundleDeposit: BundleDepositModal,
  BundleRelease: BundleReleaseModal,
  BundleWithdrawal: BundleWithdrawalModal,
};
