import {computed, action} from 'mobx';
import {format} from '@youtoken/ui.date-fns';
import {i18n} from '@youtoken/ui.service-i18n';
import {createResource} from '@youtoken/ui.data-storage';
import {ChartResource} from './types';
import {getBundleLineData} from './utils';
import {BundleLineLiveData} from './LiveData';

const timePeriodLabels: Record<ChartResource.TimePeriod, () => string> = {
  '1h': () => i18n.t('common.period.last_hour', {count: 1}),
  '24h': () => i18n.t('common.period.last_hour', {count: 24}),
  '7d': () => i18n.t('common.period.last_day', {count: 7}),
  '30d': () => i18n.t('common.period.last_day', {count: 30}),
  '90d': () => i18n.t('common.period.last_day', {count: 90}),
  '1y': () => i18n.t('common.period.last_month', {count: 12}),
};

const chartDataPeriodDateFormat = 'dd.MM.yyyy';

export class BundleChartDataResource extends createResource<
  ChartResource.BundleArgs,
  ChartResource.Data
>({
  skipRefreshOnVisible: false,
  getKey: ({type, source, sourceId}) => {
    return `pieChartData:${type}:${source}:${sourceId}`;
  },
  getData: args => getBundleLineData(args),
  cacheTime: 60 * 1000,
  refetchInterval: 60 * 1000,
  defaultArgs: {
    type: 'line',
    timePeriod: '1y',
  },
}) {
  lineLiveData!: BundleLineLiveData;

  @computed get type(): ChartResource.Type {
    return this.args.type!;
  }

  @computed get timePeriod() {
    return this.args.timePeriod!;
  }

  @computed get timePeriodFormatted() {
    return timePeriodLabels[this.timePeriod]?.();
  }

  @action setTimePeriod = (timePeriod: ChartResource.TimePeriod) => {
    this.args.timePeriod = timePeriod;
  };

  @computed get chartData(): ChartResource.Data {
    return this.lineLiveData.data;
  }

  @computed get chartDataPoints() {
    return this.lineLiveData.data.data;
  }

  @computed get chartDataPeriodFormatted() {
    const from = format(
      this.chartDataPoints[0]!.date,
      chartDataPeriodDateFormat
    );
    const to = format(
      this.chartDataPoints[this.chartDataPoints.length - 1]!.date,
      chartDataPeriodDateFormat
    );

    return `${from} — ${to}`;
  }

  @computed get hasData() {
    return this.data.data.length >= 2;
  }

  @computed get noData() {
    return !this.hasData;
  }

  @computed get chartControls() {
    return {
      type: this.type,
      timePeriod: this.timePeriod,
      setTimePeriod: this.setTimePeriod,
    };
  }

  constructor(args: ChartResource.BundleArgs, data: ChartResource.Data) {
    super(args, data);

    this.lineLiveData = new BundleLineLiveData(this);
  }

  onDestroy() {
    super.onDestroy();
  }
}
