import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {MainContent, Footer} from './elements';

// cell for ramp?
export const DeleteAccountSurfaceContent: React.FC = cell(() => {
  return (
    <Box px={{default: 24, desktop: 0}}>
      <MainContent />
      <Footer />
    </Box>
  );
});
