import * as React from 'react';
import {createWizard} from '@youtoken/ui.elements';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CryptoDirectWithdrawalWizardWrapper} from './CryptoDirectWithdrawalWizardWrapper';
import {BankCardWithdrawalWizardWrapper} from './BankCardWithdrawalWizardWrapper';
import {WithdrawalWizardChooseMethod} from './ChooseMethod';
import {
  WithdrawalWizardBankWire,
  getWithdrawalWizardBankWireTitle,
} from './BankWire';
import {
  UmaCreateCell,
  getUmaCreateTitle,
  UmaInvoiceConfirmationCell,
  getUmaInvoiceConfirmationTitle,
  UmaWithdrawalCell,
  getUmaWithdrawalTitle,
} from '../../Uma';
import {WithdrawalWizardChooseTicker} from './ChooseTicker';
import type {
  WalletsChooseTickerType,
  WalletsChooseTickerMethod,
  WithdrawalMethodsProps,
} from '../../components';

export enum WithdrawalWizardScreenName {
  CHOOSE_METHOD = 'chooseMethod',
  CHOOSE_TICKER = 'chooseTicker',
  BANK_WIRE = 'withdrawBankWire',
  BANK_CARD = 'withdrawBankCard',
  UMA_CREATE = 'createUma',
  UMA_WITHDRAWAL = 'withdrawUma',
  UMA_INVOICE_CONFIRMATION = 'umaInvoiceConfirmation',
  CRYPTO_DIRECT = 'withdrawCryptoDirect',
}

export type WithdrawalWizardPropsVariant = WithdrawalMethodsProps['variant'];

export interface WithdrawalWizardNavigator {
  [WithdrawalWizardScreenName.CHOOSE_METHOD]: {
    ticker?: string;
    variant: WithdrawalWizardPropsVariant;
  };
  [WithdrawalWizardScreenName.CHOOSE_TICKER]: {
    onPress: (ticker: string) => void;
    type: WalletsChooseTickerType;
    method: WalletsChooseTickerMethod;
  };
  [WithdrawalWizardScreenName.BANK_WIRE]: {
    ticker: string;
  };
  [WithdrawalWizardScreenName.BANK_CARD]: {
    ticker: string;
    conversionTicker?: string;
    cardId?: string;
    amount?: string;
    withError?: string;
  };
  [WithdrawalWizardScreenName.UMA_CREATE]: {};
  [WithdrawalWizardScreenName.UMA_WITHDRAWAL]: {
    ticker: string;
    uma?: string;
    amount?: string;
    fromTicker?: string;
  };
  [WithdrawalWizardScreenName.UMA_INVOICE_CONFIRMATION]: {
    uma: string;
    ticker: string;
    toTicker: string;
    amount: string;
    invoiceRequestId: string;
    requestUuid: string;
  };
  [WithdrawalWizardScreenName.CRYPTO_DIRECT]: {
    ticker: string;
    version?: string;
  };
}

const Wizard = createWizard<WithdrawalWizardNavigator>();

export interface WithdrawalWizardProps {
  variant: WithdrawalWizardPropsVariant;
  ticker?: string;
  conversionTicker?: string;
  cardId?: string;
  amount?: string;
  withError?: string;
  defaultStep?: WithdrawalWizardScreenName;
  onPressBack?: () => void;
}

export interface WithdrawalWizardWrappedProps {
  route: {
    params: Omit<WithdrawalWizardProps, 'onPressBack'>;
  };
  navigation: {
    goBack: () => void;
  };
}

export const WithdrawalWizard: React.FC<WithdrawalWizardProps> = cell(
  ({
    variant,
    ticker,
    conversionTicker,
    cardId,
    amount,
    withError,
    defaultStep = WithdrawalWizardScreenName.CHOOSE_METHOD,
    onPressBack,
  }) => {
    const {t} = useTranslation();
    const {isUmaOpened} = UmaInfoResource.use({
      shouldFetchWithSuppressErrors: true,
    });

    return (
      <Wizard.Navigator
        initialName={defaultStep}
        initialProps={{
          ticker,
          variant,
          conversionTicker,
          cardId,
          amount,
          withError,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={WithdrawalWizardScreenName.CHOOSE_METHOD}
          options={({route: {params}}: any) => ({
            headerShown: true,
            title: params.ticker
              ? t('surface.wallets.fiat_withdrawal.title', {
                  ticker: params.ticker.toUpperCase(),
                })
              : t('surface.wallets.withdrawal_wizard.select_method'),
          })}
          component={WithdrawalWizardChooseMethod}
        />
        <Wizard.Screen
          name={WithdrawalWizardScreenName.CHOOSE_TICKER}
          options={{
            headerShown: true,
            title: t('surface.more.currency.title'),
          }}
          component={WithdrawalWizardChooseTicker}
        />
        <Wizard.Screen
          name={WithdrawalWizardScreenName.BANK_WIRE}
          options={({route: {params}}: any) => ({
            headerShown: true,
            title: getWithdrawalWizardBankWireTitle(params.ticker),
          })}
          component={WithdrawalWizardBankWire}
        />
        <Wizard.Screen
          name={WithdrawalWizardScreenName.UMA_CREATE}
          options={{
            headerShown: true,
            title: getUmaCreateTitle(),
          }}
          component={UmaCreateCell}
        />
        <Wizard.Screen
          name={WithdrawalWizardScreenName.UMA_WITHDRAWAL}
          options={({route: {params}}: any) => ({
            headerShown: true,
            title: getUmaWithdrawalTitle(params.ticker, isUmaOpened),
          })}
          component={UmaWithdrawalCell}
        />
        <Wizard.Screen
          name={WithdrawalWizardScreenName.UMA_INVOICE_CONFIRMATION}
          options={({route: {params}}: any) => ({
            headerShown: true,
            title: getUmaInvoiceConfirmationTitle(params.ticker),
          })}
          component={UmaInvoiceConfirmationCell}
        />
        <Wizard.Screen
          name={WithdrawalWizardScreenName.BANK_CARD}
          options={{headerShown: false}}
          component={BankCardWithdrawalWizardWrapper}
        />
        <Wizard.Screen
          name={WithdrawalWizardScreenName.CRYPTO_DIRECT}
          options={{headerShown: false}}
          component={CryptoDirectWithdrawalWizardWrapper}
        />
      </Wizard.Navigator>
    );
  }
);

export const WithdrawalWizardWrapped = ({
  route: {
    params: {ticker, variant, defaultStep},
  },
  navigation: {goBack},
}: WithdrawalWizardWrappedProps) => {
  return (
    <WithdrawalWizard
      variant={variant}
      ticker={ticker}
      defaultStep={defaultStep}
      onPressBack={goBack}
    />
  );
};
