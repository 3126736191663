import * as React from 'react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {
  ModalContentSmart,
  type ModalContentSmartProps,
} from './ModalContentSmart';

interface SectorsModalProps extends ModalContentSmartProps {
  isOpen: boolean;
}

export const SectorsModal: React.FC<SectorsModalProps> = React.memo(
  ({isOpen, onClose, onHelpIconPress}) => {
    return (
      <ModalDynamic
        shouldShowHeaderSeparator={false}
        isOpen={isOpen}
        onClose={onClose}
        closeIconColor="$text-04"
        borderRadius={14}
        webHeight={588}
        overflow="hidden"
      >
        <ModalContentSmart
          onClose={onClose}
          onHelpIconPress={onHelpIconPress}
        />
      </ModalDynamic>
    );
  }
);
