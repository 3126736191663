import React from 'react';
import {modal} from '../../../stores';
import {Modal} from '../_common';
import type {
  WithdrawalWizardPropsVariant,
  WithdrawalWizardScreenName,
} from '@youtoken/ui.surfaces-wallets';
import {WithBoxProps} from '@youtoken/ui.primitives';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';

interface ModalWithdrawalWizardProps
  extends React.ComponentProps<typeof Modal> {
  variant: WithdrawalWizardPropsVariant;
  ticker?: string;
  conversionTicker?: string;
  cardId?: string;
  amount?: string;
  withError?: string;
  defaultStep?: WithdrawalWizardScreenName;
  onPressBack?: () => void;
}

const CryptoWithdrawalByLedger = React.lazy(() =>
  import(
    /* webpackChunkName: "crypto-withdrawal-by-ledger"*/ '@youtoken/ui.surfaces-wallets/src/CryptoWithdrawalLedger'
  ).then(result => {
    return {default: result.CryptoWithdrawalLedger};
  })
);

const WithdrawalWizard = React.lazy(() =>
  import(
    /* webpackChunkName: "crypto-withdrawal-by-address"*/ '@youtoken/ui.surfaces-wallets/src/smart/WithdrawalWizard'
  ).then(result => {
    return {default: result.WithdrawalWizard};
  })
);

const WithdrawalWizardWrapped: React.FC<
  WithBoxProps<ModalWithdrawalWizardProps>
> = props => {
  if (
    props.ticker &&
    props.variant === 'crypto' &&
    ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']
  ) {
    return <CryptoWithdrawalByLedger ticker={props.ticker} {...props} />;
  }

  return <WithdrawalWizard {...props} />;
};

export const ModalWithdrawalWizard: React.FC<ModalWithdrawalWizardProps> = ({
  variant,
  ticker,
  conversionTicker,
  cardId,
  amount,
  withError,
  defaultStep,
  ...otherProps
}) => {
  return (
    <Modal minHeight={140} {...otherProps}>
      <WithdrawalWizardWrapped
        variant={variant}
        ticker={ticker}
        conversionTicker={conversionTicker}
        cardId={cardId}
        amount={amount}
        withError={withError}
        defaultStep={defaultStep}
        onPressBack={modal.close}
      />
    </Modal>
  );
};
