import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {Error, Label, useForm} from '../../../../../components';
import {FeatureLoanIncreaseForm} from '../../state';
import {Ticker as TickerField} from './Ticker';

export const Ticker: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    ticker,
    loanIncreaseMaxAmount,
    loanIncreaseMaxAmountFormatted,
    form: {instance, amount, amountOnChange},
  } = useForm<FeatureLoanIncreaseForm>();

  const handlePressAllAmount = React.useCallback(() => {
    amountOnChange(loanIncreaseMaxAmount);
  }, [loanIncreaseMaxAmount, amountOnChange]);

  return (
    <Box width="100%" {...props}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Label mb={0}>{t('surface.loans.increase_form.amount')}</Label>
        <Button type="ghost" size="tiny" onPress={handlePressAllAmount}>
          {`${t(
            'surface.loans.increase_form.amount_all'
          )} ${loanIncreaseMaxAmountFormatted} ${ticker}`}
        </Button>
      </Box>
      <TextInput
        numeric
        value={amount}
        onChangeText={amountOnChange}
        textAlign="right"
        LeftPartComponent={<TickerField />}
      />
      <Error errors={instance.errors()} name={['amount']} />
    </Box>
  );
});
