import * as React from 'react';
import {observer} from 'mobx-react';
import {
  Redirect,
  Route,
  type RouteComponentProps,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {HODLByIdFeature} from '@youtoken/ui.surface-hodl-positions';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {webAppAdapter} from '@youtoken/ui.screen-components';
import {HodlsPortfolioSurface} from '@youtoken/ui.surface-hodl-positions';
import {HodlsInstrumentsSurface} from '@youtoken/ui.surface-hodl-instruments';
import {SwitchWithNotFound} from '../SwitchWithNotFound';
import {authRequired} from '../authRequired';
import {HODLsClosedPage, MultiHodlCreate} from '../../pages';
import {HodlInstrumentSurface} from '@youtoken/ui.surface-hodl-instruments';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {HODLPageComponent} from '../../pages/AppPages/NewMultiHodl/elements/HODLPageComponent';

const withProductFlag = (Component: React.ComponentType<any>) => {
  const ComponentWithRedirect: React.FC = observer(props => {
    const {
      data: {enableHodl},
    } = AuthMeResource.use({});

    if (!enableHodl) {
      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  });

  ComponentWithRedirect.displayName = `withProductFlag(${
    Component.displayName || ''
  }))`;

  return ComponentWithRedirect;
};

const InstrumentsPage = webAppAdapter(HodlsInstrumentsSurface);
const PortfolioPage = webAppAdapter(HodlsPortfolioSurface);
const InstrumentPage = webAppAdapter(HodlInstrumentSurface);

const MultiHodlItemPage = () => {
  const {id} = useParams<{
    id: string;
  }>();
  const {path} = useRouteMatch();
  const {navigate} = SHARED_ROUTER_SERVICE;

  const {isExtendTpSlAvailable, isFlipDirectionAvailable} = HODLByIdFeature.use(
    {
      id,
    }
  );

  React.useEffect(() => {
    if (path.includes('increase') && isExtendTpSlAvailable) {
      navigate('HodlExtendTpSl', {
        id,
      });
    } else if (path.includes('flip') && isFlipDirectionAvailable) {
      navigate('HodlFlip', {
        id,
      });
    } else if (path.includes('history')) {
      navigate('HodlHistory', {
        id,
      });
    }
  }, []);

  return <HODLPageComponent />;
};

export const MultihodlRoutes: React.FC<RouteComponentProps> = authRequired(
  withProductFlag(({match: {path}}) => {
    return (
      <InnerPageShell>
        <SwitchWithNotFound>
          <Route exact path={`${path}`}>
            <Redirect to={`${path}/instruments`} />
          </Route>
          <Route
            exact
            path={`${path}/instruments`}
            component={InstrumentsPage}
          />
          <Route
            exact
            path={`${path}/instrument/:symbol`}
            component={InstrumentPage}
          />
          <Route exact path={`${path}/portfolio`} component={PortfolioPage} />

          <Route
            exact
            path={`${path}/portfolio/closed`}
            component={HODLsClosedPage}
          />
          <Route exact path={`${path}/create`} component={MultiHodlCreate} />
          <Route
            exact
            path={`${path}/item/:id`}
            component={HODLPageComponent}
          />
          <Route
            exact
            path={`${path}/item/:id/increase`}
            component={MultiHodlItemPage}
          />
          <Route
            exact
            path={`${path}/item/:id/flip`}
            component={MultiHodlItemPage}
          />
          <Route
            exact
            path={`${path}/item/:id/history`}
            component={MultiHodlItemPage}
          />
        </SwitchWithNotFound>
      </InnerPageShell>
    );
  })
);
