import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {useIsMediumDesktopAndUp} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

type DepositButtonProps = {
  onPress: () => void;
};

export const DepositButton: React.FC<DepositButtonProps> = observer(
  ({onPress}) => {
    const {t} = useTranslation();
    const isMediumDesktop = useIsMediumDesktopAndUp();

    return (
      <Button
        type="secondary"
        size={isMediumDesktop ? 'medium' : 'small'}
        icon="fiat_deposit"
        onPress={onPress}
      >
        {t('surface.wallets.common.deposit_button')}
      </Button>
    );
  }
);
