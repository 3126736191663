import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  Box,
  type BoxProps,
  useIsMobile,
  Text,
  Heading,
  Theme,
} from '@youtoken/ui.primitives';
import {WalletsFeature} from '../../state';
import {ActionsBar} from '../ActionsBar';

export const ValueAndActions: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const {currencySign, summaryCapitalFormatted} = WalletsFeature.use({});

  const borderProps = React.useMemo(
    () =>
      isMobile
        ? {}
        : {
            borderWidth: 1,
            borderColor: '$ui-01' as keyof Theme['colors'],
            borderRadius: 12,
          },
    [isMobile]
  );

  return (
    <Box
      {...borderProps}
      pt={{default: 20, tablet: 32}}
      pb={{default: 0, tablet: 32}}
      testID="VALUE_AND_ACTIONS"
      {...boxProps}
    >
      <Box
        flex={1}
        flexShrink={0}
        alignItems="center"
        mb={{default: 40, tablet: 32}}
        testID="TOTAL_INVESTED_FIELD"
      >
        <Box flexDirection="row" alignItems="center">
          <Text color="$text-01" variant="$body-02">
            {t('surface.wallets.header.portfolio_value')}
          </Text>
        </Box>
        <Box>
          <Heading fontSize={32} lineHeight={38} testID="TOTAL_FUNDS">
            {currencySign}
            {summaryCapitalFormatted}
          </Heading>
        </Box>
      </Box>
      <Box alignItems="center">
        <ActionsBar
          width={{default: '100%', tablet: null}}
          flexShrink={1}
          gap={{default: 0, tablet: 48}}
        />
      </Box>
    </Box>
  );
});
