import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const AvaxIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
        {...props}
      >
        <Path
          fill="#E84142"
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16"
        />
        <Path
          fill="#fff"
          d="M21.644 16.416c.554-.958 1.449-.958 2.003 0l3.452 6.06c.555.957.101 1.738-1.008 1.738h-6.954c-1.096 0-1.55-.78-1.008-1.738zM14.967 4.75c.554-.958 1.436-.958 1.99 0l.769 1.385 1.814 3.188a3.31 3.31 0 0 1 0 2.885l-6.085 10.545a3.2 3.2 0 0 1-2.494 1.461H5.909c-1.109 0-1.563-.768-1.008-1.738z"
        />
      </Svg>
    );
  }
);
