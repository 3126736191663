import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {InputLikeWrapper} from '@youtoken/ui.inputs';
import {LogoColored} from '@youtoken/ui.icons/src/LogoColored/index.native';
import {LogoColoredIconName} from '@youtoken/ui.icons';
import {LoanReopenFeature} from './Feature';

type PayFromWalletProps = {
  loanId: string;
};

export const PayFromWallet: React.FC<PayFromWalletProps & BoxProps> = observer(
  ({loanId, ...boxProps}) => {
    const {t} = useTranslation();

    const {borrowedTicker, borrowedTickerFormatted, borrowedTickerAmount} =
      LoanReopenFeature.use({id: loanId});

    return (
      <Box flexDirection="column" {...boxProps}>
        <Box flexDirection="row" justifyContent="space-between" mb={8}>
          <Text variant="$body-01-high-accent">
            {t('surface.loans.reopen_form.amount')}
          </Text>
        </Box>

        <InputLikeWrapper size="medium">
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
          >
            <Box
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <LogoColored
                name={borrowedTicker as LogoColoredIconName}
                size={20}
              />
              <Text variant="$body-01-medium-accent" ml={8}>
                {borrowedTickerFormatted}
              </Text>
            </Box>

            <Box flexDirection="row">
              <Text variant="$body-02" color="$text-02">
                {t('surface.loans.reopen_form.balance')}{' '}
                <Text color="$text-01">{borrowedTickerAmount}</Text>{' '}
                {borrowedTickerFormatted}
              </Text>
            </Box>
          </Box>
        </InputLikeWrapper>
      </Box>
    );
  }
);
