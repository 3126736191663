import * as React from 'react';
import {observer} from 'mobx-react';
import {
  BundleTariffTickersListItem,
  BundlePortfolioTickersListItem,
} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type LogoColoredIconName, LogoColored} from '@youtoken/ui.icons';

interface BundleTickersScrollableListItemProps {
  item: BundleTariffTickersListItem | BundlePortfolioTickersListItem;
}

export const BundleTickersScrollableListItem: React.FC<
  BundleTickersScrollableListItemProps & BoxProps
> = observer(
  ({testID = 'BUNDLE_TICKERS_SCROLLABLE_AREA_ITEM', item, ...boxProps}) => {
    return (
      <Box
        testID={`${testID}_${item.ticker.toUpperCase()}`}
        flexDirection="row"
        alignItems="center"
        height={64}
        px={20}
        py={16}
        gap={8}
        bg="$ui-background"
        borderColor="$ui-01"
        borderWidth={1}
        borderRadius={12}
        {...boxProps}
      >
        <LogoColored
          testID="LOGO"
          name={item.ticker as LogoColoredIconName}
          size={32}
        />
        <Box>
          {item instanceof BundleTariffTickersListItem && (
            <>
              <Text
                testID="TICKER"
                variant="$body-02-medium-accent"
                color="$text-01"
              >
                {item.tickerFormatted}
              </Text>
              <Text testID="PERCENT" variant="$body-02" color="$text-02">
                {item.percentFormatted}
              </Text>
            </>
          )}
          {item instanceof BundlePortfolioTickersListItem && (
            <>
              <Text variant="$body-02-medium-accent" color="$text-01">
                <Text testID="AMOUNT">{item.amountFormatted}</Text>{' '}
                <Text testID="TICKER">{item.tickerFormatted}</Text>
              </Text>
              <Text
                testID="CONVERSION_AMOUNT"
                variant="$body-02"
                color="$text-02"
              >
                {item.conversionAmountWithSymbolFormatted}
              </Text>
            </>
          )}
        </Box>
      </Box>
    );
  }
);
