import big, {BigSource, RoundingMode} from 'big.js';
import {format as formatNumber} from 'd3-format';
import {
  getCoinDecimalPrecision,
  getCoinDecimalPrecisionForLoans,
} from '@youtoken/ui.coin-utils';
import {warning} from '@youtoken/ui.utils';

export {priceFormatterWithEllipsis} from './priceFormatterWithEllipsis';
export {priceFormatterInThousands} from './priceFormatterInThousands';
export {priceFormatter} from './priceFormatter';

export const formatWithSeparators = (
  value: number | string,
  precision?: number
) => {
  const valueNumber = +value;

  if (Number.isNaN(valueNumber)) {
    warning(
      false,
      'cannot convert ${value} to number',
      {},
      {
        value,
      }
    );
    return '';
  }

  if (valueNumber === 0) {
    return '0.0';
  }

  if (typeof precision !== 'number') {
    // if no precision is passed, preserve all numbers after dot
    const [integer, fraction] = `${value}`.split('.');
    const formattedInt = formatNumber(',')(Number(integer));
    return fraction ? `${formattedInt}.${fraction}` : formattedInt;
  }

  return formatNumber(`,.${precision}~f`)(valueNumber);
};

export const formatBigNumber = (
  value: BigSource | undefined,
  precision: number | undefined,
  dropZeroes = false,
  rm?: RoundingMode,
  withSeparators = true
) => {
  try {
    const _value = big(value!);

    if (_value.eq(0)) {
      return '0.0';
    }

    const valueRounded = _value.round(precision, rm);

    const valueWithSeparators = withSeparators
      ? valueRounded.toFixedWithSeparators(precision, rm)
      : valueRounded.toFixed();

    return dropZeroes
      ? valueWithSeparators.replace(/(\.\d*?[1-9])0+$|\.0+$/, '$1')
      : valueWithSeparators;
  } catch (e) {
    return '';
  }
};

export const toBig = (value: any) => {
  try {
    return new big(value);
  } catch (e) {
    return new big(0);
  }
};

export const formatByTickerLoan = (
  amount: BigSource,
  ticker: string,
  withSeparators = true
) => {
  return formatBigNumber(
    amount,
    getCoinDecimalPrecisionForLoans(ticker),
    true,
    undefined,
    withSeparators
  );
};

export const formatByTicker = (
  valueSource: BigSource | undefined,
  ticker: string | 'asMainCurrency' | undefined,
  rm?: RoundingMode,
  withSeparators = true
) => {
  if (valueSource === undefined || valueSource === '') {
    return '';
  }

  const p = ticker === 'asMainCurrency' ? 4 : getCoinDecimalPrecision(ticker);

  try {
    const value = big(valueSource);

    if (value.eq(0)) {
      return '0.0';
    }

    if (ticker === 'asMainCurrency') {
      return withSeparators
        ? value.toFixedWithSeparators(p, rm)
        : value.toFixed(p, rm);
    }

    return withSeparators
      ? big(value.toFixed(p, rm)).toFixedWithSeparators()
      : big(value.toFixed(p, rm)).toFixed();
  } catch (e) {
    warning(
      false,
      'cannot convert ${valueSource} to big number',
      {},
      {
        value: valueSource,
      }
    );
    return '';
  }
};

export const formatNumberToOrder = (number: number) => {
  if (number === 1) {
    return '1st';
  }

  if (number === 2) {
    return '2nd';
  }

  if (number === 3) {
    return '3rd';
  }

  return `${number}th`;
};

export const isFormattedValueEqualZero = (
  valueSource: BigSource,
  ticker: string
) => {
  try {
    const valueWithPrecision = big(valueSource).toFixed(
      getCoinDecimalPrecision(ticker)
    );

    return big(valueWithPrecision).eq(0);
  } catch (e) {
    warning(
      false,
      'cannot convert ${valueSource} to big number',
      {},
      {
        value: valueSource,
      }
    );
    return false;
  }
};

export const getAmountDecimalPrecision = (amount?: BigSource) => {
  try {
    const _amount = big(amount!).abs();

    if (_amount.gte(1000)) {
      return 2;
    }

    if (_amount.gte(100)) {
      return 4;
    }

    return 6;
  } catch (e) {
    warning(
      false,
      'cannot convert ${amount} to big number',
      {},
      {
        value: amount,
      }
    );
    return 6;
  }
};

export const formatByAmountAndTicker = (
  amount: BigSource | undefined,
  ticker: string
) => {
  const precision = Math.min(
    getAmountDecimalPrecision(amount),
    getCoinDecimalPrecision(ticker)
  );

  return formatBigNumber(amount, precision, true);
};

export const getAmountRoundValue = (amount: big) => {
  return amount.gte(100) ? 0 : amount.gte(10) ? 1 : amount.gte(1) ? 2 : 3;
};

// rounds everyting after Nth non-zero decimal digit
// e.g.: N=3, 12.0034567 -> 12.00346, 12.0000034567 -> 12.00000346
export const formatToNSignificantDigits = ({
  value,
  digits,
}: {
  value: BigSource;
  digits: number;
}): string => {
  if (value === null || value === undefined || value === '') {
    return '';
  }

  const source = toBig(value);

  if (source.mod(1).eq(0) || digits <= 0) {
    return source.toFixed(0);
  }

  const sign = source.gte(0) ? '' : '-';
  const absSource = source.abs();
  const fractional = absSource.mod(1).toString();
  const fractionalOnly = fractional.slice(2);
  const integer = absSource.minus(fractional);

  const firstSignificantIndex = fractionalOnly
    .split('')
    .findIndex(c => c !== '0');

  const fractionalBig = toBig(fractional);
  const fractionalRounded = fractionalBig.toFixed(
    firstSignificantIndex + digits
  );
  const fractionalRoundedTrimmed = toBig(fractionalRounded).toString();
  const roundedFractionalOnly = fractionalRoundedTrimmed.slice(2);

  return `${sign}${integer}.${roundedFractionalOnly}`;
};
