import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Separator, useWizard, KeyboardScrollView} from '@youtoken/ui.elements';
import {i18n} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {CryptoWithdrawalExternalForm} from '../../../../CryptoWithdrawalExternalForm';
import {HeaderBase} from '../../../../components';
import {
  type CryptoDirectWithdrawalWizardNavigator,
  CryptoDirectWithdrawalWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const getCryptoWithdrawalTitle = (ticker: string) => {
  return i18n.t('surface.wallets.crypto_withdrawal.title', {
    ticker: ticker.toUpperCase(),
  });
};

const CryptoWithdrawalContentWrapper: React.FC = observer(({children}) => {
  return (
    <KeyboardScrollView
      insideTabs={false}
      wrapInSafeArea
      scrollViewProps={{
        keyboardDismissMode: 'interactive',
        style: {flex: 1},
        contentContainerStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
    >
      {children}
    </KeyboardScrollView>
  );
});

export const CryptoDirectWithdrawal: React.FC = cell(
  () => {
    const {
      step: {
        props: {ticker, version},
      },
      goBack,
    } = useWizard<
      CryptoDirectWithdrawalWizardNavigator,
      CryptoDirectWithdrawalWizardScreenName.CRYPTO_DIRECT
    >();

    return (
      <>
        {showHeader && (
          <>
            <HeaderBase onPressBack={goBack}>
              {getCryptoWithdrawalTitle(ticker)}
            </HeaderBase>
            <Separator />
          </>
        )}

        <CryptoWithdrawalContentWrapper>
          <CryptoWithdrawalExternalForm ticker={ticker} version={version} />
        </CryptoWithdrawalContentWrapper>
      </>
    );
  },
  {
    CellWrap: ({children}) => (
      <Box minHeight={200} height="100%">
        {children}
      </Box>
    ),
  }
);
