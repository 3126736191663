import * as React from 'react';
import {z} from 'zod';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {TrustedDevicesSurfaceContent} from './Content';

export const TrustedDevicesSurface = surface(
  {
    name: 'TrustedDevices',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.trusted_devices.title'),
    headerShown: true,
  },
  () => {
    return <VariableLayout PrimaryContent={<TrustedDevicesSurfaceContent />} />;
  }
);
