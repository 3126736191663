import {observable, computed} from 'mobx';
import {Form} from './Form';

export class View {
  @observable
  form: Form;

  public constructor(form: Form) {
    this.form = form;
  }

  @computed get fromTickerFormatted() {
    return this.form.fromTicker.toUpperCase();
  }

  @computed get toTickerFormatted() {
    return this.form.toTicker.toUpperCase();
  }
}
