import * as React from 'react';
import {z} from 'zod';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {VerificationItem} from '../VerificationItem';
import {VerificationStepsWrapper} from './Wrapper';

export const VerificationStepsSurface = surface(
  {
    name: 'VerificationSteps',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.more.screen_title.verification_steps'),
    headerShown: true,
  },
  () => {
    const {accountVerificationCodes} = VerificationResource.use({});

    return (
      <VariableLayout
        PrimaryContent={
          <VerificationStepsWrapper>
            {accountVerificationCodes?.map(code => (
              <VerificationItem key={code} code={code} />
            ))}
          </VerificationStepsWrapper>
        }
      />
    );
  }
);
