import * as React from 'react';
import {observer} from 'mobx-react';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {Route, RouteComponentProps, useParams} from 'react-router-dom';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  BankCardsOverviewPage,
  NotFoundPage,
  WalletsDetails,
  WalletsList,
  WalletsSavingsPage,
  WalletsBundlesPage,
  WalletsBundlePage,
  WalletsAvtivatePersonalIBANPage,
} from '@web-app/src/components/pages';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import CONFIG from '../../config';

const WalletsListWrapped = authRequired(WalletsList);
const WalletsAddWrapped = authRequired(() => {
  React.useEffect(() => {
    SHARED_ROUTER_SERVICE.navigate('AddWallets', {});
  }, []);

  return <WalletsList />;
});
const WalletsSavingsPageWrapped = authRequired(WalletsSavingsPage);
const WalletsDetailsWrapped = authRequired(WalletsDetails);
const WalletsWizardBankWireWrapped = authRequired(() => {
  const {ticker} = useParams<{
    ticker: string;
  }>();
  React.useEffect(() => {
    SHARED_ROUTER_SERVICE.navigate('WithdrawalBankWire', {ticker});
  }, []);

  return <WalletsDetails />;
});
const WalletsWizardBankCardWithdrawalWrapped = authRequired(() => {
  const {
    queryParams: {conversionTicker, cardId, amount, withError},
  } = RouterResource.use({});
  const {ticker} = useParams<{
    ticker: string;
  }>();

  React.useEffect(() => {
    SHARED_ROUTER_SERVICE.navigate(
      'WithdrawalBankCard',
      {ticker},
      {conversionTicker, cardId, amount, withError}
    );
  }, []);

  return <WalletsDetails />;
});
const WalletsWizardWithdrawalWrapped = authRequired(() => {
  const {ticker} = useParams<{
    ticker: string;
  }>();
  const isFiat = CONFIG.TICKERS.FIAT.includes(ticker);

  React.useEffect(() => {
    SHARED_ROUTER_SERVICE.navigate('WithdrawalWizard', {
      ticker,
      variant: isFiat ? 'fiat' : 'crypto',
    });
  }, []);

  return <WalletsDetails />;
});
const BankCardsOverviewWrapped = authRequired(BankCardsOverviewPage);

const WalletsListWithModalWrapped = authRequired(() => {
  const {source, ticker} = useParams<{
    source?: string;
    ticker?: string;
  }>();
  const {checkProductAvailability} = AuthMeResource.use({});

  React.useEffect(() => {
    if (!checkProductAvailability('depositBuyCrypto')) {
      return;
    }

    SHARED_ROUTER_SERVICE.navigate('CryptoBuy', {source, ticker});
  }, []);

  return <WalletsList />;
});

const WalletsListWithIncentivesWrapped = authRequired(() => {
  React.useEffect(() => {
    SHARED_ROUTER_SERVICE.navigate('IncentivesDetailsModal', {});
  }, []);

  return <WalletsList />;
});

export const WalletsRoutes: React.FC<RouteComponentProps> = authRequired(
  observer(({match: {path}}) => {
    const {
      data: {enablePhysicalBankCard, enableVirtualBankCard},
    } = AuthMeResource.use({});

    return (
      <SwitchWithNotFound>
        <Route exact path={`${path}`} component={WalletsListWrapped} />
        <Route exact path={`${path}/add`} component={WalletsAddWrapped} />
        <Route
          exact
          path={`${path}/savings`}
          component={WalletsSavingsPageWrapped}
        />
        <Route
          exact
          path={`${path}/cards`}
          component={
            enablePhysicalBankCard || enableVirtualBankCard
              ? BankCardsOverviewWrapped
              : NotFoundPage
          }
        />
        <Route
          exact
          path={`${path}/buy`}
          component={WalletsListWithModalWrapped}
        />
        <Route
          exact
          path={`${path}/incentives`}
          component={WalletsListWithIncentivesWrapped}
        />

        <Route exact path={`${path}/bundles`} component={WalletsBundlesPage} />
        <Route
          exact
          path={`${path}/bundles/:id`}
          component={WalletsBundlePage}
        />

        <Route
          exact
          path={`${path}/:ticker`}
          component={WalletsDetailsWrapped}
        />
        <Route
          exact
          path={`${path}/:ticker/withdrawal`}
          component={WalletsWizardWithdrawalWrapped}
        />

        <Route
          exact
          path={`${path}/:ticker/activate-personal-iban`}
          component={WalletsAvtivatePersonalIBANPage}
        />

        <Route
          exact
          path={`${path}/:ticker/withdrawal/fiat/bank-wire`}
          component={WalletsWizardBankWireWrapped}
        />
        <Route
          exact
          path={`${path}/:ticker/withdrawal/fiat/bank-card`}
          component={WalletsWizardBankCardWithdrawalWrapped}
        />
      </SwitchWithNotFound>
    );
  })
);
