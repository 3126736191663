import {action, computed} from 'mobx';
import Big from 'big.js';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {FeeAllResource, FeeItemResponse} from '@youtoken/ui.resources-fee';
import {Version, WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {getCoinSymbol} from '@youtoken/ui.coin-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {invariant} from '@youtoken/ui.utils';

export class CryptoDirectWithdrawalChooseNetworkFeature extends createFeature({
  getKey: (args: {ticker: string}) =>
    `CryptoDirectWithdrawalChooseNetworkFeature:${JSON.stringify(args)}`,
  getResources: () => {
    return {
      wallet: getResourceDescriptor(WalletsResource, {}),
      feeAll: getResourceDescriptor(FeeAllResource, {}),
      authMe: getResourceDescriptor(AuthMeResource, {}),
      rate: getResourceDescriptor(RatesResource, {}),
    };
  },
}) {
  @computed
  private get ticker() {
    return this.args.ticker;
  }

  @computed.struct
  private get networksListByTicker(): Version[] | undefined {
    return this.resources.wallet.getByTicker(this.ticker)?.versions;
  }

  @action
  private getFeeItem = (version: string): FeeItemResponse | undefined => {
    return this.resources.feeAll.getFeeForWithdraw(this.ticker, version);
  };

  @action
  private getCalculatedFeeValue = (version: string): Big | undefined => {
    const feeItem = this.getFeeItem(version);

    return (
      feeItem &&
      this.resources.feeAll.calculateFee(
        feeItem.method,
        feeItem.provider,
        feeItem.ticker,
        feeItem.conversionTicker,
        0
      )
    );
  };

  @computed
  private get userMainCurrency() {
    return this.resources.authMe.mainCurrency;
  }

  @computed
  private get rate() {
    return this.resources.rate.getRate(this.ticker, this.userMainCurrency);
  }

  @computed
  private get currencySign() {
    return getCoinSymbol(this.userMainCurrency);
  }

  @computed.struct
  public get networksListFormatted() {
    invariant(
      this.networksListByTicker,
      `Cannot get networks list for the ${this.args} ticker`
    );

    return this.networksListByTicker.map(network => {
      let feeStringFormatted = '';

      // NOTE: Try to form fee string like "Fee: 0.0001 BTC (≈$0.01)"
      try {
        const feeValue = this.getCalculatedFeeValue(network.version);

        if (feeValue) {
          const feeInEquivalentValue = feeValue?.mul(this.rate);

          const feeString = `${feeValue?.toFixed()} ${this.ticker.toUpperCase()} (≈${
            this.currencySign
          }${feeInEquivalentValue?.toFixed(2)})`;

          feeStringFormatted = i18n.t('surface.fee_string.template', {
            feeString,
          }) as string;
        }
      } catch (e) {}

      return {
        networkVersion: network.version,
        titleFormatted: network.name,
        feeStringFormatted: feeStringFormatted,
      };
    });
  }
}
