import * as React from 'react';
import {AccountLevels} from '../../smarts';
import {AccountLevelDetailed} from '../../AccountLevelDetailed';

export const AccountLevelsSurfaceContent = () => {
  return (
    <>
      <AccountLevelDetailed />
      <AccountLevels />
    </>
  );
};
