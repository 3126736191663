import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MenuItemsProps} from './types';
import {MenuItem} from '@web-app/src/components/elements/MenuItem';
import {MenuItemProfile} from './MenuItemProfile';
import {MenuItemSignOut} from './MenuItemSignOut';

export const WebMenuItems: React.FC<MenuItemsProps> = observer(({toggle}) => {
  const {
    data: {enableHodl, enableMiner},
  } = AuthMeResource.use({});

  const {t} = useTranslation();

  return (
    <>
      <MenuItem
        testID="MENU_ITEM_WALLETS_BUTTON"
        onPress={toggle}
        to="/wallets"
        icon="wallets"
        pathKey="wallets"
        text={t('surface.menu.wallets')}
      />
      <MenuItem
        testID="MENU_ITEM_LOANS_BUTTON"
        onPress={toggle}
        to="/loans"
        icon="get_loan"
        pathKey="loans"
        text={t('surface.menu.loans')}
      />
      {enableHodl && (
        <MenuItem
          testID="MENU_ITEM_MULTIHODL_BUTTON"
          onPress={toggle}
          to="/multihodl"
          icon="hodl"
          pathKey="multihodl"
          text={t('surface.menu.trading')}
        />
      )}
      {enableMiner && (
        <MenuItem
          testID="MENU_ITEM_MINER_BUTTON"
          onPress={toggle}
          to="/miner"
          icon="mining"
          pathKey="miner"
          text={t('surface.menu.cloud_miner')}
        />
      )}
      <MenuItem
        testID="MENU_ITEM_TURBOCHARGE_BUTTON"
        onPress={toggle}
        to="/turbocharge"
        icon="turbo"
        pathKey="turbocharge"
        text={t('surface.menu.turbos')}
      />

      <MenuItem
        testID="MENU_ITEM_HISTORY_BUTTON"
        onPress={toggle}
        to="/account-stats"
        icon="history"
        pathKey="account-stats"
        text={t('surface.menu.transactions')}
      />
      <MenuItemProfile onPress={toggle} />
      <MenuItemSignOut />
    </>
  );
});
