import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '@youtoken/ui.form-elements';
import {ExchangeFormState} from '../../state';

const breakpoints = [25, 50, 75, 100];

export const ExchangeFormAmountButtons: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      form: {balancePercent, setAmountByPercentOfBalance},
    } = useForm<ExchangeFormState>();

    const createHandlePress = React.useCallback(
      (value: number) => () => {
        setAmountByPercentOfBalance(value);
      },
      [setAmountByPercentOfBalance]
    );

    return (
      <Box flexDirection="row" mt={16} mx={-4} {...boxProps}>
        {breakpoints.map(value => (
          <Box key={value} flex={1}>
            <Button
              testID={`CONVERT_AMOUNT_BUTTON_${value}`}
              size="large"
              type={balancePercent === value ? 'primary' : 'secondary'}
              onPress={createHandlePress(value)}
              mx={4}
            >
              {value}%
            </Button>
          </Box>
        ))}
      </Box>
    );
  }
);
