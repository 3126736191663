import * as React from 'react';
import {
  Box,
  type BoxProps,
  Heading,
  Text,
  useIsMobile,
} from '@youtoken/ui.primitives';
import {type currencyName, getCoinSymbol} from '@youtoken/ui.coin-utils';

export interface ProfitInfoElementProps {
  text: string | JSX.Element;
  currency: currencyName;
  formattedResult: string;
  resultDirection?: 'neutral' | 'up' | 'down';
}

const getSignAndAbsoluteValue = (input: string) => {
  if (input[0] === '-') {
    const value = input.substr(1);
    return ['-', value];
  }

  return ['', input];
};

export const Profit: React.FC<ProfitInfoElementProps & BoxProps> = ({
  text,
  currency,
  formattedResult = '',
  resultDirection,
  ...boxProps
}) => {
  const isMobile = useIsMobile();

  const currencySign = getCoinSymbol(currency);

  const accentColor = React.useMemo(() => {
    switch (resultDirection) {
      case 'down':
        return '$danger-01';
      case 'up':
      default:
        return '$success-01';
    }
  }, [resultDirection]);

  const [valueSign, value] = getSignAndAbsoluteValue(formattedResult);

  return (
    <Box
      flex={1}
      flexShrink={0}
      testID="CURRENT_PROFIT_FIELD"
      borderWidth={{default: 0, tablet: 1}}
      borderColor="$ui-01"
      borderRadius={12}
      p={{default: 0, tablet: 16}}
      alignItems={{default: 'flex-end', tablet: 'flex-start'}}
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center" minHeight={24}>
        {typeof text === 'string' ? (
          <Text
            color={isMobile ? '$text-02' : accentColor}
            variant="$body-02"
            textAlign="right"
          >
            {text}
          </Text>
        ) : (
          <Box flexDirection="row" alignItems="center">
            {text}
          </Box>
        )}
      </Box>

      <Heading
        variant="$heading-01"
        color={accentColor}
        style={{width: '100%'}}
        textAlign={{default: 'right', tablet: 'left'}}
      >
        {valueSign}
        {currencySign}
        {value}
      </Heading>
    </Box>
  );
};
