import * as React from 'react';
import {observer} from 'mobx-react';
import {BoxProps} from '@youtoken/ui.primitives';
import {useForm, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {TextInput} from '@youtoken/ui.inputs';
import {Box, Text} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {PredefinedAmountButtons} from '@youtoken/ui.ticker-and-amount-input';
import {coinNames} from '@youtoken/ui.coin-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type CardDepositForm} from '../../state';

export const WithdrawSumField: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();
  const {
    form: {ticker, onSumChange, hasSumError, sumError},
    view: {tickerFormatted, sumValueFormatted, amountData},
  } = useForm<CardDepositForm>();

  return (
    <Box {...props}>
      <Box mb={8}>
        <Text variant="$body-01-high-accent">
          {t('surface.apple_pay.deposit.components.sum_field.amount')}
        </Text>
      </Box>
      <TextInput
        numeric
        value={sumValueFormatted}
        onChangeText={onSumChange}
        setCaretPositionToEndOnFocus
        keyboardType="decimal-pad"
        placeholder="0.00"
        LeftPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            pl={15}
          >
            <LogoColored name={ticker as LogoColoredIconName} size={20} />

            <Text ml={8} variant="$body-01-medium-accent">
              {coinNames[ticker]}
            </Text>
          </Box>
        }
        RightPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            ml={3}
            pr={15}
          >
            <Text color="$text-02">{tickerFormatted}</Text>
          </Box>
        }
        textAlign="right"
        hasError={hasSumError}
        testID="DEPOSIT_SUM_INPUT"
      />
      <FieldErrorMessage visible={hasSumError} placement="right">
        {getTranslatedValidationMessage(sumError)}
      </FieldErrorMessage>
      <PredefinedAmountButtons
        data={amountData}
        mt={8}
        currentAmount={sumValueFormatted}
        onPressAmount={onSumChange}
        buttonsSize="small"
      />
    </Box>
  );
});
