import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {Banner} from '@youtoken/ui.elements';
import {Form, makeForm, SubmitButton} from '@youtoken/ui.form-elements';
import {FeeAllResource} from '@youtoken/ui.resources-fee';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  FieldAmount,
  FieldBankName,
  FieldPurpose,
  PaymentInfo,
} from './components';
import {DepositInvoiceForm} from './state/DepositInvoiceForm';

interface DepositInvoiceProps {
  ticker: string;
}

export const DepositInvoice: React.FC<DepositInvoiceProps> = cell(
  ({ticker}) => {
    const {t} = useTranslation();
    const resources = useResources({
      fees: getResourceDescriptor(FeeAllResource, {}),
    });

    const form = makeForm(() => new DepositInvoiceForm(ticker, resources));

    return (
      <Form form={form}>
        <Banner variant="info" testID="FIAT_DEPOSIT_BANK_WIRE_ALERT">
          {t('surface.wallets.fiat_deposit_wire.alert')}
        </Banner>

        <Box flex={1} p={24}>
          <FieldAmount />
          <PaymentInfo />
          <FieldPurpose />
          <FieldBankName />
        </Box>

        <Banner variant="danger" testID="FIAT_DEPOSIT_BANK_WIRE_IMPORTANT_INFO">
          {t('surface.wallets.fiat_deposit_wire.important_info_message')}
        </Banner>

        <Box p={24}>
          <SubmitButton size="large" testID="FIAT_DEPOSIT_BANK_WIRE_SUBMIT">
            {t('surface.wallets.fiat_deposit_wire.submit')}
          </SubmitButton>
        </Box>
      </Form>
    );
  }
);
