import {action, computed} from 'mobx';
import big from 'big.js';
import {TFuncKey} from 'react-i18next';
import {
  formatBigNumber,
  formatPercent,
  formatWithSeparators,
} from '@youtoken/ui.formatting-utils';
import {getPercentByAmount} from '@youtoken/ui.hodls-utils';
import {AdjustTpSlForm} from './AdjustTpSlForm';

export class AdjustTpSlFormView {
  @computed get calculating() {
    return this.form.calculating;
  }

  @computed get calculatedData() {
    return this.form.calculatedData;
  }

  @computed get hodlObj() {
    return this.form.hodlObj;
  }

  constructor(private form: AdjustTpSlForm) {}

  @computed get maxLoss() {
    return this.calculatedData?.maxLoss || big(this.form.maxLoss);
  }

  @computed get maxProfit() {
    try {
      return big(this.calculatedData?.maxProfit || this.form.maxProfit || 0);
    } catch (e) {
      return big(0);
    }
  }

  @computed get maxTicker() {
    return this.form.inputTicker;
  }

  @computed get initialPrice() {
    return this.form.initialPrice;
  }

  @computed get baseTicker() {
    return this.form.baseTicker;
  }

  @computed get baseTickerFormatted() {
    return this.baseTicker.toUpperCase();
  }

  @computed get quoteTicker() {
    return this.form.quoteTicker;
  }

  @computed get quoteTickerFormatted() {
    return this.quoteTicker.toUpperCase();
  }

  @computed get initialPriceFormatted() {
    return formatBigNumber(
      this.initialPrice,
      this.pricePrecision,
      undefined,
      undefined,
      false
    );
  }

  @computed get isShort() {
    return this.form.isShort;
  }

  @computed get hasFtpLimit() {
    return !!this.form.ftpPrice && this.form.ftpPrice.gt(0);
  }

  @computed get ftpLimit() {
    if (!this.hasFtpLimit) {
      return '';
    }

    return formatBigNumber(
      this.form.ftpPrice,
      this.form.pricePrecision,
      true,
      this.takeProfitRoundingMode,
      false
    );
  }

  @computed get _ftpLimitPercent() {
    if (!this.hasFtpLimit) {
      return '';
    }

    return this.form.ftpPrice
      .minus(this.form.initialPrice)
      .div(this.form.initialPrice);
  }

  @computed get ftpLimitPercent() {
    if (!this._ftpLimitPercent) {
      return '';
    }

    return formatPercent(
      this._ftpLimitPercent.abs(),
      false,
      this.takeProfitRoundingMode
    );
  }

  @computed get _stopLossLimit() {
    return this.form.mcPrice || big(0);
  }

  @computed get stopLossLimit() {
    return formatBigNumber(
      this._stopLossLimit,
      this.form.pricePrecision,
      true,
      this.stopLossRoundingMode,
      false
    );
  }

  @computed get takeProfitValue() {
    // @ts-ignore
    return this.form.$('takeProfit').get('value');
  }

  @computed get takeProfitPercent() {
    return this.form.takeProfitPercent;
  }

  @computed get stopLossValue() {
    // @ts-ignore
    return this.form.$('stopLoss').get('value');
  }

  @computed get stopLossPercent() {
    return this.form.stopLossPercent;
  }

  @action setTakeProfitLimit = () => {
    this.form.updateTakeProfitValue(
      this.form.ftpPrice.toFixed(this.form.pricePrecision)
    );
  };

  @computed get tpError() {
    // @ts-ignore
    return this.form.$('takeProfit').get('error');
  }

  @computed get tpErrorIsClickable() {
    return this.tpError === 'TAKE_PROFIT_LIMIT';
  }

  @action setStopLossLimit = () => {
    this.form.updateStopLossValue(
      this.form.mcPrice.toFixed(this.form.pricePrecision)
    );
  };

  @computed get slError() {
    // @ts-ignore
    return this.form.$('stopLoss').get('error');
  }

  @computed get slErrorIsClickable() {
    return this.slError === 'MARGIN_CALL_LIMIT';
  }

  @computed get takeProfitLimitMessage() {
    if (this.form.isShort) {
      return {
        errorKey: 'surface.hodls.validation.should_be_gte' as TFuncKey,
        amount: `${this.takeProfitValueMinFormatted} (${this.takeProfitPercentMin}%)`,
      };
    }
    return {
      errorKey: 'surface.hodls.validation.should_be_lte' as TFuncKey,
      amount: `${this.takeProfitValueMaxFormatted} (${this.takeProfitPercentMax}%)`,
    };
  }

  @computed get stopLossLimitMessage() {
    if (!this.form.isShort) {
      return {
        errorKey: 'surface.hodls.validation.should_be_gte' as TFuncKey,
        amount: `${this.stopLossValueMinFormatted} (${this.stopLossPercentMin}%)`,
      };
    }
    return {
      errorKey: 'surface.hodls.validation.should_be_lte' as TFuncKey,
      amount: `${this.stopLossValueMaxFormatted} (${this.stopLossPercentMax}%)`,
    };
  }

  @computed get initialPriceServer() {
    return this.initialPrice;
  }

  @computed get pricePrecision() {
    return this.form.pricePrecision;
  }

  @computed get takeProfitRoundingMode() {
    return this.form.takeProfitRoundingMode;
  }

  @computed get stopLossRoundingMode() {
    return this.form.stopLossRoundingMode;
  }

  @computed get takeProfitValueMin() {
    const value = this.isShort ? this.form.ftpPrice : this.form.currentPrice;

    try {
      return formatBigNumber(
        value,
        this.pricePrecision,
        true,
        this.takeProfitRoundingMode,
        false
      );
    } catch (e) {
      return undefined;
    }
  }

  @computed get takeProfitValueMinFormatted() {
    return this.takeProfitValueMin
      ? formatWithSeparators(this.takeProfitValueMin)
      : undefined;
  }

  @computed get takeProfitValueMax() {
    const value = this.isShort ? this.form.currentPrice : this.form.ftpPrice;

    try {
      return formatBigNumber(
        value,
        this.pricePrecision,
        true,
        this.takeProfitRoundingMode,
        false
      );
    } catch (e) {
      return undefined;
    }
  }

  @computed get takeProfitValueMaxFormatted() {
    return this.takeProfitValueMax
      ? formatWithSeparators(this.takeProfitValueMax)
      : undefined;
  }

  @computed get stopLossValueMin() {
    const value = !this.isShort ? this.form.mcPrice : this.form.currentPrice;

    try {
      return formatBigNumber(
        value,
        this.pricePrecision,
        true,
        this.stopLossRoundingMode,
        false
      );
    } catch (e) {
      return undefined;
    }
  }

  @computed get stopLossValueMinFormatted() {
    return this.stopLossValueMin
      ? formatWithSeparators(this.stopLossValueMin)
      : undefined;
  }

  @computed get stopLossValueMax() {
    const value = !this.isShort ? this.form.currentPrice : this.form.mcPrice;

    try {
      return formatBigNumber(
        value,
        this.pricePrecision,
        true,
        this.stopLossRoundingMode,
        false
      );
    } catch (e) {
      return undefined;
    }
  }

  @computed get stopLossValueMaxFormatted() {
    return this.stopLossValueMax
      ? formatWithSeparators(this.stopLossValueMax)
      : undefined;
  }

  @computed get takeProfitPercentMin() {
    if (this.takeProfitValueMin === undefined) {
      return '';
    }

    return getPercentByAmount(
      this.initialPriceServer,
      this.takeProfitValueMin,
      this.takeProfitRoundingMode
    );
  }

  @computed get takeProfitPercentMax() {
    if (this.takeProfitValueMax === undefined) {
      return '';
    }

    return getPercentByAmount(
      this.initialPriceServer,
      this.takeProfitValueMax,
      this.takeProfitRoundingMode
    );
  }

  @computed get stopLossPercentMin() {
    if (this.stopLossValueMin === undefined) {
      return '';
    }

    return getPercentByAmount(
      this.initialPriceServer,
      this.stopLossValueMin,
      this.stopLossRoundingMode
    );
  }

  @computed get stopLossPercentMax() {
    if (this.stopLossValueMax === undefined) {
      return '';
    }

    return getPercentByAmount(
      this.initialPriceServer,
      this.stopLossValueMax,
      this.stopLossRoundingMode
    );
  }
}
