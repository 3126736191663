import React from 'react';
import {observer} from 'mobx-react';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {FeatureDisclosureForm} from '../DisclosureSurface/state';

export const SubmitDisclosureButton: React.FC<
  React.ComponentProps<typeof SubmitButton>
> = observer(({children, disabled, ...props}) => {
  const {
    form: {instance},
  } = useForm<FeatureDisclosureForm>();

  return (
    <SubmitButton disabled={disabled || !instance.$('agree').value} {...props}>
      {children}
    </SubmitButton>
  );
});
