import Axios, {AxiosRequestConfig} from 'axios';
import {
  AxiosError,
  enhanceAndRethrowError,
  isRecaptchaError,
} from '@youtoken/ui.errors';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {CHALLENGES_SERVICE} from '@youtoken/ui.challenge-service';
import {assignHeaders} from '../utils';

export function requestHeadersInterceptor(config: AxiosRequestConfig) {
  if (!config.headers) {
    config.headers = {};
  }

  assignHeaders(config.headers);

  return config;
}

export function createRestApiClient() {
  // these headers are static, no way to change them at all at runtime
  const headers = {};

  const instance = Axios.create({
    baseURL: ENVIRONMENT.BACKEND_URL,
    timeout: 10000,
    timeoutErrorMessage: '__TIMEOUT__',

    headers,
  });

  instance.interceptors.request.use(requestHeadersInterceptor);

  instance.interceptors.response.use(
    request => request,
    enhanceAndRethrowError
  );

  instance.interceptors.response.use(
    resp => resp,
    (error: AxiosError) => {
      // handle offline mode
      if (error.__type === 'NETWORK_ERROR_OFFLINE') {
        ENVIRONMENT.enableOfflineMode();
        throw error;
      }

      // handle maintenance mode
      if (error?.response?.status === 503) {
        ENVIRONMENT.enableMaintenanceMode();
        throw error;
      }

      // handle recaptcha error
      if (isRecaptchaError(error)) {
        // if recaptcha is not initialized, just throw error
        if (!CHALLENGES_SERVICE.refs.recaptcha.current) {
          throw error;
        }
        // request new token v2 (apprently v3 failed on backed)
        return CHALLENGES_SERVICE.refs.recaptcha.current
          ?.requestTokenV2(
            error.config?.url ? `retry-${error.config.url}` : 'retry'
          )
          .then(token => {
            // retry request with new token
            const config = error.config || {};
            // for some reason, data sometimes is string, sometimes object
            try {
              if (typeof config.data === 'string') {
                const data = JSON.parse(config.data);
                data.token = token;
                config.data = JSON.stringify(data);
              } else if (typeof config.data === 'object') {
                config.data.token = token;
              } else {
                // if somehting went wrong, just throw original error
                throw error;
              }
            } catch (_) {
              throw error;
            }

            return Axios.request(config);
          });
      }

      // NOTE: this code handles cases when a person has already logged out and 'sign-out' is called again,
      // as well as the case when an incorrect username / password is entered.
      if (
        error &&
        error.config &&
        (error.config.url?.includes('signout') ||
          error.config.url?.includes('signin'))
      ) {
        throw error;
      }

      // handle challenge response from backend
      if (error?.response?.status === 428) {
        const challenge = CHALLENGES_SERVICE.extractChallenge(error);

        // just throw error if we can't process challenge
        if (!challenge || !CHALLENGES_SERVICE.canProcessChallenge(challenge)) {
          throw error;
        }

        return CHALLENGES_SERVICE.processChallenge(challenge, error).then(
          result => {
            if (result.type === 'retry-request') {
              // retry request with an updated config, if any
              return instance.request(result.payload.config);
            }

            if (result.type === 'throw-original-error') {
              throw error;
            }

            if (result.type === 'contain-original-error') {
              return;
            }

            return;
          }
        );
      }

      // handle unauthorized error
      // basically signs out user
      if (error?.response?.status === 401) {
        ENVIRONMENT.unauthorizedError();
        return;
      }

      throw error;
    }
  );

  return instance;
}
