import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {Box, Text} from '@youtoken/ui.primitives';

export const RewardsOverviewProgressAmount: React.FC = observer(() => {
  const {
    data: {completedLevelAmountFormatted, lastLevelAmountFormatted},
  } = RewardsResource.use({});

  return (
    <Box alignItems="center" p={24}>
      <Text variant="$heading-02" color="$text-01" textAlign="center">
        ${completedLevelAmountFormatted} / ${lastLevelAmountFormatted}
      </Text>
    </Box>
  );
});
