import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const DotIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
        {...props}
      >
        <Path
          fill="#fff"
          d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0"
        />
        <Path
          fill="#1E1E1E"
          d="M16.134 3C10.547 3.022 6.022 7.419 6 12.85c0 1.087.18 2.167.534 3.199.264.702 1.048 1.08 1.783.863.71-.265 1.088-1.02.862-1.726a6.3 6.3 0 0 1-.395-2.511c.122-3.922 3.492-7.006 7.527-6.888s7.21 3.394 7.088 7.316c-.115 3.672-3.09 6.654-6.864 6.877 0 0-1.423.085-2.13.169q-.391.054-.775.15a.095.095 0 0 1-.133 0 .09.09 0 0 1 0-.11l.22-1.168 1.335-5.84c.16-.73-.317-1.447-1.067-1.603s-1.489.309-1.65 1.038c0 0-3.177 14.294-3.177 14.424-.181.695.25 1.401.965 1.578l.024.005h.074c.713.18 1.442-.238 1.626-.932l.009-.035a.3.3 0 0 1 0-.065c.04-.168.44-2.07.44-2.07.304-1.435 1.494-2.535 2.984-2.758.307-.045 1.596-.13 1.596-.13 5.566-.539 9.628-5.362 9.073-10.771-.507-4.934-4.713-8.733-9.815-8.862"
        />
        <Path
          fill="#E6007A"
          d="M16.749 25.71c-.914-.185-1.808.386-1.998 1.273l-.005.025c-.198.882.377 1.753 1.286 1.945l.003.001h.047c.889.21 1.784-.323 1.997-1.186l.006-.02v-.09c.157-.891-.429-1.746-1.336-1.948"
        />
      </Svg>
    );
  }
);
