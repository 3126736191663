import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {useSafeArea} from '@youtoken/ui.hooks';
import {Box, Text} from '@youtoken/ui.primitives';
import {AgreementsList} from '@youtoken/ui.elements';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {BundleDepositFormState} from '../../state';
import {DataTable} from './DataTable';

export const Footer: React.FC = observer(() => {
  const {t} = useTranslation();

  const {bottom} = useSafeArea();

  const {
    form: {
      resources: {
        docs: {
          documents: {bundles},
        },
      },
      calculated: {loading},
      tickerFormatted,
      resultAmountFormatted,
      submitAction,
      wallet,
    },
  } = useForm<BundleDepositFormState>();

  const submitActionText = t(
    submitAction === 'deposit-wallet'
      ? 'surface.bundles.deposit_form.submit_deposit'
      : 'surface.bundles.deposit_form.submit'
  );

  return (
    <Box
      pt={16}
      px={24}
      pb={bottom}
      borderColor="$ui-01"
      borderTopWidth={1}
      gap={24}
    >
      <Box gap={16}>
        <DataTable />
        <Text
          testID="SUBMIT_INFO"
          variant="$body-01"
          lineHeight={24}
          color="$text-02"
        >
          <Trans
            t={t}
            components={{
              Strong: <Text variant="$heading-02" color="$text-01" />,
            }}
            values={{
              amount: resultAmountFormatted,
              ticker: tickerFormatted,
            }}
            i18nKey="surface.bundles.deposit_form.to_receive"
          />
        </Text>
        <AgreementsList
          testID="AGREEMENTS"
          beforeListText={t(
            'agreements.documents.without_checkbox.start_text',
            {
              call_to_action: submitActionText,
            }
          )}
          items={bundles}
        />
      </Box>
      <SubmitButton
        loading={loading}
        icon={
          submitAction === 'deposit-wallet'
            ? wallet.isFiat
              ? 'fiat_deposit'
              : 'crypto_deposit'
            : undefined
        }
      >
        {submitActionText}
      </SubmitButton>
    </Box>
  );
});
