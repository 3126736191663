import * as React from 'react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {Passcode} from './Passcode';

export interface PasscodeModalProps {
  requestPasscode: (params: {promptMessage: string}) => Promise<void>;
}

export const PasscodeModal = React.forwardRef<PasscodeModalProps, {}>(
  (_props, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const [options, setOptions] = React.useState<{
      promptMessage: string;
    } | null>(null);

    const [requestFunctions, setRequestFunctions] = React.useState<{
      resolve: () => void;
      reject: () => void;
    } | null>(null);

    const onSuccess = React.useCallback(() => {
      setIsOpen(false);
      requestFunctions?.resolve();
    }, [requestFunctions]);

    const onFail = React.useCallback(() => {
      setIsOpen(false);
      requestFunctions?.reject();
    }, [requestFunctions]);

    const requestPasscode = React.useCallback(
      (options: {promptMessage: string}) => {
        setOptions(options);
        setIsOpen(true);

        return new Promise<void>((resolve, reject) => {
          setRequestFunctions({
            resolve: () => {
              resolve();
            },
            reject,
          });
        });
      },
      []
    );

    React.useImperativeHandle(
      ref,
      () => ({
        requestPasscode,
      }),
      []
    );

    return (
      <ModalDynamic
        isOpen={isOpen}
        webWidth={500}
        webMinHeight={200}
        onClose={onFail}
      >
        <Passcode prompt={options?.promptMessage} onSuccess={onSuccess} />
      </ModalDynamic>
    );
  }
);
