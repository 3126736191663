import * as React from 'react';
import {useChartCursorState, BundleChart} from '../Charts';
import {cell} from '@youtoken/ui.cell';
import {Box, Text} from '@youtoken/ui.primitives';
import {BundleChartDataResource} from '../state/Chart/BundleChartDataResource';
import {ChartWithControlsSuspenseFallback} from './SuspenseFallbacks';
import {ChartSmartCells} from './utils/types';
import {CellWrap} from './utils/CellWrap';
import {PanResponder} from '../PanResponder';

export const BundleChartSmartCell =
  cell<ChartSmartCells.BundleChartSmartCellProps>(
    ({source, sourceId, width, height, ChartPanComponent = PanResponder}) => {
      const {setPointIndex, pointIndex, setCursorX, cursorX} =
        useChartCursorState();

      const {
        isLoading,
        timePeriodFormatted,
        chartData,
        chartDataPeriodFormatted,
        hasData,
        refetch,
      } = BundleChartDataResource.use({
        source,
        sourceId,
      });

      return (
        <>
          <ChartPanComponent
            width={width}
            height={height}
            onCursorChange={setCursorX}
          >
            <BundleChart
              displayActivityIndicator
              isFetching={isLoading}
              data={chartData}
              height={height}
              width={width}
              refetch={refetch}
              hasData={hasData}
              cursorX={hasData ? cursorX : undefined}
              onCursorIndexChange={setPointIndex}
            />
          </ChartPanComponent>
          {hasData && pointIndex === undefined && (
            <Box
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              flexDirection="row"
              justifyContent="space-between"
              bg="$ui-background"
              px={{default: 16, phone: 24}}
            >
              <Text variant="$body-02-medium-accent" color="$text-02">
                {timePeriodFormatted}
              </Text>
              <Text variant="$body-02-medium-accent" color="$text-02">
                {chartDataPeriodFormatted}
              </Text>
            </Box>
          )}
        </>
      );
    },
    {
      displayName: 'BundleChartSmartCell',
      SuspenseFallback: ChartWithControlsSuspenseFallback,
      CellWrap: CellWrap,
    }
  );
