import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useSafeArea} from '@youtoken/ui.hooks';
import {Box, Text} from '@youtoken/ui.primitives';
import {AgreementsList} from '@youtoken/ui.elements';
import {useForm, SubmitButton} from '@youtoken/ui.form-elements';
import type {BundleWithdrawalFormState} from '../../state';
import {DataTable} from './DataTable';

export const Footer: React.FC = observer(() => {
  const {t} = useTranslation();

  const {bottom} = useSafeArea();

  const {
    form: {
      resources: {
        docs: {
          documents: {bundles},
        },
      },
      resultAmountFormatted,
      tickerFormatted,
      calculated: {loading},
    },
  } = useForm<BundleWithdrawalFormState>();

  const submitActionText = t('surface.bundles.withdrawal_form.submit');

  return (
    <Box
      pt={16}
      pb={bottom}
      px={24}
      borderColor="$ui-01"
      borderTopWidth={1}
      gap={24}
    >
      <Box gap={16}>
        <DataTable />
        <Text testID="SUBMIT_INFO" variant="$body-02" color="$text-02">
          {t('surface.bundles.withdrawal_form.result', {
            ticker: tickerFormatted,
            amount: resultAmountFormatted,
          })}
        </Text>
        <AgreementsList
          testID="AGREEMENTS"
          beforeListText={t(
            'agreements.documents.without_checkbox.start_text',
            {
              call_to_action: submitActionText,
            }
          )}
          items={bundles}
        />
      </Box>
      <SubmitButton loading={loading}>{submitActionText}</SubmitButton>
    </Box>
  );
});
