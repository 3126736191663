import * as React from 'react';
import {z} from 'zod';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {LegalAndInfoSurfaceContent} from './Content';

export const LegalAndInfoSurface = surface(
  {
    name: 'LegalAndInfo',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.more.screen_title.legal_info'),
    headerShown: true,
  },
  () => {
    return <VariableLayout PrimaryContent={<LegalAndInfoSurfaceContent />} />;
  }
);
