import * as React from 'react';
import {z} from 'zod';
import {i18n} from '@youtoken/ui.service-i18n';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {ManagePaymentMethodsSurfaceContent} from './ManagePaymentMethodsSurfaceContent';
import {Wrapper} from './components';

const onAddCard = () => SHARED_ROUTER_SERVICE.navigate('AddPaymentMethod');

export const ManagePaymentMethodsSurface = surface(
  {
    name: 'PaymentMethods',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.wallets.payment_methods'),
    headerShown: true,
  },
  () => {
    const {checkProductAvailability} = AuthMeResource.use({});

    React.useEffect(() => {
      if (!checkProductAvailability('depositFiatCheckoutBankCardFrame')) {
        SHARED_ROUTER_SERVICE.navigate('More', {});
      }
    }, [checkProductAvailability]);

    return (
      <VariableLayout
        PrimaryContent={
          // remove Wrapper when moving to new layout
          <Wrapper>
            <ManagePaymentMethodsSurfaceContent onAddCard={onAddCard} />
          </Wrapper>
        }
      />
    );
  }
);
