import {useResponsiveProp, ResponsiveValue, PropValue} from '@shopify/restyle';
import {type Theme} from '../themes';

export const useResponsive = <T extends PropValue>(
  prop: ResponsiveValue<T, Theme['breakpoints']>
) => {
  return useResponsiveProp<Theme, T>(prop);
};

export const useIsSmallMobile = () => {
  //  NOTE: starting with phone => false, default true
  return (
    useResponsive({
      default: 1,
      phone: 0,
    })! > 0
  );
};

export const useIsMobile = () => {
  return (
    useResponsive({
      default: 1,
      smallPhone: 1,
      phone: 1,
      largePhone: 1,
      tablet: 0,
      desktop: 0,
      mediumDesktop: 0,
      largeDesktop: 0,
    })! > 0
  );
};

export const useIsLargeMobileAndUp = () => {
  return (
    useResponsive({
      default: 0,
      smallPhone: 0,
      phone: 0,
      largePhone: 1,
      tablet: 1,
      desktop: 1,
      mediumDesktop: 1,
      largeDesktop: 1,
    })! > 0
  );
};

export const useIsTabletAndUp = () => {
  return (
    useResponsive({
      default: 0,
      smallPhone: 0,
      phone: 0,
      tablet: 1,
      desktop: 1,
      mediumDesktop: 1,
      largeDesktop: 1,
    })! > 0
  );
};

export const useIsDesktop = () => {
  return (
    useResponsive({
      default: 0,
      smallPhone: 0,
      phone: 0,
      tablet: 0,
      desktop: 1,
      mediumDesktop: 1,
      largeDesktop: 1,
    })! > 0
  );
};

export const useIsMediumDesktopAndUp = () => {
  return (
    useResponsive({
      default: 0,
      smallPhone: 0,
      phone: 0,
      tablet: 0,
      desktop: 0,
      mediumDesktop: 1,
      largeDesktop: 1,
    })! > 0
  );
};

export const useIsLargeDesktop = () => {
  return (
    useResponsive({
      default: 0,
      smallPhone: 0,
      phone: 0,
      tablet: 0,
      desktop: 0,
      mediumDesktop: 0,
      largeDesktop: 1,
    })! > 0
  );
};
