import * as React from 'react';
import {observer} from 'mobx-react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import {ProfileRewardsRoutes} from './ProfileRewardsRoutes';
import {webAppAdapter} from '@youtoken/ui.screen-components';
import {
  AppearanceSurface,
  ProfileSurface,
  VerificationStepsSurface,
} from '@youtoken/ui.surface-more';
import {UmaCreateSurface, UmaSettingsSurface} from '@youtoken/ui.surfaces-uma';
import {DeleteAccountSurface} from '@youtoken/ui.surface-delete-account';
import {LegalAndInfoSurface} from '@youtoken/ui.surface-legal-info';
import {InnerPageShell} from '../layout/Shell';
import {TrustedDevicesSurface} from '@youtoken/ui.surface-trusted-devices';
import {AntiPhishingSurface} from '@youtoken/ui.surface-anti-phishing';
import {SecurityActivitySurface} from '@youtoken/ui.surface-security-activity';
import {
  AddPaymentMethodSurface,
  ManagePaymentMethodsSurface,
} from '@youtoken/ui.surfaces-wallets';
import {AccountLevelsSurface} from '@youtoken/ui.surface-account-levels';

const ProfilePage = authRequired(webAppAdapter(ProfileSurface));
const VerificationStepsPage = authRequired(
  webAppAdapter(VerificationStepsSurface)
);
const TrustedDevicesPage = authRequired(webAppAdapter(TrustedDevicesSurface));
const SecurityActivityPage = authRequired(
  webAppAdapter(SecurityActivitySurface)
);
const AntiPhishingPage = authRequired(webAppAdapter(AntiPhishingSurface));
const PaymentMethodsPage = authRequired(
  webAppAdapter(ManagePaymentMethodsSurface)
);
const AddPaymentMethodPage = authRequired(
  webAppAdapter(AddPaymentMethodSurface)
);
const LegalAndInfoPage = authRequired(webAppAdapter(LegalAndInfoSurface));
const DeleteAccountPage = authRequired(webAppAdapter(DeleteAccountSurface));
const AppearancePage = authRequired(webAppAdapter(AppearanceSurface));
const UmaSettingsPage = authRequired(webAppAdapter(UmaSettingsSurface));
const UmaCreatePage = authRequired(webAppAdapter(UmaCreateSurface));
const AccountLevelsPage = authRequired(webAppAdapter(AccountLevelsSurface));

export const ProfileRoutes: React.FC<RouteComponentProps> = observer(
  ({match: {path}}) => {
    const {
      products: {loyalty, rewards},
    } = AuthMeResource.use({});

    return (
      <InnerPageShell>
        <SwitchWithNotFound>
          <Route exact path={`${path}`} component={ProfilePage} />
          <Route
            exact
            path={`${path}/verification`}
            component={VerificationStepsPage}
          />
          <Route
            exact
            path={`${path}/trusted_devices`}
            component={TrustedDevicesPage}
          />
          <Route
            exact
            path={`${path}/security_activity`}
            component={SecurityActivityPage}
          />
          <Route
            exact
            path={`${path}/anti-phishing`}
            component={AntiPhishingPage}
          />
          <Route
            exact
            path={`${path}/legal-info`}
            component={LegalAndInfoPage}
          />
          <Route
            exact
            path={`${path}/delete-account`}
            component={DeleteAccountPage}
          />
          {rewards.available && (
            <Route path={`${path}/rewards`} component={ProfileRewardsRoutes} />
          )}
          {loyalty.available && (
            <Route
              exact
              path={`${path}/account-level`}
              component={AccountLevelsPage}
            />
          )}
          <Route exact path={`${path}/appearance`} component={AppearancePage} />
          <Route
            exact
            path={`${path}/payment-methods`}
            component={PaymentMethodsPage}
          />
          <Route
            exact
            path={`${path}/payment-methods/add-card`}
            component={AddPaymentMethodPage}
          />
          <Route exact path={`${path}/uma`} component={UmaSettingsPage} />
          <Route exact path={`${path}/uma/create`} component={UmaCreatePage} />
        </SwitchWithNotFound>
      </InnerPageShell>
    );
  }
);
