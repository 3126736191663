import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {TextInput, TextInputProps} from '@youtoken/ui.inputs';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {CoinsPair} from '@youtoken/ui.elements';
import Big from 'big.js';
import {LogoColoredIconName} from '@youtoken/ui.icons';

interface RateTextInputProps extends TextInputProps {
  baseTicker: string;
  quoteTicker: string;
  editable?: boolean;
}

export const RateTextInput: React.FC<RateTextInputProps> = ({
  value,
  baseTicker,
  quoteTicker,
  onChangeText,
  editable = true,
  ...textInputProps
}) => {
  const baseTickerFormatted = baseTicker.toUpperCase();
  const quoteTickerFormatted = quoteTicker.toUpperCase();
  const precision = getCoinDecimalPrecision(quoteTicker);
  const amountPlaceholder = React.useMemo(
    () => Big(0).toFixed(precision),
    [precision]
  );

  const handleTextChange = React.useCallback(
    (value: string) => {
      if (!editable) {
        return;
      }
      onChangeText?.(normalizeAmountWithPrecision(value, precision));
    },
    [editable, onChangeText, precision]
  );

  return (
    <Box position="relative">
      <TextInput
        numeric
        value={value}
        placeholder={amountPlaceholder}
        textAlign="right"
        autoComplete="off"
        autoCorrect={false}
        autoCapitalize="none"
        importantForAutofill="no"
        keyboardType="decimal-pad"
        onChangeText={handleTextChange}
        editable={editable}
        LeftPartComponent={
          <Box flexDirection="row" alignItems="center" pl={16} height="100%">
            <Box mr={8}>
              <CoinsPair
                firstTicker={baseTicker as LogoColoredIconName}
                secondTicker={quoteTicker as LogoColoredIconName}
                iconSize={20}
                iconOffset={14}
              />
            </Box>
            <Box>
              <Text variant="$body-01-medium-accent">
                {baseTickerFormatted}/{quoteTickerFormatted}
              </Text>
            </Box>
          </Box>
        }
        RightPartComponent={
          <Box
            flex={1}
            justifyContent="center"
            alignItems="center"
            pr={16}
            ml={3}
          >
            <Text color="$text-02">{quoteTickerFormatted}</Text>
          </Box>
        }
        {...textInputProps}
      />
    </Box>
  );
};
