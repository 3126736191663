import * as React from 'react';
import {observer} from 'mobx-react';
import {FlatList, Platform} from 'react-native';
import {Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  type AutoConvertListProps,
  type AutoConvertUiAdoptedItem,
  AutoConvertListFeature,
  AutoConvertDetailsModal,
  AutoConvertListItem,
} from '../../../AutoConverts';

export const AutoConvertListSurfaceContent: React.FC<AutoConvertListProps> =
  observer(({ticker}) => {
    const {list: autoConvertsList, hasAutoConverts} =
      AutoConvertListFeature.use({
        ticker,
      });
    const [detailsModalId, setDetailsModalId] = React.useState<string | null>(
      null
    );

    const handleCloseModal = React.useCallback(() => {
      setDetailsModalId(null);
    }, []);

    const renderItem = React.useCallback(
      ({item, index}: {item: AutoConvertUiAdoptedItem; index: number}) => {
        const handleOpenModal = () => {
          setDetailsModalId(item.id);
        };

        return (
          <AutoConvertListItem
            item={item}
            testID={`AUTO_CONVERT_ITEM_${index}`}
            isLast={index === autoConvertsList.length - 1}
            onPress={handleOpenModal}
          />
        );
      },
      []
    );

    if (!hasAutoConverts) {
      SHARED_ROUTER_SERVICE.navigate('AccountStats');
    }

    return (
      <Box flex={Platform.OS !== 'web' ? 1 : undefined}>
        {detailsModalId !== null && (
          <AutoConvertDetailsModal
            isOpen
            onClose={handleCloseModal}
            id={detailsModalId}
            ticker={ticker}
          />
        )}
        <Box
          borderColor="$ui-01"
          borderWidth={{default: 0, desktop: 1}}
          overflow="hidden"
          borderRadius={{default: 0, desktop: 10}}
          mb={8}
          flex={1}
        >
          <FlatList
            data={autoConvertsList}
            renderItem={renderItem}
            ItemSeparatorComponent={Separator}
          />
        </Box>
      </Box>
    );
  });
