import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {ItemProps} from '../../types';

export const Summary: React.FC<ItemProps & BoxProps> = observer(
  ({item, ...boxProps}) => {
    const {
      tickerFormatted,
      isAmountPositive,
      equivalentFormatted,
      equivalentTickerSign,
      equivalentTickerFormatted,
      amountFormatted,
    } = item;

    return (
      <Box alignItems="flex-end" {...boxProps}>
        {Boolean(equivalentFormatted) && (
          <Text
            variant={isAmountPositive ? '$body-01-high-accent' : '$body-01'}
            color={isAmountPositive ? '$text-01' : '$text-02'}
            testID={`${tickerFormatted}_BALANCE_IN_${equivalentTickerFormatted}_AMOUNT`}
          >
            {equivalentTickerSign}
            {equivalentFormatted}
          </Text>
        )}
        <Text
          variant="$body-02"
          color="$text-02"
          testID={`${tickerFormatted}_BALANCE`}
        >
          {amountFormatted} {tickerFormatted}
        </Text>
      </Box>
    );
  }
);
