import {action, computed, observable} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {Wallet, WalletsResource} from '@youtoken/ui.resource-wallets';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  WalletsMethodTickersResource,
  WalletsMethodTickersResponse,
} from '@youtoken/ui.resource-wallets-method-tickers';
import {WalletsChooseTickerMethod, WalletsChooseTickerType} from '../types';
import {getIsMethodEnabled, getItemsBySearch, toggleFilter} from './utils';
import {getFeeString} from '../../PaymentMethods/utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {NETWORKS} from './consts';

type WalletsChooseTickerFeatureArgs = {
  type: WalletsChooseTickerType;
  method: WalletsChooseTickerMethod;
};

export type WalletsChooseTickerListItem = Wallet & {fee?: string};

export class WalletsChooseTickerFeature extends createFeature({
  getKey: ({type, method}: WalletsChooseTickerFeatureArgs) => {
    return `WalletsChooseTickerFeature({type: ${type}, method: ${method})`;
  },
  getResources: ({method}) => {
    return {
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      // NOTE: Sorry for this, for the current deposit now flow this API is useless
      ...(method.includes('withdraw')
        ? {
            walletsMethodItems: getResourceDescriptor(
              WalletsMethodTickersResource,
              {
                type: 'withdrawal',
                method,
              }
            ),
          }
        : {}),
    };
  },
}) {
  //#region wallets
  @computed
  get isWalletsListCreating() {
    return this.resources.walletsResource.isWalletsListCreating;
  }

  //NOTE: Only for withdraw for now
  @computed
  private get validTickers(): Map<string, WalletsMethodTickersResponse> {
    // NOTE: return Map with available tickers as keys and their items as values: [eur: {ticker: 'eur', fee '5'}]

    return new Map(
      (this.resources.walletsMethodItems?.data ?? []).map(
        (item: WalletsMethodTickersResponse) => [
          item.ticker,
          {ticker: item.ticker, fee: item.fee},
        ]
      )
    );
  }

  //NOTE: Only for withdraw for now because otherwise this.validTickers is empty
  @action
  private getTickersFormatted = (
    wallets: Wallet[],
    variant?: WalletsChooseTickerType
  ) => {
    // NOTE: wallets filtered by the validTickers Map and with fee added to the valid items
    const walletItemsFilteredByValidTickers = wallets.reduce<
      WalletsChooseTickerListItem[]
    >((acc, wallet) => {
      const validData = this.validTickers.get(wallet.ticker);

      if (validData) {
        const walletsChooseTickerListItem: WalletsChooseTickerListItem = wallet;

        // NOTE: add fee in wallet item
        if (variant === 'cryptoAndStable') {
          if (validData.fee.min) {
            const feeString =
              validData.fee.min?.round(getCoinDecimalPrecision(wallet.ticker)) +
              ' ' +
              wallet.ticker.toUpperCase();

            walletsChooseTickerListItem.fee = i18n.t(
              'surface.wallets.withdrawal_form.min_fee',
              {
                feeString,
              }
            );
          } else {
            // NOTE: some value is needed to get the correct version of a ticker item
            walletsChooseTickerListItem.fee = '';
          }
        } else {
          walletsChooseTickerListItem.fee = getFeeString(
            [validData.fee],
            wallet.ticker,
            i18n.t
          );
        }

        acc.push(walletsChooseTickerListItem);
      }

      return acc;
    }, []);

    return this.resources.walletsResource.sortWalletsByEquivalentAmount(
      walletItemsFilteredByValidTickers
    );
  };

  @computed
  get wallets(): WalletsChooseTickerListItem[] {
    // NOTE: Due to the fundamental difference between the deposit and withdrawal flows, WalletsMethodTickersResource can only
    // be used for withdrawals for now. Hope someone will redesign the wizards soon.
    if (this.args.method.includes('withdraw')) {
      if (this.args.type === 'fiat') {
        return this.getTickersFormatted(
          this.resources.walletsResource.fiatWallets
        );
      }

      if (this.args.type === 'cryptoAndStable') {
        return this.getTickersFormatted(
          this.resources.walletsResource.cryptoAndStableWallets,
          this.args.type
        );
      }

      if (this.args.type === 'all') {
        return this.getTickersFormatted(
          this.resources.walletsResource.walletsListOrderedByEquivalentAmount
        );
      }
    }

    if (this.args.type === 'fiat') {
      return this.resources.walletsResource.fiatWallets;
    }

    if (this.args.type === 'crypto') {
      return this.resources.walletsResource.cryptoWallets;
    }

    if (this.args.type === 'stable') {
      return this.resources.walletsResource.stableWallets;
    }

    if (this.args.type === 'cryptoAndStable') {
      return this.resources.walletsResource.cryptoAndStableWallets;
    }

    if (this.args.type === 'all') {
      return this.resources.walletsResource
        .walletsListOrderedByEquivalentAmount;
    }

    return [];
  }

  //#endregion wallets

  //#region search
  @observable search: string = '';

  @action updateSearch = (search: string) => {
    this.search = search;
  };

  @computed public get shouldHideSearchbar() {
    // NOTE: this.wallets is used instead of this.filteredItems because otherwise the search bar would appear and disappear depending on the filters
    return this.wallets.length <= 9;
  }
  //#endregion search

  //#region filters
  @observable
  selectedFilters: string[] = [];

  @computed
  get shownFilters(): string[] {
    if (this.args.type === 'fiat') {
      return [];
    }

    const walletsVersionsSet = new Set(
      this.wallets.flatMap(({versions}) => versions.map(({version}) => version))
    );

    return NETWORKS.filter(network => walletsVersionsSet.has(network));
  }

  @action toggleFilter = (filter: string) => {
    this.selectedFilters = toggleFilter(this.selectedFilters, filter);
  };

  @action resetFilters = () => {
    this.selectedFilters = [];
  };
  //#endregion filters

  //#region items
  @computed.struct
  get filteredItems(): WalletsChooseTickerListItem[] {
    let items = getItemsBySearch(this.wallets, this.search);

    return items.filter(wallet => {
      const methodEnabled = getIsMethodEnabled(wallet, this.args.method);

      if (!methodEnabled) {
        return false;
      }

      const networks = wallet.versions.flatMap(({version}) => version);

      // Check if all selected filters are included in the networks array
      return this.selectedFilters.every(filter => networks.includes(filter));
    });
  }

  //#endregion items

  @action
  reset = () => {
    this.resetFilters();
    this.updateSearch('');
  };
}
