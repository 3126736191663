import React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {Box, Text} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {RewardsOverview, ReferralsWidgetSmart} from '../../../smarts';

export const ReferralsAndBonusesScreenV3 = cell(() => {
  const {t} = useTranslation();

  const {
    authMe: {
      products: {
        rewardsChallenge: {available: rewardsChallengeAvailable},
      },
      updateReferralViewedDateTime,
      updateRewardViewedDateTime,
    },
    rewards: {
      data: {totalEarnedUSDFormatted},
    },
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    rewards: getResourceDescriptor(RewardsResource, {}),
  });

  React.useEffect(() => {
    updateReferralViewedDateTime();
    updateRewardViewedDateTime();
  }, []);

  return (
    <Box>
      <Box mb={16}>
        <Text variant="$body-02" color="$text-02" mb={4}>
          {t('surface.referral_program.referrals_and_bonuses.total_earned')}
        </Text>
        <Text variant="$heading-01" color="$text-01">
          ${totalEarnedUSDFormatted}
        </Text>
      </Box>
      <Box mb={{tablet: 24, default: 16}}>
        <ReferralsWidgetSmart />
      </Box>
      {rewardsChallengeAvailable && <RewardsOverview />}
    </Box>
  );
});
