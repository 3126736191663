import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SENTRY} from '@youtoken/ui.sentry';
import {FeeItemResponse} from './FeeResponse';

export interface FeeDepositResourceArgs {
  ticker: string;
  provider: string;
}

export class FeeDepositResource extends createResource({
  getKey: (args: FeeDepositResourceArgs) =>
    `FeeDepositResource: ticker:${args.ticker} provider:${args.provider}`,
  getData: (args: FeeDepositResourceArgs) =>
    TRANSPORT.API.get(`v1/fee/deposit`, {
      params: {ticker: args.ticker, provider: `${args.provider}BankCard`},
    })
      .then(res => {
        return deserialize(FeeItemResponse, res.data);
      })
      .catch(error => {
        SENTRY.capture(error, {source: 'FeeDepositResource'});
        return deserialize(FeeItemResponse, {enabled: false});
      }),
}) {}
