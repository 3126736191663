import {Companies} from '@youtoken/ui.resource-companies';
import {banks} from '../consts/banks';
import {composeWith} from './composeWith';

export type CompaniesRequisites = ReturnType<typeof getCompaniesRequisites>;

const getCompaniesRequisites = (companies: Companies) => {
  return {
    YouHodler: [
      {
        priority: 100,
        type: 'beneficiary',
        title: 'Beneficiary',
        value: companies.youhodler.name,
      },
      {
        priority: 200,
        type: 'beneficiaryAddress',
        title: 'Beneficiary Address',
        value: companies.youhodler.address,
      },
    ],
    YouHodlerFasterPayment: [
      {
        priority: 100,
        type: 'beneficiary',
        title: 'Beneficiary',
        value: 'YOUHODLER SA',
      },
      {
        priority: 200,
        type: 'beneficiaryAddress',
        title: 'Beneficiary Address',
        value: companies.youhodler.address,
      },
    ],
    Naumard: [
      {
        priority: 100,
        type: 'beneficiary',
        title: 'Beneficiary',
        value: companies.naumard.name,
      },
      {
        priority: 200,
        type: 'beneficiaryAddress',
        title: 'Beneficiary Address',
        value: companies.naumard.address,
      },
    ],
  };
};

const getNexPayYouhodler = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.YouHodler, banks.NexPay, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'LT14 3080 0200 0000 0750',
    },
  ]);

const getNexPayNaumard = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.Naumard, banks.NexPay, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'LT58 3080 0200 0000 0249',
    },
  ]);

const getEqualsMoneyEUR = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.YouHodlerFasterPayment, banks.EqualsMoney, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'GB27 RBOS 1610 7003 4123 17',
    },
  ]);

const getEqualsMoneyUSD = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.YouHodlerFasterPayment, banks.EqualsMoney, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'GB06 RBOS 1663 0000 8778 60',
    },
  ]);

const getEqualsMoneyGBP = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.YouHodlerFasterPayment, [
    {
      priority: 300,
      type: 'bankCode',
      title: 'Bank SWIFT Code',
      value: 'RBOSGB2L',
    },
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'GB96 RBOS 1510 0032 9054 74',
    },
    {
      priority: 500,
      type: 'bankName',
      title: 'Name of the bank',
      value: 'THE ROYAL BANK OF SCOTLAND PLC',
    },
    {
      priority: 600,
      type: 'bankAddress',
      title: 'Address of the bank',
      value: '36 ST. ANDREW SQUARE, EDINBURGH, United Kingdom',
    },
  ]);

const getEqualsMoneyGBPInsideUK = (companiesRequisites: CompaniesRequisites) =>
  composeWith(
    companiesRequisites.YouHodlerFasterPayment,
    banks.EqualsMoneyFasterPayment
  );

const getEqualsMoneyCHF = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.YouHodlerFasterPayment, banks.EqualsMoney, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'GB33 RBOS 1663 0000 8803 81',
    },
  ]);

const getIntergiroEUR = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.YouHodler, banks.Intergiro, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'SE02 9770 0000 0100 0804 4793',
    },
  ]);

export const getBankAccounts = (companies: Companies) => {
  const companiesRequisites = getCompaniesRequisites(companies);

  return {
    NexPayYouhodler: getNexPayYouhodler(companiesRequisites),
    NexPayNaumard: getNexPayNaumard(companiesRequisites),
    EqualsMoneyEUR: getEqualsMoneyEUR(companiesRequisites),
    EqualsMoneyUSD: getEqualsMoneyUSD(companiesRequisites),
    EqualsMoneyGBP: getEqualsMoneyGBP(companiesRequisites),
    EqualsMoneyGBPInsideUK: getEqualsMoneyGBPInsideUK(companiesRequisites),
    EqualsMoneyCHF: getEqualsMoneyCHF(companiesRequisites),
    IntergiroEUR: getIntergiroEUR(companiesRequisites),
  };
};
