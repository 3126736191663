import * as React from 'react';
import {type GestureResponderEvent} from 'react-native';
import {Box, TouchableBox, useTheme} from '@youtoken/ui.primitives';
import {Triangle} from './Triangle';

export type DropdownProps = {
  initialOpen?: boolean;
  renderTrigger: React.FC<{
    isOpen: boolean;
    toggle: () => void;
    close: () => void;
    open: () => void;
  }>;
  renderItems: React.FC<{
    isOpen: boolean;
    toggle: () => void;
    close: () => void;
    open: () => void;
  }>;
  contentProps?: any;
};

export const Dropdown: React.FC<DropdownProps> = ({
  initialOpen = false,
  renderTrigger,
  renderItems,
  contentProps,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(initialOpen);

  const toggle = React.useCallback(() => {
    setIsOpen(v => !v);
  }, []);

  const open = React.useCallback(() => setIsOpen(true), []);
  const close = React.useCallback(() => setIsOpen(false), []);

  const isOpenRef = React.useRef(isOpen);

  React.useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  const handleClick = (event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const modalRef = React.useRef<HTMLElement>(null);
  const triggerRef = React.useRef<HTMLElement>(null);

  const {colors} = useTheme();

  return (
    <>
      <Box position={isOpen ? 'relative' : 'static'} zIndex={isOpen ? 1000 : 0}>
        <TouchableBox
          ref={triggerRef}
          position="relative"
          zIndex={isOpen ? 2 : 1}
          onPress={handleClick}
        >
          {renderTrigger({toggle, close, open, isOpen})}
        </TouchableBox>

        {isOpen && (
          <>
            <TouchableBox
              onPress={close}
              position="fixed"
              top={0}
              left={0}
              right={0}
              bottom={0}
              backgroundColor="$blackout-02"
              z-index={99}
            />

            <TouchableBox
              ref={modalRef}
              position="absolute"
              left={0}
              onPress={handleClick}
              top="calc(100% + 15px)"
              backgroundColor="$ui-background"
              pt={10}
              pb={5}
              zIndex={10000}
              borderRadius={10}
              {...contentProps}
            >
              <Triangle
                colors={colors}
                style={{
                  left: 20,
                }}
              />
              {renderItems({toggle, close, open, isOpen})}
            </TouchableBox>
          </>
        )}
      </Box>
    </>
  );
};
