import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {Box, BoxProps, TouchableBox, Text} from '@youtoken/ui.primitives';
import {Switch, useWizard} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {ExchangeFormState} from '../../state';
import {ConvertWizardParams} from '../../../ConvertWizard';
import {Platform} from 'react-native';

export const AutoConvertSection: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    form: {
      amount,
      orderType,
      isLimitOrder,
      switchOffLimitOrder,
      desiredPair,
      desiredRateForAutoConvert,
      isDesiredRateReversed,
      hasDesiredRateError,
      setOrderType,
      setDesiredRate,
    },
    view: {limitOrderTriggerText},
  } = useForm<ExchangeFormState>();
  const {navigate} = useWizard<ConvertWizardParams, 'AutoConvert'>();

  const navigateToAutoConvert = React.useCallback(() => {
    navigate('AutoConvert', {
      fromTicker: desiredPair.baseTicker,
      toTicker: desiredPair.quoteTicker,
      initialAmount: amount,
      orderType,
      desiredRate: desiredRateForAutoConvert,
      isDesiredRateReversed,
      onSuccessNative: Platform.select({
        default: undefined,
        native: (desiredRate: string) => {
          setOrderType('limit');
          setDesiredRate(desiredRate);
        },
      }),
    });
  }, [
    amount,
    orderType,
    desiredPair,
    desiredRateForAutoConvert,
    isDesiredRateReversed,
  ]);

  const handleSwitchLimitOrder = React.useCallback(() => {
    isLimitOrder ? switchOffLimitOrder() : navigateToAutoConvert();
  }, [isLimitOrder, switchOffLimitOrder, navigateToAutoConvert]);

  return (
    <TouchableBox
      p={16}
      borderWidth={1}
      borderColor="$ui-03"
      borderRadius={12}
      onPress={navigateToAutoConvert}
      testID="AUTO_CONVERT_SECTION"
      gap={8}
      {...boxProps}
    >
      <Box flexDirection="row">
        <Box flexDirection="row" alignItems="center" flexGrow={1} mr={20}>
          <Icon name="pending" />
          <Label ml={8} mr={3} mb={0}>
            {t('surface.auto_convert.title')}
          </Label>
        </Box>
        <Switch
          value={isLimitOrder}
          onValueChange={handleSwitchLimitOrder}
          testID="SET_PENDING_ORDER_SWITCHER"
        />
      </Box>
      {Boolean(limitOrderTriggerText) && (
        <Box
          backgroundColor="$interactive-02"
          borderRadius={6}
          alignSelf="flex-start"
          px={8}
          py={4}
        >
          <Text variant="$body-02" color="$text-05">
            {limitOrderTriggerText}
          </Text>
        </Box>
      )}
      {Boolean(hasDesiredRateError) && (
        <Box alignSelf="flex-start">
          <Text variant="$body-02" color="$danger-01">
            {t('surface.convert.desired_rate.error')}
          </Text>
        </Box>
      )}
    </TouchableBox>
  );
});
