import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {
  type StackNavigatorScreenWizardWrappedProps,
  createWizard,
} from '@youtoken/ui.elements';
import {CryptoDepositWizardChooseTicker} from './ChooseTicker';
import {CryptoDepositWizardDirectDeposit} from './Deposit';
import {CryptoDepositMethods} from './ChooseMethod';
import {
  getUmaCreateTitle,
  getUmaSettingsTitle,
  UmaCreateCell,
  UmaSettingsCell,
} from '../../Uma';

export enum CryptoDepositWizardScreenName {
  CHOOSE_TICKER = 'chooseTicker',
  CHOOSE_METHOD = 'chooseMethod',
  DIRECT_DEPOSIT = 'directDeposit',
  UMA = 'uma',
  UMA_CREATE = 'umaCreate',
}

export interface CryptoDepositWizardNavigator {
  [CryptoDepositWizardScreenName.CHOOSE_TICKER]: {
    method: CryptoDepositWizardScreenName;
  };
  [CryptoDepositWizardScreenName.CHOOSE_METHOD]: {
    ticker?: string;
  };
  [CryptoDepositWizardScreenName.DIRECT_DEPOSIT]: {
    ticker: string;
    version?: string;
  };
  [CryptoDepositWizardScreenName.UMA]: {};
  [CryptoDepositWizardScreenName.UMA_CREATE]: {};
}

const Wizard = createWizard<CryptoDepositWizardNavigator>();

export interface CryptoDepositWizardProps {
  ticker?: string;
  onPressBack?: () => void;
}

export const CryptoDepositWizard: React.FC<CryptoDepositWizardProps> = cell(
  ({ticker, onPressBack}) => {
    const {t} = useTranslation();

    return (
      <Wizard.Navigator
        initialName={CryptoDepositWizardScreenName.CHOOSE_METHOD}
        initialProps={{ticker}}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={CryptoDepositWizardScreenName.CHOOSE_TICKER}
          options={() => {
            return {
              title: t(
                'surface.wallets.deposit_wizard.select_ticker_crypto.title'
              ),
            };
          }}
          component={CryptoDepositWizardChooseTicker}
        />
        <Wizard.Screen
          name={CryptoDepositWizardScreenName.CHOOSE_METHOD}
          options={() => {
            return {
              title: t(
                'surface.wallets.deposit_wizard.select_ticker_crypto.title'
              ),
            };
          }}
          component={CryptoDepositMethods}
        />
        <Wizard.Screen
          name={CryptoDepositWizardScreenName.DIRECT_DEPOSIT}
          options={{headerShown: false}}
          component={CryptoDepositWizardDirectDeposit}
        />
        <Wizard.Screen
          name={CryptoDepositWizardScreenName.UMA}
          options={{title: getUmaSettingsTitle()}}
          component={UmaSettingsCell}
        />
        <Wizard.Screen
          name={CryptoDepositWizardScreenName.UMA_CREATE}
          options={{title: getUmaCreateTitle()}}
          component={UmaCreateCell}
        />
      </Wizard.Navigator>
    );
  }
);

export const CryptoDepositWizardWrapped = ({
  route: {
    params: {ticker},
  },
  navigation: {goBack},
}: StackNavigatorScreenWizardWrappedProps<{ticker: string}>) => {
  return <CryptoDepositWizard ticker={ticker} onPressBack={goBack} />;
};
