import * as React from 'react';
import {z} from 'zod';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {UmaCreateSurfaceContent} from './UmaCreateSurfaceContent';
import {UmaCreateSurfaceWrapper} from './Wrapper';

export const UmaCreateSurface = surface(
  {
    name: 'UmaCreate',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.wallets.uma.create'),
    headerShown: true,
  },
  () => {
    return (
      <VariableLayout
        PrimaryContent={
          <UmaCreateSurfaceWrapper>
            <UmaCreateSurfaceContent onSuccess={SHARED_ROUTER_SERVICE.goBack} />
          </UmaCreateSurfaceWrapper>
        }
      />
    );
  }
);
