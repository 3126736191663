import * as React from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  type BoxProps,
  Heading,
  Text,
  useTheme,
} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Separator} from '@youtoken/ui.elements';
import {Item} from './Item';

export const MainContent: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();
  const {spacing} = useTheme();

  return (
    <Box {...props}>
      <Heading variant="$heading-02" mb={16}>
        {t('surface.profile.delete_account.review')}
      </Heading>
      <Item
        icon="wallets"
        title={t('surface.profile.delete_account.steps.withdraw')}
        text={t('surface.profile.delete_account.steps.withdraw.description')}
      />
      <Separator />
      <Item
        icon="fiat_deposit"
        title={t('surface.profile.delete_account.steps.check_deposits')}
        text={t(
          'surface.profile.delete_account.steps.check_deposits.description'
        )}
      />
      <Separator />
      <Item
        icon="trading"
        title={t('surface.profile.delete_account.steps.close_deals')}
        text={t('surface.profile.delete_account.steps.close_deals.description')}
      />

      <Box py={spacing['$screen-default-offset']}>
        <Text variant="$body-01" color="$text-02">
          {t('surface.profile.delete_account.will_contact')}
        </Text>
      </Box>
    </Box>
  );
});
