import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {SavingsSummaryAprSmart} from '@youtoken/ui.surface-savings';
import {IBANBadge} from '@youtoken/ui.surface-bank-wire';
import {ItemProps} from '../../types';

export const InfoAndRate: React.FC<ItemProps & BoxProps> = observer(
  ({
    showSavings,
    variant = 'list',
    needActivatePersonalIBAN,
    item,
    ...boxProps
  }) => {
    const {
      ticker,
      tickerName,
      rateFormatted,
      rateDiffPercentFormatted,
      isRateDiffPositive,
      tickerFormatted,
      equivalentTickerFormatted,
      equivalentTickerSign,
    } = item;

    const handleBadgePress = React.useCallback(() => {
      if (variant === 'detailed') {
        if (needActivatePersonalIBAN) {
          SHARED_ROUTER_SERVICE.navigate('ActivatePersonalIBAN', {ticker});
        } else {
          SHARED_ROUTER_SERVICE.navigate('FiatDepositBankWire', {ticker});
        }
      } else {
        SHARED_ROUTER_SERVICE.navigate('WalletsItem', {ticker});
      }
    }, [variant, ticker, needActivatePersonalIBAN]);

    return (
      <Box flexDirection="row" alignItems="center" {...boxProps}>
        <Box mr={10}>
          <LogoColored name={ticker as LogoColoredIconName} size={32} />
        </Box>
        <Box>
          <Box flexDirection="row" alignItems="center">
            <Text variant="$body-01-high-accent" mr={3}>
              {tickerName}
            </Text>
            {Boolean(showSavings) && <SavingsSummaryAprSmart item={item} />}
            <IBANBadge ticker={ticker} onPress={handleBadgePress} />
          </Box>
          {item.shouldDisplayRate && (
            <Box
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
            >
              <Text
                variant="$body-02"
                color="$text-02"
                testID={`${tickerFormatted}_TO_${equivalentTickerFormatted}_RATE`}
              >
                {equivalentTickerSign}
                {rateFormatted}{' '}
              </Text>
              <Text
                variant="$body-02"
                color={isRateDiffPositive ? '$success-01' : '$danger-01'}
                testID={`${tickerFormatted}_TO_${equivalentTickerFormatted}_RATE_DIFF`}
              >
                {rateDiffPercentFormatted}%
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
