import Big from 'big.js';
import {serializable} from 'serializr';
import {computed, observable} from 'mobx';
import {bigNumber, duration} from '@youtoken/ui.utils-serializr';
import {Duration} from '@youtoken/ui.date-fns';
import {addDaysFormatted} from '@youtoken/ui.formatting-utils';

export class LoanReopenResponse {
  @observable
  @serializable(bigNumber())
  apr!: Big;

  @observable
  @serializable(bigNumber())
  ltv!: Big;

  @observable
  @serializable(duration())
  maxDuration!: Duration;

  @observable
  @serializable(bigNumber())
  mcPrice!: Big;

  @observable
  @serializable(bigNumber())
  newMc!: Big;

  @observable
  @serializable(bigNumber())
  overdraftDiff!: Big;

  @observable
  @serializable(bigNumber())
  reopenFee!: Big;

  @observable
  @serializable(bigNumber())
  reopenFeeAmount!: Big;

  @observable
  @serializable(bigNumber())
  settlementFeeAmount!: Big;

  @observable
  @serializable(bigNumber())
  totalAmount!: Big;

  @computed get aprFormatted() {
    return this.apr.mul(100).toFixed();
  }

  @computed get ltvFormatted() {
    return this.ltv.mul(100).toFixed();
  }

  @computed get reopenFeeFormatted() {
    return this.reopenFee.mul(100).toFixed();
  }

  @computed get until() {
    const durationInDays = this.maxDuration.days;

    return durationInDays
      ? addDaysFormatted(new Date(Date.now()), durationInDays).toUTCString()
      : undefined;
  }
}
