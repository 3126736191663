import * as React from 'react';
import axios, {type AxiosRequestConfig} from 'axios';
import {ModalDynamic} from '@youtoken/ui.modal';
import {Box} from '@youtoken/ui.primitives';
import {TermsAndConditionsFooter} from './components';
import {useStyle} from './hooks';
import {type TermsAndConditionsImperativeMethods} from '../types';
import {type ModalStatus} from './types';
import {AxiosError} from '@youtoken/ui.errors';
import {ChallengeResult} from '@youtoken/ui.challenge-service/src/types';

export const TermsAndConditionsComponent = React.forwardRef<
  TermsAndConditionsImperativeMethods,
  {}
>((_, ref) => {
  const [status, setStatus] = React.useState<ModalStatus>('closed');
  const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
  const resolveAcceptPromiseRef = React.useRef<
    ((value?: AxiosRequestConfig) => void) | null
  >(null);
  const rejectAcceptPromiseRef = React.useRef<((error: Error) => void) | null>(
    null
  );
  const [html, _setHtml] = React.useState('');
  const [bottomElement, setBottomElement] =
    React.useState<HTMLDivElement | null>(null);

  const setHtml = React.useCallback(async (link: string) => {
    return axios.get(link).then(res => {
      _setHtml(res.data);
    });
  }, []);

  React.useImperativeHandle(ref, () => ({
    show: (
      link: string,
      originalError: AxiosError
    ): Promise<ChallengeResult> => {
      if (!originalError.config) {
        return Promise.reject(new Error('originalError.config is missing'));
      }

      const config = originalError.config;

      return new Promise<ChallengeResult>((resolve, reject) => {
        const data = {
          ...JSON.parse(config.data),
          acceptedTC: true,
        };

        resolveAcceptPromiseRef.current = () => {
          resolve({
            type: 'retry-request',
            payload: {
              config: {
                ...config,
                data,
              },
            },
          });
        };
        rejectAcceptPromiseRef.current = reject;
        setHtml(link).then(() => {
          setStatus('open');
        });
      });
    },
  }));

  const handleClose = React.useCallback(() => {
    rejectAcceptPromiseRef.current?.(new Error('terms-and-conditions'));
    resolveAcceptPromiseRef.current = null;
    rejectAcceptPromiseRef.current = null;
    setStatus('closed');
    setIsScrolledToBottom(false);
  }, []);

  const css = useStyle();

  const applyCss = React.useCallback(
    (body: string) => `
    <div>
      <style>
        ${css}
      </style>
      ${body}
    <div>
    `,
    [css]
  );

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry?.isIntersecting) {
        setIsScrolledToBottom(true);
        if (status === 'open') {
          setTimeout(() => {
            setStatus('read');
          }, 500);
        }
      } else {
        setIsScrolledToBottom(false);
      }
    });
    if (bottomElement) {
      observer.observe(bottomElement);
    }
    return () => {
      observer.disconnect();
    };
  }, [bottomElement, status]);

  const handleAccept = React.useCallback(() => {
    resolveAcceptPromiseRef.current?.();
    resolveAcceptPromiseRef.current = null;
    rejectAcceptPromiseRef.current = null;
    setStatus('closed');
    setIsScrolledToBottom(false);
  }, []);

  const handleScrollDown = React.useCallback(() => {
    bottomElement?.scrollIntoView({behavior: 'smooth'});
  }, [bottomElement]);

  return (
    <ModalDynamic
      isOpen={status !== 'closed'}
      onClose={handleClose}
      webWidth={700}
      mt={100}
      mb={100}
    >
      <div
        style={{padding: '20px'}}
        dangerouslySetInnerHTML={{__html: applyCss(html)}}
      />
      <TermsAndConditionsFooter
        onAccept={handleAccept}
        onScrollDown={handleScrollDown}
        isBottom={isScrolledToBottom}
        isRead={status === 'read'}
        p={20}
      />
      <Box
        width={1}
        height={1}
        backgroundColor="$transparent"
        ref={setBottomElement}
        mt={-40}
      />
    </ModalDynamic>
  );
});
