import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {AutoConvertFormState} from '../../state';
import {useForm} from '@youtoken/ui.form-elements';

export const CurrentRate: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {rateCurrentFormatted},
    view: {fromTickerFormatted, toTickerFormatted},
  } = useForm<AutoConvertFormState>();

  return (
    <Box {...boxProps}>
      <Text variant="$body-02" color="$text-02">
        {t('surface.auto_converts.current_rate')}: 1 {fromTickerFormatted} ={' '}
        {rateCurrentFormatted} {toTickerFormatted}
      </Text>
    </Box>
  );
});
