import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {createWizard} from '@youtoken/ui.elements';
import {cell} from '@youtoken/ui.cell';
import {CryptoDirectDeposit} from './CryptoDirect';
import {
  CryptoDirectDepositChooseNetwork,
  getCryptoDirectDepositChooseNetworkTitle,
} from './CryptoChooseNetwork';

export enum CryptoDirectDepositWizardScreenName {
  CRYPTO_DIRECT_CHOOSE_NETWORKS = 'depositCryptoDirectNetworks',
  CRYPTO_DIRECT = 'depositCryptoDirect',
}

export interface CryptoDirectDepositWizardNavigator {
  [CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT]: {
    ticker: string;
    version?: string;
  };
  [CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT_CHOOSE_NETWORKS]: {
    ticker: string;
  };
}

const Wizard = createWizard<CryptoDirectDepositWizardNavigator>();

export interface CryptoDirectDepositWizardProps {
  ticker: string;
  version?: string;
  onPressBack?: () => void;
}

export const CryptoDirectDepositWizard: React.FC<CryptoDirectDepositWizardProps> =
  cell(({ticker, version, onPressBack}) => {
    const {t} = useTranslation();

    return (
      <Wizard.Navigator
        initialName={
          version
            ? CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT
            : CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT_CHOOSE_NETWORKS
        }
        initialProps={{
          ticker,
          version,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT}
          options={({route: {params}}: any) => ({
            headerShown: true,
            title: t('surface.wallets.crypto_deposit.title', {
              ticker: params.ticker.toUpperCase(),
            }),
          })}
          component={CryptoDirectDeposit}
        />
        <Wizard.Screen
          name={
            CryptoDirectDepositWizardScreenName.CRYPTO_DIRECT_CHOOSE_NETWORKS
          }
          options={({route: {params}}: any) => ({
            headerShown: true,
            title: getCryptoDirectDepositChooseNetworkTitle(params.ticker),
          })}
          component={CryptoDirectDepositChooseNetwork}
        />
      </Wizard.Navigator>
    );
  });
