import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {BundleDepositFormState} from '../../../state';

export const DataTable: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {
      calculated: {loading, data},
    },
  } = useForm<BundleDepositFormState>();

  if (!data) {
    return null;
  }

  return (
    <InformationTable
      testID="DATA_TABLE"
      opacity={loading ? 0.7 : 1}
      px={0}
      py={0}
      gap={12}
    >
      <InformationTableRow
        testID="MIN_DEPOSIT"
        left={t('surface.bundles.deposit_form.min_deposit')}
        right={
          <Text variant="$body-02" color="$text-02">
            {data.minDepositFormatted} {data.tickerFormatted}
          </Text>
        }
        mb={0}
      />
      <InformationTableRow
        testID="FEE"
        left={t('surface.bundles.deposit_form.fee')}
        right={
          <Text variant="$body-02" color="$text-02">
            {data.feeFormatted} {data.tickerFormatted}
          </Text>
        }
        mb={0}
      />
    </InformationTable>
  );
});
