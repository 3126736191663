import * as React from 'react';
import {useResource} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {PromoBanner} from '@youtoken/ui.elements';
import {TouchableBoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {
  openQuizByLink,
  HODLsListFeature,
} from '@youtoken/ui.surface-hodl-positions';

export const TraderQuizPromo = cell<TouchableBoxProps>(props => {
  const {accountId, enableHodlQuiz, traderQuizEnabled, setTraderQuizEnabled} =
    useResource(AuthMeResource, {});
  const {isEmpty} = HODLsListFeature.use({});

  const handlePress = React.useCallback(() => {
    openQuizByLink(accountId, 'multihodl');
  }, [accountId]);

  const handlePressClose = React.useCallback(() => {
    DATA_LAYER.trackStrict('hodl-quiz-removed', {});
    setTraderQuizEnabled(false);
  }, []);

  const {t} = useTranslation();

  if (!enableHodlQuiz || !traderQuizEnabled) {
    return null;
  }

  return (
    <PromoBanner
      text={t('surface.hodls.quiz.promo_text')}
      imageName="multihodl"
      showActionButton
      buttonText={t('common.actions.start')}
      onPressBanner={handlePress}
      onPressClose={!isEmpty ? handlePressClose : undefined} // NOTE: not show close button in case user never created HODLs
      {...props}
    />
  );
});
