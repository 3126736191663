import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox, useResponsive} from '@youtoken/ui.primitives';
import {LoyaltyIcon} from '@youtoken/ui.icons';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {cell} from '@youtoken/ui.cell';

type AccountLevelButtonProps = {
  showText: boolean;
  onPress: () => void;
};

export const AccountLevelButton: React.FC<AccountLevelButtonProps> = cell(
  ({showText, onPress}) => {
    const {
      currentLevelData: {name, cardColor, cardIconName},
    } = LoyaltyResource.use({});

    return (
      <TouchableBox
        bg={cardColor}
        borderRadius={16}
        gap={4}
        py={6}
        pl={showText ? 8 : 6}
        pr={showText ? 12 : 6}
        flexDirection="row"
        alignItems="center"
        onPress={onPress}
      >
        <LoyaltyIcon name={cardIconName} size={20} />
        {showText && (
          <Text variant="$body-01" color="$text-01">
            {name}
          </Text>
        )}
      </TouchableBox>
    );
  }
);
