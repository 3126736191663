import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {coinNames} from '@youtoken/ui.coin-utils';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {type WithdrawalForm} from '../../state/WithdrawalForm';
import {FormInputField} from '../FormInputField';

export const FieldAmount: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {instance},
    view: {
      ticker,
      tickerFormatted,
      walletAmount,
      walletAmountFormatted,
      isWalletEmpty,
    },
  } = useForm<WithdrawalForm>();

  const normalizer = React.useCallback(
    (value: string) => normalizeAmountByTicker(value, ticker),
    [ticker, normalizeAmountByTicker]
  );

  const handleClickMaxAmount = React.useCallback(() => {
    instance
      .$('amount')
      .set('value', normalizeAmountByTicker(walletAmount.toString(), ticker));
  }, [instance, ticker, walletAmount, normalizeAmountByTicker]);

  return (
    <FormInputField
      numeric
      name="amount"
      testID="FIAT_WITHDRAWAL_BANK_WIRE_AMOUNT"
      maxLength={15}
      textAlign="right"
      keyboardType="decimal-pad"
      placeholder="0.00"
      normalizer={normalizer}
      label={
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Label>{t('surface.wallets.withdrawal_bank_wire_form.amount')}</Label>

          <TouchableBox
            px={8}
            py={4}
            borderRadius={4}
            backgroundColor="$interactive-02"
            testID="ALL_BUTTON"
            onPress={handleClickMaxAmount}
            disabled={isWalletEmpty}
          >
            <Text variant="$body-02-medium-accent" color="$text-05">
              {t('surface.wallets.withdrawal_bank_wire_form.amount_all')}{' '}
              <Text variant="$body-02-medium-accent">
                {walletAmountFormatted}
              </Text>{' '}
              {tickerFormatted}
            </Text>
          </TouchableBox>
        </Box>
      }
      LeftPartComponent={
        <Box
          height="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          pl={15}
        >
          <LogoColored name={ticker as LogoColoredIconName} size={20} />

          <Text ml={8} variant="$body-01-medium-accent">
            {coinNames[ticker]}
          </Text>
        </Box>
      }
      RightPartComponent={
        <Box
          height="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          ml={3}
          pr={15}
        >
          <Text color="$text-02">{tickerFormatted}</Text>
        </Box>
      }
    />
  );
});
