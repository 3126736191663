import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {useSafeArea} from '@youtoken/ui.hooks';
import {ExchangeFormState} from '../../state';
import {type ExchangeFormFooterProps} from './types';

export const ExchangeFormFooterV2: React.FC<
  ExchangeFormFooterProps & BoxProps
> = observer(({...boxProps}) => {
  const {t} = useTranslation();
  const {bottom} = useSafeArea();

  const {
    form: {checkIsLoading, rateTimeLeft, isLimitOrder},
    view: {conversionTickerFormatted},
  } = useForm<ExchangeFormState>();

  const buttonText = isLimitOrder
    ? t('surface.auto_converts.form.submit')
    : `${t('surface.wallets.conversion_form.submit', {
        amount: conversionTickerFormatted,
      })} (${rateTimeLeft})`;

  return (
    <Box
      p={24}
      pb={bottom}
      borderTopWidth={1}
      borderColor="$ui-01"
      {...boxProps}
    >
      <SubmitButton testID="CONVERSION_SUBMIT_BUTTON" loading={checkIsLoading}>
        {buttonText}
      </SubmitButton>
    </Box>
  );
});
