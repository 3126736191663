import * as React from 'react';
import {z} from 'zod';
import {SurfaceDefinition, type TSurfaceDefenitionOptions} from './types';
import {useSurfaceParams} from './SurfaceParamsProvider';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {observer} from 'mobx-react';
import {SurfaceMetaProvider} from './SurfaceMetaProvider';

const SurfaceWrapper = function SurfaceWrapper({
  children,
}: React.PropsWithChildren<{}>) {
  return <Box flex={1}>{children}</Box>;
};

export function surface<
  Name extends string,
  ParamsSchema extends z.ZodObject<z.ZodRawShape>
>(
  {
    name,
    paramsSchema,
    getTitle,
    headerShown = true,
    headerLeft,
    headerRight,
  }: TSurfaceDefenitionOptions<Name, ParamsSchema>,
  Component: React.ComponentType<z.infer<ParamsSchema>>
): SurfaceDefinition<Name, ParamsSchema> {
  const ObservingComponent = observer(Component);

  // should be put into error boundary
  const SurfaceComponent = cell(
    () => {
      const params = useSurfaceParams<z.infer<ParamsSchema>>();

      paramsSchema.parse(params);

      return (
        <SurfaceMetaProvider name={name}>
          <ObservingComponent {...params} />
        </SurfaceMetaProvider>
      );
    },
    {
      CellWrap: SurfaceWrapper,
    }
  );

  SurfaceComponent.displayName = `surface<(${name})>`;

  return {
    name,
    paramsSchema,
    component: SurfaceComponent,
    getTitle,
    headerShown,
    headerLeft,
    headerRight,
  };
}
