import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BaseAction} from './BaseAction';
import type {ActionProps} from '../types';

export const SparksAction: React.FC<ActionProps & BoxProps> = observer(
  ({
    sparksAmountFormatted,
    operationVolumeAmount,
    operationVolumeAmountFormatted,
    product,
    volumeEquivalentFormatted,
    multiplierFormatted,
    onPress,
    ...boxProps
  }) => {
    const {t} = useTranslation();

    const benefitMainBadge = `+${sparksAmountFormatted}`;

    const benefitTitle = t('surface.upgrade_modal.sparks.benefit_title', {
      amount: sparksAmountFormatted,
    });

    const benefitSmallBadge =
      operationVolumeAmount.toNumber() % 1000
        ? `+${operationVolumeAmount}`
        : `+${operationVolumeAmount.toString().slice(0, -3)}K`;

    const benefitText = React.useMemo(() => {
      if (product === 'hodl') {
        return t(`surface.upgrade_modal.hodl_benefit`, {
          volumeEquivalent: volumeEquivalentFormatted,
          multiplier: multiplierFormatted,
        });
      }
      if (product === 'convert') {
        return t(`surface.upgrade_modal.convert_benefit`, {
          volumeEquivalent: volumeEquivalentFormatted,
        });
      }
    }, [t, product, volumeEquivalentFormatted, multiplierFormatted]);

    return (
      <BaseAction
        chevronColor="$interactive-01"
        borderColor="$attention-02"
        flex={1}
        onPress={onPress}
        {...boxProps}
      >
        <Box
          px={8}
          height={32}
          borderRadius={32}
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          backgroundColor="$attention-02"
          mr={12}
        >
          <Icon name="spark" color="$attention-01" size={16} />
          <Text variant="$body-03-high-accent" color="$attention-01">
            {benefitMainBadge}
          </Text>
        </Box>

        <Box flexDirection="column" flex={0.95}>
          <Box flexDirection="row" alignItems="center" mb={3}>
            <Text variant="$body-02-high-accent">{benefitTitle}</Text>
            <Box
              flexDirection="row"
              backgroundColor="$interactive-02"
              ml={6}
              px={6}
              py={2}
              borderRadius={24}
            >
              <Icon name="volume" size={12} color="$interactive-01" />
              <Text variant="$body-03" color="$interactive-01">
                {benefitSmallBadge}
              </Text>
            </Box>
          </Box>

          <Text variant="$body-02" color="$text-02">
            {benefitText}
          </Text>
        </Box>
      </BaseAction>
    );
  }
);
