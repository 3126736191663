import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  ManagePaymentMethodsSurfaceContent,
  type ManagePaymentMethodsSurfaceContentProps,
} from './ManagePaymentMethodsSurfaceContent';

export const ManagePaymentMethodsCell: React.FC<ManagePaymentMethodsSurfaceContentProps> =
  cell(({onAddCard}) => {
    const {checkProductAvailability} = AuthMeResource.use({});

    React.useEffect(() => {
      if (!checkProductAvailability('depositFiatCheckoutBankCardFrame')) {
        SHARED_ROUTER_SERVICE.navigate('More', {});
      }
    }, [checkProductAvailability]);
    return <ManagePaymentMethodsSurfaceContent onAddCard={onAddCard} />;
  });
