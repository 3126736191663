import * as React from 'react';
import {ScrollView} from 'react-native';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {FeeAllResource} from '@youtoken/ui.resources-fee';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {
  FeeRow,
  FieldAmount,
  IncentivesBonuses,
  PaymentDataRow,
  Submit,
} from './components';
import {UnlimintCoDiFormArgs} from './types';
import {UnlimintCoDiForm} from './state/UnlimintCoDiForm';

export const UnlimintCoDi: React.FC<UnlimintCoDiFormArgs> = cell(
  ({ticker, onSubmit}) => {
    const resources = useResources({
      feeAll: getResourceDescriptor(FeeAllResource, {}),
      rates: getResourceDescriptor(RatesResource, {}),
    });

    const form = makeForm(
      () =>
        new UnlimintCoDiForm(
          {
            ticker,
            onSubmit,
          },
          resources
        )
    );

    return (
      <Form form={form}>
        <ScrollView
          keyboardDismissMode="interactive"
          keyboardShouldPersistTaps="handled"
          style={{
            flex: 1,
          }}
          contentContainerStyle={{
            minHeight: '100%',
          }}
        >
          <Box flex={1} p={24}>
            <FieldAmount />
            <IncentivesBonuses mb={8} />
            <FeeRow />
            <PaymentDataRow />
          </Box>
        </ScrollView>
        <Submit />
      </Form>
    );
  }
);
