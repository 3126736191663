import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {
  createWizard,
  type StackNavigatorScreenWizardWrappedProps,
} from '@youtoken/ui.elements';
import {DepositMethodEnum} from '../../constants';
import {FiatDepositWizardChooseTicker} from './ChooseTicker';
import {FiatDepositWizardChooseMethod} from './ChooseMethod';
import {FiatDepositWizardBankCard} from './BankCard';
import {FiatDepositBankWireWizardBankWire} from './BankWire';
import {getFiatDepositWizardCoDiOptions, FiatDepositWizardCoDi} from './CoDi';
import {getFiatDepositWizardSPEIOptions, FiatDepositWizardSPEI} from './SPEI';
import {FiatDepositWizardVolet} from './Volet';
import {getFiatDepositBankWireWizardScreenOptions} from '../FiatDepositBankWireWizard';
import {type CardDepositMethods} from '../CardDepositWizard';
import {Providers} from '../../components';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  UmaCreateCell,
  getUmaCreateTitle,
  getUmaSettingsTitle,
  UmaSettingsCell,
} from '../../Uma';

export enum FiatDepositWizardScreenName {
  CHOOSE_TICKER = 'chooseTicker',
  CHOOSE_METHOD = 'chooseMethod',
  APPLE_PAY = DepositMethodEnum.APPLE_PAY,
  BANK_CARD = DepositMethodEnum.BANK_CARD,
  BANK_CARD_IFRAME = DepositMethodEnum.BANK_CARD_IFRAME,
  BANK_WIRE = DepositMethodEnum.BANK_WIRE,
  CODI = DepositMethodEnum.CODI,
  SPEI = DepositMethodEnum.SPEI,
  VOLET = DepositMethodEnum.VOLET,
  UMA = DepositMethodEnum.UMA,
  UMA_CREATE = 'umaCreate',
  SKRILL = DepositMethodEnum.SKRILL,
}

export interface FiatDepositWizardScreenPropsWithTicker {
  ticker: string;
}

export interface FiatDepositWizardCheckoutScreensProps {
  ticker: string;
  provider: Providers;
  operationMethod: CardDepositMethods;
}

export interface FiatDepositWizardNavigator {
  [FiatDepositWizardScreenName.CHOOSE_TICKER]: {};
  [FiatDepositWizardScreenName.CHOOSE_METHOD]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.APPLE_PAY]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.BANK_CARD]: FiatDepositWizardCheckoutScreensProps;
  [FiatDepositWizardScreenName.BANK_CARD_IFRAME]: FiatDepositWizardCheckoutScreensProps;
  [FiatDepositWizardScreenName.BANK_WIRE]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.CODI]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.SPEI]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.VOLET]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.UMA]: {};
  [FiatDepositWizardScreenName.UMA_CREATE]: {};
  [FiatDepositWizardScreenName.SKRILL]: FiatDepositWizardCheckoutScreensProps;
}

const Wizard = createWizard<FiatDepositWizardNavigator>();

export interface FiatDepositWizardProps {
  ticker?: string;
  defaultStep?: FiatDepositWizardScreenName;
  onPressBack?: () => void;
}

export const FiatDepositWizard: React.FC<FiatDepositWizardProps> = cell(
  ({ticker, defaultStep, onPressBack}) => {
    const {t} = useTranslation();
    const {bankWireDeposit} = AuthMeResource.use({});
    const initialName =
      defaultStep ||
      (ticker
        ? FiatDepositWizardScreenName.CHOOSE_METHOD
        : FiatDepositWizardScreenName.CHOOSE_TICKER);

    return (
      <Wizard.Navigator
        initialName={initialName}
        initialProps={{
          ticker,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={FiatDepositWizardScreenName.CHOOSE_TICKER}
          options={{
            headerShown: true,
            title: t('surface.wallets.deposit_wizard.select_ticker_fiat.title'),
          }}
          component={FiatDepositWizardChooseTicker}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.CHOOSE_METHOD}
          options={({route: {params}}: any) => {
            return {
              title: t('surface.wallets.fiat_deposit.title', {
                ticker: params?.ticker?.toUpperCase(),
              }),
            };
          }}
          component={FiatDepositWizardChooseMethod}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.APPLE_PAY}
          options={{
            headerShown: false,
          }}
          component={FiatDepositWizardBankCard}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.BANK_CARD}
          options={{
            headerShown: false,
          }}
          component={FiatDepositWizardBankCard}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.BANK_CARD_IFRAME}
          options={{
            headerShown: false,
          }}
          component={FiatDepositWizardBankCard}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.BANK_WIRE}
          options={getFiatDepositBankWireWizardScreenOptions(bankWireDeposit)}
          component={FiatDepositBankWireWizardBankWire}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.CODI}
          options={getFiatDepositWizardCoDiOptions}
          component={FiatDepositWizardCoDi}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.SPEI}
          options={getFiatDepositWizardSPEIOptions}
          component={FiatDepositWizardSPEI}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.VOLET}
          options={{
            headerShown: false,
          }}
          component={FiatDepositWizardVolet}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.UMA}
          options={{title: getUmaSettingsTitle()}}
          component={UmaSettingsCell}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.UMA_CREATE}
          options={{title: getUmaCreateTitle()}}
          component={UmaCreateCell}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.SKRILL}
          options={{
            headerShown: false,
          }}
          component={FiatDepositWizardBankCard}
        />
      </Wizard.Navigator>
    );
  }
);

export const FiatDepositWizardWrapped = ({
  route: {
    params: {ticker, defaultStep},
  },
  navigation: {goBack},
}: StackNavigatorScreenWizardWrappedProps<{
  ticker: string;
  defaultStep: string;
}>) => {
  return (
    <FiatDepositWizard
      ticker={ticker}
      defaultStep={defaultStep as FiatDepositWizardScreenName}
      onPressBack={goBack}
    />
  );
};
