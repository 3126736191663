import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {
  BundleTariffOverviewResource,
  BundleOperationsResource,
} from '@youtoken/ui.resource-bundles';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {
  type BundleWithdrawalFormStateResources,
  BundleWithdrawalFormState,
} from './state';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Content, Footer} from './components';

export {getBundleWithdrawalFormTitle} from './utils';

export interface BundleWithdrawalFormProps {
  id: string;
  onClose?: () => void;
}

export const BundleWithdrawalForm: React.FC<
  BundleWithdrawalFormProps & BoxProps
> = cell(({id, onClose, ...boxProps}) => {
  const {rates, authMe, wallets, bundleTariffOverview, bundleOperations} =
    useResources({
      rates: getResourceDescriptor(RatesResource, {}),
      authMe: getResourceDescriptor(AuthMeResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      bundleTariffOverview: getResourceDescriptor(
        BundleTariffOverviewResource,
        {
          id,
        }
      ),
      bundleOperations: getResourceDescriptor(BundleOperationsResource, {
        id,
      }),
    });

  const docs = DocsResource.use({country: authMe.residenceOrCountry});

  const resources = {
    rates,
    authMe,
    wallets,
    bundleTariffOverview,
    bundleOperations,
    docs,
  } as BundleWithdrawalFormStateResources;

  const state = makeForm(() => {
    return new BundleWithdrawalFormState({onClose}, resources);
  });

  const {
    form: {
      calculated: {error},
    },
  } = state;

  if (error) {
    // NOTE: throw an error if the calculation fails
    throw error;
  }

  return (
    <Form form={state}>
      <Box testID="BUNDLE_WITHDRAWAL_FORM" flex={1} {...boxProps}>
        {!state.form.calculated.data ? (
          <DefaultSuspenseFallback />
        ) : (
          <>
            <Content />
            <Footer />
          </>
        )}
      </Box>
    </Form>
  );
});
