import {computed, observable} from 'mobx';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {
  formatBigNumber,
  formatByTicker,
  toBig,
} from '@youtoken/ui.formatting-utils';
import type {FormAuthorized, FormUnauthorizedV1, FormUnauthorizedV2} from './.';

type BaseForm = FormAuthorized | FormUnauthorizedV1 | FormUnauthorizedV2;

export class ViewBase<Form extends BaseForm> {
  @observable
  form: Form;

  @computed
  get balanceFormatted() {
    return formatByTicker(this.form.balance, this.form.ticker);
  }

  @computed
  get tickerPrecision() {
    return getCoinDecimalPrecision(this.form.ticker);
  }

  @computed
  get tickerFormatted() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  get conversionTickerFormatted() {
    return this.form.conversionTicker.toUpperCase();
  }

  @computed
  get amountFormatted() {
    return formatBigNumber(toBig(this.form.amount), this.tickerPrecision, true);
  }

  @computed
  get amountWithoutFeeFormatted() {
    return formatBigNumber(
      this.form.amountWithoutFee,
      this.tickerPrecision,
      true
    );
  }

  @computed
  get rateFormatted() {
    return formatBigNumber(this.form.rate, this.form.conversionTickerPrecision);
  }

  @computed
  get feeFormatted() {
    return formatBigNumber(this.form.fee, this.tickerPrecision, true);
  }

  public constructor(form: Form) {
    this.form = form;
  }
}
