import {computed, action, observable} from 'mobx';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export type ActivatePersonalIBANFeatureArgs = {
  ticker: string;
};

export class ActivatePersonalIBANFeature extends createFeature({
  getKey: ({ticker}: ActivatePersonalIBANFeatureArgs) =>
    `ActivatePersonalIBANFeature:${ticker}`,
  getResources: () => {
    return {
      IBANAccounts: getResourceDescriptor(IBANAccountsResource, {}),
      verification: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @observable isAccountOpening = false;

  @computed get personalIBANStatus() {
    const data = this.resources.IBANAccounts.getIBANAccountByTicker(
      this.args.ticker
    );
    return data?.personalIBANStatus ?? '';
  }

  @computed get productForDisclosure() {
    return this.resources.IBANAccounts.provider;
  }

  @computed get showAgreements() {
    const isFiatRepublicAgreementSigned = AuthMeResource.getInstanceSafely({})
      ?.products.fiatRepublic.agreement;

    return (
      this.personalIBANStatus !== 'HAS_PENDING_TRANSACTIONS' &&
      this.productForDisclosure &&
      // NOTE: If product is fiat-republic, check the agreement
      (this.productForDisclosure !== 'fiat-republic' ||
        !isFiatRepublicAgreementSigned)
    );
  }

  @computed get actionDisabled() {
    return (
      ['HAS_PENDING_TRANSACTIONS', 'ALREADY_PERSONAL'].includes(
        this.personalIBANStatus
      ) || this.isAccountOpening
    );
  }

  @computed get addressType() {
    return this.resources.verification.data.data.addressType;
  }

  @action public handleOpenAccount = async () => {
    if (this.addressType !== 'FULL') {
      LOCAL_NOTIFICATIONS.error({
        text: i18n.t('validation.ADDRESS_VERIFICATION_REQUIRED'),
      });
      return VerificationResource.launchAddressVerification();
    }

    try {
      this.isAccountOpening = true;

      await this.resources.IBANAccounts.openAccount(this.args.ticker);

      SHARED_ROUTER_SERVICE.goBack();
    } catch (e: any) {
      const error = extractErrorFromResponse(e.response?.data, '_error');
      const errorTranslated = getTranslatedValidationMessage(error);

      this.isAccountOpening = false;
      LOCAL_NOTIFICATIONS.error({
        text: errorTranslated || i18n.t('common.errors.smth_went_wrong'),
      });
    }
  };
}
