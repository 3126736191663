import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {BundleWithdrawalFormState} from '../../../state';
import {BundleTickersList} from '../../../../../components';

export const Tickers: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {
      calculated: {loading, data},
    },
  } = useForm<BundleWithdrawalFormState>();

  if (!data?.tickers.length) {
    return null;
  }

  return (
    <BundleTickersList
      items={data.tickers}
      opacity={loading ? 0.7 : 1}
      {...boxProps}
    />
  );
});
