import {computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {
  AutoConvertResource,
  AutoConvertResponse,
} from '@youtoken/ui.resource-auto-convert';
import {
  formatByTicker,
  getLockedPair,
  reverseRate,
} from '@youtoken/ui.formatting-utils';
import {AutoConvertUiAdoptedItem} from './types';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {computedFn} from 'mobx-utils';

interface AutoConvertListFeatureArgs {
  ticker?: string;
}

export class AutoConvertListFeature extends createFeature({
  getKey: (args: AutoConvertListFeatureArgs) => {
    return `feature:autoConvertList:${JSON.stringify(args)}`;
  },
  getResources: ({ticker}: AutoConvertListFeatureArgs) => {
    return {
      wallets: getResourceDescriptor(WalletsResource, {}),
      autoConverts: getResourceDescriptor(AutoConvertResource, {ticker}),
    };
  },
}) {
  constructor(args: any, resources: any) {
    super(args, resources);
  }

  @computed get hasAutoConverts() {
    return this.resources.autoConverts.data.length > 0;
  }
  @computed
  public get list() {
    return this.formatAutoConvertItems(this.resources.autoConverts.data);
  }

  private formatAutoConvertItems(
    items: AutoConvertResponse[]
  ): AutoConvertUiAdoptedItem[] {
    return items.map(item => {
      const lockedPair = getLockedPair(
        this.resources.wallets,
        item.fromTicker,
        item.toTicker
      );
      const uiAdoptedTriggerRate =
        lockedPair?.baseTicker === item.fromTicker
          ? item.triggerRate
          : reverseRate(item.triggerRate);
      const uiAdoptedBaseTicker = lockedPair
        ? lockedPair.baseTicker
        : item.toTicker;
      const uiAdoptedQuoteTicker = lockedPair
        ? lockedPair.quoteTicker
        : item.fromTicker;
      return {
        ...item,
        tickerPrecision: getCoinDecimalPrecision(item.toTicker, 8), // todo remove
        triggerRateFormatted: formatByTicker(item.triggerRate, item.toTicker),
        fromTickerFormatted: item.fromTicker.toUpperCase(),
        toTickerFormatted: item.toTicker.toUpperCase(),
        uiAdoptedTriggerRateFormatted: formatByTicker(
          uiAdoptedTriggerRate,
          uiAdoptedQuoteTicker
        ),
        uiAdoptedBaseTickerFormatted: uiAdoptedBaseTicker.toUpperCase(),
        uiAdoptedQuoteTickerFormatted: uiAdoptedQuoteTicker.toUpperCase(),
      };
    });
  }

  getAutoConvertById = computedFn((id: string) => {
    return this.list.find(autoConvert => autoConvert.id === id);
  });
}
