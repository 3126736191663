import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {Icon} from '@youtoken/ui.icons';
import {UmaInvoiceConfirmationForm} from '../../state';

export const InfoTableSection: React.FC = observer(() => {
  const {t} = useTranslation();
  const {
    form: {
      invoiceData: {
        fee,
        totalAmountFormatted,
        amountToReceiveFormatted,
        convertedFromTickerFormatted,
        convertedToTickerFormatted,
      },
    },
  } = useForm<UmaInvoiceConfirmationForm>();

  return (
    <Box>
      <InformationTable py={16}>
        <InformationTableRow
          left={t('surface.bank_cards.transfer.from')}
          right={
            <>
              <Text variant="$body-02"> {convertedFromTickerFormatted} </Text>
              <Icon name="uma" size={24} height={16} />
            </>
          }
          testID="UMA_INVOICE_TABLE_FROM_FIELD"
        />

        <InformationTableRow
          left={t('surface.wallets.conversion_form_v2.to')}
          right={
            <>
              <Text variant="$body-02"> {convertedToTickerFormatted} </Text>
              <Icon name="uma" size={24} height={16} />
            </>
          }
          testID="UMA_INVOICE_TABLE_TO_FIELD"
        />

        <InformationTableRow
          left={t('surface.hodls.hodl_form.details.converted_to')}
          right={
            <Text variant="$body-02">
              {convertedFromTickerFormatted} → {convertedToTickerFormatted}
            </Text>
          }
          testID="UMA_INVOICE_TABLE_CONVERTED_TO_FIELD"
        />

        <InformationTableRow
          left={t('surface.wallets.uma.withdrawal.they_will_receive')}
          right={
            <Text variant="$body-02">
              {amountToReceiveFormatted} {convertedToTickerFormatted}
            </Text>
          }
          testID="UMA_INVOICE_TABLE_RECEIVE_FIELD"
        />

        <InformationTableRow
          left={t('surface.bank_wire.withdrawal.fee.label')}
          right={
            <Text variant="$body-02">
              {fee} {convertedFromTickerFormatted}
            </Text>
          }
          testID="UMA_INVOICE_TABLE_FEE_FIELD"
        />

        <InformationTableRow
          isLast
          left={t(
            'surface.wallets.fiat_deposit.unlimint_codi.payment_row.text'
          )}
          right={
            <Text variant="$body-02">
              ≈ {totalAmountFormatted} {convertedFromTickerFormatted}
            </Text>
          }
          testID="UMA_INVOICE_TABLE_PAY_FIELD"
        />
      </InformationTable>
    </Box>
  );
});
