import * as React from 'react';

import {modal} from '../../../stores';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {FirstAutoConvertModalContent} from '@youtoken/ui.surfaces-wallets';

export const modalName = 'firstAutoConvert';

export const FirstAutoConvertModal: React.FC = () => {
  const {opened, close} = modal;

  return (
    <ModalDynamic
      isOpen={opened?.name === modalName}
      onClose={close}
      webOverlayBackgroundColor="$transparent"
      webMinHeight={200}
    >
      <FirstAutoConvertModalContent onPressBack={close} />
    </ModalDynamic>
  );
};
