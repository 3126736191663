import * as React from 'react';
import {BundleTariffOverviewResource} from '@youtoken/ui.resource-bundles';
import {cell} from '@youtoken/ui.cell';
import {type ModalDynamicProps, ModalDynamic} from '@youtoken/ui.modal';
import {
  type BundleDepositFormProps,
  getBundleDepositFormTitle,
  BundleDepositForm,
} from '../../forms';

export const BundleDepositModal = cell<
  BundleDepositFormProps & ModalDynamicProps
>(({id, onClose}) => {
  const {
    data: {
      tariff: {
        design: {title},
      },
    },
  } = BundleTariffOverviewResource.use({
    id,
  });

  return (
    <ModalDynamic
      testID="BUNDLE_DEPOSIT_MODAL"
      isOpen
      title={getBundleDepositFormTitle(title)}
      onClose={onClose}
      webMinHeight={650}
      webMaxHeight="90vh"
    >
      <BundleDepositForm id={id} onClose={onClose} />
    </ModalDynamic>
  );
});
