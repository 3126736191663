import {Platform} from 'react-native';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  getDeviceID,
  getOSVersion,
  getApplicationVersion,
  getDeviceModel,
  getDeviceBrand,
  getDeviceNameSanitized,
} from '@youtoken/ui.security-utils';
import {GLOBAL} from '@youtoken/ui.service-global';

type HeaderValue = string | undefined | null;

const cachedHeaders = new Map<any, HeaderValue>();

function getCachedHeader(
  name: string,
  getValue: () => HeaderValue
): HeaderValue {
  if (cachedHeaders.has(name)) {
    return cachedHeaders.get(name);
  }

  const value = getValue();

  cachedHeaders.set(name, value);

  return value;
}

export function assignHeader(
  headers: Record<string, string>,
  name: string,
  getValue: () => HeaderValue,
  useCache = true
): void {
  let value;

  if (useCache) {
    value = getCachedHeader(name, getValue);
  } else {
    value = getValue();
  }

  if (value) {
    headers[name] = value;
  } else {
    if (headers[name]) {
      delete headers[name];
    }
  }
}

const deviceType = Platform.select({
  ios: 'ios',
  android: 'android',
  web: 'web',
})!;

export function assignHeaders(headers: Record<string, string> = {}): void {
  assignHeader(headers, 'Authorization', () => GLOBAL.token, false);
  assignHeader(headers, 'x-device-type', () => deviceType);
  assignHeader(headers, 'x-use-i18n-errors', () => 'true');
  assignHeader(headers, 'x-device-uuid', getDeviceID);
  assignHeader(headers, 'x-os-version', getOSVersion);
  assignHeader(headers, 'x-app-version', getApplicationVersion);
  assignHeader(headers, 'x-device-model', getDeviceModel);
  assignHeader(headers, 'x-device-brand', getDeviceBrand);
  assignHeader(headers, 'x-device-name', getDeviceNameSanitized);

  assignHeader(
    headers,
    'x-skip-recaptcha',
    () => (ENVIRONMENT.SKIP_RECAPTCHA ? '1' : undefined),
    false
  );

  assignHeader(headers, 'x-system-locale', () => i18n.systemLang, false);
  assignHeader(headers, 'x-locale', () => i18n.language, false);

  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    assignHeader(headers, 'x-a-id', () => ENVIRONMENT.ADVERTISING_ID, false);
    assignHeader(
      headers,
      'x-appsflyer-uid',
      () => ENVIRONMENT.APPSFLYER_ID,
      false
    );
  }

  assignHeader(
    headers,
    'x-sift-session-id',
    () => ENVIRONMENT['SESSION_ID'],
    false
  );

  if (Platform.OS === 'web') {
    assignHeader(
      headers,
      'x-web-app-env',
      () => ENVIRONMENT.WEB_APP_ENV,
      false
    );

    if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ramp-app']) {
      assignHeader(
        headers,
        'x-session-id',
        () => ENVIRONMENT['RAMP_SESSION_ID'],
        false
      );
    }
  }
}
