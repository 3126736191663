import {observable} from 'mobx';
import {View} from './View';
import type {
  ExchangeAuthorizedFormArgs,
  ExchangeAuthorizedNewFormResources,
} from './types';
import {Form} from './Form';

export {Form} from './Form';
export {View} from './View';
export {Calculated} from './Calculated';

export class ExchangeFormState {
  @observable
  public form: Form;

  @observable
  public view: View<Form>;

  constructor(
    args: ExchangeAuthorizedFormArgs,
    resources: ExchangeAuthorizedNewFormResources
  ) {
    this.form = new Form(args, resources);
    this.view = new View(this.form);
  }
}
