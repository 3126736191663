import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {FeatureList, Illustration, Link, Trans} from '@youtoken/ui.elements';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {type BaseIconName} from '@youtoken/ui.icons';

export const HowIncentivesWorkContent: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {
      maxPercentOnDeposit,
      minDepositForGetIncentives,
      incentivesLimitFormatted,
      exampleDepositValueFormatted,
      exampleIncentivesValueFormatted,
      incentivesExpirationPeriodFormatted,
    } = IncentivesFeature.use({});

    const featureItems = React.useMemo(() => {
      return [
        {
          icon: 'hodl' as BaseIconName,
          title: t('surface.incentives.feature.title.boost'),
          children: t('surface.incentives.feature.text.boost'),
        },
        {
          icon: 'first_trade' as BaseIconName,
          title: t('surface.incentives.feature.title.get_bonuses'),
          children: (
            <Trans
              i18nKey="surface.incentives.feature.text.get_bonuses"
              parent={props => (
                <Text variant="$body-02" color="$text-01" {...props} />
              )}
              values={{
                percent: maxPercentOnDeposit,
                depositValue: exampleDepositValueFormatted,
                incentivesValue: exampleIncentivesValueFormatted,
                minDeposit: minDepositForGetIncentives,
                incentivesLimit: incentivesLimitFormatted,
              }}
            />
          ),
        },
        {
          icon: 'pending' as BaseIconName,
          title: t('surface.incentives.feature.title.expiration'),
          children: (
            <Box>
              <Text variant="$body-02" mb={4}>
                {t('surface.incentives.feature.text.expiration', {
                  period: incentivesExpirationPeriodFormatted,
                })}
              </Text>
              <Text variant="$body-02">
                {'\u2022'}{' '}
                {t('surface.incentives.feature.text.expiration_step_1')}
              </Text>
              <Text variant="$body-02">
                {'\u2022'}{' '}
                {t('surface.incentives.feature.text.expiration_step_2')}
              </Text>
            </Box>
          ),
        },
      ];
    }, [
      maxPercentOnDeposit,
      exampleDepositValueFormatted,
      exampleIncentivesValueFormatted,
      minDepositForGetIncentives,
      incentivesLimitFormatted,
    ]);

    return (
      <Box {...boxProps}>
        <Box mt={24} alignItems="center">
          <Illustration
            name="illustration-4"
            style={{width: 200, height: 200}}
          />
        </Box>
        <Box my={24} alignItems="center">
          <Heading mb={8}>
            {t('surface.incentives.boost.title', {
              percent: maxPercentOnDeposit,
            })}
          </Heading>
          <Text variant="$body-01" color="$text-02">
            {t('surface.incentives.boost.text')}
          </Text>
        </Box>

        <FeatureList size="medium" px={0} mb={24} items={featureItems} />

        <Box mb={24}>
          <Trans
            i18nKey="surface.incentives.feature.text.help_center"
            parent={props => (
              <Text variant="$body-02" color="$text-02" {...props} />
            )}
            components={{
              Link: (
                <Link
                  url={t('surface.incentives.expiration.helpdesk_link')}
                  variant="$body-02"
                />
              ),
            }}
            defaults="Visit our <Link>Help Center</Link> to read more"
          />
        </Box>
      </Box>
    );
  }
);
