import * as React from 'react';
import {observer} from 'mobx-react';
import {ScrollView} from 'react-native';
import {MinerFeature} from '@youtoken/ui.resources-miner';
import {useAttachPullToRefreshToFeature} from '@youtoken/ui.hooks';
import {MinerSurface} from './MinerSurface';

export const MinerSurfaceContent: React.FC = observer(() => {
  const miner = MinerFeature.use({});
  const {refreshControl} = useAttachPullToRefreshToFeature(miner);

  return (
    <ScrollView refreshControl={refreshControl}>
      <MinerSurface />
    </ScrollView>
  );
});
