import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, Heading} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Illustration} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {LightSparkLogo} from '..';

export interface UmaWelcomeFormProps {
  onPress: () => void;
}

export const UmaWelcomeForm: React.FC<UmaWelcomeFormProps> = observer(
  ({onPress}) => {
    const {t} = useTranslation();

    return (
      <>
        <Box
          px={24}
          flexGrow={{desktop: undefined, default: 1}}
          justifyContent="center"
          alignItems="center"
        >
          <Illustration
            name="illustration-3"
            style={{width: 200, height: 200}}
          />
          <Heading variant="$heading-02" mb={8}>
            {t('surface.wallets.uma.welcome.title')}
          </Heading>
          <Text color="$text-02" textAlign="center" mb={8}>
            {t('surface.wallets.uma.welcome.explanation')}
          </Text>
        </Box>

        <Box p={24}>
          <Button
            onPress={onPress}
            size="large"
            testID="UMA_CREATE_CREATE_BUTTON"
          >
            {t('surface.wallets.uma.create')}
          </Button>
          <LightSparkLogo pt={{default: 32, desktop: 24}} />
        </Box>
      </>
    );
  }
);
