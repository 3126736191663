import {Wallet} from '@youtoken/ui.resource-wallets';
import {type WalletComboboxItem} from '@youtoken/ui.ticker-and-amount-input';
import {AxiosError} from '@youtoken/ui.errors';
import {extractErrorFromResponse} from '@youtoken/ui.validation-messages';

export const walletToComboboxItem = ({
  ticker,
  tickerFormatted,
  amount,
  amountFormatted,
  tickerName,
}: Wallet): WalletComboboxItem => {
  return {
    ticker,
    tickerFormatted,
    amountFormatted,
    coinName: tickerName,
    hasAmount: amount?.gt(0),
    key: ticker,
    keywords: `${ticker} ${tickerName}`,
  };
};

export const handleFormSubmitRateChangedError = (
  axiosError: AxiosError,
  cb: () => void
) => {
  const axiosErrorData = axiosError?.response?.data;

  const validationError = extractErrorFromResponse(axiosErrorData, '_error');

  const isRateChangedValidationError = Boolean(
    validationError && validationError.i18n?.label === 'RATE_CHANGED'
  );

  if (isRateChangedValidationError) {
    cb();
  }
};
