import {surface} from '@youtoken/ui.screen-components';
import * as React from 'react';
import {AutoConvertListSurfaceContent} from './Content/AutoConvertListSurfaceContent';
import {i18n} from '@youtoken/ui.service-i18n';
import {z} from 'zod';

export const AutoConvertListSurface = surface(
  {
    paramsSchema: z.object({
      ticker: z.string().optional(),
    }),
    name: 'AutoConvertsList',
    getTitle: () => i18n.t('surface.auto_convert.title.plural'),
    headerShown: true,
  },
  ({ticker}) => {
    return <AutoConvertListSurfaceContent ticker={ticker} />;
  }
);
