import {
  createTRPCClient,
  httpBatchLink,
  loggerLink,
  type TRPCLink,
} from '@trpc/client';
import {observable} from '@trpc/server/observable';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {assignHeaders} from '..';

// config is empty for now
// just to match the signature of the function
const errorHandlerLink: (config?: {}) => TRPCLink<any> = _config => () => {
  // here we just got initialized in the app - this happens once per app
  // useful for storing cache for instance
  return ({next, op}) => {
    // this is when passing the result to the next link
    // each link needs to return an observable which propagates results
    return observable(observer => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          // TODO: rename to the ANAUHTORIZED
          if (err?.data?.code === 'ACCESS_DENIED') {
            ENVIRONMENT.unauthorizedError();
            return;
          }

          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });

      return unsubscribe;
    });
  };
};

export function createTRPC(): ReturnType<typeof createTRPCClient<any>> {
  return createTRPCClient({
    links: [
      loggerLink(),
      errorHandlerLink(),
      httpBatchLink({
        url: `${ENVIRONMENT.BACKEND_URL}/trpc`,
        // You can pass any HTTP headers you wish here
        headers() {
          const headers = {};

          assignHeaders(headers);

          return headers;
        },
        async fetch(url, options) {
          const response = await fetch(url, {
            ...options,
            credentials: 'include',
          });

          return response;
        },
      }),
    ],
  });
}
