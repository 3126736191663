import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {
  ChartSection,
  DesiredRateField,
  Footer,
  CurrentRate,
} from './components';
import {AutoConvertFormState} from './state';
import {makeForm, Form} from '@youtoken/ui.form-elements';
import {type AutoConvertFormArgs} from './state/types';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {ScrollView} from 'react-native';

export const AutoConvertForm: React.FC<AutoConvertFormArgs & BoxProps> =
  observer(
    ({
      fromTicker,
      toTicker,
      initialAmount,
      desiredRate,
      isDesiredRateReversed,
      onSuccess,
      ...boxProps
    }) => {
      const resources = useResources({
        ratesResource: getResourceDescriptor(RatesResource, {}),
      });

      const state = makeForm(
        () =>
          new AutoConvertFormState(
            {
              fromTicker,
              toTicker,
              initialAmount,
              desiredRate,
              isDesiredRateReversed,
              onSuccess,
            },
            resources
          )
      );

      return (
        <Form form={state}>
          <Box flex={1} {...boxProps}>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              keyboardDismissMode="on-drag"
              style={[
                {
                  flex: 1,
                },
              ]}
            >
              <DesiredRateField p={24} />
              <CurrentRate px={24} mb={24} />
              <ChartSection pt={12} pb={8} />
            </ScrollView>
            <Footer />
          </Box>
        </Form>
      );
    }
  );
