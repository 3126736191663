import {Platform} from 'react-native';
import {
  ImpactFeedbackStyle,
  NotificationFeedbackType,
  impactAsync,
  notificationAsync,
} from 'expo-haptics';
import {action, computed, observable, transaction} from 'mobx';
import {computedFn} from 'mobx-utils';
import {min, union, without} from 'lodash';
import {AxiosError} from '@youtoken/ui.errors';
import {TRANSPORT} from '@youtoken/ui.transport';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {i18n} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type MinerBlockState, MinerBlock, MinerBlockStatus} from './MinerBlock';
import {MinerOverviewResource} from './MinerOverviewResource';
import {MinerOverviewResponse} from './MinerOverviewResponse';
import {MinerOverviewUnauthorisedResource} from './MinerOverviewUnauthorisedResource';

export interface MinerHexagon {
  pressing: number;
  state: MinerBlockState;
  block: MinerBlock;
}

const haptic = Platform.select({
  ios: () => {
    impactAsync(ImpactFeedbackStyle.Light);
  },
  default: () => {},
})!;

const hapticSuccess = Platform.select({
  ios: () => {
    notificationAsync(NotificationFeedbackType.Success);
  },
  default: () => {},
})!;

export class MinerOverviewHexagonalGridUnаuthorized {
  @computed
  public get totalMinedAmountFormatted() {
    return this._overview.data.totalMinedAmountFormatted;
  }

  @computed
  public get totalMinedTickerFormatted() {
    return this._overview.data.totalMinedTickerFormatted;
  }

  @computed
  public get totalMinedAmountUSDFormatted() {
    return this._overview.data.totalMinedAmountUSDFormatted;
  }

  @computed
  public get sparkBalance() {
    return this._overview.data.sparkBalance;
  }

  @computed
  public get sparkBalanceFormatted() {
    return this._overview.data.sparkBalanceFormatted;
  }

  @computed
  public get timeLeftUntilNextFreeSparksDrop() {
    return this._overview.data.timeLeftUntilNextFreeSparksDrop;
  }

  @computed
  public get resetCost() {
    return this._overview.data.resetCost;
  }

  @computed
  public get proposedAction() {
    return this._overview.data.proposedAction;
  }

  @computed
  public get proposedActionAmount() {
    if (this.proposedAction === 'UNBLOCK_BLOCKS') {
      return this.resetCost;
    }

    if (
      this.proposedAction === 'GET_SPARKS_TO_UNBLOCK' &&
      this.resetCost > this.sparkBalance
    ) {
      return this.resetCost - this.sparkBalance;
    }

    if (
      this.proposedAction === 'GET_SPARKS_TO_MINE' &&
      this._blocksCheapestMiningPrice > this.sparkBalance
    ) {
      return this._blocksCheapestMiningPrice - this.sparkBalance;
    }

    return 0;
  }

  @observable
  public foreground!: string[];

  @computed
  public get hexagons(): MinerHexagon[] {
    return this._blocks.map(({id}) => {
      return this._getHexagon(id);
    });
  }

  @action
  public setHexagonPressing = (blockId: string, value: number) => {
    this._pressing[blockId] = value;
  };

  @action
  public clear = () => {
    transaction(() => {
      this._pressing = {};
      this._state = {};
      this.foreground = [];
    });
  };

  @action
  public insufficientSparks = (_blockId: string) => {};

  @action
  public miningStart = (_blockId: string) => {
    SHARED_ROUTER_SERVICE.navigate('SignUp');
  };

  @action
  public miningEnd = (_blockId: string) => {};

  @action
  public claimingStart = (_blockId: string) => {};

  @action
  public claiming = (_blockId: string) => {};

  @action
  public showInfoStart = (_blockId: string) => {};

  @action
  public showInfoEnd = (_blockId: string) => {};

  @action
  public navigateToGetMoreSparks = (
    _source: string,
    _options?: {
      noticeText?: string;
    }
  ) => {};

  @action
  public navigateToResetBlocks = (_source: string) => {};

  @action
  public resetMiner = () => {};

  constructor(
    protected _overview:
      | MinerOverviewResource
      | MinerOverviewUnauthorisedResource
  ) {
    this.clear();
  }

  // PRIVATE SECTION

  @computed
  private get _blocks(): MinerBlock[] {
    return this._overview.data.blocks;
  }

  @computed
  private get _blocksEnabled() {
    return this._blocks.filter(block => block.status !== 'DISABLED');
  }

  @computed
  private get _blocksCheapestMiningPrice() {
    return min(this._blocksEnabled.map(block => block.miningPrice)) ?? 0;
  }

  @observable
  private _pressing!: {[key: string]: number};

  @observable
  private _state!: {[id: string]: MinerBlockState | null};

  private _getHexagon = computedFn((blockId: string) => {
    return {
      pressing: this._getHexagonPressing(blockId),
      state: this._getHexagonState(blockId),
      block: this._getHexagonBlock(blockId),
    };
  });

  private _getHexagonPressing = computedFn((blockId: string): number => {
    return this._pressing[blockId] ?? 0;
  });

  private _getHexagonState = computedFn((blockId: string): MinerBlockState => {
    if (this._state[blockId]) {
      return this._state[blockId]!;
    }

    const {miningPrice, status} = this._getHexagonBlock(blockId)!;

    if (status === 'AVAILABLE' && this.sparkBalance < miningPrice) {
      return 'INSUFFICIENT_SPARKS';
    }

    return status;
  });

  protected _getHexagonBlock = computedFn((blockId: string) => {
    return this._blocks.find(block => block.id === blockId)!;
  });

  public getHexagonInsufficientSparks = computedFn((blockId: string) => {
    const {miningPrice} = this._getHexagonBlock(blockId);

    return miningPrice - this.sparkBalance;
  });

  @action
  protected _setHexagonState = (
    blockId: string,
    status: MinerBlockState | null
  ) => {
    transaction(() => {
      this._state[blockId] = status;

      if (status === null) {
        this._setHexagonForeground(blockId, false);
      } else if (['CLAIMING', 'CLAIMED_INFO'].includes(status)) {
        this._setHexagonForeground(blockId, true);
      }
    });
  };

  @action
  protected _setHexagonBlockStatus = (
    blockId: string,
    status: MinerBlockStatus
  ) => {
    this._getHexagonBlock(blockId).status = status;
  };

  @action
  private _setHexagonForeground = (blockId: string, value: boolean) => {
    if (value) {
      this.foreground = union(this.foreground, [blockId]);
    } else {
      this.foreground = without(this.foreground, blockId);
    }
  };
}

export class MinerOverviewHexagonalGridAuthorized extends MinerOverviewHexagonalGridUnаuthorized {
  @action
  public insufficientSparks = (blockId: string) => {
    haptic();

    return this.navigateToGetMoreSparks('hexagon', {
      noticeText: i18n.t(
        'surface.miner.unlock_blocks.need_more_sparks_to_mine',
        {
          count: this.getHexagonInsufficientSparks(blockId),
        }
      ),
    });
  };

  @action
  public miningStart = (blockId: string) => {
    haptic();

    DATA_LAYER.trackStrict('miner-block-mining-attempt', {
      category: 'miner',
      type: 'block-mining',
    });

    this._setHexagonState(blockId, 'MINING_STARTING');
    TRANSPORT.API.post<MinerOverviewResponse>('/v1/miner/start', {
      blockId,
    })
      .then(({data}) => {
        this._updateData(data);
      })
      .catch((error: AxiosError) => {
        this._handleError(error);
      })
      .finally(() => {
        this._setHexagonState(blockId, null);
      });
  };

  @action
  public miningEnd = (blockId: string) => {
    this._setHexagonBlockStatus(blockId, 'READY');
  };

  @action
  public claimingStart = (blockId: string) => {
    haptic();

    DATA_LAYER.trackStrict('miner-block-claim-attempt', {
      category: 'miner',
      type: 'block-claim',
    });

    this._setHexagonState(blockId, 'CLAIMING_STARTING');
    TRANSPORT.API.post<MinerOverviewResponse>('/v1/miner/claim', {
      blockId,
    })
      .then(({data}) => {
        this._updateData(data);
        this._setHexagonState(blockId, 'CLAIMING');

        hapticSuccess();
      })
      .catch((error: AxiosError) => {
        this._handleError(error);
        this._setHexagonState(blockId, null);
      });
  };

  @action
  public claiming = (blockId: string) => {
    this._setHexagonState(blockId, 'CLAIMING_ENDING');
  };

  @action
  public showInfoStart = (blockId: string) => {
    haptic();

    this._setHexagonState(blockId, 'CLAIMED_INFO');
  };

  @action
  public showInfoEnd = (blockId: string) => {
    this._setHexagonState(blockId, null);
  };

  @action
  public navigateToGetMoreSparks = (
    source: string,
    options?: {
      noticeText?: string;
    }
  ) => {
    const eventName = 'miner-get-more';
    const eventCategory = 'miner';

    const {currentLevel} = LoyaltyResource.getInstanceSafely({})?.data!;

    if (currentLevel < 3) {
      DATA_LAYER.trackStrict(eventName, {
        category: eventCategory,
        type: `${source}-upgrade-level`,
      });

      return SHARED_ROUTER_SERVICE.navigate('UpgradeYourLevel');
    }

    DATA_LAYER.trackStrict(eventName, {
      category: eventCategory,
      type: `${source}-get-more`,
    });

    SHARED_ROUTER_SERVICE.navigate('MinerGetMoreSparksModal', options ?? {});
  };

  @action
  public navigateToResetBlocks = (source: string) => {
    if (this.sparkBalance < this.resetCost) {
      return this.navigateToGetMoreSparks(source);
    }

    DATA_LAYER.trackStrict('miner-board-unlock-preview', {
      category: 'miner',
      type: `${source}-unlock`,
    });

    SHARED_ROUTER_SERVICE.navigate('MinerResetBlocksModal');
  };

  @action
  public resetMiner = () => {
    return TRANSPORT.API.post<MinerOverviewResponse>('/v1/miner/reset').then(
      ({data}) => {
        this._updateData(data);
        this.clear();
      }
    );
  };

  // PRIVATE SECTION

  protected _overview!: MinerOverviewResource;

  @action
  private _updateData = (data: MinerOverviewResponse) => {
    this._overview.updateData(data);
  };

  @action
  private _handleError = (error: AxiosError) => {
    if (
      error?.response?.status === 400 &&
      error?.response?.data?.block?.startsWith('Block is not')
    ) {
      this._overview.refetch();
    }
  };
}
