import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm} from '@youtoken/ui.form-elements';
import {Text} from '@youtoken/ui.primitives';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../../state';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {MethodItem} from '../../../../components';
import {SectionTitle} from './SectionTitle';

type InstantMethodsProps = {
  onMethodSelected: (method: string) => void;
};

export const InstantMethods: React.FC<InstantMethodsProps> = observer(
  ({onMethodSelected}) => {
    const {t} = useTranslation();
    const {
      form: {possibleInstantDepositMethods, depositMethodsData, depositMethod},
      view: {tickerFormatted, conversionTickerFormatted},
    } = useForm<
      BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
    >();

    if (possibleInstantDepositMethods.length === 0) {
      return null;
    }

    return (
      <>
        <SectionTitle
          title={t('ramp.conversion_form.pay_with.instant_methods.title')}
          subtitleElement={
            <Text variant="$body-02" color="$text-02">
              <Trans
                t={t}
                values={{tickerFormatted, conversionTickerFormatted}}
                i18nKey="ramp.conversion_form.pay_with.instant_methods.description"
              />
            </Text>
          }
        />

        {possibleInstantDepositMethods.map(method => (
          <MethodItem
            key={method}
            hasActive
            isActive={depositMethod == method}
            onPress={() => onMethodSelected(method)}
            {...depositMethodsData[method]!}
          />
        ))}
      </>
    );
  }
);
