import * as React from 'react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {BorderBoxWrapper, VariableLayout} from '@youtoken/ui.screen-components';
import {type HodlInstrumentSurfaceContentProps} from './types';
import {
  ChartSection,
  InstrumentActionsSection,
  NonWorkingHoursSection,
  OverviewInstrumentSection,
  PromoSection,
  TradingViewChartSection,
  AboutInstrumentCell,
  MarketStatsCell,
} from '../../../components';

export const HodlInstrumentSurfaceContent: React.FC<
  HodlInstrumentSurfaceContentProps
> = ({symbol}) => {
  const {getInstrumentById} = HODLsTariffsFeature.use({});

  const {baseTicker, quoteTicker, isCategoryRollovers} =
    getInstrumentById(symbol)!;

  const [isTradingViewChartOpen, setIsTradingViewChartOpen] =
    React.useState(false);

  const handleTradingViewChartOpen = React.useCallback(() => {
    setIsTradingViewChartOpen(true);
  }, []);

  const handleTradingViewChartClose = React.useCallback(() => {
    setIsTradingViewChartOpen(false);
  }, []);

  return (
    <VariableLayout
      PrimaryContent={
        // remove wrapper for new design
        <BorderBoxWrapper flex={1}>
          <OverviewInstrumentSection instrumentId={symbol} />

          {!isTradingViewChartOpen && (
            <ChartSection
              instrumentId={symbol}
              onPressTradingViewModeButton={handleTradingViewChartOpen}
            />
          )}
          {isTradingViewChartOpen && (
            <TradingViewChartSection
              instrumentId={symbol}
              onClose={handleTradingViewChartClose}
            />
          )}
          {isCategoryRollovers && (
            <PromoSection
              baseTicker={baseTicker}
              quoteTicker={quoteTicker}
              mx={-1}
            />
          )}
          <MarketStatsCell baseTicker={baseTicker} quoteTicker={quoteTicker} />
          <AboutInstrumentCell
            baseTicker={baseTicker}
            quoteTicker={quoteTicker}
          />
          <NonWorkingHoursSection instrumentId={symbol} />
          <InstrumentActionsSection
            instrumentId={symbol}
            backgroundColor="$ui-background"
            borderBottomLeftRadius={16}
            borderBottomRightRadius={16}
            position="sticky"
            bottom={0}
          />
        </BorderBoxWrapper>
      }
    />
  );
};
