import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalFormState} from '../types';
import {CryptoWithdrawalExternalFormState} from '../CryptoWithdrawalExternalForm/state';

export const CryptoWithdrawalExternalAmountFeeInfo: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    walletTickerFormatted,
    feeMinAmountFormatted,
    feeEstimationTimeLokalised,
  } = useForm<CryptoWithdrawalFormState>() as CryptoWithdrawalExternalFormState;

  return (
    <Box mt={16}>
      <Text variant="$body-02" color="$text-02">
        {t('surface.wallets.crypto_withdrawal.minimum')}:{' '}
        <Text variant="$body-02-high-accent">{feeMinAmountFormatted}</Text>{' '}
        {walletTickerFormatted}.{' '}
        {t('surface.wallets.crypto_withdrawal.estimated_arrival')}:{' '}
        {feeEstimationTimeLokalised}
      </Text>
    </Box>
  );
});
