import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {PayFromWallet} from './PayFromWallet';
import {NewParameters} from './NewParameters';
import {OverviewTable} from './OverviewTable';
import {Total} from './Total';
import {LoanReopenFeature} from './Feature';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export type LoanReopenSurfaceProps = {
  loanId: string;
} & BoxProps;

export const LoanReopenSurface: React.FC<LoanReopenSurfaceProps> = ({
  loanId,
  ...boxProps
}) => {
  const {t} = useTranslation();
  const [isLoading, setLoading] = React.useState(false);

  const {reopen, isBalanceEnough, borrowedTicker} = LoanReopenFeature.use({
    id: loanId,
  });

  const handlePressReopen = React.useCallback(() => {
    setLoading(true);
    reopen()
      .then(() => {
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      })
      .finally(() => setLoading(false));
  }, []);

  const handlePressDeposit = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
      ticker: borrowedTicker,
    });
  }, [borrowedTicker]);

  React.useEffect(() => {
    DATA_LAYER.trackStrict('loan-regular-reopen-attempt', {});
  }, []);

  return (
    <Box {...boxProps}>
      <Box p={24}>
        <PayFromWallet loanId={loanId} mb={24} />
        <NewParameters loanId={loanId} mb={24} />
        <OverviewTable loanId={loanId} mb={16} />
        <Total loanId={loanId} mb={24} />

        {isBalanceEnough ? (
          <Button size="large" onPress={handlePressReopen} loading={isLoading}>
            {t('surface.loans.reopen_form.cta')}
          </Button>
        ) : (
          <Button size="large" onPress={handlePressDeposit}>
            {t('common.action.deposit_in_ticker', {
              ticker: borrowedTicker.toUpperCase(),
            })}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export const LoanReopenSurfaceCell = cell(LoanReopenSurface);
