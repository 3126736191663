import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {DocsResponse, DocumentItem} from './DocsResponse';
import {computed} from 'mobx';

export interface DocsResourceArgs {
  country: string;
}

export const privacyNoticeUnified = {
  name: 'Privacy Notice',
  link: 'https://app.youhodler.com/docs/youhodler_privacy_notice.pdf',
  i18nName: 'agreement.naumard.general.privacy_policy.name',
  i18nLink: 'https://app.youhodler.com/docs/youhodler_privacy_notice.pdf',
};

export const getGeneralAgreements = (legalEntity: string): DocumentItem[] => {
  if (legalEntity === 'ITA') {
    return [
      {
        name: 'General Terms',
        link: 'https://app.youhodler.com/docs/youhodler_ita_terms_of_service.pdf',
        i18nName: 'agreement.youhodler.general.terms.name',
      },
    ] as DocumentItem[];
  }
  return [
    {
      name: 'General Terms',
      link: 'https://app.youhodler.com/docs/youhodler_terms_of_service.pdf',
      i18nName: 'agreement.youhodler.general.terms.name',
    },
  ] as DocumentItem[];
};

export class DocsResource extends createStaticResource({
  getKey: ({country}: DocsResourceArgs) => `docsResource:${country}`,
  getData: ({country}: DocsResourceArgs) =>
    TRANSPORT.API.get('/v1/docks/agreements', {
      params: {country},
    }).then(res => deserialize(DocsResponse, res.data)),
}) {
  @computed get documents() {
    return this.data;
  }
}
