import * as React from 'react';
import {observer} from 'mobx-react';
import {Button, type IButtonProps} from '@youtoken/ui.buttons';
import {type HODLItem, HodlStatus} from '@youtoken/ui.resource-hodl';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {nanoid} from 'nanoid';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';

interface StatusButtonProps extends IButtonProps {
  hodl: HODLItem;
}

interface renderStatusButtonProps {
  status: HodlStatus;
  isExpired: boolean;
  isHodlExpired: boolean;
  closedButtonTitle: string;
  onCancel: () => void;
  onClose?: () => void;
  inputAmountFormatted: string;
  inputTickerFormatted: string;
  closeNowOutputAmount?: string;
  outputTicker: string;
  buttonProps?: IButtonProps;
}

const renderStatusButton: React.FC<renderStatusButtonProps> = ({
  status,
  isExpired,
  isHodlExpired,
  closedButtonTitle,
  onCancel,
  onClose,
  closeNowOutputAmount,
  inputAmountFormatted,
  inputTickerFormatted,
  outputTicker,
  buttonProps,
}) => {
  const {t} = useTranslation();
  return (
    <>
      {status === HodlStatus.PENDING && (
        <Button {...buttonProps} type="secondary" onPress={onCancel}>
          {t('surface.hodls.item.status_button.pending', {
            value: inputAmountFormatted,
            ticker: inputTickerFormatted,
          })}
        </Button>
      )}
      {status === HodlStatus.PROCESSING && (
        <Button {...buttonProps} disabled>
          {t('surface.hodls.item.status_button.opening')}
        </Button>
      )}
      {status === HodlStatus.CLOSING && (
        <Button {...buttonProps} disabled>
          {t('surface.hodls.item.status_button.closing')}
        </Button>
      )}
      {status === HodlStatus.CANCELED && isExpired && (
        <Button {...buttonProps} disabled>
          {t('surface.hodls.item.status_button.expired')}
        </Button>
      )}
      {status === HodlStatus.CANCELED && !isExpired && (
        <Button {...buttonProps} disabled>
          {t('surface.hodls.item.status_button.canceled')}
        </Button>
      )}
      {status === HodlStatus.DECLINED && (
        <Button {...buttonProps} disabled>
          {t('surface.hodls.item.status_button.declined')}
        </Button>
      )}
      {status === HodlStatus.CLOSED && isHodlExpired && (
        <Button {...buttonProps} disabled>
          {t('surface.hodls.item.status_button.expired')}
        </Button>
      )}
      {status === HodlStatus.CLOSED && !isHodlExpired && (
        <Button {...buttonProps} disabled>
          {closedButtonTitle}
        </Button>
      )}
      {status === HodlStatus.OPEN && (
        <Button {...buttonProps} onPress={onClose}>
          {`${t(
            'surface.hodls.item.status_button.close_and_get'
          )} ≈ ${closeNowOutputAmount} ${outputTicker}`}
        </Button>
      )}
    </>
  );
};

export const HODLItemStatusButton: React.FC<StatusButtonProps> = observer(
  ({hodl, ...buttonProps}) => {
    const {id, cancelHodl} = hodl;

    const requestIdRef = React.useRef(nanoid());
    const [isLoading, setIsLoading] = React.useState(false);

    const handleCancel = React.useCallback(() => {
      setIsLoading(true);
      hodl
        ?.cancelHodl(requestIdRef.current)
        .then(() => SHARED_ROUTER_SERVICE.navigate('MultiHODLPortfolio'))
        .catch(e => {
          handleGeneralErrorTranslated(e.response?.data);
        })
        .finally(() => setIsLoading(false));
    }, [cancelHodl]);

    const handleClose = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('HodlCloseNow', {id});
    }, [id]);

    if (!hodl) {
      return null;
    }

    const {
      status,
      closedButtonTitle,
      inputAmountFormatted,
      inputTickerFormatted,
      activeOutputAmountFormatted: closeNowOutputAmount,
      outputTickerUpperCase: outputTicker,
      isExpired,
      isHodlExpired,
    } = hodl;

    return renderStatusButton({
      status,
      isExpired,
      isHodlExpired,
      closedButtonTitle,
      onCancel: handleCancel,
      onClose: handleClose,
      inputAmountFormatted,
      inputTickerFormatted,
      closeNowOutputAmount,
      outputTicker,
      buttonProps: {loading: isLoading, ...buttonProps},
    });
  }
);
