import * as React from 'react';
import ReactModal, {type Styles} from 'react-modal';
import {cell} from '@youtoken/ui.cell';
import {
  Box,
  type BoxProps,
  Heading,
  Text,
  TouchableBox,
  useTheme,
} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {CloseIcon} from './CloseIcon';
import {type ModalProps} from './types';
import {MODAL_RADIUS} from '..';

// NOTE. The content must be a cell
const ModalContent: React.FC<BoxProps> = cell(({children}) => children, {
  CellWrap: props => {
    return <Box {...props} />;
  },
});

export const Modal: React.FC<ModalProps & BoxProps> = ({
  title,
  subtitle,
  isOpen,
  onClose,
  webMinHeight = 350,
  webWidth = 590,
  webHeight,
  shouldShowHeaderSeparator = true,
  closePosition = 'inside',
  closeIconColor = '$text-01',
  overflow = 'visible',
  testID = 'MODAL',
  children,
  webOverlayBackgroundColor = '$blackout-01',
  closeable = true,
  webModalZIndex = 400,
  ...boxProps
}) => {
  const {colors} = useTheme();

  const customStyles = React.useMemo(() => {
    return {
      overlay: {
        backgroundColor: colors[webOverlayBackgroundColor],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: webModalZIndex,
        overflow: 'auto',
      },
      content: {
        position: 'unset',
        top: 'unset',
        left: 'unset',
        right: 'unset',
        bottom: 'unset',
        background: 'transparent',
        margin: 'auto',
        padding: 0,
        borderRadius: '10px',
        paddingTop: '24px',
        paddingBottom: '24px',
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        overflow,
      },
    };
  }, [colors, webOverlayBackgroundColor, overflow]);

  const showTitleBlock = Boolean(title || subtitle);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={closeable}
      isOpen={isOpen}
      onRequestClose={closeable ? onClose : undefined}
      style={customStyles as Styles}
    >
      {closeable && closePosition === 'outside' && (
        <TouchableBox
          alignItems="center"
          justifyContent="center"
          width={40}
          height={40}
          position="absolute"
          top={16}
          right={16}
          onPress={onClose}
        >
          <Box
            justifyContent="center"
            alignItems="center"
            width={24}
            height={24}
            backgroundColor="$text-04"
            borderRadius={12}
          >
            <Icon name="close" size={20} color="$text-02" />
          </Box>
        </TouchableBox>
      )}
      <ModalContent
        backgroundColor="$ui-background"
        borderRadius={MODAL_RADIUS}
        width={{default: `calc(100vw - 24px)`, desktop: webWidth}}
        height={webHeight}
        minHeight={webMinHeight}
        overflow={overflow}
        testID={testID}
        margin="auto"
        {...boxProps}
      >
        {showTitleBlock && (
          <>
            <Box
              justifyContent="center"
              alignItems="center"
              py={24}
              px={{default: 16, phone: 24}}
            >
              {Boolean(title) && (
                <Heading
                  variant={
                    Boolean(subtitle) ? '$heading-02' : '$heading-02-responsive'
                  }
                  textAlign="center"
                  testID={`${testID}_TITLE`}
                >
                  {title}
                </Heading>
              )}
              {Boolean(subtitle) && (
                <Box pt={8} testID={`${testID}_SUBTITLE`}>
                  {typeof subtitle === 'string' ? (
                    <Text
                      variant="$body-02"
                      color="$text-02"
                      textAlign="center"
                    >
                      {subtitle}
                    </Text>
                  ) : (
                    subtitle
                  )}
                </Box>
              )}
            </Box>
            {shouldShowHeaderSeparator && <Separator />}
          </>
        )}
        {closeable && closePosition === 'inside' && (
          <CloseIcon
            onClose={onClose}
            closeIconColor={closeIconColor}
            testID={`${testID}_CLOSE`}
          />
        )}
        <Box flex={1}>{children}</Box>
      </ModalContent>
    </ReactModal>
  );
};
