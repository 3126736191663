import * as React from 'react';
import {z} from 'zod';
import {surface, VariableLayout} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {AntiPhishingSurfaceContent} from './Content';

export const AntiPhishingSurface = surface(
  {
    name: 'AntiPhishing',
    paramsSchema: z.object({}),
    getTitle: () => i18n.t('surface.profile.security.antiphishing.form_title'),
    headerShown: true,
  },
  () => {
    return <VariableLayout PrimaryContent={<AntiPhishingSurfaceContent />} />;
  }
);
