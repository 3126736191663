import * as React from 'react';
import {Image} from 'react-native';
import {warning} from '@youtoken/ui.utils';
import {QuestionOutlinedIcon} from '../__generated/Icon/QuestionOutlined';
import {iconSet} from './set';
import {type IconName, type IconProps} from './types';

export {type IconName};

export function LogoColored({name, size = 32, ...props}: IconProps) {
  const isIconPresentInSet = Boolean(iconSet[name]);

  warning(
    isIconPresentInSet,
    `LogoColored: icon with name="${name}" was not found!`,
    {name}
  );

  if (isIconPresentInSet) {
    return (
      <Image
        source={iconSet[name]}
        resizeMode="cover"
        style={{
          width: size,
          height: size,
          resizeMode: 'cover',
        }}
        {...props}
      />
    );
  }

  return <QuestionOutlinedIcon size={size} {...props} />;
}
