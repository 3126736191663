import {
  observable,
  computed,
  reaction,
  type IReactionDisposer,
  action,
} from 'mobx';
import {debounce} from 'lodash';
import {Form} from './Form';
import {Calculated} from './Calculated';
import {formatPercentTillPrecision, toBig} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';
import type {
  LoanRepayOwnFundsFormArgs,
  LoanRepayOwnFundsFormResources,
} from '../../types';

export class LoanRepayOwnFundsFormV2State {
  @observable
  public form: Form;

  @observable
  public calculated: Calculated;

  @observable
  public disposers: IReactionDisposer[] = [];

  @computed
  public get loadingOrSubmitting() {
    return this.calculated.loading || this.form.submitting;
  }

  @computed
  public get ticker() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  public get conversionTicker() {
    return this.form.conversionTicker.toUpperCase();
  }

  @computed
  public get walletAmount() {
    return toBig(this.form.wallet?.amount)
      .round(getCoinDecimalPrecisionForLoans(this.form.ticker))
      .toString();
  }

  @computed
  public get overdraftAmount() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.loanOverdraftAmount)
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toString()
    );
  }

  @computed
  public get walletAmountFormatted() {
    return toBig(this.form.wallet?.amount)
      .round(getCoinDecimalPrecisionForLoans(this.form.ticker))
      .toFixedWithSeparators();
  }

  @computed
  public get overdraftAmountFormatted() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.loanOverdraftAmount)
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toFixedWithSeparators()
    );
  }

  @computed
  public get conversionAmount() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.conversionAmount)
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toFixedWithSeparators()
    );
  }

  @computed
  public get feeAmount() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.fee)
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toFixedWithSeparators()
    );
  }

  @computed
  public get repaid() {
    return Boolean(this.calculated.data?.repaid);
  }

  @computed
  public get ltv() {
    return formatPercentTillPrecision(this.calculated.data?.ltv, 4);
  }

  @computed
  public get mcPercent() {
    if (this.calculated.data?.mcPrice) {
      return formatPercentTillPrecision(
        toBig(-1).plus(
          toBig(this.calculated.data.mcPrice).div(this.form.loan.initialPrice)
        ),
        2
      );
    }
    return '0';
  }

  @computed
  public get mcPrice() {
    return (
      this.calculated.data?.borrowedTicker &&
      toBig(this.calculated.data?.mcPrice)
        .round(
          getCoinDecimalPrecisionForLoans(this.calculated.data?.borrowedTicker)
        )
        .toString()
    );
  }

  @computed
  public get mcPriceFormatted() {
    return (
      this.calculated.data?.borrowedTicker &&
      toBig(this.calculated.data?.mcPrice)
        .round(
          getCoinDecimalPrecisionForLoans(this.calculated.data?.borrowedTicker)
        )
        .toFixedWithSeparators()
    );
  }

  constructor(
    args: LoanRepayOwnFundsFormArgs,
    resources: LoanRepayOwnFundsFormResources
  ) {
    this.form = new Form(args, resources);
    this.calculated = new Calculated(this.form);
    this.disposers = [
      reaction(
        () => {
          return this.form.amount;
        },
        debounce(() => {
          this.calculated.load();
        }, 300),
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  @action
  public dispose = () => {
    this.disposers.forEach(disposer => disposer?.());
  };
}
