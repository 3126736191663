import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps, TouchableText} from '@youtoken/ui.primitives';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {useForm, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {coinNames} from '@youtoken/ui.coin-utils';
import {TextInput} from '@youtoken/ui.inputs';
import {Trans} from '@youtoken/ui.elements';
import {CardWithdrawalForm} from '../../state';

export const WithdrawSumField: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();
  const {
    form: {
      ticker,
      onSumChange,
      hasSumError,
      sumError,
      setAllAmountToWithdraw,
      allSourceAmountFormatted,
    },
    view: {
      tickerFormatted,
      sumValueFormatted,
      minAmountFormatted,
      maxAmountFormatted,
    },
  } = useForm<CardWithdrawalForm>();

  const TransTouchableWrapper = React.useCallback(
    ({children}: {children: React.ReactNode}) => {
      return (
        <TouchableText
          variant="$body-02"
          color="$text-05"
          onPress={setAllAmountToWithdraw}
        >
          {children}
        </TouchableText>
      );
    },
    [setAllAmountToWithdraw]
  );

  return (
    <Box {...props}>
      <Box mb={8} flexDirection="row" justifyContent="space-between">
        <Text variant="$body-01-high-accent">
          {t('surface.apple_pay.deposit.components.sum_field.amount')}
        </Text>
        <Trans
          i18nKey="surface.hodls.hodl_form.amount.action.all_source"
          components={{
            Bold: <Text variant="$body-02-medium-accent" />,
          }}
          values={{
            amount: allSourceAmountFormatted,
            ticker: tickerFormatted,
          }}
          parent={TransTouchableWrapper}
        />
      </Box>

      <TextInput
        numeric
        value={sumValueFormatted}
        onChangeText={onSumChange}
        setCaretPositionToEndOnFocus
        keyboardType="decimal-pad"
        placeholder="0.00"
        LeftPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            pl={15}
          >
            <LogoColored name={ticker as LogoColoredIconName} size={20} />

            <Text ml={8} variant="$body-01-medium-accent">
              {coinNames[ticker]}
            </Text>
          </Box>
        }
        RightPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            ml={3}
            pr={15}
          >
            <Text color="$text-02">{tickerFormatted}</Text>
          </Box>
        }
        textAlign="right"
        hasError={hasSumError}
        testID="WITHDRAW_SUM_INPUT"
      />
      <FieldErrorMessage visible={hasSumError} placement="right">
        {getTranslatedValidationMessage(sumError)}
      </FieldErrorMessage>
      <Text mt={8} variant="$body-02" color="$text-02">
        {t('surface.bank_cards.deposit.limit.min', {
          amount: minAmountFormatted,
          ticker: tickerFormatted,
        })}
      </Text>
      <Text variant="$body-02" color="$text-02">
        {t('surface.bank_cards.deposit.limit.max', {
          amount: maxAmountFormatted,
          ticker: tickerFormatted,
        })}
      </Text>
    </Box>
  );
});
