import * as React from 'react';
import {
  type DepositNowWizardProps,
  DepositNowWizard,
} from '@youtoken/ui.surfaces-wallets';
import {modal} from '../../../stores';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';

export const modalDepositNowWizardName = 'depositWizard';

type ModalDepositWizardProps = Omit<DepositNowWizardProps, 'onPressBack'> &
  Omit<ModalDynamicProps, 'children'>;

export const ModalDepositWizard = ({source}: ModalDepositWizardProps) => {
  const {opened, close} = modal;

  return (
    <ModalDynamic
      isOpen={opened?.name === modalDepositNowWizardName}
      onClose={close}
      webOverlayBackgroundColor="$transparent"
      webMinHeight={150}
    >
      <DepositNowWizard source={source} onPressBack={close} />
    </ModalDynamic>
  );
};
