import {Big} from 'big.js';
import {toBig} from '../number';
import {invariant} from '@youtoken/ui.utils';

type WalletsTickers = {
  fiatTickers: string[];
  stableTickers: string[];
  cryptoTickers: string[];
};

export type TLockedPair = {
  baseTicker: string;
  quoteTicker: string;
};

const getCurrencyType = (walletsResource: WalletsTickers, ticker: string) => {
  if (walletsResource.fiatTickers.includes(ticker)) {
    return 'fiat';
  }
  if (walletsResource.stableTickers.includes(ticker)) {
    return 'stable';
  }
  if (walletsResource.cryptoTickers.includes(ticker)) {
    return 'crypto';
  }

  invariant(false, `Ticker is not found in walletsResource: ${ticker}`);
};

export const getLockedPair = (
  walletsResourceTickers: WalletsTickers,
  ticker: string,
  conversionTicker: string
): TLockedPair => {
  const typeA = getCurrencyType(walletsResourceTickers, ticker);
  const typeB = getCurrencyType(walletsResourceTickers, conversionTicker);

  // NOTE: nothing to revert
  if (typeA === typeB) {
    return {baseTicker: ticker, quoteTicker: conversionTicker};
  }

  // NOTE: crypto is priority to be the baseTicker, then - stable
  if (typeA === 'crypto' || (typeA === 'stable' && typeB === 'fiat')) {
    return {baseTicker: ticker, quoteTicker: conversionTicker};
  }

  // NOTE: if not the case from above - revert
  return {baseTicker: conversionTicker, quoteTicker: ticker};
};

export const reverseRate = (rate: Big) => {
  return rate.eq(0) ? toBig(0) : toBig(1).div(rate);
};
