import * as React from 'react';
import {
  Box,
  useIsDesktop,
  useIsMediumDesktopAndUp,
} from '@youtoken/ui.primitives';
import {useSurfaceOptions} from '../../Surface';

const TOP_PADDING = 24;

// NOTE: New design
// const TOP_PADDING = {
//   default: 0,
//   desktop: 24,
//   mediumDesktop: 32,
//   largeDesktop: 40,
// };

export const ContentArea: React.FC = ({children}) => {
  const isDesktop = useIsDesktop();
  const isMediumDesktop = useIsMediumDesktopAndUp();
  const {options} = useSurfaceOptions();
  const menuHeight = isMediumDesktop ? 64 : 56;

  return (
    <Box
      flex={1}
      minHeight={`calc(100vh - ${menuHeight}px)`}
      // NOTE: New design
      // px={isDesktop ? 24 : 0}
      pt={options.headerShown ? TOP_PADDING : 0}
    >
      {children}
    </Box>
  );
};
