import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';

export const BundleTickersListInfo: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box testID="BUNDLE_TICKERS_LIST_INFO">
      <Text variant="$body-02" color="$text-01">
        {t('surface.bundle.tickers_info')}
      </Text>
    </Box>
  );
});
