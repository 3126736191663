import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import {webAppAdapter} from '@youtoken/ui.screen-components';
import {MinerSurface} from '@youtoken/ui.surface-miner';
import {InnerPageShell} from '../layout/Shell';

const MinerPage = webAppAdapter(MinerSurface);

export const MinerRoutes: React.FC<RouteComponentProps> = authRequired(
  ({match: {path}}) => {
    return (
      <InnerPageShell>
        <SwitchWithNotFound>
          <Route exact path={path} component={MinerPage} />
        </SwitchWithNotFound>
      </InnerPageShell>
    );
  }
);
