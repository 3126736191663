import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, YStack} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalFormState} from '../types';
import {CryptoWithdrawalExternalAgree} from './';

export const CryptoWithdrawalExternalFooter: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    walletTickerFormatted,
    walletTicker,
    receiveFormatted,
    feeStringFormated,
    submitting,
    submit,
  } = useForm<CryptoWithdrawalFormState>();

  return (
    <Box p={24}>
      <YStack>
        <YStack>
          <Text variant="$body-01" lineHeight={28} color="$text-02">
            <Text variant="$heading-01" color="$text-01">
              {receiveFormatted}
            </Text>{' '}
            {walletTickerFormatted} {/* this is special case for bitcoin */}
            {/* see: WL-1984  BTC blockchain fee add info - https://yhdl.slack.com/archives/D02HPNYTTS9/p1703844171986399 */}
            {walletTicker === 'btc'
              ? t('surface.wallets.crypto_withdrawal.to_send')
              : t('surface.wallets.crypto_withdrawal.to_receive')}
          </Text>
        </YStack>
        <YStack>
          <Text variant="$body-02" color="$text-02">
            {feeStringFormated}
          </Text>
        </YStack>
      </YStack>

      <CryptoWithdrawalExternalAgree pt={16} />

      <Box pt={16}>
        <Button
          size="large"
          width="100%"
          loading={submitting}
          onPress={submit}
          testID="WITHDRAW_BUTTON"
        >
          {t('surface.wallets.crypto_withdrawal.submit')}
        </Button>
      </Box>
    </Box>
  );
});
