import {z} from 'zod';
import * as React from 'react';
import {useParams} from 'react-router';
import {YStack} from '@youtoken/ui.primitives';
import type {SurfaceDefinition} from '../../types';
import {SurfaceParamsProvider} from '../../SurfaceParamsProvider';
import {SurfaceOptionsProvider} from '../../SurfaceOptionsProvider';
import {NavHeader} from '../../../Layout/WebLayout/NavHeader';
import {ContentArea} from '../../../Layout/WebLayout/ContentArea';

export function webAppAdapter<
  Name extends string,
  ParamsSchema extends z.ZodObject<z.ZodRawShape>
>(
  surface: SurfaceDefinition<Name, ParamsSchema>
): React.ComponentType & {surfaceName: Name} {
  const {component, name, ...options} = surface;

  function Component() {
    const params = useParams<z.infer<ParamsSchema>>();

    return (
      <SurfaceParamsProvider params={params}>
        <SurfaceOptionsProvider options={options}>
          <NavHeader />
          <ContentArea>
            <YStack gap="$gap-l" flex={1}>
              <surface.component {...params} />
            </YStack>
          </ContentArea>
        </SurfaceOptionsProvider>
      </SurfaceParamsProvider>
    );
  }

  Component.displayName = `webAppAdapter<(${surface.name})>`;

  Component.surfaceName = surface.name;

  return Component;
}
