import * as React from 'react';
import {Platform} from 'react-native';
import {useWizard} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HeaderBase} from '../../../components';
import type {ConvertWizardParams, ConvertWizardStepConvertProps} from '..';
import {AutoConvertForm} from '../../AutoConvertForm';

const isNative = Platform.select({
  default: false,
  native: true,
});

export const AutoConvertStep = () => {
  const {
    step: {
      props,
      props: {fromTicker, toTicker, isDesiredRateReversed, onSuccessNative},
    },
    navigate,
  } = useWizard<ConvertWizardParams, 'AutoConvert'>();

  const {t} = useTranslation();
  const convertProps = {
    ...props,
    fromTicker: isDesiredRateReversed ? toTicker : fromTicker,
    toTicker: isDesiredRateReversed ? fromTicker : toTicker,
  };

  const navigateToConvert = React.useCallback(
    (props: ConvertWizardStepConvertProps) => {
      isNative && onSuccessNative?.(props.desiredRate ?? '');
      navigate('Convert', {
        ...props,
      });
    },
    [navigate, onSuccessNative, isNative]
  );

  // NOTE: return to the previous step without the props change
  const handlePressBack = React.useCallback(() => {
    navigateToConvert(convertProps);
  }, [navigateToConvert, convertProps]);

  return (
    <>
      {!isNative && (
        <HeaderBase shouldShowSeparator onPressBack={handlePressBack}>
          {t('surface.auto_convert.title')}
        </HeaderBase>
      )}
      <AutoConvertForm {...props} onSuccess={navigateToConvert} />
    </>
  );
};
