import {computed, observable} from 'mobx';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {getInputTickerOptions} from '../../../utils';
import {Form} from './Form';

export class View {
  @observable
  public form: Form;

  constructor(form: Form) {
    this.form = form;
  }

  @computed.struct get tickersOptions() {
    return getInputTickerOptions(
      this.form.resources.wallets.walletsListOrderedByEquivalentAmount
    );
  }

  @computed get ticker() {
    return this.form.instance.$('ticker').value;
  }

  @computed get umaDomainFormatted() {
    return this.form.resources.umaInfo.umaDomainFormatted;
  }

  @computed get umaErrorFormatted(): string {
    return getTranslatedValidationMessage(this.form.instance.$('uma').error);
  }
}
