import * as React from 'react';
import {BundleTariffOverviewResource} from '@youtoken/ui.resource-bundles';
import {cell} from '@youtoken/ui.cell';
import {type ModalDynamicProps, ModalDynamic} from '@youtoken/ui.modal';
import {
  type BundleWithdrawalFormProps,
  getBundleWithdrawalFormTitle,
  BundleWithdrawalForm,
} from '../../forms';

export const BundleWithdrawalModal = cell<
  BundleWithdrawalFormProps & ModalDynamicProps
>(({id, onClose}) => {
  const {
    data: {
      tariff: {
        design: {title},
      },
    },
  } = BundleTariffOverviewResource.use({
    id,
  });

  return (
    <ModalDynamic
      testID="BUNDLE_WITHDRAWAL_MODAL"
      isOpen
      title={getBundleWithdrawalFormTitle(title)}
      onClose={onClose}
      webMinHeight={650}
      webMaxHeight="90vh"
    >
      <BundleWithdrawalForm id={id} onClose={onClose} />
    </ModalDynamic>
  );
});
