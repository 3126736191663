import * as React from 'react';
import {TKey} from '@youtoken/ui.service-i18n';
import {ExchangeFormReverseButton} from '../ReverseButton';
import {ExchangeFormConversionPairSource} from './Source';
import {ExchangeFormConversionPairTarget} from './Target';
import {Box} from '@youtoken/ui.primitives';

export {ExchangeFormConversionPairField} from './Field';
export {ExchangeFormConversionPairSource} from './Source';

export interface ExchangeFormConversionPairProps {
  showBalance?: boolean;
  targetLabelKey?: TKey;
  targetAmountEditable?: boolean;
  initialAmount?: string;
  withFastHandlers?: boolean;
  testIdSourceInput?: string;
  testIdTargetInput?: string;
  testIdReverseButton?: string;
}

export const ExchangeFormConversionPair: React.FC<
  ExchangeFormConversionPairProps
> = ({
  showBalance,
  targetLabelKey,
  targetAmountEditable,
  initialAmount,
  withFastHandlers,
  testIdSourceInput,
  testIdTargetInput,
  testIdReverseButton,
}) => {
  return (
    <Box zIndex={1}>
      <ExchangeFormConversionPairSource
        withFastHandlers={withFastHandlers}
        testID={testIdSourceInput}
      />
      <ExchangeFormReverseButton testID={testIdReverseButton} />
      <ExchangeFormConversionPairTarget
        labelKey={targetLabelKey}
        amountEditable={targetAmountEditable}
        testID={testIdTargetInput}
      />
    </Box>
  );
};
