import {primitive, serializable, date, object} from 'serializr';
import {computed, observable} from 'mobx';
import {Big} from 'big.js';
import {bigNumber, number} from '@youtoken/ui.utils-serializr';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {formatWithSeparators} from '@youtoken/ui.formatting-utils';

/**
 * For documentation check:
 * https://youhodler.atlassian.net/wiki/spaces/AL/pages/3589603331/UMA+FE+API#POST-%2Fuma%2Finvoice-request--%E2%80%93-%D0%9E%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BF%D0%BB%D0%B0%D1%82%D1%91%D0%B6%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D0%B0-%D0%B4%D0%BB%D1%8F-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B8%D0%BD%D0%B2%D0%BE%D0%B9%D1%81%D0%B0
 **/

export class UserData {
  @serializable(primitive())
  ticker!: string;

  @serializable(bigNumber())
  amount!: Big;
}

export class UmaInvoiceResponse {
  @observable
  @serializable(primitive())
  invoiceRequestId!: string;

  @observable
  @serializable(primitive())
  invoiceHash!: string;

  @observable
  @serializable(number())
  fee!: number;

  @observable
  @serializable(number())
  rate!: number;

  // The total amount the sender will pay
  @observable
  @serializable(number())
  totalAmount!: number;

  // The expiration time in sec
  @observable
  @serializable(date())
  expiresAtTimestamp!: Date;

  @observable
  @serializable(object(UserData))
  receiver!: UserData;

  @observable
  @serializable(object(UserData))
  sender!: UserData;

  @computed
  public get amountToReceiveFormatted() {
    const precision = getCoinDecimalPrecision(this.receiver.ticker);

    return this.receiver.amount.toFixedWithSeparators(precision);
  }

  @computed
  public get totalAmountFormatted() {
    return formatWithSeparators(this.totalAmount);
  }

  @computed
  public get convertedFromTickerFormatted() {
    return this.sender.ticker.toUpperCase();
  }

  @computed
  public get convertedToTickerFormatted() {
    return this.receiver.ticker.toUpperCase();
  }

  @computed
  public get expirationInterval() {
    const d = new Date();

    // NOTE: 10000 - because timer should end 10 seconds before the actual expiration time
    // @ts-ignore we could do this
    return (this.expiresAtTimestamp - d - 10000) / 1000;
  }

  @computed
  public get rateFormatted() {
    return formatWithSeparators(this.rate);
  }
}
