import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanReopenFeature} from './Feature';

export type OverviewTableProps = {
  loanId: string;
};

export const OverviewTable: React.FC<OverviewTableProps & BoxProps> = observer(
  ({loanId, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      borrowedTickerFormatted,
      overdraftDiff,
      reopenFeeFormatted,
      reopenFeeAmount,
      hasPaymentsForExtend,
    } = LoanReopenFeature.use({id: loanId});

    if (!hasPaymentsForExtend) {
      return null;
    }

    return (
      <Box {...boxProps}>
        <Box flexDirection="row" mb={16}>
          <Text variant="$body-01-high-accent">
            {t('surface.loans.reopen_form.extend_payment')}
          </Text>
        </Box>

        <Box flexDirection="column">
          {Boolean(+reopenFeeAmount) && (
            <InformationTableRow
              left={t('surface.loans.reopen_form.service_fee')}
              right={
                <Box flexDirection="row">
                  <Text variant="$body-02" color="$text-02">
                    {' '}
                    {reopenFeeFormatted}%{' '}
                  </Text>
                  <Text variant="$body-02">{reopenFeeAmount} </Text>
                  <Text variant="$body-02" color="$text-02">
                    {borrowedTickerFormatted}
                  </Text>
                </Box>
              }
              mb={Boolean(+overdraftDiff) ? 8 : 0}
            />
          )}
          {Boolean(+overdraftDiff) && (
            <InformationTableRow
              left={t('surface.loans.reopen_form.loan_debt')}
              leftTooltipText={t('surface.loans.reopen_form.loan_debt.tooltip')}
              right={
                <Box flexDirection="row">
                  <Text variant="$body-02"> {overdraftDiff} </Text>
                  <Text variant="$body-02" color="$text-02">
                    {borrowedTickerFormatted}
                  </Text>
                </Box>
              }
              isLast
            />
          )}
        </Box>
      </Box>
    );
  }
);
