import {computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {Version, WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {FeeAllResource} from '@youtoken/ui.resources-fee';
import {invariant} from '@youtoken/ui.utils';

export class CryptoDirectDepositChooseNetworkFeature extends createFeature({
  getKey: (args: {ticker: string}) =>
    `CryptoDirectDepositChooseNetworkFeature:${JSON.stringify(args)}`,
  getResources: () => {
    return {
      wallet: getResourceDescriptor(WalletsResource, {}),
      feeAll: getResourceDescriptor(FeeAllResource, {}),
      authMe: getResourceDescriptor(AuthMeResource, {}),
      rate: getResourceDescriptor(RatesResource, {}),
    };
  },
}) {
  @computed
  private get ticker() {
    return this.args.ticker;
  }

  @computed.struct
  private get networksListByTicker(): Version[] | undefined {
    return this.resources.wallet.getByTicker(this.ticker)?.versions;
  }

  @computed.struct
  public get networksListFormatted() {
    invariant(
      this.networksListByTicker,
      `Cannot get networks list for the ${this.args} ticker`
    );

    return this.networksListByTicker.map(network => {
      return {
        networkVersion: network.version,
        titleFormatted: network.name,
      };
    });
  }
}
