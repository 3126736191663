import React from 'react';
import {observer} from 'mobx-react';
import {PageSwitcher, PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export type HodlTabSwitcherProps = {
  page: PageSwitcherValue;
};

export const HodlTabSwitcher: React.FC<HodlTabSwitcherProps & BoxProps> =
  observer(({page, ...boxProps}) => {
    const handleChangePage = React.useCallback((value: PageSwitcherValue) => {
      if (value === PageSwitcherValue.PORTFOLIO) {
        SHARED_ROUTER_SERVICE.navigate('MultiHODLPortfolio');
      } else {
        SHARED_ROUTER_SERVICE.navigate('MultiHODLInstruments');
      }
    }, []);

    return (
      <Box {...boxProps}>
        <PageSwitcher activeValue={page} onChoose={handleChangePage} />
      </Box>
    );
  });
