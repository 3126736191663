import {comparer, computed} from 'mobx';
import {tickerToComboboxItem} from './utils';
import {ViewBase} from '../ViewBase';
import {FormUnauthorizedV1, FormUnauthorizedV2} from '../.';

export class View extends ViewBase<FormUnauthorizedV1 | FormUnauthorizedV2> {
  //#region wallets

  @computed({
    equals: comparer.shallow,
  })
  get wallets() {
    return this.form.tickers.map(ticker => {
      return tickerToComboboxItem(ticker);
    });
  }

  //#endregion wallets

  //#region conversionWallets

  @computed({
    equals: comparer.shallow,
  })
  get conversionWallets() {
    return this.form.conversionTickers.map(ticker => {
      return tickerToComboboxItem(ticker);
    });
  }

  //#endregion conversionWallets

  public constructor(form: FormUnauthorizedV1 | FormUnauthorizedV2) {
    super(form);
  }
}
