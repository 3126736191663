import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {ClickableId} from '@youtoken/ui.elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {InformationTableRightElement} from '@youtoken/ui.hodl-elements';

interface DataTableProps {
  hodl: HODLItem;
}

export const InfoTableSection: React.FC<DataTableProps> = observer(
  ({hodl: item}) => {
    const {t} = useTranslation();

    return (
      <Box px={24}>
        <InformationTable py={16}>
          {item.tableRowStatus.marginCallLevelIsActive && (
            <InformationTableRow
              left={item.marginCallNameTranslated}
              leftTooltipText={item.marginCallTooltipTranslated}
              right={
                <InformationTableRightElement
                  mainText={item.marginCallFormatted}
                  additionalText={item.pricePair}
                  additionalTextId="HODL_INFO_TABLE_TICKERS_PAIR"
                />
              }
            />
          )}

          {item.tableRowStatus.takeProfitLevelIsActive && (
            <InformationTableRow
              left={item.takeProfitNameTranslated}
              leftTooltipText={item.takeProfitTooltipTranslated}
              right={
                <InformationTableRightElement
                  mainText={item.takeProfitFormatted}
                  additionalText={item.pricePair}
                />
              }
            />
          )}

          {item.tableRowStatus.initialPriceIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.initial_price')}
              right={
                <InformationTableRightElement
                  mainText={item.initialPriceFormatted}
                  additionalText={item.pricePair}
                />
              }
            />
          )}

          {item.tableRowStatus.triggerPriceIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.trigger_price')}
              leftTooltipText={t(
                'surface.hodls.item.details_table.trigger_price_tooltip'
              )}
              right={
                <InformationTableRightElement
                  mainText={item.triggerPriceFormatted}
                  additionalText={item.pricePair}
                />
              }
            />
          )}

          {item.tableRowStatus.closedPriceIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.closed_price')}
              right={
                <InformationTableRightElement
                  mainText={item.closedPriceFormatted}
                  additionalText={item.pricePair}
                />
              }
            />
          )}

          <InformationTableRow
            left={t('surface.hodls.item.details_table.initial_amount')}
            right={
              <InformationTableRightElement
                mainText={item.inputAmountFormatted}
                additionalText={item.inputTickerFormatted}
              />
            }
          />

          {item.tableRowStatus.additionalInitialAmountIsActive && (
            <InformationTableRow
              left={t(
                item.isClosedLike
                  ? 'surface.hodls.item.details_table.initial_bonus_amount'
                  : 'surface.hodls.item.details_table.used_bonuses'
              )}
              leftTooltipText={t(
                item.isClosedLike
                  ? 'surface.hodls.item.details_table.initial_bonus_amount.tooltip'
                  : 'surface.hodls.item.details_table.used_bonuses.tooltip'
              )}
              right={
                <InformationTableRightElement
                  mainText={item.additionalInputAmountFormatted}
                  additionalText={item.additionalInputTickerUI}
                />
              }
            />
          )}

          {item.tableRowStatus.totalCollateralIsActive && (
            <InformationTableRow
              left={t(
                item.isPendingLike
                  ? 'surface.hodls.item.details_table.pending_collateral'
                  : 'surface.hodls.item.details_table.total_collateral'
              )}
              leftTooltipText={
                item.isPendingLike
                  ? t(
                      'surface.hodls.item.details_table.pending_collateral_tooltip'
                    )
                  : undefined
              }
              right={
                <InformationTableRightElement
                  mainText={item.totalAmountFormatted}
                  additionalText={item.collateralTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.profitLosstIsActive && (
            <InformationTableRow
              left={t(
                `surface.hodls.item.details_table.profit_loss.${item.profitLossText}`
              )}
              leftTooltipText={t(
                'surface.hodls.item.details_table.profit_loss_tooltip'
              )}
              right={
                <InformationTableRightElement
                  mainText={item.profitLossAmount}
                  additionalText={item.outputTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.conversionPLIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.conversion_pl')}
              leftTooltipText={t(
                'surface.hodls.item.details_table.conversion_pl_tooltip'
              )}
              right={
                <InformationTableRightElement
                  mainText={item.conversionPLAmount!}
                  additionalText={item.inputTickerFormatted}
                />
              }
            />
          )}

          {item.tableRowStatus.outputAmountIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.output_amount')}
              leftTooltipText={t(
                'surface.hodls.item.details_table.output_amount_tooltip'
              )}
              right={
                <InformationTableRightElement
                  mainText={item.closedOutputAmountFormatted}
                  additionalText={item.outputTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.additionalOutputAmountIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.returned_bonuses')}
              leftTooltipText={t(
                'surface.hodls.item.details_table.returned_bonuses.tooltip'
              )}
              right={
                <InformationTableRightElement
                  mainText={item.additionalOutputAmountFormatted!}
                  additionalText={item.additionalInputTickerUI}
                />
              }
            />
          )}

          {item.tableRowStatus.totalBorrowedIsActive && (
            <InformationTableRow
              left={t(
                item.isPendingLike
                  ? 'surface.hodls.item.details_table.pending_borrowed'
                  : 'surface.hodls.item.details_table.total_borrowed'
              )}
              leftTooltipText={t(
                item.isPendingLike
                  ? 'surface.hodls.item.details_table.pending_borrowed_tooltip'
                  : 'surface.hodls.item.details_table.total_borrowed_tooltip'
              )}
              right={
                <InformationTableRightElement
                  mainText={item.borrowedAmountFormatted}
                  additionalText={item.borrowedTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.legacyFeeIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.legacy_fee', {
                percent: item.closeFeePercent,
                period: item.settlementPeriod,
              })}
              right={
                <InformationTableRightElement
                  preInfoText={`${item.closeFeePercent}%, ${item.settlementPeriod}`}
                  mainText={item.legacyFeeAmount}
                  additionalText={item.outputTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.marginCallFeeIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.margin_call_fee')}
              right={
                <InformationTableRightElement
                  preInfoText={`${item.marginCallFeePercent}%`}
                  mainText={item.marginCallFeeAmount}
                  additionalText={item.borrowedTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.originationIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.origination_fee')}
              right={
                <InformationTableRightElement
                  preInfoText={`${item.openingFeePercent}%`}
                  mainText={item.openingFeeAmount}
                  additionalText={item.borrowedTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.settlementIsActive && (
            <>
              <InformationTableRow
                left={t('surface.hodls.item.details_table.rollover_fee')}
                leftTooltipText={t(
                  'surface.hodls.item.details_table.rollover_fee_tooltip'
                )}
                right={
                  <InformationTableRightElement
                    mainText={item.settlementFeeAmountPerPeriod}
                    additionalText={item.borrowedTickerUpperCase}
                    postInfoText={`(${item.settlementFeePercent}%) ${t(
                      'surface.hodls.common.per_period',
                      {
                        period: item.settlementPeriod,
                      }
                    )}`}
                  />
                }
              />

              <InformationTableRow
                left={t('surface.hodls.item.details_table.rollover_fee_total')}
                right={
                  <InformationTableRightElement
                    mainText={item.settlementFeeAmount}
                    additionalText={item.borrowedTickerUpperCase}
                    postInfoText={item.settlementFeeIterationsDaysAndHours}
                  />
                }
              />
            </>
          )}

          {item.tableRowStatus.hftFeeIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.quick_close_fee')}
              right={
                <InformationTableRightElement
                  mainText={item.hftFeeAmountFormatted}
                  additionalText={item.hftFeeAmountTicker}
                />
              }
            />
          )}

          {item.tableRowStatus.revShareIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.rev_share_fee')}
              right={
                <InformationTableRightElement
                  preInfoText={`${item.revShareFeePercent}%`}
                  mainText={`≈ ${item.revShareFeeAmount}`}
                  additionalText={item.outputTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.unifiedFeeIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.unified_fee')}
              leftTooltipText={t(
                'surface.hodls.item.details_table.unified_fee_tooltip'
              )}
              right={
                <InformationTableRightElement
                  preInfoText={`${item.unifiedFeePercent}%`}
                  mainText={`≈ ${item.unifiedFeeAmount}`}
                  additionalText={item.borrowedTickerUpperCase}
                />
              }
            />
          )}

          {item.tableRowStatus.hasTradingVolume && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.trading_volume')}
              right={
                <InformationTableRightElement
                  mainText={item.tradingVolumeUsdFormatted}
                  // NOTE: It's always USD for now
                  additionalText="USD"
                />
              }
            />
          )}

          <InformationTableRow
            left={t(
              `surface.hodls.item.details_table.created_or_started.${item.createdOrStartedAtText}`
            )}
            right={
              <Text variant="$body-02" color="$text-02">
                {item.formattedStartDate}
              </Text>
            }
          />

          {item.tableRowStatus.expirationOrFinishedIsActive && (
            <InformationTableRow
              left={item.expirationOrFinishedText}
              right={
                <Text variant="$body-02" color="$text-02">
                  {item.expirationOrFinishedDate}
                </Text>
              }
            />
          )}

          {item.tableRowStatus.closedIsActive && (
            <InformationTableRow
              left={item.closedButtonTitle}
              right={
                <Text variant="$body-02" color="$text-02">
                  {item.formattedClosedDate}
                </Text>
              }
            />
          )}

          {item.tableRowStatus.expirationIsActive && (
            <InformationTableRow
              left={t('surface.hodls.item.details_table.expires_in')}
              right={
                <Text variant="$body-02" color="$text-02">
                  {`${item.hodlExpireAtDistanceFormatted} (${item.hodlExpireAtFormatted})`}
                </Text>
              }
            />
          )}

          <InformationTableRow
            left={t('surface.hodls.item.details_table.multihodl_id')}
            right={
              <ClickableId
                id={item.id}
                notificationKey="surface.hodls.item.id.message.copied"
                testID="HODL_INFO_TABLE_MH_ID"
              />
            }
            isLast
          />
        </InformationTable>
      </Box>
    );
  }
);
