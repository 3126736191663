import {uniq} from 'lodash';
import {Big} from 'big.js';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {getStringFromArray} from './getStringFromArray';

export const getFeeString = (
  fees: {percent?: Big; min?: Big}[],
  ticker: string,
  t: typeof i18n.t
) => {
  const percents = uniq(fees.map(fee => formatPercent(fee.percent))).map(
    Number
  );
  const minimums = uniq(fees.map(fee => Number(fee.min)))
    .filter(Boolean)
    .map(Number);

  const percentsString = getStringFromArray(percents, '%', t);
  const minimumsString = getStringFromArray(
    minimums,
    ` ${ticker.toUpperCase()}`,
    t
  );

  const feeString = [percentsString, minimumsString]
    .filter(i => i)
    .join(` ${t('surface.fee_string.template.or')} `);

  if (!feeString) {
    return '';
  }

  return t(
    'surface.fee_string.template',
    // @ts-ignore
    {feeString},
    t
  );
};
