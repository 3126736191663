import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import {webAppAdapter} from '@youtoken/ui.screen-components';
import {AccountStatsSurface} from '@youtoken/ui.surface-account-stats';
import {InnerPageShell} from '../layout/Shell';
import {AutoConvertListSurface} from '@youtoken/ui.surface-history-list';

const AccountStatsPage = webAppAdapter(AccountStatsSurface);
const AutoConvertsListPage = webAppAdapter(AutoConvertListSurface);

export const AccountStatsRoutes: React.FC<RouteComponentProps> = authRequired(
  ({match: {path}}) => {
    return (
      <InnerPageShell>
        <SwitchWithNotFound>
          <Route exact path={`${path}`} component={AccountStatsPage} />
          <Route
            path={`${path}/auto-converts`}
            component={AutoConvertsListPage}
          />
        </SwitchWithNotFound>
      </InnerPageShell>
    );
  }
);
