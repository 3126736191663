import {computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {CardDepositFormResources} from './types';
import {Form} from './Form';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';

export class View {
  @observable
  public instance: MobxReactForm;

  @observable
  public form: Form;

  @observable
  public resources: CardDepositFormResources;

  // NOTE: Card deposit is available only for fiat currencies for now
  amountData = ['100', '200', '500', '1,000'].map(value => ({
    label: value,
    amountBaseCurrency: value.replace(',', ''),
    amountEquivalent: value.replace(',', ''),
  }));

  public constructor(form: Form, resources: CardDepositFormResources) {
    this.resources = resources;
    this.form = form;
    this.instance = form.instance;
  }

  @computed
  get tickerFormatted() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  get sumValueFormatted(): string {
    return this.form.sumField.value;
  }

  @computed
  get conversionTickerFormatted() {
    return this.form.conversionTicker?.toUpperCase();
  }

  @computed
  public get minAmountFormatted(): string {
    return (
      this.form.tickerFees?.minAmount.toFixed(this.form.tickerPrecision) ?? ''
    );
  }

  @computed
  public get maxAmountFormatted(): string {
    return (
      this.form.tickerFees?.maxAmount.toFixed(this.form.tickerPrecision) ?? ''
    );
  }

  @computed
  public get feeValueFormatted(): string {
    if (this.form.conversionTicker) {
      return this.form.feeValueInConversionTicker.toFixed(
        this.form.conversionTickerPrecision
      );
    }
    return this.form.feeValue.toFixed(this.form.tickerPrecision, 0);
  }

  @computed
  public get feeMaxValueFormatted(): string {
    if (!this.form.isMaxCommissionEstablished) {
      return '';
    }

    return this.form.tickerFees?.max.toString() ?? '';
  }

  @computed
  public get feeMinValueFormatted(): string {
    if (!this.form.isMinCommissionEstablished) {
      return '';
    }

    return this.form.tickerFees?.min.toString() ?? '';
  }

  @computed
  public get feePercentFormatted(): string {
    return formatPercent(this.form.feePercent);
  }

  @computed
  public get receiveSumFormatted(): string {
    return this.form.sumValue
      .minus(this.feeValueFormatted)
      .toFixed(this.form.tickerPrecision);
  }

  @computed
  public get paySumFormatted(): string {
    if (this.form.conversionTicker) {
      return '~' + this.form.sumInConversionTickerWithFeeFormatted;
    }

    return this.form.sumValue
      .plus(this.feeValueFormatted)
      .toFixedWithSeparators(this.form.tickerPrecision, 0);
  }

  @computed
  public get incentivesBonusFormatted(): string {
    return this.form.incentivesBonus.toFixedWithSeparators(
      getCoinDecimalPrecision('bonus')
    );
  }
}
