import * as React from 'react';
import {observer} from 'mobx-react';
import {BundlePortfolioTickersListItem} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {BundleTickersHeader} from '..';
import {BundleTickersListItem} from '.';
import {BundleTickersListInfo} from './Info';

interface BundleTickersListProps {
  showHeader?: boolean;
  showInfo?: boolean;
  items: BundlePortfolioTickersListItem[];
}

const renderItem = (item: BundlePortfolioTickersListItem) => {
  return <BundleTickersListItem key={item.ticker} item={item} />;
};

export const BundleTickersList: React.FC<BundleTickersListProps & BoxProps> =
  observer(({showHeader = true, showInfo = true, items, ...boxProps}) => {
    if (!items.length) {
      return null;
    }

    return (
      <Box testID="BUNDLE_TICKERS_LIST" gap={8} {...boxProps}>
        <Box gap={4}>
          {showHeader && (
            <BundleTickersHeader variant="$body-01-high-accent" items={items} />
          )}
          {showInfo && <BundleTickersListInfo />}
        </Box>
        <Box>{items.map(renderItem)}</Box>
      </Box>
    );
  });
