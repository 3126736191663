import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic} from '@youtoken/ui.modal';
import {Button} from '@youtoken/ui.buttons';
import {Box} from '@youtoken/ui.primitives';
import {AutoConvertResource} from '@youtoken/ui.resource-auto-convert';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface AutoConvertDeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  id: string;
  ticker?: string;
}

export const AutoConvertDeleteConfirmationModal: React.FC<AutoConvertDeleteConfirmationModalProps> =
  cell(({isOpen, onClose, onDelete, id, ticker}) => {
    const {deleteAutoConvert, refetch} = AutoConvertResource.use({ticker});

    const handleDelete = React.useCallback(() => {
      deleteAutoConvert(id)
        .then(() => {
          refetch();
        })
        .then(onDelete);
    }, [deleteAutoConvert, id, onClose]);

    const {t} = useTranslation();

    return (
      <ModalDynamic
        isOpen={isOpen}
        onClose={onClose}
        title={t('surface.auto_convert_details.confimation.question')}
        minHeight={200}
      >
        <Box px={24} py={32}>
          <Button
            type="secondary"
            alignSelf="stretch"
            mb={16}
            onPress={handleDelete}
          >
            {t('surface.auto_convert_details.confimation.delete')}
          </Button>
          <Button type="secondary" alignSelf="stretch" onPress={onClose}>
            {t('surface.auto_convert_details.confimation.cancel')}
          </Button>
        </Box>
      </ModalDynamic>
    );
  });
