import {getSettlementFeeTickerComposite} from './getSettlementFeeTickerComposite';
import {HODLItem} from '@youtoken/ui.resource-hodl';
import {type IAbstractHowHODLWorks, type ILoanSequenceItem} from './types';
import {invariant} from '@youtoken/ui.utils';

export const getHodlDetailsData = (
  loansSequence: ILoanSequenceItem[],
  totalCollateralAmountFormatted: string,
  repaymentDueAmountFormatted: string,
  hodl: HODLItem
): IAbstractHowHODLWorks => {
  const {
    isBeforeKyc,
    inputAmountFormatted,
    _inputAmount,
    inputTickerFormatted,
    additionalInputAmountFormatted,
    additionalInputTickerUI,
    collateralTickerUpperCase,
    borrowedAmountFormatted,
    borrowedTickerUpperCase,
    settlementPeriod,
    settlementFeePercent,
    settlementFeeAmountPerPeriod,
  } = hodl;
  const hasConversion = Boolean(
    collateralTickerUpperCase !== inputTickerFormatted && loansSequence?.[0]
  );

  invariant(settlementPeriod, 'settlementPeriod is required');

  return {
    loansSequence: loansSequence,
    isBeforeKyc,
    isInputAmountPositive: _inputAmount.gt(0),
    inputAmountFormatted: inputAmountFormatted,
    inputTickerFormatted,
    hasConversion,
    additionalInputAmountFormatted,
    additionalInputTickerFormatted: additionalInputTickerUI,
    totalCollateralAmountFormatted: totalCollateralAmountFormatted,
    totalCollateralTickerFormatted: collateralTickerUpperCase,
    totalBorrowedAmountFormatted: borrowedAmountFormatted,
    totalBorrowedTickerFormatted: borrowedTickerUpperCase,
    settlementPeriodFormatted: settlementPeriod,
    settlementFeePercentFormatted: settlementFeePercent,
    rolloverAmountFormatted: settlementFeeAmountPerPeriod,
    rolloverTickerFormatted: getSettlementFeeTickerComposite(
      borrowedTickerUpperCase,
      settlementFeePercent,
      settlementPeriod
    ),
    repaymentDueAmountFormatted: repaymentDueAmountFormatted,
    repaymentDueTickerFormatted: borrowedTickerUpperCase,
  };
};
