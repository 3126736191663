import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {ManagePaymentMethodsCell} from '../../../CardPaymentMethods';
import {HeaderBase} from '../../../components';
import {
  CardDepositWizardNavigator,
  CardDepositWizardScreenName,
} from '../index';
import {Platform} from 'react-native';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CardDepositPaymentMethods: React.FC = observer(() => {
  const {t} = useTranslation();
  const {navigate, goBack} = useWizard<
    CardDepositWizardNavigator,
    CardDepositWizardScreenName.MAIN
  >();

  const handlePressAddCard = React.useCallback(() => {
    navigate(CardDepositWizardScreenName.ADD_CARD);
  }, [navigate]);

  return (
    <Box flex={1}>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {t('surface.wallets.payment_methods')}
        </HeaderBase>
      )}
      <ManagePaymentMethodsCell onAddCard={handlePressAddCard} />
    </Box>
  );
});
