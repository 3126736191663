import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const CryptoWithdrawIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M17.272 2.726a.5.5 0 0 1 .337.544l-.02.088-4.939 14.814a.5.5 0 0 1-.885.127l-.044-.078-2.931-6.448a.5.5 0 0 0-.17-.204l-.078-.045-6.449-2.93A.5.5 0 0 1 2.06 7.7l.083-.036 14.814-4.938a.5.5 0 0 1 .316 0M14.15 5.103 5.485 8.142a.25.25 0 0 0-.02.464l3.576 1.605zm1.06 1.06-5.108 5.109 1.606 3.577a.25.25 0 0 0 .464-.02z"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
