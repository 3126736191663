import {useTheme} from '@youtoken/ui.primitives';

export const useStyle = () => {
  const {colors} = useTheme();

  return `
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Ubuntu+Mono:wght@400;700&display=swap');
  body {
      font-family: -apple-system, BlinkMacSystemFont, Roboto, system-ui, sans-serif;
  font-weight: 400;
    padding: 0 20px;
    background-color: ${colors['$ui-background']};
    color: ${colors['$text-01']};
    font-size: 14px;
    line-height: 18px;
  }

  h1 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 1em;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 1em;
  }

  ol {
    padding-left: 0;
  }

  ol ol {
    padding-left: 12px;
  }

  ol ul {
    padding-left: 12px;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  li {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  a {
    color: ${colors['$text-02']};
    text-decoration: underline;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  a:visited {
    color: ${colors['$text-02']};
  }
`;
};
