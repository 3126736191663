import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {useSafeArea} from '@youtoken/ui.hooks';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

const handleRedirect = () => {
  SHARED_ROUTER_SERVICE.navigate('AccountStats');
};

export interface FirstAutoConvertModalContentProps {
  onPressBack: () => void;
}

export const FirstAutoConvertModalContent: React.FC<
  FirstAutoConvertModalContentProps & BoxProps
> = observer(({onPressBack, ...boxProps}) => {
  const {t} = useTranslation();
  const {bottom} = useSafeArea();
  const {setFirstAutoConvertModalShown} = AuthMeResource.use({});

  React.useEffect(() => {
    setFirstAutoConvertModalShown();
  }, []);

  return (
    <Box flex={1} px={24} pb={bottom} {...boxProps}>
      <Box flex={1} alignItems="center" justifyContent="center" my={40}>
        <Icon name="history" size={96} />
        <Box width={260}>
          <Heading variant="$heading-02" textAlign="center">
            {t('surface.first_auto_convert.text')}
          </Heading>
        </Box>
      </Box>
      <Box gap={16}>
        <Button type="secondary" size="large" onPress={handleRedirect}>
          {t('surface.first_auto_convert.action.to_transactions')}
        </Button>
        <Button type="secondary" size="large" onPress={onPressBack}>
          {t('common.actions.close')}
        </Button>
      </Box>
    </Box>
  );
});
