import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {
  HeaderBase,
  useWalletsChooseTickerItemWrapper,
  WalletsChooseTicker,
} from '../../../components';
import {
  type CryptoDepositWizardNavigator,
  CryptoDepositWizardScreenName,
} from '..';
import {Platform} from 'react-native';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CryptoDepositWizardChooseTicker: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    step: {
      props: {method},
    },
    navigate,
    goBack,
  } = useWizard<
    CryptoDepositWizardNavigator,
    CryptoDepositWizardScreenName.CHOOSE_TICKER
  >();

  const handlePress = React.useCallback(
    (ticker: string) => {
      DATA_LAYER.trackStrict('buy-wizard-currency-chosen', {
        type: 'crypto',
        ticker,
      });

      navigate(method, {ticker});
    },
    [navigate]
  );

  const TouchableWrapper = useWalletsChooseTickerItemWrapper(handlePress);

  return (
    <>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {t('surface.wallets.deposit_wizard.select_ticker_crypto.title')}
        </HeaderBase>
      )}
      <WalletsChooseTicker
        type="cryptoAndStable"
        method="deposit"
        ListItemWrapper={TouchableWrapper}
      />
    </>
  );
});
