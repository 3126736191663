import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {Switch} from '@youtoken/ui.elements';
import {useForm, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalFormState} from '../types';

export const CryptoWithdrawalExternalAgree: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      form: {agreeLabel, agree, agreeHasError, agreeError, agreeOnChange},
    } = useForm<CryptoWithdrawalFormState>();

    return (
      <Box {...boxProps}>
        <Box flexDirection="row">
          <Box flexGrow={1} flexShrink={1} mr={20}>
            <Text variant="$body-02" color="$text-02">
              {agreeLabel}
            </Text>
          </Box>
          <Box>
            <Switch
              value={agree}
              onValueChange={agreeOnChange}
              testID="CONFIRM_ADDRESS_SWITCHER"
              hasError={agreeHasError}
            />
          </Box>
        </Box>
        <FieldErrorMessage visible={agreeHasError}>
          {agreeError}
        </FieldErrorMessage>
      </Box>
    );
  }
);
